import { getInputOptsByBlockedField } from 'lib/utils'

export default {
  id: 'documentary',
  title: 'Documents',
  defaultValues: {
    FakeDocs: [],
    FraudDocumentary: { FakeDocs: [] },
  },
  validate: (form) => {
    const fraud = form.getValues()

    if (
      fraud &&
      fraud.FraudDocumentary &&
      (!fraud.FraudDocumentary.FakeDocs ||
        fraud.FraudDocumentary.FakeDocs.length === 0)
    ) {
      form.setError('FraudDocumentary.FakeDocs', {
        message: 'Saisir au moins un document frauduleux',
      })
      return false
    }

    return true
  },
  sections: [
    {
      title: null,
      rows: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'FraudDocumentary.id',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudDocumentary.blocked',
            defaultValue: 'false',
            label: 'Opération déjouée',
            whenYes: [
              [
                {
                  type: 'select',
                  name: 'FraudDocumentary.fraud_detection',
                  label: "Nom de l'outil",
                  placeholder: 'Choisir un moyen',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  values: [
                    {
                      label: 'Vialink',
                      value: 'Vialink',
                    },
                    {
                      label: 'Humain',
                      value: 'Humain',
                    },
                    {
                      label: 'Splunk',
                      value: 'Splunk',
                    },
                    {
                      label: 'Autres outils',
                      value: 'Autres outils',
                    },
                  ],
                  when: {
                    'Autres outils': [
                      [
                        {
                          name: 'FraudDocumentary.fraud_detection_tool',
                          placeholder: "Précisez l'outil",
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                        },
                      ],
                    ],
                  },
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'select',
            autofocus: true,
            name: 'FraudDocumentary.fake_doc_use',
            label: 'Utilisation du document frauduleux',
            placeholder: 'Choisir une utilisation',
            values: [
              {
                label: 'Crédit immobilier',
                value: 'Crédit immobilier',
              },
              {
                label: 'Crédit consommation',
                value: 'Crédit consommation',
              },
              {
                label: 'Crédit Equipement',
                value: 'Crédit Equipement',
              },
              {
                label: 'Crédit de fonctionnement',
                value: 'Crédit de fonctionnement',
              },
              {
                label: 'Épargne',
                value: 'Épargne',
              },
              {
                label: 'Assurance',
                value: 'Assurance',
              },
              {
                label: 'Epargne Salariale',
                value: 'Epargne Salariale',
              },
              {
                label: 'Ouverture de compte client',
                value: 'Ouverture de compte client',
              },
            ],
          },
          {
            type: 'select',
            name: 'FraudDocumentary.occurence',
            label: 'Survenance de la fraude',
            placeholder: 'Choisir...',
            values: [
              {
                label: "À l'entrée en relation",
                value: "À l'entrée en relation",
              },
              {
                label: 'Durant la vie du compte',
                value: 'Durant la vie du compte',
              },
            ],
          },
        ],
        [
          {
            type: 'array',
            name: 'FraudDocumentary.FakeDocs',
            options: (props) =>
              getInputOptsByBlockedField(props, {
                required: 'Ce champ est requis',
              }),
            label: 'Nature des documents frauduleux',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'textarea',
                  name: 'identity',
                  label: 'Identité inscrite sur le faux document',
                  helpText:
                    'Nom, prénom, date de naissance, SIREN, SIRET, dénomination',
                },
              ],
              [
                {
                  type: 'select',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  name: 'nature',
                  label: 'Nature des documents frauduleux',
                  values: [
                    {
                      label: 'Pouvoir',
                      value: 'Pouvoir',
                    },
                    {
                      label: 'Identité',
                      value: 'Identité',
                    },

                    {
                      label: 'Identité Personne Morale',
                      value: 'Identité Personne Morale',
                    },
                    {
                      label: 'Justificatif de domicile',
                      value: 'Justificatif de domicile',
                    },

                    {
                      label: 'Justificatif de domicile Société',
                      value: 'Justificatif de domicile Société',
                    },
                    {
                      label: 'Justificatif de revenu',
                      value: 'Justificatif de revenu',
                    },
                    {
                      label: 'Justificatif de revenu professionnel',
                      value: 'Justificatif de revenu professionnel',
                    },
                    {
                      label: 'Nomination',
                      value: 'Nomination',
                    },
                    {
                      label: 'Contrat de travail',
                      value: 'Contrat de travail',
                    },
                    {
                      label: 'Garantie',
                      value: 'Garantie',
                    },
                    {
                      label: 'Autres',
                      value: 'Autres',
                    },
                  ],
                  when: {
                    'Contrat de travail': [
                      [
                        {
                          type: 'select',
                          label: 'Détail du justificatif',
                          name: 'proof_detail',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Contrat de travail',
                              value: 'Contrat de travail',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    Garantie: [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          label: 'Détail du justificatif',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Garantie',
                              value: 'Garantie',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    Autres: [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          label: 'Détail du justificatif',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Autres',
                              value: 'Autres',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    Pouvoir: [
                      [
                        {
                          type: 'select',
                          label: 'Détail du justificatif',
                          name: 'proof_detail',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Délégation de pouvoirs',
                              value: 'Délégation de pouvoirs',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    Identité: [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          label: 'Détail du justificatif',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            { label: 'Passeport', value: 'Passeport' },
                            {
                              label: 'Carte de sejour / de resident',
                              value: 'Carte de sejour / de resident',
                            },
                            {
                              label: "Carte Nationale d'Identité périmée",
                              value: "Carte Nationale d'Identité périmée",
                            },
                            {
                              label: "Carte Nationale d'Identité",
                              value: "Carte Nationale d'Identité",
                            },
                            {
                              label: 'Permis de Conduire',
                              value: 'Permis de Conduire',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    'Identité Personne Morale': [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          placeholder: 'Choisir...',
                          label: 'Détail du justificatif',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Certif coutume Stes etrangeres',
                              value: 'Certif coutume Stes etrangeres',
                            },
                            {
                              label: 'Contrat regissant la Fiducie',
                              value: 'Contrat regissant la Fiducie',
                            },
                            {
                              label: 'Copie delib delivrant competenc',
                              value: 'Copie delib delivrant competenc',
                            },
                            {
                              label: 'Decret/arrete de creation',
                              value: 'Decret/arrete de creation',
                            },
                            {
                              label: 'Delib creation collectivite',
                              value: 'Delib creation collectivite',
                            },
                            {
                              label: 'Delib intercommunalite',
                              value: 'Delib intercommunalite',
                            },
                            {
                              label: 'Extrait de declaration au JO',
                              value: 'Extrait de declaration au JO',
                            },
                            {
                              label: 'Extrait JO reco util publique',
                              value: 'Extrait JO reco util publique',
                            },
                            { label: 'Extrait kbis', value: 'Extrait kbis' },
                            {
                              label: 'Fiche information BANATIC',
                              value: 'Fiche information BANATIC',
                            },
                            {
                              label: 'KBIS Automatique',
                              value: 'KBIS Automatique',
                            },
                            {
                              label: 'Proces verbal election du CE',
                              value: 'Proces verbal election du CE',
                            },
                            {
                              label: 'Recepisse de depot des statuts',
                              value: 'Recepisse de depot des statuts',
                            },
                            {
                              label: 'Recepisse declaration prefecture',
                              value: 'Recepisse declaration prefecture',
                            },
                            {
                              label: 'Reglement de copropriete',
                              value: 'Reglement de copropriete',
                            },
                            {
                              label: 'Statuts certifies conformes',
                              value: 'Statuts certifies conformes',
                            },
                          ],
                        },
                      ],
                    ],

                    'Justificatif de domicile': [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          label: 'Détail du justificatif',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Attestation d hebergement',
                              value: 'Attestation d hebergement',
                            },
                            {
                              label: 'Avis imposition/non imposition',
                              value: 'Avis imposition/non imposition',
                            },
                            {
                              label: 'Carte grise vehicule',
                              value: 'Carte grise vehicule',
                            },
                            {
                              label: 'Certificat d hebergement',
                              value: 'Certificat d hebergement',
                            },
                            {
                              label: 'Doc logement/Ass.MRH/Carte grise',
                              value: 'Doc logement/Ass.MRH/Carte grise',
                            },
                            {
                              label: 'Elect./gaz/eau/tel./multimedia',
                              value: 'Elect./gaz/eau/tel./multimedia',
                            },
                            {
                              label: 'Facture / Echeancier Gaz',
                              value: 'Facture / Echeancier Gaz',
                            },
                            {
                              label: 'Facture telephonie (- 3 mois)',
                              value: 'Facture telephonie (- 3 mois)',
                            },
                            {
                              label: 'Ordonnance juge de tutelle',
                              value: 'Ordonnance juge de tutelle',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],

                    'Justificatif de domicile Société': [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          placeholder: 'Choisir...',
                          label: 'Détail du justificatif',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Document offciel avec entete',
                              value: 'Document offciel avec entete',
                            },
                            {
                              label: 'Elect./gaz/eau/tel./multimedia',
                              value: 'Elect./gaz/eau/tel./multimedia',
                            },
                            {
                              label: 'Extrait de deliberation',
                              value: 'Extrait de deliberation',
                            },
                            { label: 'Extrait kbis', value: 'Extrait kbis' },
                            {
                              label: 'Justif adresse du fiduciaire',
                              value: 'Justif adresse du fiduciaire',
                            },
                            {
                              label: 'KBIS Automatique',
                              value: 'KBIS Automatique',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    'Justificatif de revenu': [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          placeholder: 'Choisir...',
                          label: 'Détail du justificatif',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: '3 derniers bulletins de salaire',
                              value: '3 derniers bulletins de salaire',
                            },
                            {
                              label: 'Attestations revenus sociaux',
                              value: 'Attestations revenus sociaux',
                            },
                            {
                              label: 'Avis imposition/non imposition',
                              value: 'Avis imposition/non imposition',
                            },
                            {
                              label: 'Declaration/honneur sans revenu',
                              value: 'Declaration/honneur sans revenu',
                            },
                            {
                              label: 'Doc.Salarie/Etudiant/Retraite',
                              value: 'Doc.Salarie/Etudiant/Retraite',
                            },
                            {
                              label: 'Fiche declarative activite eco',
                              value: 'Fiche declarative activite eco',
                            },
                            {
                              label: 'Pension, Retraite',
                              value: 'Pension, Retraite',
                            },
                            {
                              label: 'Releves bancaires',
                              value: 'Releves bancaires',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    'Justificatif de revenu professionnel': [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          placeholder: 'Choisir...',
                          label: 'Détail du justificatif',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          values: [
                            {
                              label: 'Approbation des comptes annuels',
                              value: 'Approbation des comptes annuels',
                            },
                            {
                              label: 'Bilan automatique',
                              value: 'Bilan automatique',
                            },
                            {
                              label: 'Bilan automatique corrige',
                              value: 'Bilan automatique corrige',
                            },
                            { label: 'Bilan manuel', value: 'Bilan manuel' },
                            {
                              label: 'Comptes administratifs',
                              value: 'Comptes administratifs',
                            },
                            {
                              label: 'Cpte resultats dernier exercice',
                              value: 'Cpte resultats dernier exercice',
                            },
                            {
                              label: 'CR Bilan previsionnel(creation)',
                              value: 'CR Bilan previsionnel(creation)',
                            },
                            {
                              label: 'Dernier budget',
                              value: 'Dernier budget',
                            },
                            {
                              label: 'Eval flux/estim.ventil(creation)',
                              value: 'Eval flux/estim.ventil(creation)',
                            },
                            {
                              label: 'Ident personne morale',
                              value: 'Ident personne morale',
                            },
                            {
                              label: 'Rapport de reddition',
                              value: 'Rapport de reddition',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                    Nomination: [
                      [
                        {
                          type: 'select',
                          name: 'proof_detail',
                          label: 'Détail du justificatif',
                          placeholder: 'Choisir...',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),

                          values: [
                            {
                              label: 'Copie decision nommant directeur',
                              value: 'Copie decision nommant directeur',
                            },
                            {
                              label: 'Copie delib delegation/fonction',
                              value: 'Copie delib delegation/fonction',
                            },
                            {
                              label: 'Copie delib delivrant competenc',
                              value: 'Copie delib delivrant competenc',
                            },
                            {
                              label: 'Decision nommant rep Etab Publ',
                              value: 'Decision nommant rep Etab Publ',
                            },
                            {
                              label: 'Extr.delib designant ordonnateur',
                              value: 'Extr.delib designant ordonnateur',
                            },
                            { label: 'Extrait kbis', value: 'Extrait kbis' },
                            {
                              label: 'KBIS Automatique',
                              value: 'KBIS Automatique',
                            },
                            {
                              label: 'PV conseil administration',
                              value: 'PV conseil administration',
                            },
                            {
                              label: 'Statuts Automatique',
                              value: 'Statuts Automatique',
                            },
                            {
                              label: 'Statuts certifies conformes',
                              value: 'Statuts certifies conformes',
                            },
                          ],
                        },
                        { type: 'blank' },
                      ],
                    ],
                  },
                },
                {
                  type: 'file',
                  name: 'Files',
                },
              ],
            ],
          },
        ],

        [
          {
            type: 'date',
            name: 'FraudDocumentary.execution_date',
            label: "Date d'exécution",
            options: (props) =>
              getInputOptsByBlockedField(props, {
                required: 'Ce champ est requis',
              }),
            // options: { required: "Ce champ est requis" },
          },
          { type: 'blank' },
        ],
        //     ],
        //   },
        // ],
      ],
    },
  ],
}
