import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <path d="M50 10c-22.055 0-40 17.945-40 40s17.945 40 40 40 40-17.945 40-40-17.945-40-40-40zm0 6c18.812 0 34 15.188 34 34S68.812 84 50 84 16 68.812 16 50s15.188-34 34-34zm0 5a3 3 0 00-3 3v26c0 1.11.605 2.074 1.5 2.594l20.781 12a2.995 2.995 0 103-5.188L53 48.281v-24.28a3 3 0 00-3-3z" />
        </svg>
    )
}

export default SvgComponent
