const useApiFetch = (getData, onError = console.error) => {
  return async ({ pageSize, pageIndex, filters, order }) => {
    let formattedFilters
    if (filters) {
      formattedFilters = [
        ...filters.map((f) => [f.id, f.value.operator, f.value.value]),
      ]
    }
    let formattedOrder
    if (order) {
      formattedOrder = [...order.map((f) => [f.id, f.desc ? 'DESC' : 'ASC'])]
    }

    try {
      const req = await getData(
        pageIndex * pageSize,
        pageSize,
        formattedFilters,
        formattedOrder
      )

      if (req.results) {
        const totalCount = Math.ceil(req.total / pageSize)
        return [req.results, totalCount, req.total]
      } else {
        onError(req.error)
      }
    } catch (err) {
      onError(err)
    }
  }
}

export { useApiFetch }
