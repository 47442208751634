module.exports = [
    { label: "Afghanistan", value: "AF" },
    { label: "Afrique du Sud", value: "ZA" },
    { label: "Albanie", value: "AL" },
    { label: "Algérie", value: "DZ" },
    { label: "Allemagne", value: "DE" },
    { label: "Allemagne de l'EST", value: "DD" },
    { label: "Andorre", value: "AD" },
    { label: "Angola", value: "AO" },
    { label: "Anguilla", value: "AI" },
    { label: "Antarctique", value: "AQ" },
    { label: "Antigua et Barbuda", value: "AG" },
    { label: "Antilles néerlandaises", value: "AN" },
    { label: "Arabie Saoudite", value: "SA" },
    { label: "Argentine", value: "AR" },
    { label: "Arménie", value: "AM" },
    { label: "Aruba", value: "AW" },
    { label: "Australie", value: "AU" },
    { label: "Autriche", value: "AT" },
    { label: "Azerbaïdjan", value: "AZ" },
    { label: "Bahamas", value: "BS" },
    { label: "Bahrein", value: "BH" },
    { label: "Bangladesh", value: "BD" },
    { label: "Barbade", value: "BB" },
    { label: "Bélarus", value: "BY" },
    { label: "Belgique", value: "BE" },
    { label: "Bélize", value: "BZ" },
    { label: "Bénin", value: "BJ" },
    { label: "Bermudes", value: "BM" },
    { label: "Bhoutan", value: "BT" },
    { label: "Bolivie (État plurinational de)", value: "BO" },
    { label: "Bonaire, Saint-Eustache et Saba", value: "BQ" },
    { label: "Bosnie-Herzégovine", value: "BA" },
    { label: "Botswana", value: "BW" },
    { label: "Bouvet, Ile", value: "BV" },
    { label: "Brésil", value: "BR" },
    { label: "Brunéi Darussalam", value: "BN" },
    { label: "Bulgarie", value: "BG" },
    { label: "Burkina Faso", value: "BF" },
    { label: "Burundi", value: "BI" },
    { label: "Cabo Verde", value: "CV" },
    { label: "Caïmans, Iles", value: "KY" },
    { label: "Cambodge", value: "KH" },
    { label: "Cameroun", value: "CM" },
    { label: "Canada", value: "CA" },
    { label: "Chili", value: "CL" },
    { label: "Chine", value: "CN" },
    { label: "Christmas, île", value: "CX" },
    { label: "Chypre", value: "CY" },
    { label: "Cocos/Keeling (Îles)", value: "CC" },
    { label: "Colombie", value: "CO" },
    { label: "Comores", value: "KM" },
    { label: "Congo", value: "CG" },
    { label: "Congo, République démocratique du", value: "CD" },
    { label: "Cook, Iles", value: "CK" },
    { label: "Corée, République de", value: "KR" },
    { label: "Corée, République populaire démocratique de", value: "KP" },
    { label: "Costa Rica", value: "CR" },
    { label: "Côte d'Ivoire", value: "CI" },
    { label: "Croatie", value: "HR" },
    { label: "Cuba", value: "CU" },
    { label: "Curaçao", value: "CW" },
    { label: "Danemark", value: "DK" },
    { label: "Djibouti", value: "DJ" },
    { label: "Dominicaine, République", value: "DO" },
    { label: "Dominique", value: "DM" },
    { label: "Egypte", value: "EG" },
    { label: "El Salvador", value: "SV" },
    { label: "Emirats arabes unis", value: "AE" },
    { label: "Equateur", value: "EC" },
    { label: "Erythrée", value: "ER" },
    { label: "Espagne", value: "ES" },
    { label: "Estonie", value: "EE" },
    { label: "Etats-Unis d'Amérique", value: "US" },
    { label: "Ethiopie", value: "ET" },
    { label: "Falkland/Malouines (Îles)", value: "FK" },
    { label: "Féroé, îles", value: "FO" },
    { label: "Fidji", value: "FJ" },
    { label: "Finlande", value: "FI" },
    { label: "France", value: "FR" },
    { label: "Gabon", value: "GA" },
    { label: "Gambie", value: "GM" },
    { label: "Géorgie", value: "GE" },
    { label: "Géorgie du sud et les îles Sandwich du sud", value: "GS" },
    { label: "Ghana", value: "GH" },
    { label: "Gibraltar", value: "GI" },
    { label: "Grèce", value: "GR" },
    { label: "Grenade", value: "GD" },
    { label: "Groenland", value: "GL" },
    { label: "Guadeloupe", value: "GP" },
    { label: "Guam", value: "GU" },
    { label: "Guatemala", value: "GT" },
    { label: "Guernesey", value: "GG" },
    { label: "Guinée", value: "GN" },
    { label: "Guinée-Bissau", value: "GW" },
    { label: "Guinée équatoriale", value: "GQ" },
    { label: "Guyana", value: "GY" },
    { label: "Guyane française", value: "GF" },
    { label: "Haïti", value: "HT" },
    { label: "Heard, Ile et MacDonald, îles", value: "HM" },
    { label: "Honduras", value: "HN" },
    { label: "Hong Kong", value: "HK" },
    { label: "Hongrie", value: "HU" },
    { label: "Île de Man", value: "IM" },
    { label: "Îles mineures éloignées des Etats-Unis", value: "UM" },
    { label: "Îles vierges britanniques", value: "VG" },
    { label: "Îles vierges des Etats-Unis", value: "VI" },
    { label: "Inde", value: "IN" },
    { label: "Indien (Territoire britannique de l'océan)", value: "IO" },
    { label: "Indonésie", value: "ID" },
    { label: "Iran, République islamique d'", value: "IR" },
    { label: "Iraq", value: "IQ" },
    { label: "Irlande", value: "IE" },
    { label: "Islande", value: "IS" },
    { label: "Israël", value: "IL" },
    { label: "Italie", value: "IT" },
    { label: "Jamaïque", value: "JM" },
    { label: "Japon", value: "JP" },
    { label: "Jersey", value: "JE" },
    { label: "Jordanie", value: "JO" },
    { label: "Kazakhstan", value: "KZ" },
    { label: "Kenya", value: "KE" },
    { label: "Kirghizistan", value: "KG" },
    { label: "Kiribati", value: "KI" },
    { label: "Koweït", value: "KW" },
    { label: "Lao, République démocratique populaire", value: "LA" },
    { label: "Lesotho", value: "LS" },
    { label: "Lettonie", value: "LV" },
    { label: "Liban", value: "LB" },
    { label: "Libéria", value: "LR" },
    { label: "Libye", value: "LY" },
    { label: "Liechtenstein", value: "LI" },
    { label: "Lituanie", value: "LT" },
    { label: "Luxembourg", value: "LU" },
    { label: "Macao", value: "MO" },
    { label: "Macédoine, l'ex-République yougoslave de", value: "MK" },
    { label: "Madagascar", value: "MG" },
    { label: "Malaisie", value: "MY" },
    { label: "Malawi", value: "MW" },
    { label: "Maldives", value: "MV" },
    { label: "Mali", value: "ML" },
    { label: "Malte", value: "MT" },
    { label: "Mariannes du nord, Iles", value: "MP" },
    { label: "Maroc", value: "MA" },
    { label: "Marshall, Iles", value: "MH" },
    { label: "Martinique", value: "MQ" },
    { label: "Maurice", value: "MU" },
    { label: "Mauritanie", value: "MR" },
    { label: "Mayotte", value: "YT" },
    { label: "Mexique", value: "MX" },
    { label: "Micronésie, Etats Fédérés de", value: "FM" },
    { label: "Moldova, République de", value: "MD" },
    { label: "Monaco", value: "MC" },
    { label: "Mongolie", value: "MN" },
    { label: "Monténégro", value: "ME" },
    { label: "Montserrat", value: "MS" },
    { label: "Mozambique", value: "MZ" },
    { label: "Myanmar", value: "MM" },
    { label: "Namibie", value: "NA" },
    { label: "Nauru", value: "NR" },
    { label: "Népal", value: "NP" },
    { label: "Nicaragua", value: "NI" },
    { label: "Niger", value: "NE" },
    { label: "Nigéria", value: "NG" },
    { label: "Niue", value: "NU" },
    { label: "Norfolk, Ile", value: "NF" },
    { label: "Norvège", value: "NO" },
    { label: "Nouvelle-Calédonie", value: "NC" },
    { label: "Nouvelle-Zélande", value: "NZ" },
    { label: "Oman", value: "OM" },
    { label: "Ouganda", value: "UG" },
    { label: "Ouzbékistan", value: "UZ" },
    { label: "Pakistan", value: "PK" },
    { label: "Palaos", value: "PW" },
    { label: "Palestine, Etat de", value: "PS" },
    { label: "Panama", value: "PA" },
    { label: "Papouasie-Nouvelle-Guinée", value: "PG" },
    { label: "Paraguay", value: "PY" },
    { label: "Pays-Bas", value: "NL" },
    { label: "Pays inconnu", value: "XX" },
    { label: "Pays multiples", value: "ZZ" },
    { label: "Pérou", value: "PE" },
    { label: "Philippines", value: "PH" },
    { label: "Pitcairn", value: "PN" },
    { label: "Pologne", value: "PL" },
    { label: "Polynésie française", value: "PF" },
    { label: "Porto Rico", value: "PR" },
    { label: "Portugal", value: "PT" },
    { label: "Qatar", value: "QA" },
    { label: "République arabe syrienne", value: "SY" },
    { label: "République centrafricaine", value: "CF" },
    { label: "Réunion", value: "RE" },
    { label: "Roumanie", value: "RO" },
    { label: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord", value: "GB" },
    { label: "Russie, Fédération de", value: "RU" },
    { label: "Rwanda", value: "RW" },
    { label: "Sahara occidental", value: "EH" },
    { label: "Saint-Barthélemy", value: "BL" },
    { label: "Saint-Kitts-et-Nevis", value: "KN" },
    { label: "Saint-Marin", value: "SM" },
    { label: "Saint-Martin (partie française)", value: "MF" },
    { label: "Saint-Martin (partie néerlandaise)", value: "SX" },
    { label: "Saint-Pierre-et-Miquelon", value: "PM" },
    { label: "Saint-Siège", value: "VA" },
    { label: "Saint-Vincent-et-les-Grenadines", value: "VC" },
    { label: "Sainte-Hélène, Ascension et Tristan da Cunha", value: "SH" },
    { label: "Sainte-Lucie", value: "LC" },
    { label: "Salomon, Iles", value: "SB" },
    { label: "Samoa", value: "WS" },
    { label: "Samoa américaines", value: "AS" },
    { label: "Sao Tomé-et-Principe", value: "ST" },
    { label: "Sénégal", value: "SN" },
    { label: "Serbie", value: "RS" },
    { label: "Seychelles", value: "SC" },
    { label: "Sierra Leone", value: "SL" },
    { label: "Singapour", value: "SG" },
    { label: "Slovaquie", value: "SK" },
    { label: "Slovénie", value: "SI" },
    { label: "Somalie", value: "SO" },
    { label: "Soudan", value: "SD" },
    { label: "Soudan du Sud", value: "SS" },
    { label: "Sri Lanka", value: "LK" },
    { label: "Suède", value: "SE" },
    { label: "Suisse", value: "CH" },
    { label: "Suriname", value: "SR" },
    { label: "Svalbard et île Jan Mayen", value: "SJ" },
    { label: "Swaziland", value: "SZ" },
    { label: "Tadjikistan", value: "TJ" },
    { label: "Taïwan, Province de Chine", value: "TW" },
    { label: "Tanzanie, République unie de", value: "TZ" },
    { label: "Tchad", value: "TD" },
    { label: "Tchécoslovaquie", value: "CS" },
    { label: "Tchèque, République", value: "CZ" },
    { label: "Terres australes françaises", value: "TF" },
    { label: "Thaïlande", value: "TH" },
    { label: "Timor-Leste", value: "TL" },
    { label: "Togo", value: "TG" },
    { label: "Tokelau", value: "TK" },
    { label: "Tonga", value: "TO" },
    { label: "Trinité-et-Tobago", value: "TT" },
    { label: "Tunisie", value: "TN" },
    { label: "Turkménistan", value: "TM" },
    { label: "Turks-et-Caïcos (Îles)", value: "TC" },
    { label: "Turquie", value: "TR" },
    { label: "Tuvalu", value: "TV" },
    { label: "Ukraine", value: "UA" },
    { label: "URSS", value: "SU" },
    { label: "Uruguay", value: "UY" },
    { label: "Vanuatu", value: "VU" },
    { label: "Venezuela (République bolivarienne du)", value: "VE" },
    { label: "Viet Nam", value: "VN" },
    { label: "Viet Nam (Sud)", value: "VD" },
    { label: "Wallis et Futuna", value: "WF" },
    { label: "Yémen", value: "YE" },
    { label: "Yougoslavie", value: "YU" },
    { label: "Zaïre", value: "ZR" },
    { label: "Zambie", value: "ZM" },
    { label: "Zimbabwe", value: "ZW" }
]
