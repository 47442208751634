export function getDeepValue(obj, path, def) {
  /**
   * If the path is a string, convert it to an array
   * @param  {String|Array} path The path
   * @return {Array}             The path array
   */
  var stringToPath = function (path) {
    // If the path isn't a string, return it
    if (typeof path !== 'string') return path

    // Create new array
    var output = []

    // Split to an array with dot notation
    path.split('.').forEach(function (item) {
      // Split to an array with bracket notation
      item.split(/\[([^}]+)\]/g).forEach(function (key) {
        // Push to the new array
        if (key.length > 0) {
          output.push(key)
        }
      })
    })

    return output
  }

  // Get the path as an array
  path = stringToPath(path)

  // Cache the current object
  var current = obj

  // For each item in the path, dig into the object
  for (var i = 0; i < path.length; i++) {
    // If the item isn't found, return the default (or null)
    if (!current[path[i]] && current[path[i]] !== 0) {
      return def
    }

    // Otherwise, update the current  value
    current = current[path[i]]
  }

  return current
}

export function compareArrays(array1, array2) {
  return (
    array1.length === array2.length &&
    array1.every(function (value, index) {
      return value === array2[index]
    })
  )
}

export function getInputOptsByBlockedField(
  { form, prefix },
  options,
  blockedFieldName
) {
  let blockedField = prefix + 'blocked'

  if (blockedFieldName) {
    blockedField = blockedFieldName
  } else {
    // Hack for cheque-in.
    // Keep only first prefix level
    blockedField = prefix ? prefix.split('.')[0] + '.blocked' : 'blocked'
  }

  const blocked = form.watch(blockedField)

  if (blocked && blocked !== 'false') return {}
  return options
}

export function getInputOptsByBlockedAndFraudTypeField(
  { form, prefix },
  options
) {
  let blockedField = prefix + 'blocked'
  let fraudTypeField = prefix + 'fraud_type'

  const blocked = form.watch(blockedField)
  const fraudType = form.watch(fraudTypeField)

  if (
    (blocked && blocked !== 'false') ||
    (fraudType && fraudType === 'Escroquerie')
  )
    return {}

  return options
}

/**
 * Returns number from str. Can finish by a dot.
 */
export function getNumberFromString(str) {
  const numberVal = str.replace(',', '.').replace(/[^0-9.]+/g, '')
  const isDot = numberVal.includes('.')
  const splittedNumberVal = numberVal.split('.')
  const firstVal = splittedNumberVal.shift()
  const stringfinalValue = `${firstVal}${isDot?'.':''}${splittedNumberVal.join('')}`

  if (stringfinalValue.endsWith('.')) {
    return stringfinalValue
  }

  const floatFinalValue = parseFloat(stringfinalValue)
  return isNaN(floatFinalValue)  ? 0 : floatFinalValue
}
