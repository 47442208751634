import React, { useEffect, useState } from 'react'

import { useHistory, Link, useParams } from 'react-router-dom'
import CustomersSharingAddresses from 'components/common/customer/CustomersSharingAddresses'
import Layout from 'components/Layout'
import CustomerForm from 'components/common/customer/CustomerForm'
import UserIcon from 'components/svg/user'
import useUsers from 'hooks/useUsers'

export default (props) => {
  // const id = props.match.params.id
  let { id } = useParams();
  const { isAudit } = useUsers()
  const consult = isAudit()

  const [customer, setCustomer] = useState({ id })

  const history = useHistory()

  useEffect(() => {
    setCustomer({ id })
  }, [id])

  return (
    <Layout location={props.location} className="w-4/6">
      <h1 className="flex items-center">
        <UserIcon className="fill-current text-brand" />
        <span className="ml-2">Fiche client</span>
      </h1>
      <CustomersSharingAddresses customer={customer} />
      {customer &&
        <div className={`form-container mx-auto mt-4 w-full relative rounded-sm bg-white p-6 shadow-lg`} >
          <CustomerForm
            consult={consult}
            customer={customer}
            onSuccess={(selectedCustomer) => {
              setCustomer(selectedCustomer)
            }}
            onCancel={() => history.push('/clients')}
          />
        </div>
      }
    </Layout>
  )
}

