import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <path d="M50 27.082C29.168 27.082 14.582 50 14.582 50S25.207 72.918 50 72.918C75 72.918 85.418 50 85.418 50S71.043 27.082 50 27.082zm0 37.5c-8.125 0-14.582-6.457-14.582-14.582S41.875 35.418 50 35.418 64.582 41.875 64.582 50 58.125 64.582 50 64.582z" />
            <path d="M56.25 50c0 3.453-2.797 6.25-6.25 6.25s-6.25-2.797-6.25-6.25 2.797-6.25 6.25-6.25 6.25 2.797 6.25 6.25" />
        </svg>
    )
}

export default SvgComponent
