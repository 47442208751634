import NetworkOrganisationSelect from 'components/common/NetworkOrganisationSelect'
import { getInputOptsByBlockedField } from 'lib/utils'

const blockedFieldName = 'FraudInsurance.blocked'

export default {
  id: 'insurance',
  title: 'Assurance',
  sections: [
    {
      title: null,
      rows: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'FraudInsurance.id',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudInsurance.blocked',
            defaultValue: 'false',
            label: 'Opération déjouée',
            whenYes: [
              [
                {
                  type: 'select',
                  name: 'FraudInsurance.fraud_detection',
                  label: "Nom de l'outil",
                  placeholder: 'Choisir un moyen',
                  values: [
                    {
                      label: 'CTC',
                      value: 'CTC',
                    },
                    {
                      label: "Réserve d'encaissement",
                      value: "Réserve d'encaissement",
                    },
                    {
                      label: 'Outils internes',
                      value: 'Outils internes',
                    },
                  ],
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'select',
            autofocus: true,
            name: 'FraudInsurance.product_type',
            label: 'Type de produit',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
            placeholder: 'Choisir un type',
            values: [
              { label: 'ADE', value: 'ADE' },
              {
                label: 'Capitalisation',
                value: 'Capitalisation',
              },

              {
                label: 'Dépendance ; ADE ; Assurance IARD Habitation',
                value: 'Dépendance ; ADE ; Assurance IARD Habitation',
              },
              {
                label: 'MAV',
                value: 'MAV',
              },
              {
                label: 'Obsèques ; Santé',
                value: 'Obsèques ; Santé',
              },

              {
                label: 'PJ',
                value: 'PJ',
              },
              {
                label: 'Retraite épargne ; Prévoyance',
                value: 'Retraite épargne ; Prévoyance',
              },
              {
                label: 'Véhicules',
                value: 'Véhicules',
              },
              {
                label: 'Vie',
                value: 'Vie',
              },
            ],
          },
          {
            type: 'select',
            name: 'FraudInsurance.occurence',
            label: 'Survenance de la fraude',
            placeholder: 'Choisir...',
            values: [
              {
                label: "À l'entrée en relation",
                value: "À l'entrée en relation",
              },
              {
                label: 'Durant la vie du contrat',
                value: 'Durant la vie du contrat',
              },
              {
                label: 'Lors de la réalisation du sinistre',
                value: 'Lors de la réalisation du sinistre',
              },
              {
                label: 'À la réalisation du sinistre',
                value: 'À la réalisation du sinistre',
              },
            ],
          },
        ],
        [
          {
            name: 'FraudInsurance.contract_id',
            label: 'Référence contrat',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
            helpText: 'Référence de contrat si elle existe',
          },
        ],
        [
          {
            networkName: 'FraudInsurance.affectedNetworkId',
            organisationName: 'FraudInsurance.organisationNetworkId',
            type: 'custom',
            label: 'Client',
            Component: (props) =>
              NetworkOrganisationSelect({ ...props, blockedFieldName }),
          },
        ],
        [
          {
            type: 'number',
            name: 'FraudInsurance.contracts_number',
            label: 'Nombre de contrats impactés',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
          },
          {
            type: 'text',
            name: 'FraudInsurance.other_customers_ids',
            label: 'Identifiants autres clients',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudInsurance.fake_statement',
            defaultValue: 'false',
            label: 'Fausse déclaration',
          },
        ],
        [
          {
            type: 'multi-checkbox',
            // name: 'FraudInsurance.suspicion_abuse_person',
            label: "Suspicion d'abus de faiblesse",
            options: {
              choices: [
                {
                  label: 'Clause bénéficiaire',
                  // value: 'entrant',
                  name: 'FraudInsurance.weakness_abuse_benef_clause',
                },
                {
                  label: 'Rachat',
                  // value: 'sortant',
                  name: 'FraudInsurance.weakness_abuse_repurchase',
                },
              ],
            },
          },
        ],
        [
          {
            type: 'select',
            name: 'FraudInsurance.sinister',
            label: 'Sinistre',
            placeholder: 'Choisir un sinistre',
            values: [
              { label: 'Pas de sinistre', value: 'Pas de sinistre' },
              { label: 'Sinistre avéré', value: 'Sinistre avéré' },
              { label: 'Sinistre imaginaire', value: 'Sinistre imaginaire' },
              { label: 'Sinistre simulé', value: 'Sinistre simulé' },
              { label: 'Sinistre provoqué ', value: 'Sinistre provoqué' },
              { label: 'Sinistre exploité', value: 'Sinistre exploité' },
            ],
          },
          {
            type: 'select',
            name: 'FraudInsurance.recovery_type',
            label: 'Type de recouvrement',
            placeholder: 'Choisir un type',
            values: [
              { label: "Répétition de l'indu", value: "Répétition de l'indu" },
              { label: 'Contentieux civil', value: 'Contentieux civil' },
              { label: 'Contentieux Pénal', value: 'Contentieux Pénal' },
              {
                label: 'Contentieux pénal suivi par le réseau',
                value: 'Contentieux pénal suivi par le réseau',
              },
            ],
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudInsurance.blocking_code',
            defaultValue: 'false',
            label: 'Code blocage',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudInsurance.prosecutor_report',
            defaultValue: 'false',
            label: 'Signalement procureur',
          },
        ],
        [
          {
            type: 'date',
            name: 'FraudInsurance.execution_date',
            label: "Date d'exécution",
          },
          { type: 'blank' },
        ],
      ],
    },
  ],
}
