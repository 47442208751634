import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import TrashIcon from 'components/svg/trash'
import EditIcon from 'components/svg/edit'
import PersonIcon from 'components/svg/person'
import EyeIcon from 'components/svg/eye'
import DraftIcon from 'components/svg/draft'
import DuplicateIcon from 'components/svg/duplicate2'
import Modal from '../Modal'
import Confirm from 'components/common/ConfirmModal'
import useFrauds from 'hooks/useFrauds'

const EditAction = ({ to, entity }) => {
  const history = useHistory()

  // const [redirect, setRedirect] = useState(false)
  return (
    <button
      onClick={async () => {
        if (await Confirm(`Voulez-vous modifier ${entity} ?`)) {
          history.push(to)
        }
      }}
      to={to}
      className="ml-2 text-xl text-blue-500"
    >
      {/* {redirect && <Redirect to={to} />} */}
      <EditIcon className="fill-current" />
    </button>
  )
}
const DuplicateAction = ({ id, setAdded, entity }) => {
  // const history = useHistory()

  const { duplicateFraud } = useFrauds()
  // const [redirect, setRedirect] = useState(false)
  return (
    <button
      onClick={async () => {
        if (await Confirm(`Voulez-vous dupliquer ${entity} ?`)) {
          const { results } = await duplicateFraud(id)
          console.log(results)
          setAdded(results.id)
        }
      }}
      className="ml-2 text-xl text-blue-500"
    >
      <DuplicateIcon className="fill-current" />
    </button>
  )
}

const ShowAction = ({ to }) => {
  return (
    <Link to={to} className="ml-2 text-2xl text-blue-500">
      <EyeIcon className="fill-current" />
    </Link>
  )
}

const RemoveAction = (props) => {
  const { value, onRemove } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmRemove = () => {
    onRemove(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={`Êtes-vous sur de vouloir supprimer l'élément ?`}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        tiny
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmRemove}
            >
              Confirmer la suppression
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={() => setOpenModal(true)}
        className="ml-2 text-xl text-red-500 cursor-pointer"
      >
        <TrashIcon className="fill-current" />
      </div>
    </>
  )
}
const DraftAction = (props) => {
  const { value, onDraft } = props
  const [openModal, setOpenModal] = useState(false)
  const confirmDraft = () => {
    onDraft(value)
    setOpenModal(false)
  }
  return (
    <>
      <Modal
        title={`Êtes-vous sur de vouloir mettre l'élément en brouillon ?`}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        tiny
      >
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
              onClick={() => setOpenModal(false)}
            >
              Annuler
            </div>
            <div
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={confirmDraft}
            >
              Confirmer la mise en brouillon
            </div>
          </div>
        </div>
      </Modal>
      <div
        onClick={() => setOpenModal(true)}
        className="ml-2 text-xl text-red-500 cursor-pointer"
      >
        <DraftIcon className="fill-current" />
      </div>
    </>
  )
}

const ConnectAsAction = ({ user, connectAs, setUser }) => {
  return (
    <button
      title="Se connecter en temps que"
      onClick={async () => {
        if (
          await Confirm(
            `Voulez-vous vous connecter en tant que "${user.userName || ''}" ?`
          )
        ) {
          const { results } = await connectAs(user.id)
          setUser(results)
        }
      }}
      className="ml-2 text-2xl text-blue-500"
    >
      <PersonIcon className="fill-current" />
    </button>
  )
}

const Actions = ({ children }) => (
  <div className="flex items-center justify-end">{children}</div>
)

export {
  EditAction,
  Actions,
  RemoveAction,
  DraftAction,
  ShowAction,
  ConnectAsAction,
  DuplicateAction,
}
