import React from "react"

import Layout from "components/Layout"
import FraudsList from "components/frauds/List"

const FraudsListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <FraudsList />
    </Layout>
  )
}

export default FraudsListPage
