import React from "react";

const Tooltip = ({ tooltip }) => {
  return (
    <div className="group inline-block">
      <div className="px-2 ml-3 text-black bg-gray-200 rounded-full ">?</div>
      <div
        className="absolute z-10 flex flex-col hidden w-auto p-3 bg-white shadow-lg transform translate-x-8 -translate-y-8 group-hover:block"
      >
       { tooltip }
      </div>
    </div>
  );
};

export default Tooltip;
