import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    getScenarios(offset = 0, limit = 100, filters, order, populate = true) {
      return Api.getScenarios(token, {
        query: { offset, limit, filters, order, populate },
      })
    },
    getScenario(id, populate = false) {
      return Api.getScenario(token, id, { query: { populate } })
    },
    deleteScenario(id) {
      return Api.deleteScenario(token, id)
    },
    findScenarios(FraudTypeId) {
      return Api.getScenarios(token, {
        query: {
          filters: [['FraudTypeId', FraudTypeId]],
          offset: 0,
          limit: 1000,
        },
      })
    },
    calculateScenario(fraudId) {
      return Api.calculateScenario(token, fraudId)
    },
    createScenario(body) {
      return Api.createScenario(token, { body })
    },
    updateScenario(body) {
      return Api.updateScenario(token, body.id, { body })
    },
  }
}
