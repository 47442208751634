import React from 'react'

import { useHistory } from 'react-router-dom'

import Form from 'components/form/Form'
import confirm from 'components/common/ConfirmModal'

import useScenarios from 'hooks/useScenarios'
import { useNotify } from 'stores/notify'

import createFormdefinitions from './FormDefinitions.js'

export default function FraudsForm({ entity = {} }) {
  const { updateScenario, createScenario } = useScenarios()
  const history = useHistory()

  const formdefinitions = createFormdefinitions()

  const { setErrorMessage, setSuccessMessage } = useNotify((n) => n)

  async function onSubmit(entity) {
    try {
      // entity.conds = entity.conds ? entity.conds : []

      if (entity.id) {
        await updateScenario(entity)
      } else {
        await createScenario(entity)
      }

      setSuccessMessage('Scénario bien enregistré')
      history.push('/scenarios')
    } catch (err) {
      console.error(err)
      setErrorMessage(err.message)
    }
  }

  async function onCancel() {
    if (await confirm('Êtes-vous sûr de vouloir quitter ?')) {
      history.push('/scenarios')
    }
  }

  return (
    <Form
      entity={entity}
      formdefinitions={formdefinitions}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
