import React from 'react'


import UserForm from 'components/users/Form'
import UserIcon from 'components/svg/user'
import Layout from 'components/Layout'

const UsersNewPage = ({ location }) => {

  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <UserIcon className="fill-current text-brand" />
        <span className="ml-2">Fiche Utilisateur</span>
      </h1>
      <UserForm />
    </Layout>
  )
}

export default UsersNewPage
