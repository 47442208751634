import amountAndCurrency from './commonfields/amountAndCurrency'
import Countries from './commonfields/countries'
import BICComponent from './commonfields/BICComponent'
import { validateBIC } from 'lib/codeBic'
import { getInputOptsByBlockedField } from 'lib/utils'

export default {
  id: 'levy',
  title: 'Prélèvements entrants',
  // next: "2",
  defaultValues: { FraudLevyIns: [{}] },
  sections: [
    {
      title: null,
      rows: [
        [
          {
            name: 'FraudLevyIns',
            type: 'array',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        type: 'select',
                        name: 'fraud_detection',
                        label: "Nom de l'outil",
                        options: () => ({
                          required: 'Ce champ est requis',
                        }),
                        placeholder: 'Choisir un moyen',
                        values: [
                          {
                            label: 'PRISMOP',
                            value: 'PRISMOP',
                          },
                          {
                            label: 'Autres outils',
                            value: 'Autres outils',
                          },
                        ],
                        when: {
                          'Autres outils': [
                            [
                              {
                                name: 'fraud_detection_tool',
                                placeholder: "Précisez l'outil",
                                options: (props) =>
                                  getInputOptsByBlockedField(props, {
                                    required: 'Ce champ est requis',
                                  }),
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  label: 'Agrégat',
                  defaultValue: 'false',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'transaction_id',
                  autofocus: true,
                  label: 'Identifiant transaction',
                  helpText: 'Identifiant du prélèvement',
                },
              ],

              amountAndCurrency,

              [
                {
                  name: 'bank_name',
                  label: 'Banque du débiteur',
                },
                {
                  name: 'bank_domiciliation',
                  label: 'Domiciliation bancaire du débiteur',
                  placeholder: 'IBAN / BBAN',
                },
              ],
              [
                {
                  name: 'swift_bic',
                  type: 'custom',
                  Component: BICComponent,
                  label: 'Code SWIFT / BIC du débiteur',
                  options: (props) => {
                    const required =
                      props.form.watch(props.prefix + 'aggregate') ===
                        'false' || !props.form.watch(props.prefix + 'aggregate')
                    if (required)
                      return getInputOptsByBlockedField(props, {
                        required: 'Ce champ est requis',
                        validate: validateBIC,
                      })
                    return getInputOptsByBlockedField(props, {
                      validate: validateBIC,
                    })
                  },
                },
              ],

              [
                {
                  name: 'creditor_id',
                  label: 'Identifiant du créancier',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'rejected',
                  label: 'Rejet',
                },
                { type: 'blank' },
              ],
              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'opération",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'fraud_type',
                  label: 'Catégorie de la fraude',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  placeholder: 'Choisir une catégorie',
                  values: [
                    {
                      label: 'Faux',
                      value: 'Faux',
                    },
                    {
                      label: 'Détournement',
                      value: 'Détournement',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'nature',
                  label: 'Nature du prélevement',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  placeholder: 'Choisir une nature',
                  values: [
                    {
                      label: 'Core',
                      value: 'Core',
                    },
                    {
                      label: 'BtoB',
                      value: 'BtoB',
                    },
                    {
                      label: 'Non SEPA',
                      value: 'Non SEPA',
                    },
                  ],
                },
              ],

              [
                {
                  type: 'select',
                  name: 'init_channel',
                  label: "Canal d'initiation",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  values: [
                    { value: 'Télématique', label: 'Télématique' },
                    { value: 'Saisie unitaire', label: 'Saisie unitaire' },
                  ],
                },
                {
                  type: 'select',
                  name: 'consent_channel',
                  label: 'Canal de consentement',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  placeholder: 'Choisir un canal',
                  values: [
                    {
                      label: 'Internet',
                      value: 'Internet',
                    },
                    {
                      label: 'Télématique',
                      value: 'Télématique',
                    },
                    {
                      label: 'Papier',
                      value: 'Papier',
                    },
                  ],
                },
              ],

              [
                {
                  type: 'select',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  name: 'geo_zone',
                  label: 'Zone géographique',
                  values: [
                    {
                      label: 'Transaction nationale',
                      value: 'Transaction nationale',
                    },
                    {
                      label: "Transfrontalière au sein de l'EEE",
                      value: "Transfrontalière au sein de l'EEE",
                    },
                    {
                      label: "Transfrontalière hors de l'EEE",
                      value: "Transfrontalière hors de l'EEE",
                    },
                  ],
                },
                Countries({
                  label: 'Code pays',
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                  name: 'country',
                }),
              ],
              
              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
