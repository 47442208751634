import React, { useEffect, useState } from 'react'

import Layout from 'components/Layout'
import FraudsForm from 'components/frauds/Form'
import { useFraudStore } from 'stores/fraud'
import { useForms } from 'stores/form'
import NewIcon from 'components/svg/new'

const FraudsNewPage = (props) => {
  const currentStep = props.match.params.step
  const [loading, setLoading] = useState(true)

  const { resetForm } = useForms((uf) => uf)
  const { resetFraud, fraud } = useFraudStore((uf) => uf)

  useEffect(() => {
    resetForm('fraud')
    resetFraud()
    setLoading(false)
  }, [])

  return (
    <Layout location={props.location} className="w-4/6">
      <h1 className="flex items-center"><NewIcon className="fill-current text-brand" /><span className="ml-2">Saisie nouvelle fraude</span> </h1>
      {!loading && <FraudsForm {...props} key={`fraud-step-${currentStep}`} />}
    </Layout>
  )
}

export default FraudsNewPage
