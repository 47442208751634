import { AmountAndCurrencyWithProp } from './commonfields/amountAndCurrency'
import { getInputOptsByBlockedField } from 'lib/utils'

export default function Belongings(title, prop) {
  return {
    id: 'belongings',
    title: title,
    // next: "2",
    defaultValues: { [prop]: [{}] },
    sections: [
      {
        title: null,
        rows: [
          [
            {
              name: prop,
              type: 'array',
              label: 'Effet entrant',
              fields: [
                [
                  // Used for update entity by its id
                  {
                    hide: true,
                    type: 'hidden',
                    name: `id`,
                  },
                ],
                [
                  {
                    type: 'yes-no',
                    name: `blocked`,
                    defaultValue: 'false',
                    label: 'Opération déjouée',
                    whenYes: [
                      [
                        {
                          name: `fraud_detection_tool`,
                          options: () => ({
                            required: 'Ce champ est requis',
                          }),
                          label: "Nom de l'outil",
                        },
                      ],
                    ],
                  },
                ],
                [
                  {
                    //   type: "yes-no",
                    name: 'aggregate',
                    type: 'yes-no',
                    label: 'Agrégat',
                    defaultValue: 'false',
                    whenYes: [
                      [
                        {
                          label: "Nombre d'occurrences",
                          name: 'occurences_number',
                          options: (props) =>
                            getInputOptsByBlockedField(props, {
                              required: 'Ce champ est requis',
                            }),
                          type: 'number',
                        },
                        { type: 'blank' },
                      ],
                    ],
                  },
                ],
                [
                  {
                    name: 'transaction_id',
                    label: "Identifiant de l'effet",
                    autofocus: true,
                  },
                  { type: 'blank' },
                ],
                [
                  {
                    type: 'select',
                    name: `fraud_type`,
                    label: 'Type de fraude',
                    options: (props) =>
                      getInputOptsByBlockedField(props, {
                        required: 'Ce champ est requis',
                      }),
                    autofocus: true,
                    values: [
                      {
                        value: 'Vol',
                        label: 'Vol',
                      },
                      {
                        value: 'Perte',
                        label: 'Perte',
                      },
                      {
                        value: 'Contrefaçon',
                        label: 'Contrefaçon',
                      },
                      {
                        value: 'Falsification',
                        label: 'Falsification',
                      },
                      {
                        value: 'Détournement',
                        label: 'Détournement',
                      },
                      {
                        value: 'Rejeu',
                        label: 'Rejeu',
                      },
                    ],
                  },
                ],
                [
                  {
                    type: 'yes-no',
                    name: `extravagant_financial_operations`,
                    label: 'Cavalerie sur effets',
                    defaultValue: 'false',
                  },
                ],
                AmountAndCurrencyWithProp(),
                // [
                //   {
                //     type: 'number',
                //     name: `amount`,
                //     label: 'Montant',
                //     options: (props) =>
                // getInputOptsByBlockedField(props, {
                //   required: 'Ce champ est requis',
                // }),
                //   },
                //   {
                //     type: 'select',
                //     name: `currency`,
                //     label: 'Devise',
                //     values: currencies,
                //     className: 'w-48',
                //     defaultValue: 'EUR',
                //   },
                // ],

                [
                  {
                    type: 'date',
                    name: `execution_date`,
                    label: "Date d'exécution",
                    options: (props) =>
                      getInputOptsByBlockedField(props, {
                        required: 'Ce champ est requis',
                      }),
                  },
                  { type: 'blank' },
                ],
              ],
            },
          ],
        ],
      },
    ],
  }
}
