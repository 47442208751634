import React, { useEffect } from 'react'
import Close from '../svg/close'
import usePortal from 'react-useportal'

export default function Modal({
  title = '',
  isOpen = false,
  onClose = () => undefined,
  children,
  tiny = false,
  medium = false,
  verticalScroll = false,
}) {
  const { Portal } = usePortal()
  if (!isOpen) return null

  if (verticalScroll) {
    // document.body.classList.toggle('overflow-hidden', 'h-full')
    document.body.classList.add('overflow-hidden', 'h-full')
  }
  
  function onCloseEvent() {
    document.body.classList.remove('overflow-hidden', 'h-full')

    onClose()
  }

  return (
    <Portal>
      <div
        className={`${
          tiny || medium ? 'fixed' : 'absolute'
        } z-40  top-0 left-0 w-full 
        `}
        style={{
          background: 'rgba(0, 0, 0, 0.45)',
          backdropFilter: 'blur(4px)',
          minHeight: '100%'
        }}
        onClick={onCloseEvent}
      >
        <div
          className={`form-container mx-auto mt-16 ${
            tiny ? 'w-1/4' : medium ? 'w-1/2' : 'w-2/3'
          } relative rounded-sm bg-white p-6 shadow-lg
          ${verticalScroll ? 'overflow-auto' : ''}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="absolute top-0 right-0 mt-4 mr-4 text-2xl cursor-pointer"
            onClick={onCloseEvent}
          >
            <Close />
          </div>
          <div className="my-4 text-3xl font-bold">{title}</div>
          <div className="w-full">{children}</div>
        </div>
      </div>
    </Portal>
  )
}
