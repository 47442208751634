// import shallow from "zustand/shallow.cjs"

// import Router from "next/router"
// import useStore from "../store"
import Api from 'lib/api'

import { useUser } from 'stores/user'

// const API_URL = process.env.URL_API

/**
 * Return files in obj (check if obj has field `typee` with file value).
 * Return Array<{name: String, file: File}>
 */
export function findFiles(obj, prefix = '', files = []) {
  if (Array.isArray(obj)) {
    let i = 0

    for (const o of obj) {
      findFiles(o, `${prefix}.${i}`, files)
      i++
    }
  } else {
    // Object.keys(obj).forEach((key) => {
    if (
      typeof obj === 'object' &&
      obj.hasOwnProperty('typee') &&
      obj.typee === 'file'
    ) {
      files.push({ name: prefix, file: obj })
    } else {
      Object.keys(obj).forEach((key) => {
        if (obj[key] && typeof obj[key] === 'object') {
          const newPrefix = `${prefix ? prefix + '.' : ''}${key}`

          findFiles(obj[key], newPrefix, files)
        }
      })
    }
    // })
  }

  return files
}

// If there are files,
// - construct formdata,
// - remove all files id if it isn't an integer,
// - json parse all fields (because content-type is multipart/formdata, and must contains only string and files),
// - add files to formdata.
export function formatFiles(formData, files, payload) {
  formData = new FormData()

  files.forEach(({ file }) => {
    // Remove non integer id, that means it's new file
    // (id generated by react-files)
    if (!Number.isInteger(file.id)) {
      delete file.id
    }
  })

  Object.keys(payload).forEach((key) => {
    if (payload[key] === undefined) {
      formData.append(key, null)
    } else {
      formData.append(key, JSON.stringify(payload[key]))
    }
  })

  files.forEach(({ name, file }) => {
    if (file.id) {
      formData.append(name, JSON.stringify(file))
    } else {
      formData.append(
        name,
        new Blob([file], { type: file.type }),
        file.name || 'file'
      )
    }
  })
  return formData
}
/**
 * Returns a shallow copy of `object`, with props included in `keys` removed
 * @param {*} object Original object
 * @param {string[]} keys An array of keys to exclude from the shallow copy
 * @returns {*}
 *
 * @example
 * const orignal = { name: 'abji', num: '9' }
 * const other = remove(orignal, ['num'])
 *
 * expect(other).toMatchObject({ name: 'abji' })
 */
// const remove = (object, keys) => {
//   return Object.entries(object).reduce((acc, [key, value]) => {
//     if (!keys.includes(key)) {
//       acc[key] = value
//     }

//     return acc
//   }, {})
// }

export default () => {
  const token = useUser(({ token }) => token)

  return {
    getFraudsSameBusinessLines: async (
      offset = 0,
      limit = 100,
      filters,
      order
    ) => {
      try {
        const rawResponse = await Api.getFraudsSameBusinessLines(token, {
          query: { offset, limit, filters, order },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },

    createFraud: async (payload) => {
      return Api.createFraud(token, {
        body: payload,
      })
    },
    deleteFraud: async (payload) => {
      const { ref } = payload

      try {
        const rawResponse = await Api.deleteFraud(token, ref)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    updateFraud: async (payload) => {
      // Check if files exist in payload
      const files = findFiles(payload)

      let formData = payload

      if (files.length) {
        formData = formatFiles(formData, files, payload)
      }
      const { ref } = payload

      let headers = { Accept: 'application/json' }

      // If there are files, add content-type header to multipart.
      if (files.length) {
        headers['Content-Type'] = 'multipart/form-data'
      } else {
        headers['Content-Type'] = 'application/json'
      }

      return Api.updateFraud(token, ref, {
        body: formData,
        headers,
      })
    },

    createFraudDocumentary: async (payload) => {
      try {
        const rawResponse = await Api.createFraudDocumentary(token, {
          body: payload,
          query: { putAssoc: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },

    getFraud: async (id) => {
      try {
        return await Api.getFraud(token, id, { query: { populate: true } })
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    duplicateFraud: async (id) => {
      try {
        return await Api.duplicateFraud(token, id)
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },

    getFraudDefinition: () => {
      return Api.getFraudDefinition(token)
    },
  }
}
