import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useAsyncDebounce } from 'react-table'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'

import { useForms } from 'stores/form'
import { useUser } from 'stores/user'
import { useFraudStore } from 'stores/fraud'

import useFrauds from 'hooks/useFrauds'
import FormDefinitions from './formdefinition'
import FormRow from 'components/form/FormRow'
import Breadcrumbs from 'components/frauds/Breadcrumbs'
import { useNotify } from 'stores/notify'
import confirm from 'components/common/ConfirmModal'
import { useReactToPrint } from 'react-to-print'
import PrintIcon from 'components/svg/print'
import { FRAUD_STATUS } from 'lib/constants'

const fraudNamespace = 'fraud'

function calculateStatus(breadCrumbs, realSteps, currentStatus, newStatus) {
  const allOk = realSteps.every((step) => {
    if (step === 'overview') {
      return true
    }

    return breadCrumbs[step.replace(/\-/g, '_')] === 'ok'
  })

  if (allOk) {
    if (newStatus) return newStatus
    return currentStatus === FRAUD_STATUS.CLOS
      ? FRAUD_STATUS.CLOS
      : FRAUD_STATUS.EN_COURS
  }

  return FRAUD_STATUS.BROUILLON
}

/**
 * Clean fraud forms if fraud type is false
 */
const cleanFraudByType = (fraud, resetForm) => {
  const formToReinit = [
    'is_insurance',
    'is_trade',
    'is_cyber',
    'is_consumer_credit',
    'is_belongings_in',
    'is_belongings_out',
    'is_levy_in',
    'is_levy_out',
    'is_bank_transfer_out',
    'is_bank_transfer_in',
    'is_cash_in',
    'is_cash_out',
    'is_cb_in',
    'is_cb_out',
    'is_cheque_in',
    'is_cheque_out',
    'is_documentary',
  ]

  formToReinit.forEach((prop) => {
    if (!fraud[prop]) {
      resetForm(fraudNamespace, prop.replace('is_', '').replace(/_/g, '-'))
    }
  })
}

// All form steps name.
// The order here will reflected to the order shown to the user
const FORM_STEPS = [
  'customer',
  'general',
  'fraud-type',
  'documentary',
  'trade',
  'cyber',
  'consumer-credit',
  'insurance',
  'bank-transfer-in',
  'bank-transfer-out',
  'cash-in',
  'cash-out',
  'cheque-in',
  'cheque-out',
  'cb-in',
  'cb-out',
  'levy-in',
  'levy-out',
  'belongings-in',
  'belongings-out',
  'loss',
  'fraud-tracking',
  'overview',
]

const DEFAULT_STEPS = [
  'customer',
  'general',
  'loss',
  'fraud-tracking',
  'fraud-type',
  'overview',
]

const removeNullValues = (obj) => {
  return Object.keys(obj).reduce((objRes, key) => {
    if (
      (Array.isArray(obj[key]) && obj[key].length > 0) ||
      (!Array.isArray(obj[key]) && obj[key] !== null)
    ) {
      objRes[key] = obj[key]
    }

    return objRes
  }, {})
}

/**
 * Return steps by fraud types value.
 * @param {Object} fraud
 */
const getRealFormSteps = (fraud) => {
  return FORM_STEPS.filter(
    (s) =>
      DEFAULT_STEPS.includes(s) ||
      // Fraud type property can be boolean or string.
      // Need to stringify value to check "true" value.
      (fraud && String(fraud[`is_${s.replace(/\-/g, '_')}`]) === 'true')
  )
}

export default function FraudsNew(props) {
  // Get current step in url.
  // If steps is unknown, init it to 'general'.
  // let step = props.match.params.step
  // let fraudId = props.match.params.fraudId
  const { step, ref } = useParams()
  const { readOnly, checkScenario } = props

  // const { steps, setSteps } = useFraudForm(({ steps, setSteps }) => ({
  //   steps,
  //   setSteps,
  // }))

  const printRef = useRef(null)
  const { createFraud, updateFraud } = useFrauds()
  const { setSuccessMessage, setErrorMessage } = useNotify((n) => n)
  const { setForm, getForm, resetForm } = useForms((uf) => uf)
  const [showPrint, setShowPrint] = useState(false)

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: 'Synthèse Fraude',
    pageStyle:
      '@page { size: auto;  margin: 10mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
  })

  // Get current user token.
  const currentUser = useUser((currentUser) => currentUser)

  const fraudStore = useFraudStore((f) => f)

  const history = useHistory()

  const fraud = fraudStore.getFraud()
  const [realSteps, setRealSteps] = useState(getRealFormSteps(fraud))
  // const [realCurrentIndcStep, setRealCurrentIndcStep] = useState(0)
  const [isFinish, setIsFinish] = useState(false)
  // let realSteps = getRealFormSteps(fraud)

  /**
   * Save and match the form data to one or more models in database
   *
   * @param  {[type]} (payload) form data
   * @return {[type]}           {results: object, total: integer} or error
   */
  const save = useCallback(
    useAsyncDebounce(async (payload, validForm = true) => {
      const stepStatus = validForm ? 'ok' : 'ko'

      // Field in db has _ instead of -
      const stepStatusName = step.replace(/\-/g, '_')

      if (step === FORM_STEPS[0] && !payload.id) {
        payload.FraudBreadcrumb = {
          [stepStatusName]: stepStatus,
        }

        return createFraud(payload)
      }

      const fraud = fraudStore.getFraud()

      const breadCrumbs = {
        ...fraud.FraudBreadcrumb,
        [stepStatusName]: stepStatus,
      }

      let statusToUpdate = payload.status

      // if (!statusToUpdate) {
      statusToUpdate = calculateStatus(
        breadCrumbs,
        realSteps,
        fraud.status,
        payload.status
      )
      // }

      // fraudId = forms.general.id
      payload.FraudBreadcrumb = {
        id: fraud.FraudBreadcrumb.id,
        [stepStatusName]: stepStatus,
      }

      const consolidatedData = {
        ...payload,
        ref: fraud ? fraud.ref : null,
        status: statusToUpdate,
      }

      // Specific to remove some model links.
      if (payload.FraudTracking) {
        if (!payload.FraudTracking.customer_complaint) {
          payload.FraudTracking.FraudTrackingCustomerFile = null
        }
        if (!payload.FraudTracking.business_line_complaint) {
          payload.FraudTracking.FraudTrackingBusinessLineFile = null
        }
      }

      if (payload.FraudChequeIns) {
        payload.FraudChequeIns.forEach((fci) => {
          if (fci.FraudCheques) {
            fci.FraudCheques = fci.FraudCheques.map((fc) => ({
              ...fc,
              execution_date: fci.execution_date,
            }))
          }
        })
      }

      return updateFraud(consolidatedData)
    }, 250),
    [step, getForm, createFraud, realSteps]
  )

  useEffect(() => {
    setRealSteps(getRealFormSteps(fraud))
  }, [fraud])

  // Redirect to general step if unknown step.
  if (!FORM_STEPS.includes(step) || !realSteps.includes(step)) {
    history.replace(`/fraudes/${ref}/${FORM_STEPS[0]}`)
  }

  // Get Form definition
  const Form = useMemo(() => FormDefinitions[step], [step])
  // const Form = FormDefinitions[step]

  const form = useForm({
    defaultValues: {
      // ...Form.defaultValues,
      // ...getForm(fraudNamespace, step),
    },
  })

  const { reset } = form

  // Reset form data if step change, with default values and store.
  useEffect(() => {
    const values = getForm(fraudNamespace, step)
    // Remove fraud keys if valiue is an empty array
    const ffraud = removeNullValues(fraud)

    reset({
      ...Form.defaultValues,
      ...removeNullValues(values),
      ...ffraud,
    })
  }, [reset, getForm, step, fraud])

  useEffect(() => {
    if (step === 'overview') {
      setIsFinish(true)
      setShowPrint(true)
    } else {
      setShowPrint(false)
      setIsFinish(false)
    }
  }, [step])
  // Check if it's last step.
  // if ('overview' === step) {
  //   isFinish = true
  // }

  const goToStep = useCallback(
    (currentFraudRef, newStep) => {
      // const steps = getRealFormSteps(fraud)
      // const indcStep = getIndcStepByUrlParam(step, steps)
      const urlToGo = readOnly
        ? `/fraudes/${currentFraudRef}/${newStep}`
        : `/fraudes/${currentFraudRef}/edit/${newStep}`
      history.push(urlToGo)
    },
    [readOnly]
  )

  const saveFraud = useCallback(
    async (data, validForm) => {
      // save to DB at each step
      try {
        const req = await save(data, validForm)
        let savedFraud = req.results

        if (req.errors && Array.isArray(req.errors)) {
          req.errors.forEach((error) => setErrorMessage(error))
        }
        if (props.checkScenario) {
          props.checkScenario(savedFraud)
        }

        // Réinit form if fraud-type form saved
        if (step === 'fraud-type') {
          cleanFraudByType(data, resetForm)
        }

        // If store fraud has no User (author), set current user.
        const fraud = fraudStore.getFraud()

        if (!fraud.User) {
          savedFraud.User = currentUser
        }

        if (!req.error) {
          setForm(fraudNamespace, step, savedFraud)
          fraudStore.setFraud(req.results)
        }

        return req.results
      } catch (err) {
        console.log('🚀 ~ file: Form.js ~ line 358 ~ err', err)
        setErrorMessage(err.message)
      }
    },
    [setForm, step]
  )

  /**
   * If obj has no prop called arrayField, set an empty array value.
   * Return true if obj was cleaned, false instead.
   */
  const cleanArrayValue = useCallback((obj, arrayField) => {
    if (!obj.hasOwnProperty(arrayField)) {
      obj[arrayField] = []
      return true
    }

    return false
  })

  /**
   * Clean array props, setted to an empty array instead of undefined by current form.
   */
  const cleanValuesBeforeSave = useCallback((step, values) => {
    if (step === 'cheque-in') {
      if (cleanArrayValue(values, 'FraudChequeIns')) {
        values['FraudChequeIns'] = []
      } else {
        for (const fraudCheque of values.FraudChequeIns) {
          cleanArrayValue(fraudCheque, 'FraudCheques')
        }
      }
    } else if (step === 'cheque-out') {
      cleanArrayValue(values, 'FraudChequeOuts')
    } else if (step === 'bank-transfer-in') {
      cleanArrayValue(values, 'FraudBankTransferIns')
    } else if (step === 'bank-transfer-out') {
      cleanArrayValue(values, 'FraudBankTransferOuts')
    } else if (step === 'cash-in') {
      cleanArrayValue(values, 'FraudCashIns')
    } else if (step === 'cash-out') {
      cleanArrayValue(values, 'FraudCashOuts')
    } else if (step === 'documentary') {
      if (values.FraudDocumentary) {
        cleanArrayValue(values.FraudDocumentary, 'FakeDocs')
      }
    } else if (step === 'levy-out') {
      cleanArrayValue(values, 'FraudLevyOuts')
    } else if (step === 'levy-in') {
      cleanArrayValue(values, 'FraudLevyIns')
    }

    // else if (step === 'belongings-in') {
    //   cleanArrayValue(values, 'FraudBelongingsIn')
    // } else if (step === 'belongings-in') {
    //   cleanArrayValue(values, 'FraudBelongingsOut')
    // }
  })

  const checkErrorAndPersistFraud = useCallback(
    async (payload) => {
      form.clearErrors()
      const values = form.getValues()

      let validForm = await form.trigger()
      if (Form.validate) {
        validForm = Form.validate(form) && validForm
      }

      // if (step === 'fraud-type') {
      //   // Check if current step is fraud-type, and nothing is checked, then no valid form
      //   const everyFalse = Object.keys(values).every((key) => !values[key])
      //   validForm = !everyFalse
      // }

      if (
        validForm ||
        (await confirm(
          'Le formulaire comporte des erreurs, enregistrer et continuer ?'
        ))
      ) {
        try {
          const valuesToSave = {
            ...values,
            ...payload,
          }

          cleanValuesBeforeSave(step, valuesToSave)
          return saveFraud(valuesToSave, validForm)
        } catch (err) {
          console.error(err)
          setErrorMessage(err.message)
        }
      }
    },
    [form, step, Form]
  )

  // Valid, save form and change step
  const changeStep = useCallback(
    async ({ goto = false, next = false, previous = false }) => {
      if (readOnly) {
        let stepTarget = goto

        const getStepIdx = realSteps.findIndex((s) => s === step)
        if (next) {
          stepTarget = realSteps[getStepIdx + 1]
        }

        if (previous) {
          stepTarget = realSteps[getStepIdx - 1]
        }

        goToStep(fraud.ref, stepTarget)
        return
      }

      const savedFraud = await checkErrorAndPersistFraud()

      if (savedFraud) {
        let stepTarget = goto

        const newSteps = getRealFormSteps(savedFraud)
        const getStepIdx = newSteps.findIndex((s) => s === step)
        if (next) {
          stepTarget = newSteps[getStepIdx + 1]
        }

        if (previous) {
          stepTarget = newSteps[getStepIdx - 1]
        }

        goToStep(savedFraud.ref, stepTarget)
      }
    },
    [form, goToStep, fraud, step, readOnly]
  )

  const setCurrentStepData = useCallback(
    (data) => {
      setForm(fraudNamespace, step, data)
      reset(data)
    },
    [setForm, step, reset, form]
  )

  const saveAndGoBack = useCallback(() => {
    // TODO: use named step instead of indice
    // get current step, take the next step in the table realSteps
    // changeStep(realCurrentIndcStep - 1)

    changeStep({ previous: true })
  }, [realSteps, step])

  const saveAndGoNext = useCallback(() => {
    if (isFinish) {
      setSuccessMessage('Dossier de fraude bien enregistré')
      history.push(`/fraudes`)
    } else {
      changeStep({ next: true })
    }
  }, [realSteps, isFinish])

  const saveInDraft = useCallback(async () => {
    const savedFraud = await checkErrorAndPersistFraud({
      status: FRAUD_STATUS.BROUILLON,
    })

    if (savedFraud) {
      setSuccessMessage('Dossier de fraude bien enregistré en brouillon')
      history.push(`/fraudes`)
    }
  }, [])

  const saveAndPublish = useCallback(async () => {
    const savedFraud = await checkErrorAndPersistFraud()

    if (savedFraud) {
      setSuccessMessage('Dossier de fraude bien enregistré')
      history.push(`/fraudes`)
    }
  }, [])

  useEffect(() => {
    if (fraud && fraud.FraudBreadcrumb && step) {
      const stepStatus = fraud.FraudBreadcrumb[step.replace(/\-/g, '_')] || ''
      if (stepStatus === 'ko') {
        form.trigger()

        if (Form.validate) {
          Form.validate(form)
        }
      }
    }
  }, [realSteps, Form])

  return (
    <div className="flex">
      <div className="w-3/4 page-container">
        <form
          // onSubmit={form.handleSubmit(onSubmit, onError)}
          className="w-full form-container"
        >
          <h2 className="relative">
            <span>{Form.title}</span>
            {showPrint && (
              <span className="absolute top-0 right-0 mt-1 mr-2 text-3xl">
                <PrintIcon
                  onClick={handlePrint}
                  className="cursor-pointer fill-current"
                />
              </span>
            )}
          </h2>

          <div
            className="form-content"
            ref={printRef}
            style={{ backgroundColor: 'white' }}
          >
            {Form.sections.map(({ title, rows }, sidx) => (
              <React.Fragment key={`section-${sidx}`}>
                {title && (
                  <div
                    key={`section-${sidx}-title`}
                    className="section-header text-accent"
                  >
                    {title}
                  </div>
                )}
                {rows &&
                  rows.map((row, ridx) => (
                    <FormRow
                      key={`section-${sidx}-${ridx}`}
                      inputs={row}
                      idx={`$${sidx}-${ridx}`}
                      form={form}
                      readOnly={readOnly}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))}
              </React.Fragment>
            ))}
          </div>
          <div className="bottom-form">
            {step !== FORM_STEPS[0] && (
              <button
                type="button"
                onClick={() => {
                  saveAndGoBack()
                }}
                className="previous-step"
              >
                &larr;
              </button>
            )}
            {step === FORM_STEPS[0] && <div />}
            {!isFinish && (
              <button
                type="button"
                className="next-step bg-accent"
                onClick={() => {
                  saveAndGoNext()
                }}
              >
                &rarr;
              </button>
            )}
            {isFinish && !readOnly && (
              <div>
                <button
                  type="button"
                  className="mr-4 next-step bg-accent"
                  onClick={async () => {
                    if (
                      await confirm(
                        'Voulez-vous enregistrer et mettre la fraude en brouillon ?'
                      )
                    )
                      saveInDraft()
                  }}
                >
                  Enregistrer en brouillon
                </button>
                <button
                  type="button"
                  className="next-step bg-brand"
                  onClick={async () => {
                    if (
                      await confirm(
                        'Voulez-vous enregistrer et publier la fraude ?'
                      )
                    )
                      saveAndPublish()
                  }}
                >
                  Enregistrer
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
      <Breadcrumbs
        steps={realSteps}
        currentStep={step}
        readOnly={readOnly}
        changeStep={changeStep}
        isFinish={isFinish}
        saveInDraft={saveInDraft}
        saveAndPublish={saveAndPublish}
        saveAndGoBack={saveAndGoBack}
        saveAndGoNext={saveAndGoNext}
      />
    </div>
  )
}
