import General from "./general.js"
import FraudType from "./fraud-type"
import Loss from "./loss"
import FraudTracking from "./fraud-tracking"
import BankTransferIn from "./bank-transfer-in.js"
import BankTransferOut from "./bank-transfer-out.js"
import CashIn from "./cash-in.js"
import CashOut from "./cash-out.js"
import ChequeIn from "./cheque-in.js"
import ChequeOut from "./cheque-out.js"
import LevyIn from "./levy-in.js"
import LevyOut from "./levy-out.js"
import CbIn from "./cb-in.js"
import CbOut from "./cb-out.js"
import Documentary from "./documentary.js"
import BelongingsIn from "./belongings-in.js"
import BelongingsOut from "./belongings-out.js"
import Trade from "./trade.js"
import Cyber from "./cyber.js"
import ConsumerCredit from "./consumer-credit.js"
import Customer from "./customer.js"
import Insurance from "./insurance.js"
import Overview from './overview.js'

const forms = {
  general: General,
  customer: Customer,
  loss: Loss,
  'fraud-tracking': FraudTracking,
  'fraud-type': FraudType,
  'bank-transfer-in': BankTransferIn,
  'bank-transfer-out': BankTransferOut,
  'cash-in': CashIn,
  'cash-out': CashOut,
  'cheque-in': ChequeIn,
  'cheque-out': ChequeOut,
  'levy-in': LevyIn,
  'levy-out': LevyOut,
  documentary: Documentary,
  'belongings-in': BelongingsIn,
  'belongings-out': BelongingsOut,
  trade: Trade,
  cyber: Cyber,
  'consumer-credit': ConsumerCredit,
  insurance: Insurance,
  overview: Overview,
  'cb-in': CbIn,
  'cb-out': CbOut,
}

export const numberOfSteps = Object.keys(forms).length

export default forms
