import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'

import useBusinessLines from 'hooks/useBusinessLines'
import Select from 'components/form/Select'
import { getInputOptsByBlockedField, getDeepValue } from 'lib/utils'

export default function NetworkOrganisationSelect({
  form,
  input: { networkName, organisationName },
  blockedFieldName,
  disabled = false,
}) {
  const { getBusinessLines, getBusinessLinesByNetwotkId } = useBusinessLines()
  const inputNetworkError = getDeepValue(form.errors, networkName)
  const inputOrgError = getDeepValue(form.errors, organisationName)

  const watchedFields = form.watch([networkName, organisationName])
  const [networks, setNetworks] = useState([])
  const [businessLines, setBusinessLines] = useState([])

  useEffect(() => {
    const asyncGetBusinessLines = async () => {
      const req = await getBusinessLines(0, 100, [['level', 1]])
      const res = req.results.map((bl) => ({
        label: bl.name,
        value: bl.id,
      }))
      setNetworks(res)
    }

    asyncGetBusinessLines()
  }, [])

  useEffect(() => {
    if (watchedFields[networkName]) {
      getBusinessLinesByNetwotkId(watchedFields[networkName]).then((req) => {
        const res = req.results.map((bl) => ({
          label: bl.name,
          value: bl.id,
        }))
        setBusinessLines(res)
      })
    } else {
      setBusinessLines([])
    }
  }, [watchedFields[networkName]])

  return (
    <div className="flex-wrap form-row col-2">
      <Controller
        name={networkName}
        control={form.control}
        rules={getInputOptsByBlockedField({ form }, blockedFieldName)}
        render={({ onChange, onBlur, value, name }) => (
          <Select
            error={inputNetworkError && inputNetworkError.message}
            placeholder={'Réseau...'}
            name={name}
            value={value}
            options={getInputOptsByBlockedField({ form }, blockedFieldName)}
            values={networks}
            onChange={(e) => {
              onChange(e && e.value)
            }}
            label={'Réseau impacté'}
            disabled={disabled}
          />
        )}
      />
      <Controller
        name={organisationName}
        control={form.control}
        rules={getInputOptsByBlockedField({ form }, blockedFieldName)}
        render={({ onChange, onBlur, value, name }) => {
          return (
            <Select
              key={JSON.stringify(businessLines)}
              placeholder={'Établissement impacté'}
              name={name}
              value={value}
              error={inputOrgError && inputOrgError.message}
              options={getInputOptsByBlockedField({ form }, blockedFieldName)}
              values={businessLines}
              onChange={(e) => {
                onChange(e && e.value)
              }}
              label={'Établissement impacté'}
              disabled={disabled}
            />
          )
        }}
      />
    </div>
  )
}
