import React, { useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { AmountAndCurrencyWithProp } from './commonfields/amountAndCurrency'
import { getDeepValue } from 'lib/utils'
import Select from 'components/form/Select'
import { Controller } from 'react-hook-form'
import { getInputOptsByBlockedField } from 'lib/utils'
import Countries from './commonfields/countries'

const Cheque = [
  [
    {
      name: 'cheque_num',
      label: 'Numéro de chèque',
    },
    {
      type: 'select',
      name: 'cheque_type',
      label: 'Type de chèque',
      values: [
        {
          label: 'Chèque de banque',
          value: 'Chèque de banque',
        },
        {
          label: 'Chèque classique',
          value: 'Chèque classique',
        },
      ],
    },
  ],
]

export default {
  id: 'FraudChequeIn',
  title: 'Remises de chèque',
  // next: "2",
  defaultValues: { FraudChequeIns: [{}] },
  validate: (form) => {
    const fraud = form.getValues()
    let noError = true

    if (fraud && fraud.FraudChequeIns) {
      noError = fraud.FraudChequeIns.reduce((noError, fci, indc) => {
        if (!fci.FraudCheques || fci.FraudCheques.length === 0) {
          form.setError(`FraudChequeIns.${indc}.FraudCheques`, {
            message: 'Saisir au moins un chèque',
          })

          return false
        }

        return noError
      }, noError)
    }

    return noError
  },
  sections: [
    {
      // title: "Remise de chèque",
      rows: [
        [
          {
            name: 'FraudChequeIns',
            type: 'array',
            label: 'Remise de chèque',
            fields: [
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        type: 'select',
                        name: 'fraud_detection',
                        options: () => ({
                          required: 'Ce champ est requis',
                        }),
                        label: "Nom de l'outil",
                        placeholder: 'Choisir un moyen',
                        values: [
                          {
                            label: 'CTC',
                            value: 'CTC',
                          },
                          {
                            label: "Réserve d'encaissement",
                            value: "Réserve d'encaissement",
                          },
                          {
                            label: 'Outils internes',
                            value: 'Outils internes',
                          },
                          {
                            label: 'Autres',
                            value: 'Autres',
                          },
                        ],
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  name: 'aggregate',
                  type: 'yes-no',
                  label: 'Agrégat',
                  defaultValue: 'false',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  // This component compute the amount from
                  // the cheque.
                  // useful for exports
                  type: 'custom',
                  Component: ({ form, input, prefix }) => {
                    const chequesName = `${prefix}FraudCheques`
                    const amountName = `${prefix}amount`
                    const blockedName = `${prefix}blocked`
                    const watchFields = useWatch({
                      control: form.control,
                      name: [chequesName, amountName, blockedName],
                    })
                    const cheques = watchFields[chequesName]
                    const amount = watchFields[amountName]
                    const blocked = watchFields[blockedName]

                    useEffect(() => {
                      if (amount) {
                        if (blocked) {
                          form.setValue(`${prefix}blocked_amount`, amount)
                        } else {
                          form.setValue(`${prefix}blocked_amount`, 0)
                        }
                      }
                    }, [blocked])

                    useEffect(() => {
                      if (cheques && cheques.length > 0) {
                        const currentAmount = cheques.reduce(
                          (currentAmount, cheque) => {
                            return (
                              currentAmount +
                              parseFloat(cheque.consolidated_currency)
                            )
                          },
                          0
                        )
                        if (currentAmount !== parseFloat(amount)) {
                          form.setValue(`${prefix}amount`, currentAmount)
                          form.setValue(
                            `${prefix}consolidated_currency`,
                            currentAmount
                          )
                          if (blocked) {
                            form.setValue(
                              `${prefix}blocked_amount`,
                              currentAmount
                            )
                          } else {
                            form.setValue(`${prefix}blocked_amount`, 0)
                          }
                        }
                      }
                    }, [amount, cheques, blocked])

                    return <></>
                  },
                },
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
                {
                  hide: true,
                  type: 'hidden',
                  name: 'blocked_amount',
                  defaultValue: 0,
                },
                {
                  hide: true,
                  type: 'hidden',
                  name: 'consolidated_currency',
                  defaultValue: 0,
                },
                {
                  hide: true,
                  type: 'hidden',
                  name: 'amount',
                  defaultValue: 0,
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'transaction_id',
                  label: 'Identifiant transaction',
                  helpText: 'Identifiant de la remise de chèque',
                  autofocus: true,
                },
              ],

              [
                {
                  type: 'select',
                  name: 'deposit_means',
                  label: 'Moyen de remise',
                  placeholder: 'Choisir un moyen de remise',
                  values: [
                    {
                      label: 'Manuel',
                      value: 'Manuel',
                    },
                    {
                      label: 'Automate',
                      value: 'Automate',
                    },
                  ],
                },
              ],

              [
                {
                  name: 'agency_name',
                  label: 'Lieu de remise',
                  placeholder: "Nom de l'agence",
                },
                {
                  type: 'select',
                  name: 'deposit_type',
                  label: 'Type de remise',
                  placeholder: 'Choisir un type',
                  values: [
                    {
                      label: 'Classique',
                      value: 'Classique',
                    },
                    {
                      label: 'Déplacé',
                      value: 'Déplacé',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'operation",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) => ({
                    required: 'Ce champ est requis',
                  }),
                },
                { type: 'blank' },
              ],
              [
                {
                  type: 'custom',
                  Component: ({ form, prefix }) => {
                    return (
                      <div className="mb-4">
                        Total des chèques :{' '}
                        {form.watch(prefix + 'consolidated_currency')}€
                      </div>
                    )
                  },
                },
              ],

              [
                {
                  name: 'FraudCheques',
                  type: 'array',
                  label: 'Chèque',
                  fields: [
                    [
                      // Used for update entity by its id
                      {
                        hide: true,
                        type: 'hidden',
                        name: 'id',
                      },
                      {
                        // set exeuction date from FraudChequeIns
                        type: 'custom',
                        Component: ({ form, prefix, disabled }) => {
                          const pathSplitted = prefix.split('.')
                          const fci = pathSplitted[0]
                          const fci_execution_date = form.watch(
                            `${fci}.execution_date`
                          )
                          return (
                            <input
                              type="hidden"
                              name={`${prefix}execution_date`}
                              value={fci_execution_date}
                              ref={form.register}
                              disabled={disabled}
                            />
                          )
                        },
                      },
                    ],

                    ...Cheque,

                    AmountAndCurrencyWithProp(
                      false,
                      ({ form, prefix }, options) => {
                        return options
                      }
                    ),

                    [
                      Countries({
                        label: 'Pays du PSP du payeur',
                        name: 'buyer_psp_country',
                        defaultValue: 'FR',
                        options: (_props) => ({
                          required: 'Ce champ est requis',
                        }),
                      }),

                      {
                        type: 'select',
                        name: 'reject_reason',
                        label: 'Motif de rejet',
                        options: (props) => ({
                          // required: 'Ce champ est requis',
                        }),
                        placeholder: 'Choisir un motif',
                        values: [
                          { label: '10-Perte', value: '10-Perte' },
                          { label: '11-Vol', value: '11-Vol' },
                          {
                            label: '12-Utilisation frauduleuse',
                            value: '12-Utilisation frauduleuse',
                          },
                          {
                            label: '13-Décision judiciaire',
                            value: '13-Décision judiciaire',
                          },
                          {
                            label: '20-Chèque prescrit',
                            value: '20-Chèque prescrit',
                          },
                          {
                            label: "30-Absence d'une mention obligatoire",
                            value: "30-Absence d'une mention obligatoire",
                          },
                          {
                            label: '31-Signature non conforme',
                            value: '31-Signature non conforme',
                          },
                          {
                            label: '32-Insuffisance signature',
                            value: '32-Insuffisance signature',
                          },
                          {
                            label: '33-Falsification Surcharge',
                            value: '33-Falsification Surcharge',
                          },
                          {
                            label: '34-Faux chèques',
                            value: '34-Faux chèques',
                          },
                          {
                            label: "35-Absence ou irrégularité de l'endos",
                            value: "35-Absence ou irrégularité de l'endos",
                          },
                          {
                            label: '40-Décision judiciaire',
                            value: '40-Décision judiciaire',
                          },
                          {
                            label: '41-Saisie attribution ou conservatoire',
                            value: '41-Saisie attribution ou conservatoire',
                          },
                          {
                            label: '42-Avis à tiers détenteur',
                            value: '42-Avis à tiers détenteur',
                          },
                          {
                            label: '43-Décès du titulaire',
                            value: '43-Décès du titulaire',
                          },
                          {
                            label:
                              '44-Dénonciation de convention de compte collectif',
                            value:
                              '44-Dénonciation de convention de compte collectif',
                          },
                          {
                            label: '45-Motif réglementaire',
                            value: '45-Motif réglementaire',
                          },
                          {
                            label: '60-Chèque impayé pour le montant total',
                            value: '60-Chèque impayé pour le montant total',
                          },
                          {
                            label: '61-Chèque impayé pour le montant  partiel',
                            value: '61-Chèque impayé pour le montant  partiel',
                          },
                          {
                            label: '62-Compte clôturé',
                            value: '62-Compte clôturé',
                          },
                          {
                            label: 'Rejet hors délai',
                            value: 'Rejet hors délai',
                          },
                        ],
                      },
                      {
                        type: 'custom',
                        name: `fraud_type`,
                        label: 'Type de fraude',

                        Component: function FraudType({
                          form,
                          prefix,
                          input,
                          disabled,
                        }) {
                          const reject_reason = form.watch(
                            prefix + 'reject_reason'
                          )

                          let selection
                          useEffect(() => {
                            if (
                              [
                                '31-Signature non conforme',
                                'Rejet hors délai',
                                '10-Perte',
                                '11-Vol',
                                '12-Utilisation frauduleuse',
                              ].includes(reject_reason)
                            ) {
                              selection = 'Vol/perte'
                            } else if (
                              ['33-Falsification Surcharge'].includes(
                                reject_reason
                              )
                            ) {
                              selection = 'Falsification'
                            } else if (
                              ['34-Faux chèques'].includes(reject_reason)
                            ) {
                              selection = 'Contrefaçon'
                            } else if (
                              [
                                "35-Absence ou irrégularité de l'endos",
                              ].includes(reject_reason)
                            ) {
                              selection = 'Détournement/rejeu'
                            } else {
                              if (reject_reason)
                                selection = 'Hors périmètre collecte BdF'
                            }
                            if (selection)
                              form.setValue(`${prefix}fraud_type`, selection)
                          }, [reject_reason])

                          const inputOptions = input.options({ form, prefix })
                          const inputNetworkError = getDeepValue(
                            form.errors,
                            prefix + input.name
                          )

                          return (
                            <Controller
                              name={prefix + input.name}
                              control={form.control}
                              rules={inputOptions}
                              render={({
                                onChange,
                                value,
                                defaultValue,
                                name,
                              }) => {
                                return (
                                  <Select
                                    name={name}
                                    options={inputOptions}
                                    defaultValue={defaultValue}
                                    value={value}
                                    disabled={disabled}
                                    error={
                                      inputNetworkError &&
                                      inputNetworkError.message
                                    }
                                    values={[
                                      {
                                        value: 'Vol/perte',
                                        label: 'Vol/perte',
                                      },
                                      {
                                        value: 'Contrefaçon',
                                        label: 'Contrefaçon',
                                      },
                                      {
                                        value: 'Falsification',
                                        label: 'Falsification',
                                      },
                                      {
                                        value: 'Détournement/rejeu',
                                        label: 'Détournement/rejeu',
                                      },
                                      {
                                        value: 'Hors périmètre collecte BdF',
                                        label: 'Hors périmètre collecte BdF',
                                      },
                                    ]}
                                    onChange={(e) => {
                                      onChange(e && e.value)
                                    }}
                                    label={input.label}
                                  />
                                )
                              }}
                            />
                          )
                        },
                        options: (props) => ({
                          required: 'Ce champ est requis',
                        }),
                        autofocus: true,
                      },
                    ],
                    [
                      { type: 'blank' },
                      {
                        type: 'custom',
                        name: `fake_data`,
                        label: 'Données falsifiées',

                        Component: function FakeData({ form, prefix, input }) {
                          const fraud_type = form.watch(prefix + 'fraud_type')

                          const inputOptions = input.options({ form, prefix })

                          if (fraud_type !== 'Falsification') return <></>

                          return (
                            <Controller
                              name={prefix + input.name}
                              control={form.control}
                              rules={inputOptions}
                              render={({ onChange, value, name }) => {
                                return (
                                  <Select
                                    name={name}
                                    options={inputOptions}
                                    value={value}
                                    values={[
                                      {
                                        label: 'Montant',
                                        value: 'Montant',
                                      },
                                      {
                                        label: 'Ordre',
                                        value: 'Ordre',
                                      },
                                    ]}
                                    onChange={(e) => {
                                      onChange(e && e.value)
                                    }}
                                    label={input.label}
                                  />
                                )
                              }}
                            />
                          )
                        },
                        options: (props) => ({}),
                      },
                    ],
                  ],
                },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
