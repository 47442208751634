import React from 'react'

import { Controller } from 'react-hook-form'

export default function CheckBox(
  {
    label,
    name,
    required,
    // onChange,
    className = '',
    helpText,
    error,
    // value,
    suffix,
    defaultValue,
    form,
    register,
    disabled = undefined,
  },
  ref
) {
  let classes = className

  if (required) {
    classes += ' required'
  }
  
  return (
    <div>
      <label
        htmlFor={name}
        className={`${classes} checkbox-label flex items-center whitespace-no-wrap`}
      >
        <Controller
          name={name}
          control={form.control}
          render={({ onChange, onBlur, value, name }) => (
            <input
              type="checkbox"
              className="mr-1"
              id={name}
              checked={value}
              name={name}
              onChange={(e) => {
                onChange(e.target.checked)
              }}
              disabled={disabled}
            />
          )}
        />

        <span>{label}</span>
      </label>

      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}
    </div>
  )
}
