import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    downloadFile(path) {
      return Api.downloadFile(token, {
        query: {
          filters: [['path', path]],
        },
      })
    },
  }
}
