import amountAndCurrency from './commonfields/amountAndCurrency'
import { getInputOptsByBlockedField } from 'lib/utils'

export default {
  id: 'FraudCbOut',
  title: 'CB sortant',
  // next: "2",
  defaultValues: { FraudCbOuts: [{}] },
  sections: [
    {
      // title: "Dépôts d'espèces",
      rows: [
        [
          {
            name: 'FraudCbOuts',
            type: 'array',
            label: 'CB sortant',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
                { type: 'blank' },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        name: 'fraud_detection',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        label: "Nom de l'outil",
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  label: 'Agrégat',
                  defaultValue: 'false',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  name: 'operation_type',
                  label: "Type de l'opération",
                  type: 'select',
                  values: [
                    {
                      label: 'Paiement CB',
                      value: 'Paiement CB',
                    },
                    {
                      label: 'Retrait CB',
                      value: 'Retrait CB',
                    },
                  ],
                },
              ],

              amountAndCurrency,

              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
