import { COUNTRIES } from '../../../../lib/constants'

export default function Countries({ label, name, placeholder, options = {}, defaultValue }) {
  return {
    type: 'select',
    name,
    label,
    options,
    placeholder,
    defaultValue,
    values: COUNTRIES.map(({ label, value }) => ({ label, value })),
  }
}
