import React from 'react'

import Form from 'components/export/Form'
import ExportIcon from 'components/svg/export'
import Layout from 'components/Layout'

const ExportPage = ({ location }) => {

  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <ExportIcon className="fill-current text-brand" />
        <span className="ml-2">Générer un export</span>
      </h1>
      <Form />
    </Layout>
  )
}

export default ExportPage
