import React, { useCallback, useEffect, useState } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import useCustomers from '../../../hooks/useCustomers'
import Select from '../../form/Select'
import Modal from '../Modal'
import CustomerForm from './CustomerForm'
import dayjs from 'dayjs'
import CustomersSharingAddresses from './CustomersSharingAddresses'
import confirm from 'components/common/ConfirmModal'

const getNames = (str) => {
  const names = str.split(' ')
  let firstname = ''
  let lastname = ''

  names.forEach((name, i) => {
    if (i === 0) lastname = name
    if (i === 1) firstname = name
    if (i > 1) firstname += ` ${name}`
  })

  return {
    firstname,
    lastname,
  }
}

const sortByUpdatedAt = (a, b) => {
  const updatedDateA = new Date(a.updatedAt)
  const updatedDateB = new Date(b.updatedAt)
  if (updatedDateA < updatedDateB) return 1
  if (updatedDateA > updatedDateB) return -1
  return 0
}

const getBusinessName = (customer) => {
  if (customer.BusinessLines && customer.BusinessLines.length > 0) {
    const mostRecentBusinessLine = customer.BusinessLines.sort((a, b) =>
      sortByUpdatedAt(a.CustomerBusinessLine, b.CustomerBusinessLine)
    )
    const { name } = mostRecentBusinessLine[0]
    return `${name}`
  }
  return ''
}

const getLocation = (customer) => {
  if (customer.Addresses && customer.Addresses.length > 0) {
    const mostRecentAddress = customer.Addresses.sort((a, b) =>
      sortByUpdatedAt(a.CustomerAddress, b.CustomerAddress)
    )
    const { postalCode, city } = mostRecentAddress[0]
    return `${postalCode} ${city}`
  }
  return ''
}

const getBirthdate = (customer) => {
  return customer.birthdate
    ? dayjs(customer.birthdate).format('DD/MM/YYYY')
    : ''
}

const formatOptionLabel = (value) => {
  let label
  if (value.lastname && value.firstname && getBirthdate(value))
    label = `${value.lastname} ${value.firstname} ${getBirthdate(value)}`
  else label = value.denomination

  if (value.id) {
    return (
      <>
        <span className="mr-2 font-bold">{label}</span>
        <span className="text-xs text-gray-600">{getLocation(value)}</span>
        <span
          className="float-right px-2 mt-1 ml-2 lowercase align-middle border border-solid rounded-full opacity-75 text-accent border-accent"
          style={{ fontSize: '11px' }}
        >
          {getBusinessName(value)}
        </span>
      </>
    )
  }
  return value.label
}
const getSelectLabel = (customer) =>
  `${customer.firstname} ${customer.lastname}`

export default ({
  form,
  prefix,
  BusinessLineId,
  setCurrentStepData,
  disabled,
  input,
}) => {
  const { name } = input
  const [openModal, setOpenModal] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(null)

  const [newOption, setNewOption] = useState({
    firstname: '',
    lastname: '',
  })

  const { getCustomers, getCustomer } = useCustomers()

  const findCustomers = async (customerName) => {
    const customers = await getCustomers(0, 20, [["Nom", "like", customerName]])
    return customers.results.map((customer) => {
      return {
      ...customer,
      CustomerId: customer.CustomerId,
      value: customer.CustomerId,
      label: customer.customerName || customer.denomination,
    }})
  }

  useEffect(() => {
    const values = form.getValues()

    if (values.CustomerId) {
      const asyncGetCurrentCustomer = async () => {
        const req = await getCustomers(0, 1, [["customerId", parseInt(values.CustomerId)]])
        setCurrentCustomer(req.results[0])
      }

      asyncGetCurrentCustomer()
    }
  }, [form])

  const selectedCustomer = useWatch({
    control: form.control,
    name: 'CustomerId',
  })

  const onSelectedCustomer = (selection) => {
    const customerEntry = {
      ...selection,
      label: getSelectLabel(selection),
      value: selection.id,
    }
    const data = form.getValues()

    setCurrentStepData({
      ...data,
      CustomerId: customerEntry.id,
    })
  }

  const editCustomer = useCallback(async () => {
    const customer = await getCustomer(currentCustomer.customerId)
    setNewOption(customer.results)
    setOpenModal(true)
  }, [selectedCustomer, currentCustomer])

  const defaultOptions = []
  
  if (currentCustomer) {
    defaultOptions.push({value: currentCustomer.customerId, label: currentCustomer.customerName})
  }

  return (
    <div className="w-full">
      <Controller
        control={form.control}
        name={name}
        render={({ onChange, value, name }) => {
          return (
            <>
              <Select
                async
                noOptionsMessage={() => "Taper pour rechercher un client..."}
                key={`customer-${currentCustomer && currentCustomer.customerId}`}
                placeholder={'Choisir un client...'}
                name={name}
                value={value}
                keepOldValue
                disabled={disabled}
                values={defaultOptions}
                formatOptionLabel={formatOptionLabel}
                onChange={(customer) => {
                  setCurrentCustomer(customer)
                  if (customer) {
                    onChange(customer.customerId)
                  } else {
                    onChange(null)
                  }
                }}
                label={'Client'}
                creatable={{
                  formatCreateLabel: (str) => `Créer le client ${str}`,
                  onCreateOption: (str) => {
                    setNewOption(getNames(str))
                    setOpenModal(true)
                  },
                }}
                loadOptions={findCustomers}
                className={'w-full'}
              />
              {selectedCustomer && !disabled && (
                <div>
                  <div className="text-right">
                    <a
                      className="text-sm text-right text-blue-500 underline cursor-pointer"
                      onClick={editCustomer}
                    >
                      modifier la fiche client
                    </a>
                  </div>
                  <CustomersSharingAddresses
                    customer={{ id: selectedCustomer }}
                  />
                </div>
              )}
            </>
          )
        }}
      />

      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title={
          selectedCustomer
            ? 'Modifier la fiche client'
            : 'Créer un nouveau client'
        }
      >
        <CustomerForm
          BusinessLineId={BusinessLineId}
          customer={newOption}
          onSuccess={(selectedCustomer) => {
            setOpenModal(false)
            onSelectedCustomer(selectedCustomer)
          }}
          onCancel={async () => await confirm(
            "Êtes-vous sûr d'annuler et perdre les modifications ?"
          ) && setOpenModal(false)}
        />
      </Modal>
    </div>
  )
}
