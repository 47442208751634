export default {
  id: 'fraud-types',
  title: 'Choix des étapes à compléter',
  // next: "2",
  defaultValues: {},
  sections: [
    {
      title: 'Déclaration de fraude : Documentaire / Cyber',
      rows: [
        [
          {
            type: 'checkbox',
            name: 'is_documentary',
            label: 'Documentaire',
          },
          {
            type: 'checkbox',
            name: 'is_cyber',
            label: 'Cyber',
          },
        ],
      ],
    },
    {
      title: 'Déclaration de fraude : Opérations',
      rows: [
        [
          {
            type: 'multi-checkbox',
            label: 'Virements',
            options: {
              choices: [
                {
                  label: 'Fond entrant',
                  // value: 'entrant',
                  name: 'is_bank_transfer_in',
                },
                {
                  label: 'Fond sortant',
                  // value: 'sortant',
                  name: 'is_bank_transfer_out',
                },
              ],
            },
          },
        ],
        [
          {
            type: 'multi-checkbox',
            label: 'Espèces',
            options: {
              choices: [
                {
                  label: 'Fond entrant',
                  // value: 'entrant',
                  name: 'is_cash_in',
                },
                {
                  label: 'Fond sortant',
                  // value: 'sortant',
                  name: 'is_cash_out',
                },
              ],
            },
          },
        ],

        [
          {
            type: 'multi-checkbox',
            label: 'Chèques',
            options: {
              choices: [
                {
                  label: 'Fond entrant',
                  name: 'is_cheque_in',
                },
                {
                  label: 'Fond sortant',
                  name: 'is_cheque_out',
                },
              ],
            },
          },
        ],
        [
          {
            type: 'multi-checkbox',
            label: 'CB',
            options: {
              choices: [
                {
                  label: 'Fond entrant',
                  name: 'is_cb_in',
                },
                {
                  label: 'Fond sortant',
                  name: 'is_cb_out',
                },
              ],
            },
          },
        ],

        [
          {
            type: 'multi-checkbox',
            label: 'Prélèvements',
            options: {
              choices: [
                {
                  label: 'Fond entrant',
                  name: 'is_levy_in',
                },
                {
                  label: 'Fond sortant',
                  name: 'is_levy_out',
                },
              ],
            },
          },
        ],

        [
          {
            type: 'multi-checkbox',
            label: 'Effets',
            options: {
              choices: [
                {
                  label: 'Fond entrant',
                  name: 'is_belongings_in',
                },
                {
                  label: 'Fond sortant',
                  name: 'is_belongings_out',
                },
              ],
            },
          },
        ],

        // [
        //   {
        //     type: 'multi-checkbox',
        //     label: 'Autre',
        //     options: {
        //       choices: [
        //         {
        //           label: 'Retrait Carte bancaire',
        //           // value: 'entrant',
        //           name: 'is_credit_card_withdrawal',
        //         },
        //         {
        //           label: 'Monnaie électronique',
        //           // value: 'sortant',
        //           name: 'is_electronic_currency',
        //         },
        //       ],
        //     },
        //   },
        // ],

        // [
        //   {
        //     type: "yes-no",
        //     name: "is_credit-card",
        //     label: "Carte bleue",
        //   },
        // ],
      ],
    },
    {
      type: 'checkbox',
      name: 'is_cyber',
      label: 'Cyber',
    },

    {
      title: 'Déclaration de fraude : Autres',
      rows: [
        [
          {
            type: 'checkbox',
            name: 'is_consumer_credit',
            label: 'Crédit conso',
          },
          {
            type: 'checkbox',
            name: 'is_insurance',
            label: 'Assurance-Vie',
          }, {
            type: 'checkbox',
            name: "is_trade",
            label: "Trade"
          }
        ],
      ],
    },
  ]
}
