import React from "react"
import SortDescIcon from "components/svg/sortDesc"
import SortAscIcon from "components/svg/sortAsc"

const SortIndicator = (props) => {
    const { column, ...otherProps } = props
        if (column.isSorted) {
            if (column.isSortedDesc) {
                return <SortDescIcon {...otherProps} />
            }
            return <SortAscIcon {...otherProps} />
        }
        return ""
}

export {
    SortIndicator
}
