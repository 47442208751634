import React, { useState, useEffect, useRef } from 'react'
import Select from '../../form/Select'
import Input from '../../form/Input'
import { useForm, Controller } from "react-hook-form"
import useAddresses from '../../../hooks/useAddresses'
import { useNotify } from "../../../stores/notify"
import COUNTRIES from './countries'

const getErrorForfield = (errors, fieldName) => {
	return (errors && errors[fieldName] && errors[fieldName].message) || undefined
}


export default ({ address, onSuccess, onCancel }) => {
	const { line1 } = address
	const { register, handleSubmit, watch, errors, control, formState, clearErrors, getValues ,setValue} = useForm({
		defaultValues: {
			line1,
			country: "FR"
		}
	})


	const [errorsOnSubmit, setErrorsOnSubmit] = useState(false)
  const [cityOptions, setCityOptions] = useState([])

	const { createAddress, findAddress , findCityByPostalCode} = useAddresses()
	const { setErrorMessage, setSuccessMessage } = useNotify(({ setErrorMessage, setSuccessMessage }) => ({
		setErrorMessage,
		setSuccessMessage
	}))


	useEffect(() => {
		if (formState.isDirty) {
			clearErrors()
			setErrorsOnSubmit(false)
		}
	}, [formState.isDirty])

  useEffect(() => {
    const fetchCitiesFromCode = async () => {
      const cities = await findCityByPostalCode(watch("postalCode"))
      if (cities && cities.results) {
        const options = cities.results.map(c => ({label:c.name, value:c.name}))
        setCityOptions(options)
        if (options[0])
          setValue("city", options[0].value)
      }
    }

    fetchCitiesFromCode()
  }, [watch("postalCode")])

	const onSubmit = async address => {
		setErrorsOnSubmit(false)
		const newAddress = {
			...address,
		}

		const isExistingAddress = await findAddress({
			line1: address.line1,
			postalCode: address.postalCode,
			city: address.city,
			country: address.country,
		})
		if (isExistingAddress.results) {
			setSuccessMessage(`L'adresse existe et a été automatiquement sélectionnée`)
			onSuccess(isExistingAddress.results)
		} else {

			const res = await createAddress(newAddress)
			if (res.error) {
				setErrorMessage(res.error.message)
			} else {
				setSuccessMessage(`L'adresse a été créée avec succès`)
				onSuccess(res.results)
			}
		}
	}

	const onError = (errors) => {
		setErrorsOnSubmit(true)
	}

	return (
    <form key="new-address-modal" id="new-address">
      <div className="form-row col-1">
        <Input
          type="text"
          name="line1"
          label="Adresse"
          placeholder="Adresse"
          ref={register({ required: 'Adresse obligatoire' })}
          error={getErrorForfield(errors, 'line1')}
          required
        />
      </div>
      <div className="form-row col-1">
        <Input
          type="text"
          name="line2"
          placeholder="Complément d'adresse"
          label="Complément d'adresse"
          ref={register()}
          error={getErrorForfield(errors, 'line2')}
        />
      </div>
      <div className="form-row col-2">
        <Input
          type="text"
          name="postalCode"
          placeholder="Code postal"
          label="Code postal"
          ref={register({ required: 'Code postal obligratoire' })}
          error={getErrorForfield(errors, 'postalCode')}
          required
        />

        <Controller
          name="city"
          control={control}
          rules={{ required: 'Le pays est obligatoire' }}
    render={({ onChange, onBlur, value, name, defaultValue }) => { 

      return (
        <Select
          key={JSON.stringify(cityOptions)}
          name={name}
          defaultValue={defaultValue}
          placeholder="Ville"
          label="Ville"
          creatable={{
            formatCreateLabel: (str) => `${str}`,
            onCreateOption: (str) => {
              const newValue = { label: str, value: str }
              setCityOptions([...cityOptions, newValue])
              onChange(str)
            },
          }}
          values={cityOptions}
          onChange={(e) => onChange(e && e.value)}
          value={value}
          error={getErrorForfield(errors, 'city')}
          required
        />
      ) }}
        />
      </div>
      <Controller
        name="country"
        control={control}
        rules={{ required: 'Le pays est obligatoire' }}
        render={({ onChange, onBlur, value, name, defaultValue }) => (
          <>
            <Select
              placeholder={'Pays...'}
              required
              name={name}
              value={value}
              values={COUNTRIES}
              options={COUNTRIES}
              defaultValue={defaultValue}
              onChange={(e) => {
                onChange(e ? e.value : null)
              }}
              label={'Pays'}
            />
          </>
        )}
      />

      <div className="flex justify-end mt-8">
        <div
          className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
          onClick={onCancel}
        >
          Annuler
        </div>
        <div
          className="p-2 px-4 ml-2 text-white rounded-sm cursor-pointer bg-accent"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Créer
        </div>
      </div>
      {errorsOnSubmit && (
        <div className="mt-2 text-sm text-right text-red-500">
          Le formulaire contient des erreurs.
        </div>
      )}
    </form>
  )
}
