import React, { useEffect } from 'react'

import { getDeepValue } from 'lib/utils'
import { COUNTRIES } from 'lib/constants'
import Input from 'components/form/Input'

export default ({ disabled, form, prefix, input }) => {
  const inputName = prefix + input.name

  const inputError = getDeepValue(form.errors, inputName)
  const bicValue = form.watch(inputName)
  const options = input.options({ form, prefix })

  useEffect(() => {
    if (bicValue) {
      const countryFromBic = bicValue.substring(4, 6)
      if (countryFromBic) {
        const country = COUNTRIES.find(
          (c) =>
            c.value && c.value.toLowerCase() === countryFromBic.toLowerCase()
        )
        if (country) {
          form.setValue(prefix + 'geo_zone', country.zone_geo)
          form.setValue(prefix + 'country', country.value)
          if (country.type_virement) {
            form.setValue(prefix + 'payment_type', country.type_virement)
          }
        }
      }
    }
  }, [bicValue])
  return (
    <Input
      ref={form.register(options)}
      key={inputName + JSON.stringify(options)}
      disabled={disabled}
      type="text"
      name={inputName}
      label={input.label}
      value={bicValue}
      error={inputError && inputError.message}
      required={options && options.required}
      autofocus={true}
    />
  )
}
