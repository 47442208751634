import create from "zustand"
import { immer, persist } from "state/middlewares"
import shallow from "lib/shallow"

const formsStore = create(
  persist(
    "forms",
    immer((set, get) => ({
      forms: {},
      // Saves the form values in a persisted store
      // so that it can be retrieved later
      setForm: (namespace, name, formValues) => {
        set((state) => {
          if (!state.forms[namespace]) state.forms[namespace] = {}
          state.forms[namespace][name] = formValues
        })
      },
      // Gets form values from the store for the form
      // with the given name
      getForm: (namespace, name) => {
        const state = get()
        if (
          !Object.hasOwnProperty.call(state.forms, namespace) ||
          !Object.hasOwnProperty.call(state.forms[namespace], name)
        ) {
          return {}
        }

        return state.forms[namespace][name]
      },
      getAllForms: (namespace) => {
        return get().forms
      },
      resetForm: (namespace, name) => {
        set((state) => {
          if (!name) {
            delete state.forms[namespace]
          } else if (state.forms[namespace]){
            delete state.forms[namespace][name]
          }
        })
      }
      
    }))
  )
)

export const useForms = (slices) => shallow(formsStore, slices)
export const formsApi = formsStore
