import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M82.25 29.438c-.047-.14-.11-.281-.172-.422-.008-.016-.02-.023-.027-.039-.031-.062-.043-.14-.086-.195a7.805 7.805 0 00-1.438-2.016L59.172 5.407c-.672-.672-1.437-1.203-2.25-1.547-.015-.015-.03-.031-.047-.031a.29.29 0 00-.109-.031c-.953-.453-2.031-.672-3.125-.672h-28.64a7.815 7.815 0 00-7.813 7.812v78.125c0 4.313 3.5 7.813 7.812 7.813h50c4.313 0 7.813-3.5 7.813-7.813V32.297c0-1-.188-1.968-.563-2.859zm-4.781-1.313h-14.97a4.701 4.701 0 01-4.687-4.687V8.469zm-27.47 43.75c-7.75 0-14.061-6.312-14.061-14.061S42.25 43.752 50 43.752s14.062 6.312 14.062 14.062S57.749 71.876 50 71.876z" />
            <path d="M55.141 58.266l-3.578 3.578V50c0-.86-.703-1.562-1.563-1.562s-1.562.703-1.562 1.562v11.844l-3.578-3.578c-.61-.61-1.61-.61-2.219 0s-.61 1.61 0 2.219l6.25 6.25c.14.14.313.25.5.328.078.031.14.062.219.062.125.047.25.063.39.063s.266-.016.391-.063c.078 0 .14-.03.219-.062.187-.078.36-.188.5-.328l6.25-6.25c.61-.61.61-1.61 0-2.219s-1.61-.61-2.22 0z" />
        </svg>
    )
}

export default SvgComponent
