import React, { useState } from 'react'

import Form from 'components/form/Form'
import ExportIcon from 'components/svg/export'
import Layout from 'components/Layout'
import Api from 'lib/api'
import { findFiles, formatFiles } from 'hooks/useFrauds'
import { useUser } from 'stores/user'
import useUsers from 'hooks/useUsers'
import { useNotify } from 'stores/notify'
import { useRedirect } from 'stores/redirect'

const ImportForm = ({
  setErrorMessage,
  getAllMyBusinessLines,
  entity,
  handleResponse,
}) => {
  const formDefinitions = {
    id: 'import',
    title: 'Import',
    // next: "2",
    // defaultValues: { conditions: [] },
    sections: [
      {
        title: null,
        rows: [
          [
            {
              isClearable: false,
              type: 'async-select',
              name: 'businessLineId',
              label:
                "Choisir l'établissement sur lequel seront importées les fraudes",
              getList: getAllMyBusinessLines,
              options: { required: 'Ce champ est requis' },
            },
          ],
          [
            {
              options: { required: 'Ce champ est requis', limit: 1 },
              type: 'file',
              name: 'FraudImport',
              label: 'Fichier',
              multiple: false,
              btnHelpText: 'Choisir votre fichier de fraudes à importer.',
            },
          ],
        ],
      },
    ],
  }

  const [formEntity, setFormEntity] = useState(entity)
  const { token } = useUser(({ token }) => ({ token }))

  const [loading, setLoading] = useState(false)

  const onSubmit = async (e) => {
    setLoading(true)
    const files = findFiles(e)
    let formData = e
    formData = formatFiles(formData, files, e)
    setFormEntity(e)

    let headers = { Accept: 'application/json' }

    headers['Content-Type'] = 'multipart/form-data'
    try {
      const { results } = await Api.importFrauds(token, {
        body: formData,
        headers,
      })

      handleResponse(results)
    } catch (err) {
      setErrorMessage(err.message)
    }
    setLoading(false)
  }
  return (
    <Form
      loading={loading}
      btnSave="Importer"
      entity={formEntity}
      formdefinitions={formDefinitions}
      onSubmit={onSubmit}
    />
  )
}

const ImportPage = ({ location }) => {
  const { getAllMyBusinessLines } = useUsers()

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const { setRedirect } = useRedirect(({ setRedirect }) => ({
    setRedirect,
  }))

  const handleResponse = (res) => {
    const { id } = res

    if (id) {
      setRedirect(`/import/${id}`)
    } else {
      setErrorMessage(res.error || 'Une erreur est survenue.')
    }
  }

  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <ExportIcon className="fill-current text-brand" />
        <span className="ml-2">Importer des fraudes</span>
      </h1>
      <ImportForm
        setErrorMessage={setErrorMessage}
        getAllMyBusinessLines={getAllMyBusinessLines}
        handleResponse={handleResponse}
      />
    </Layout>
  )
}

export default ImportPage
