import * as React from "react"
import SortDescIcon from "components/svg/sortDesc"

function SvgComponent(props) {
    return (
        <SortDescIcon {...props} style={{
            transform: "scaleY(-1)"
        }} />
    )
}

export default SvgComponent
