import Api from "lib/api";

import { useUser } from "stores/user";

export default () => {
  const token = useUser(({ token }) => token)

  return {
    exportCSV: async (payload) => {

      try {
        const rawResponse = await Api.export(token, {
          body: payload
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
  }
};
