import React from 'react'

import { Link } from 'react-router-dom'

export default function LoggedOutHeader() {
  return (
    <div className="flex items-center place-content-center mt-32 mb-8">
      {/* <Link to="/"> */}
        <img
          src="/logo-bpce.png"
          alt="logo bpce"
          className="logo"
          style={{ width: '400px' }}
        />
      {/* </Link> */}
      <span className="text-3xl mx-6">|</span>
      <span className="text-brand font-bold text-3xl">FREGAT</span>
    </div>
  )
}
