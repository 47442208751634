import React, { useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import Table from 'components/common/table'
import IncompleteIcon from 'components/svg/incomplet'
import WarningIcon from 'components/svg/warning'
import useFrauds from 'hooks/useFrauds'
import useUsers from 'hooks/useUsers'
import useFraudListViews from 'hooks/useFraudListViews'
import { dateFmt } from 'components/common/table/formats'
import {
  DefaultColumnFilter,
  DateColumnFilter,
  BooleanColumnFilter,
  SelectColumnFilter,
} from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  EditAction,
  RemoveAction,
  ShowAction,
  DuplicateAction,
} from 'components/common/table/actions'
import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'updatedAt',
    desc: true,
  },
]

const FraudsList = () => {
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { isDeclarant } = useUsers()

  const { deleteFraud } = useFrauds()
  const { getFraudListViewsSameBusinessLines } = useFraudListViews()

  const fetchData = useApiFetch(getFraudListViewsSameBusinessLines)

  const [removed, setRemoved] = useState(null)
  const [added, setAdded] = useState(null)
  const onRemove = async (ref) => {
    const req = await deleteFraud({ ref })
    if (req.results && req.results.success) {
      setSuccessMessage(`Elément supprimé avec succès`)
      setRemoved(ref)
    } else {
      setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'N°',
        accessor: 'ref', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return (
            <Link to={`/fraudes/${original.ref}/overview`}>{original.ref}</Link>
          )
        },
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Établissement',
        accessor: 'blName',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Processus',
        accessor: 'processName',
        Filter: DefaultColumnFilter,
        // Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: 'Fraude',
        accessor: 'label',
        Filter: DefaultColumnFilter,
        Cell: ({ row: { original } }) => {
          return (
            <Link
              to={`/fraudes/${original.ref}/overview`}
              className="text-blue-500"
            >
              {original.label}
            </Link>
          )
        },
      },
      {
        Header: 'Id client',
        accessor: 'customerRef',
        Filter: DefaultColumnFilter,
        Cell: ({ row: { original } }) => {
          return (
            <Link
              to={`/clients/${original.customerId}/edit`}
              className="text-blue-500"
            >
              {original.customerRef}
            </Link>
          )
        },
      },
      {
        Header: 'Nom client',
        accessor: 'customerName',
        Filter: DefaultColumnFilter,
        Cell: ({ row: { original } }) => {
          return (
            <Link
              to={`/clients/${original.customerId}/edit`}
              className="text-blue-500"
            >
              {original.customerName}
            </Link>
          )
        },
      },
      {
        Header: 'Fraude brute',
        accessor: 'try_amount',
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => value || 0 + '€',
      },
      {
        Header: 'Statut',
        accessor: 'status',
        Filter: (props) =>
          SelectColumnFilter({
            ...props,
            values: [
              'Brouillon',
              'Brouillon complet',
              'Brouillon incomplet',
              'Clos',
              'En cours',
            ],
          }),
        Cell: ({ value, row: { original } }) => {
          return (
            <span className="flex flex-row w-24">
              {value}
              {!original.completed && value === 'Brouillon' && (
                <span title="Brouillon incomplet">
                  <WarningIcon className="ml-2 " />
                </span>
              )}
            </span>
          )
        },
      },
      {
        Header: 'Modifiée le',
        accessor: 'updatedAt',
        Filter: DateColumnFilter,
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'ref',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <ShowAction to={`/fraudes/${props.value}/customer`} />
            {isDeclarant() && (
              <>
                <DuplicateAction setAdded={setAdded} entity={'la fraude'} id={props.value} />
                <EditAction
                  entity={'la fraude'}
                  to={`/fraudes/${props.value}/edit/customer`}
                />
                <RemoveAction onRemove={onRemove} {...props} />
              </>
            )}
          </Actions>
        ),
      },
    ],
    []
  )

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des fraudes"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        rowAdded={added}
        initialSortBy={initialSortBy}
      />
    </div>
  )
}

export default FraudsList
