import React from "react"

import Layout from "components/Layout"
import CustomersList from "components/customers/List"

const CustomersListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <CustomersList />
    </Layout>
  )
}

export default CustomersListPage
