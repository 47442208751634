import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <path d="M83.5 83.5C102 65 102 35 83.5 16.5S35-2 16.5 16.5s-18.5 48.5 0 67 48.5 18.5 67 0zM24.102 24.102C38.403 9.801 61.704 9.801 76 24.102 90.301 38.403 90.301 61.704 76 76c-14.301 14.301-37.602 14.301-51.898 0C9.7 61.602 9.7 38.398 24.102 24.102zM44.2 30c-.102-1 .102-2 .5-2.898 1.2-2.602 4-4 6.8-3.3 2.7.698 4.5 3.3 4.302 6.198-.102 1.899-.2 3.7-.301 5.602-.399 7-.801 14-1.102 20.898-.101 2.3-2 4.102-4.3 4.102-2.399 0-4.2-1.801-4.301-4.301-.102-1.399-.102-2.801-.2-4.2-.3-4.5-.5-9-.8-13.601-.196-2.7-.399-5.602-.598-8.5zm0 40.602c0-3.199 2.602-5.898 5.8-5.898s5.802 2.602 5.9 5.8c0 3.2-2.602 5.802-5.802 5.802-3.199.093-5.898-2.504-5.898-5.704z" />
        </svg>
    )
}

export default SvgComponent
