import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import reset from 'state/dataReset'
import shallow from 'lib/shallow'

import Api from 'lib/api'

const fraudStore = create(
  persist(
    'fraud',
    immer((set, get) => ({
      token: null,
      fraud: {},
      error: null,
      loading: false,
      setError: (error) => {},

      async storeFraudSave(fraud) {
        set((state) => ({
          ...state,
          ...fraud,
        }))
      },

      setFraud(fraud) {
        set((state) => {
          state.fraud = { ...state.fraud, ...fraud }
        })
      },
      resetFraud() {
        set((state) => {
          state.fraud = { }
        })
      },
      getFraud() {
        const state = get()
        return state.fraud
      },
    }))
  )
)

export const useFraudStore = (slices) => shallow(fraudStore, slices)
export const fraudApi = fraudStore
