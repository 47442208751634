import Api from 'lib/api'

import { useUser } from 'stores/user'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    getBusinessLines: async (offset = 0, limit = 1000, filters = []) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getBusinessLines(token, {
          query: { offset, limit, filters },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    getBusinessLineByAccToken: (token) => {
      return Api.getBusinessLineByAccToken(token)
    },
    getBusinessLinesByNetwotkId: (networkId) => {
      return Api.getBusinessLinesByNetwotkId(token, networkId)
    },
    deleteCustomerBusinessLine: async (customerBusinessLine) => {
      //   setLoading(true)
      const { id } = customerBusinessLine
      try {
        const rawResponse = await Api.deleteCustomerBusinessLine(token, id)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    createCustomerBusinessLine: async (payload) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.createCustomerBusinessLine(token, {
          body: payload,
          query: { populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
  }
}
