import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="10 10 80 80"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M74.984 27.484l-2.844 2.813c-9.926 9.949-20.55 21.348-30.469 31.405L27.515 50.047l-3.062-2.563-5.094 6.188 3.094 2.53 17 14 2.78 2.313 2.595-2.562c10.75-10.773 22.414-23.391 33-34l2.812-2.844z" />
    </svg>
  )
}

export default SvgComponent
