import { useUser } from 'stores/user'
import Api from 'lib/api'
import { USER_ROLE } from 'lib/constants'

export default () => {
  const { token, BusinessLines = [], role, is_nps } = useUser((o) => o)

  function hasRole(role) {
    const isRole = BusinessLines.find(
      (bl) => bl.UserBusinessLine && bl.UserBusinessLine.role === role
    )

    return isRole != null
  }

  function isAuditEtablissement() {
    return hasRole(USER_ROLE.AUDIT_ETABLISSEMENT)
  }

  function isAuditGroupe() {
    return hasRole(USER_ROLE.AUDIT_GROUPE)
  }

  function isAudit() {
    return isAuditGroupe() || isAuditEtablissement()
  }

  function isDeclarant() {
    return hasRole(USER_ROLE.DECLARANT)
  }

  function isDeclarantTransverse() {
    return hasRole(USER_ROLE.DECLARANT_TRANSVERSE)
  }

  function isReferent() {
    return hasRole(USER_ROLE.REFERENT)
  }

  function isAdmin() {
    return role === USER_ROLE.ADMIN
  }

  function isNPS() {
    return is_nps
  }

  return {
    upsertAccreditation: async (user) => {
      //   setLoading(true)
      try {
        return await Api.upsertAccreditation(token, {
          body: user,
          query: { populate: true },
        })
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    declineAccreditation(userAccreditationtoken) {
      return Api.declineAccreditation(token, userAccreditationtoken)
    },
    acceptAccreditation(params) {
      return Api.acceptAccreditation(token, params)
    },

    checkRegistrationToken(token) {
      return Api.checkRegistrationToken(token)
    },

    registerAccreditation(params) {
      return Api.registerAccreditation(params)
    },

    requestAccess(email) {
      return Api.requestAccess(email)
    },
    reinitPassword(email) {
      return Api.reinitPassword(email)
    },
    checkLoginToken(token) {
      return Api.checkLoginToken(token)
    },
    getAllMyBusinessLines() {
      return Api.getAllMyBusinessLines(token)
    },
    setPassword({ token, password }) {
      return Api.setPassword({ token, password })
    },
    connectAs(id) {
      return Api.connectAs(token, id)
    },
    getUser: async (id) => {
      try {
        const rawResponse = await Api.getUser(token, id, {
          query: { populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },

    getUsersView: async (offset = 0, limit = 100, filters, order) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getUsersView(token, {
          query: { offset, limit, filters, order, populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    getUsers: async (offset = 0, limit = 100, filters, order) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getUsers(token, {
          query: { offset, limit, filters, order, populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    deleteUser: async (payload) => {
      const { id } = payload

      try {
        const rawResponse = await Api.deleteUser(token, id)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    isDeclarant: () => {
      return (
        isDeclarant() || isDeclarantTransverse() || isReferent() || isAdmin()
      )
    },
    isDeclarantTransverse: () => {
      return isDeclarantTransverse() || isReferent() || isAdmin()
    },
    isReferent: () => {
        return isReferent() || isAdmin()
    },
    isAuditEtablissement: () => {
      return isAuditEtablissement()
    },
    isAuditGroupe: () => {
      return isAuditGroupe()
    },
    isAudit: isAudit,
    isAdmin: isAdmin,
    isNPS,
  }
}
