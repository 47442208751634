// import { useHistory } from 'react-router-dom'
import Form from 'components/form/Form'
import dayjs from 'dayjs'
import useExport from 'hooks/useExport'
import React, { useState } from 'react'
import { useNotify } from 'stores/notify'
import createFormdefinitions from './FormDefinitions.js'




const fmtStartDate = (date) => {
  const startdate = dayjs(date)
  return startdate.toJSON()
}
const fmtEndDate = (date) => {
  const enddate = dayjs(date).add(1, 'days')
  return enddate.toJSON()
}
export default function ExportForm({ entity = {} }) {
  // const history = useHistory()
  const { exportCSV } = useExport()
  const formdefinitions = createFormdefinitions()
  const [formEntity, setFormEntity] = useState(entity)
  const [loading, setLoading] = useState(false)

  const { setErrorMessage, setSuccessMessage } = useNotify((n) => n)

  async function onSubmit(entity) {
    setFormEntity(entity)

    try {
      setLoading(true)
      const req = await exportCSV({
        dateType: entity['date-options'],
        startDate: fmtStartDate(entity['start-date']),
        endDate: fmtEndDate(entity['end-date']),
        exportType: entity['export-type'],
        options: {
          fraudType:
            entity['fraudType'] === 'all' ? false : entity['fraudType'],
          businessLinesIds: entity['businessLinesIds'],
        },
      })

        setLoading(false)
        setSuccessMessage('Export terminé')
    } catch (err) {
      console.error(err)
      setErrorMessage(err.message)
    }
  }

  return (
    <Form
      loading={loading}
      btnSave="Télécharger"
      entity={formEntity}
      formdefinitions={formdefinitions}
      onSubmit={onSubmit}
    />
  )
}
