import Api from 'lib/api'

import { useUser } from 'stores/user'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    updateCustomerBusinessLines: (id, payload) => {
      return Api.updateCustomerBusinessLines(token, id, { body: payload })
    },
  }
}
