import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import useProcesses from 'hooks/useProcesses'
import Layout from 'components/Layout'
import ProcessesForm from 'components/processes/Form'

const ProcessesEditPage = ({ location }) => {
  let { id } = useParams()
  const [entity, setEntity] = useState(null)
  const { getProcess } = useProcesses()

  useEffect(() => {
    getProcess(id).then((req) => {
      setEntity(req.results)
    })
  }, [id])

  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <span className="ml-2">Fiche Procesus</span>
      </h1>
      {entity && <ProcessesForm entity={entity} />}
    </Layout>
  )
}

export default ProcessesEditPage
