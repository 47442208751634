import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <g fillRule="evenodd">
                <path d="M49.941 52.656c17 0 30.695 12.398 30.695 27.625v1.77H19.363c-.117-.59-.117-1.18-.117-1.77 0-15.23 13.812-27.625 30.695-27.625zM65.172 33.176c0 8.41-6.82 15.23-15.23 15.23s-15.23-6.82-15.23-15.23 6.82-15.23 15.23-15.23 15.23 6.82 15.23 15.23" />
            </g>
        </svg>
    )
}

export default SvgComponent
