import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import reset from 'state/dataReset'
import shallow from 'lib/shallow'
import Api from 'lib/api'
// user store
// TODO: move each store in its own module
const userStore = create(
  persist(
    'user',
    immer((set, get) => ({
      token: null,
      doubleAuthToken: null,
      error: null,
      loading: false,
      setError: (error) => {
        set((state) => {
          state.error = error
        })
      },
      setUser: (user) => {
        set((state) => ({
          ...user,
          error: null,
          token: user.tokens.token,
        }))
      },
      async login({ email, password }) {
        try {
          const { results } = await Api.signin({ email, password })
          set((state) => ({
            doubleAuthToken: results.doubleAuthToken,
            error: null,
          }))
          return { success: true }
        } catch (err) {
          console.log('login -> err', err)
          set((state) => {
            state.error = err
          })
          return { success: false }
        }
      },

      logout() {
        set((state) => ({ token: null }))
        reset()
      },
      async passwordForgotRequest(email) {
        try {
          const { results } = await Api.passwordForgotRequest({
            email,
          })
          if (results) {
            set((state) => ({
              ...state,
              ...results,
              error: null,
            }))
          } else {
            set((state) => {
              state.error = new Error(
                'Problème lors de la réinitialisation du mot de passe'
              )
            })
          }
        } catch (error) {
          set((state) => {
            state.error = error
          })
        }
      },
      async validateRecoveryCode({ email, recoveryCode }) {
        try {
          const { results } = await Api.validateRecoveryCode({
            email,
            recoveryCode,
          })
          if (results) {
            set(() => ({
              ...results,
              error: null,
            }))
            return results
          } else {
            set((state) => {
              state.error = 'Problème lors de la saisie du code'
            })
          }
        } catch (error) {
          // console.error(error)
          set((state) => {
            state.error = error.message
          })
        }
      },
      async checkValidationCode({ code, doubleAuthToken }) {
        try {
          const { results } = await Api.checkValidationCode({
            code,
            doubleAuthToken,
          })

          if (results && results.tokens) {
            set((state) => ({
              ...results,
              token: results.tokens.token,
              error: null,
            }))
            return results
          } else {
            set((state) => {
              state.error = new Error("Le code n'est pas valide")
            })
          }
        } catch (error) {
          set((state) => {
            state.error = error
          })
          return { error }
        }
      },
      async sendNewCode({ doubleAuthToken }) {
        try {
          await Api.sendNewCode({
            doubleAuthToken,
          })
        } catch (error) {
          set((state) => {
            state.error = error
          })
          return { error }
        }
      },

      async changePassword(token, password) {
        try {
          const { results } = await Api.changePassword(token, {
            password,
          })
          if (results) {
            set((state) => ({
              ...results,
              error: null,
              token: results.tokens.token,
            }))

            // set(state => {
            //   state.error = null
            // })
            return results
          }
          set((state) => {
            state.error = new Error('La mise à jour  de la recette a échoué')
          })
        } catch (error) {
          set((state) => {
            state.error = error
          })
        }
      },
      async updateUser(update) {
        try {
          const { token } = get()
          await Api.updateUser(token, update)

          set((state) => {
            state.firstname = update.firstname
            state.lastname = update.lastname
            state.email = update.email
          })

          return {
            success: true,
          }
        } catch (error) {
          set((state) => {
            state.error = new Error(
              'La mise à jour de vos informations a échoué.'
            )
          })

          return { success: false }
        }
      },
    }))
  )
)

export const useUser = (slices) => shallow(userStore, slices)
export const userApi = userStore
