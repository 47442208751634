import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import shallow from 'lib/shallow'

const redirectStore = create(
  persist(
    'redirect',
    immer((set, get) => ({
      redirect: null,
      // Saves the form values in a persisted store
      // so that it can be retrieved later
      setRedirect: (redirect) => {
        set((state) => ({
          ...state,
          redirect,
        }))
      },
      getRedirect: () => {
        return get().redirect
      },
      // Gets form values from the store for the form
      // with the given name
      resetRedirect: () => {
        set((state) => ({
          ...state,
          redirect: null
        }))
      },
    }))
  )
)

export const useRedirect = (slices) => shallow(redirectStore, slices)
export const redirectApi = redirectStore
