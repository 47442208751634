import React, { useEffect, useState } from 'react'
// import logo from "./logo.svg"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import { useUser } from './stores/user'
import LoginPage from './pages/LoginPage'
import ForbiddenByIpPage from './pages/ForbiddenByIpPage'
import DashboardPage from './pages/DashboardPage'
import FraudsListPage from './pages/FraudsListPage'
import ScenariosListPage from './pages/ScenariosListPage'
import ScenariosEditPage from './pages/ScenariosEditPage'
import ScenariosNewPage from './pages/ScenariosNewPage'
import CustomersListPage from './pages/CustomersListPage'
import CustomersNewPage from './pages/CustomersNewPage'
import UsersEditPage from './pages/UsersEditPage'
import UsersNewPage from './pages/UsersNewPage'
import UsersListPage from './pages/UsersListPage'
import FraudsNewPage from './pages/FraudsNewPage'
import FraudsEditPage from './pages/FraudsEditPage'

import FraudTypesListPage from './pages/FraudTypesListPage'
import FraudTypesNewPage from './pages/FraudTypesNewPage'
import FraudTypesEditPage from './pages/FraudTypesEditPage'

import ProcessesListPage from './pages/ProcessesListPage'
import ProcessesNewPage from './pages/ProcessesNewPage'
import ProcessesEditPage from './pages/ProcessesEditPage'

import useHandshake from 'hooks/useHandshake'
import FraudsShowPage from './pages/FraudsShowPage'
import CustomersEditPage from 'pages/CustomersEditPage'
import RegisterPage from 'pages/RegisterPage'
import ValidateAccessPage from 'pages/ValidateAccessPage'
import SetPasswordPage from 'pages/SetPasswordPage'
import RequestAccessPage from 'pages/RequestAccessPage'
import ForgotPasswordPage from 'pages/ForgotPasswordPage'
import ExportPage from 'pages/ExportPage'
import ImportPage from 'pages/ImportPage'
import ImportLoggingPage from 'pages/ImportLoggingPage'
import ImportShowPage from 'pages/ImportShowPage'
import Notify from 'components/Notify'
import { useRedirect } from 'stores/redirect'

import useUsers from 'hooks/useUsers'

function App() {
  // Load persisted data from local/async storage

  const { token, setUser } = useUser(({ token, setUser }) => ({
    token,
    setUser,
  }))
  const { redirect, resetRedirect } = useRedirect((o) => o)
  const { getHandshake } = useHandshake((o) => o)

  const [handshake, setHandshake] = useState(false)
  const [ipOK, setIpOk] = useState(false)

  // const history = useHistory()

  useEffect(() => {
    if (token && redirect) {
      resetRedirect()
      window.location.href = redirect
    }
  }, [token, redirect])

  useEffect(() => {
    getHandshake(token)
      .then((results) => {
        if (results && results.results.user) {
          setUser(results.results.user)
        }
        setHandshake(true)
        setIpOk(true)
      })
      .catch((statusCode) => {
        if (statusCode === 423) {
          setHandshake(true)
        }
      })
  }, [token])

  return (
    <div className="app-container ">
      <Router>
        {handshake && !ipOK && <ForbiddenByIpPage />}
        {handshake && ipOK && (
          <Switch>
            <Route path="/login">
              {token ? (
                <Redirect to={{ pathname: '/fraudes' }} />
              ) : (
                <LoginPage />
              )}
            </Route>

            <Route path="/forgot-password" component={ForgotPasswordPage} />
            <Route
              path="/accreditations/request-access"
              component={RequestAccessPage}
            />
            <Route path="/accreditations/register" component={RegisterPage} />
            <Route
              path="/accreditations/validate"
              component={ValidateAccessPage}
            />
            <Route path="/set-password" component={SetPasswordPage} />
            <ReferentRoute path="/utilisateurs/new" component={UsersNewPage} />
            <ReferentRoute
              path="/utilisateurs/:id/edit"
              component={UsersEditPage}
            />
            <ReferentRoute path="/utilisateurs" component={UsersListPage} />
            <AdminRoute path="/scenarios/new" component={ScenariosNewPage} />
            <AdminRoute
              path="/scenarios/:id/edit"
              component={ScenariosEditPage}
            />
            <AdminRoute path="/scenarios" component={ScenariosListPage} />

            <AdminRoute
              path="/type-fraudes/new"
              component={FraudTypesNewPage}
            />
            <AdminRoute
              path="/type-fraudes/:id"
              component={FraudTypesEditPage}
            />
            <AdminRoute path="/type-fraudes" component={FraudTypesListPage} />

            <AdminRoute path="/processus/new" component={ProcessesNewPage} />
            <AdminRoute path="/processus/:id" component={ProcessesEditPage} />
            <AdminRoute path="/processus" component={ProcessesListPage} />

            <ProtectedRoute path="/dashboard" component={DashboardPage} />
            <ProtectedRoute exact path="/fraudes" component={FraudsListPage} />
            <ProtectedRoute
              exact
              path="/clients"
              component={CustomersListPage}
            />
            <ProtectedRoute
              exact
              path="/clients/new"
              component={CustomersNewPage}
            />
            <ProtectedRoute
              path="/fraudes/new/:step"
              component={FraudsNewPage}
            />
            <ProtectedRoute
              path="/fraudes/:ref/edit/:step"
              component={FraudsEditPage}
            />
            <ProtectedRoute
              path="/fraudes/:ref/:step"
              component={FraudsShowPage}
            />
            <ProtectedRoute
              path="/clients/:id/edit"
              component={CustomersEditPage}
            />
            <ProtectedRoute path="/exports" component={ExportPage} />
            <ProtectedRoute path="/imports" component={ImportPage} />
            <ProtectedRoute
              path="/imports-logging"
              component={ImportLoggingPage}
            />
            <ProtectedRoute path="/import/:id" component={ImportShowPage} />
            <ProtectedRoute path="/" component={FraudsListPage} />
          </Switch>
        )}
      </Router>
      <Notify />
    </div>
  )
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = useUser(({ token: t }) => t)

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {token ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )}
        </>
      )}
    />
  )
}

const ReferentRoute = ({ component: Component, ...rest }) => {
  const { isAdmin, isReferent } = useUsers()

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {isAdmin() || isReferent() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )}
        </>
      )}
    />
  )
}

const AdminRoute = ({ component: Component, ...rest }) => {
  const { isAdmin } = useUsers()

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {isAdmin() ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )}
        </>
      )}
    />
  )
}

export default App
