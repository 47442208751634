import React from 'react'

import Layout from 'components/Layout'
import { useHistory, useParams, Link } from 'react-router-dom'

import ImportShow from 'components/imports/Show'

const ImportListPage = ({ location }) => {
  const { id } = useParams()
  return (
    <Layout location={location}>
      <ImportShow id={id} />
    </Layout>
  )
}

export default ImportListPage
