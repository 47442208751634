import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    findProcesses() {
      return Api.findProcesses(token, { query: { offset: 0, limit: 1000 } })
    },
    getProcesses(offset = 0, limit = 100, filters, order, populate = true) {
      return Api.getProcesses(token, {
        query: { offset, limit, filters, order, populate },
      })
    },

    getProcess(id, populate = false) {
      return Api.getProcess(token, id, { query: { populate } })
    },
    deleteProcess(id) {
      return Api.deleteProcess(token, id)
    },
    createProcess(body) {
      return Api.createProcess(token, { body })
    },
    updateProcess(body) {
      return Api.updateProcess(token, body.id, { body })
    },
  }
}
