import amountAndCurrency from './commonfields/amountAndCurrency'
import { getInputOptsByBlockedField } from 'lib/utils'

const CashAutomaton = [
  [
    {
      name: 'automaton_ref',
      label: "Référence d'automate",
      // placeholder: "Référence automate",
    },
    {
      name: 'automaton_model',
      label: "Modèle de l'automate",
      // placeholder: "Modèle automate",
    },
  ],
]

export default {
  id: 'FraudCashIn',
  title: "Dépôts d'espèces",
  // next: "2",
  defaultValues: { FraudCashIns: [{}] },
  sections: [
    {
      // title: "Dépôts d'espèces",
      rows: [
        [
          {
            name: 'FraudCashIns',
            type: 'array',
            label: "Dépôt d'espèces",
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        name: 'fraud_detection',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        label: "Nom de l'outil",
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  defaultValue: 'false',
                  label: 'Agrégat',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'transaction_id',
                  label: 'Identifiant de la transaction',
                  helpText: 'Identifiant du dépôt',
                  autofocus: true,
                },
              ],

              amountAndCurrency,

              ...CashAutomaton,

              [
                {
                  type: 'select',
                  name: 'deposit_way',
                  label: 'Moyen de dépôt',
                  placeholder: 'Choisir un moyen de dépôt',
                  values: [
                    {
                      label: 'Guichet',
                      value: 'Guichet',
                    },
                    {
                      label: 'Urne',
                      value: 'Urne',
                    },
                    {
                      label: 'Automate',
                      value: 'Automate',
                    },
                  ],
                },

                // [
                {
                  type: 'select',
                  name: 'automaton_location',
                  label: "Implantation de l'automate",
                  placeholder: 'Choisir une implantation',
                  values: [
                    {
                      label: "Intérieur de l'agence",
                      value: "Intérieur de l'agence",
                    },
                    {
                      label: 'Extérieur',
                      value: 'Extérieur',
                    },
                  ],
                },
                // ],
              ],
              [
                {
                  name: 'deposit_agency',
                  label: 'Lieu de dépôt',
                  placeholder: "Nom de l'agence",
                },
                {
                  type: 'select',
                  name: 'deposit_type',
                  label: 'Type de versement',
                  placeholder: 'Choisir un type',
                  values: [
                    {
                      label: 'Classique',
                      value: 'Classique',
                    },
                    {
                      label: 'Déplacé',
                      value: 'Déplacé',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'operation",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'fraud_type',
                  label: 'Catégorie de fraude',
                  placeholder: 'Choisir une catégorie',
                  values: [
                    {
                      label: 'Faux billets',
                      value: 'Faux billets',
                    },
                    {
                      label: 'Enveloppe vide',
                      value: 'Enveloppe vide',
                    },
                    {
                      label: 'Faux montant',
                      value: 'Faux montant',
                    },
                    {
                      label: 'Billets Maculés',
                      value: 'Billets Maculés',
                    },
                  ],
                },
              ],

              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
