import React from 'react'

import Layout from 'components/Layout'
import FraudTypesList from 'components/fraud-types/List'

const FraudTypesListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <FraudTypesList />
    </Layout>
  )
}

export default FraudTypesListPage
