import { getInputOptsByBlockedField } from 'lib/utils'

export default {
  id: 'cyber',
  title: 'Cyber',
  // next: "cyber",
  // defaultValues: { FraudCyber: [{}] },
  sections: [
    {
      title: null,
      rows: [
        // [
        //   {
        //     name: 'FraudCyber.FraudCyber',
        //     type: 'array',
        //     label: 'Cyber',
        //     // defaultLength: 1,
        //     fields: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'FraudCyber.id',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudCyber.blocked',
            defaultValue: 'false',
            label: 'Opération déjouée',
            whenYes: [
              [
                {
                  name: 'FraudCyber.fraud_detection',
                  label: "Nom de l'outil",
                  options: { required: 'Ce champ est requis' },
                },
              ],
            ],
          },
        ],
        [
          {
            name: 'FraudCyber.fake_mail_address',
            type: 'checkbox',
            label: 'Adresse mail fictive',
          },
          {
            name: 'FraudCyber.mail_hacked',
            type: 'checkbox',
            label: 'Boite mail piratée',
          },
        ],
        [
          {
            name: 'FraudCyber.client_workstation_malware',
            type: 'checkbox',
            label: 'Malware poste client',
          },
          {
            name: 'FraudCyber.phishing',
            type: 'checkbox',
            label: 'Phishing',
          },
        ],
        [
          {
            name: 'FraudCyber.sim_swapping',
            type: 'checkbox',
            label: 'Sim Swapping',
          },
          { type: 'blank' },
        ],

        // [
        //   {
        //     type: 'select',
        //     name: 'FraudCyber.email_spoofing',
        //     label: 'Usurpation messagerie client',
        //     values: [
        //       {
        //         label: 'Boite mail piratée',
        //         value: 'Boite mail piratée',
        //       },
        //       {
        //         label: 'Adresse mail fictive',
        //         value: 'Adresse mail fictive',
        //       },
        //     ],
        //   },
        //   {
        //     type: 'select',
        //     name: 'FraudCyber.bank_hack',
        //     label: 'Piratage Banque en Ligne',
        //     values: [
        //       {
        //         label: 'Phishing',
        //         value: 'Phishing',
        //       },
        //       {
        //         label: 'Malware',
        //         value: 'Malware',
        //       },
        //       {
        //         label: 'Non défini',
        //         value: 'Non défini',
        //       },
        //     ],
        //   },
        // ],
        [
          {
            type: 'select',
            name: 'FraudCyber.auth_hijacking',
            label: "Moyens d'authentifications détournés",
            values: [
              {
                label: 'Mobile client',
                value: 'Mobile client',
              },
              {
                label: "Secur'Pass (et autres)",
                value: "Secur'Pass (et autres)",
              },
              {
                label: 'Lecteur CAP',
                value: 'Lecteur CAP',
              },
              {
                label: 'Certificat materiel',
                value: 'Certificat materiel',
              },
            ],
          },
          {
            type: 'select',
            name: 'FraudCyber.auth_user',
            label: 'Authentification réalisé',
            values: [
              {
                label: 'Fraudeur',
                value: 'Fraudeur',
              },
              {
                label: 'Client',
                value: 'Client',
              },
            ],
          },
        ],
        [
          {
            name: 'FraudCyber.remote_auth_id',
            label: 'Identifiant de connexion banque à distance',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudCyber.different_credentials_used',
            label:
              "Identifiant de connexion utilisé différent de l'identifiant d'abonné (Usager / Délégué / Comptable)",
            defaultValue: false,
            whenYes: [
              [
                {
                  name: 'FraudCyber.subscription_id',
                  label: 'Identifiant abonnement',
                },
              ],
              [
                {
                  type: 'select',
                  name: 'FraudCyber.subscription_customer_civility',
                  label: 'Civilité du client abonnement',
                  values: [
                    {
                      label: 'Mr',
                      value: 'Mr',
                    },
                    {
                      label: 'Mme',
                      value: 'Mme',
                    },
                  ],
                },
              ],
              [
                {
                  name: 'FraudCyber.subscription_customer_lastname',
                  label: 'Nom du client abonnement',
                },
                {
                  name: 'FraudCyber.subscription_customer_firstname',
                  label: 'Prénom du client abonnement',
                },
              ],
              [
                {
                  name: 'FraudCyber.sub_subscription_id',
                  label: 'Identifiant sous-abonnement',
                },
              ],
              [
                {
                  type: 'select',
                  name: 'FraudCyber.sub_subscription_customer_civility',
                  label: 'Civilité du client sous abonnement',
                  values: [
                    {
                      label: 'Mr',
                      value: 'Mr',
                    },
                    {
                      label: 'Mme',
                      value: 'Mme',
                    },
                  ],
                },
              ],
              [
                {
                  name: 'FraudCyber.sub_subscription_customer_lastname',
                  label: 'Nom du client sous-abonnement',
                },
                {
                  name: 'FraudCyber.sub_subscription_customer_firstname',
                  label: 'Prénom du client sous abonnement',
                },
              ],
              ,
            ],
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudCyber.manual_validation',
            label: 'Validation manuelle banque',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudCyber.modified_mobile_phone',
            label: 'Modification numéro mobile client',
          },
        ],
        [
          {
            type: 'date',
            name: 'FraudCyber.execution_date',
            label: "Date d'exécution",
            options: (props) =>
              getInputOptsByBlockedField(
                props,
                {
                  required: 'Ce champ est requis',
                },
                'FraudCyber.blocked'
              ),
          },
          { type: 'blank' },
        ],
        //   ],
        // },
        // ],
        [
          {
            type: 'select',
            name: 'FraudCyber.nature',
            label: "Fraude SSI de l'entité",
            autofocus: true,
            values: [
              {
                label: "Arrrêt du système d'information",
                value: "Arrrêt du système d'information",
              },
              {
                label: 'Fuites de données',
                value: 'Fuites de données',
              },
              {
                label: 'Altération des données',
                value: 'Altération des données',
              },
            ],
          },
        ],
      ],
    },
  ],
}
