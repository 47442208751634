import React from 'react'

import { getDeepValue } from 'lib/utils'
import YesNoInput from 'components/form/YesNoInput'
import { useWatch, Controller } from 'react-hook-form'
import Tooltip from 'components/common/Tooltip'

export default {
  id: 'general',
  title: 'Informations générales',
  // next: "2",
  defaultValues: {
    image_risk: 'false',
    proven: 'false',
    spread_risk: 'false',
    detection_date: new Date(),
  },
  sections: [
    {
      title: null,
      rows: [
        [
          {
            type: 'gdprInstruction',
          },
        ],
        [
          {
            type: 'hidden',
            name: 'id',
          },
        ],
        [
          {
            name: 'label',
            label: 'Libellé',
            autofocus: true,
            helpText: 'Décrire brièvement le cas de fraude',
            // options: { required: "Ce champ est requis" },
          },
        ],
        [
          {
            // type: "select",
            name: 'origin_detection',
            label: 'Origine de la détection',
            width: 34,
            helpText: 'Saisir le nom du service qui a informé du cas de fraude',
            // values: [{ label: "Osirisk", value: "Osirisk" }],
            // options: { required: "Ce champ est requis" },
          },
        ],
        [
          {
            type: 'date',
            name: 'detection_date',
            label: 'Date de la détection',
            options: {
              max: new Date(),
            },
            helpText:
              'Date à laquelle la 1ère opération de fraude a été détectée',
            // options: { required: "Ce champ est requis" },
          },
          {
            type: 'blank',
          },
        ],
        [
          {
            type: 'textarea',
            name: 'description',
            label: 'Description',
            options: { rows: 15 },
            helpText:
              "Décrire le scénario de fraude en étant le plus précis possible. Revenir dessus s'il manque des éléments au moment de la saisie.",
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'image_risk',
            label: "Risque d'image",
            // options: { required: "Ce champ est requis" },
            whenYes: [
              [
                {
                  type: 'textarea',
                  name: 'image_risk_description',
                  label: "Description du risque d'image",
                  // options: { r equired: "Ce champ est requis" },
                  rows: 5,
                },
              ],
              [
                {
                  type: 'select',
                  name: 'image_risk_interlocutor',
                  label: 'Interlocuteur',
                  values: [
                    {
                      label: 'Tous interlocuteurs ou Actionnaires',
                      value: 'Tous interlocuteurs ou Actionnaires',
                    },
                    {
                      label: 'Agences de notation',
                      value: 'Agences de notation',
                    },
                    {
                      label: 'Clients',
                      value: 'Clients',
                    },
                    {
                      label: 'Médias',
                      value: 'Médias',
                    },
                    {
                      label: 'Partenaires',
                      value: 'Partenaires',
                    },
                    {
                      label: 'Personnel',
                      value: 'Personnel',
                    },
                    {
                      label: 'Superviseur',
                      value: 'Superviseur',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'image_risk_level',
                  label: 'Niveau',
                  values: [
                    {
                      label: '--',
                      value: '--',
                    },
                    {
                      label: 'Faible',
                      value: 'Faible',
                    },
                    {
                      label: 'Moyen',
                      value: 'Moyen',
                    },
                    {
                      label: 'Fort',
                      value: 'Fort',
                    },
                    {
                      label: 'Critique',
                      value: 'Critique',
                    },
                  ],
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'spread_risk',
            label: 'Risque propagation',
          },
        ],
      ],
    },
  ],
}
