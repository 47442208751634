import React from "react";

export default () => {

  return (
    <div>
      <div className="text-gray-700 text-xs bg-gray-100 p-2 rounded-sm">
        Certaines des sections ci-dessous incluent des zones de commentaires libres. Elles ne doivent en aucun cas comporter de données à caractère personnel des personnes concernées par les déclarations de fraude, sauf exception(s). Attention, votre commentaire doit être neutre, factuel, objectif et respectueux des personnes concernées, jamais excessif ou insultant. Sont interdits : les mots pouvant faire apparaitre des informations sur les origines raciales, opinions philosophiques ou religieuses, l’appartenance politique ou syndicale, la santé ou la vie sexuelle, les infractions ou condamnations, la qualité de militaire.
      </div>
      <div className="mt-2 text-right text-xs">
        <a href="/Fiche Pratique Privacy - Zones de commentaires.pdf" target="_blank">
          <span className="font-bold">GDPR : </span><span>voir guide pratique zones commentaires</span>
        </a>
      </div>
    </div>
  );
}
