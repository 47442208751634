import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 90 90" {...props}>
            <path d="M87.102 27L73 12.898c-1.2-1.2-3.102-1.2-4.2 0L19.2 62.398c-1 1-1.602 2.2-1.7 3.602l-5.398 18.199c-.3 1.101 0 2.199.801 3 .602.601 1.3.898 2.102.898.3 0 .601 0 .898-.102l18.301-5.398c1.3-.2 2.5-.8 3.5-1.7l49.5-49.5c.602-.6.898-1.3.898-2.1-.101-.997-.402-1.696-1-2.298zM33.399 76.5l-9.898-9.898L60.603 29.5l9.898 9.898zM74.7 35.199l-9.898-9.898 6-6 9.898 9.898z" />
        </svg>
    )
}

export default SvgComponent
