import React from 'react'

import { Link } from 'react-router-dom'

import { useForm } from 'react-hook-form'

import LoggedOutHeader from 'components/common/LoggedOutHeader'

import Input from 'components/form/Input'

const LoginComponent = ({ onSubmit }) => {
  const { register, handleSubmit, watch, errors } = useForm()

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <form
            className="form-container w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <div className="login-form "> */}

            <Input
              key={`login-section-email`}
              ref={register({ required: 'Champ obligatoire' })}
              type="text"
              name="email"
              label="Email"
              placeholder="Email"
              value={watch('email')}
              error={errors.email && errors.email.message}
              required={'Champ obligatoire'}
              autofocus={true}
            />

            <Input
              key={`login-section-password`}
              ref={register({ required: 'Champ obligatoire' })}
              type="password"
              name="password"
              label="Mot de passe"
              placeholder="Mot de passe..."
              value={watch('password')}
              error={errors.password && errors.password.message}
              required={'Champ obligatoire'}
              autofocus={true}
            />

            <div className="flex flex-col w-full pt-6 pb-8 mb-4 ">
              <div className="flex items-center justify-between">
                <button
                  className="bg-accent text-white py-3 px-4 rounded"
                  type="submit"
                >
                  Se connecter
                </button>
                <div>
                  <Link
                    to="/accreditations/request-access"
                    className="bg-white text-accent py-3 px-4 rounded"
                  >
                    Première connexion ?
                  </Link>
                  <Link
                    to="/forgot-password"
                    className="bg-white text-accent py-3 px-4 rounded"
                  >
                    Mot de passe oublié ?
                  </Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default LoginComponent
