import React, { useState, useEffect, useCallback } from 'react'
import { Controller, useWatch } from 'react-hook-form'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import useProcesses from 'hooks/useProcesses'
import useFraudTypes from 'hooks/useFraudTypes'
import useScenarios from 'hooks/useScenarios'
import { useFraudStore } from 'stores/fraud'

import Select from 'components/form/Select'
import YesNoInput from 'components/form/YesNoInput'
import DateInput from 'components/form/Date'

import { getDeepValue } from 'lib/utils'
// import alert from 'components/common/AlertModal'
// import alert from 'components/common/AlertModal'

dayjs.extend(utc)
export default {
  id: 'FraudTracking',
  title: 'Suivi de la fraude',
  // next: "cyber",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'FraudTracking.id',
          },
          {
            hide: true,
            inputType: 'checkbox',
            type: 'hidden',
            name: 'FraudTracking.notified_major_fraud',
            defaultValue: false,
          },
        ],
        [
          {
            type: 'gdprInstruction',
          },
        ],
        [
          {
            type: 'custom',
            name: 'occurence_date',
            label: 'Date de survenance',
            options: {
              max: new Date(),
              required:
                "Ce champ est requis, veuillez saisir une opération avec une date d'exécution",
            },
            helpText: 'Date à laquelle la 1ère opération frauduleuse a eu lieu',
            // options: { required: "Ce champ est requis" },

            Component: ({ disabled, form, prefix, input }) => {
              let options = input.options
              let occurence_date = form.watch('occurence_date')
              let reporting_month = form.watch('FraudTracking.reporting_month')

              const inputName = input.name
              const inputError = getDeepValue(form.errors, inputName)

              useEffect(() => {
                if (occurence_date) {
                  occurence_date = new Date(occurence_date)
                  occurence_date.setDate(1)
                  occurence_date.setHours(0, 0, 0)
                  form.setValue('FraudTracking.reporting_month', occurence_date)
                }
              }, [occurence_date])

              return (
                <DateInput
                  prefix={prefix}
                  options={options}
                  name={input.name}
                  label={input.label}
                  placeholder="DD/MM/YYYY"
                  className="flex flex-col"
                  error={(inputError && inputError.message) || undefined}
                  form={form}
                  disabled={disabled}
                />
              )
            },
          },
          {
            type: 'custom',
            name: 'FraudTracking.reporting_month',
            options: { showMonthYearPicker: true, max: '2099-01-01' },
            label: 'Mois de reporting',

            Component: ({ disabled, form, prefix, input }) => {
              let options = input.options
              let occurence_date = form.watch('occurence_date')
              const inputName = input.name
              const inputError = getDeepValue(form.errors, inputName)

              return (
                <DateInput
                  prefix={prefix}
                  options={options}
                  name={input.name}
                  label={input.label}
                  placeholder="MM/YYYY"
                  className="flex flex-col"
                  error={(inputError && inputError.message) || undefined}
                  form={form}
                  disabled={disabled}
                />
              )
            },
          },
          {
            type: 'select',
            label: 'Statut de la fraude',
            name: 'status',
            options: { required: 'Ce champ est requis' },
            isClearable: false,
            values: [
              {
                label: 'Brouillon',
                value: 'Brouillon',
              },
              {
                label: 'En cours',
                value: 'En cours',
              },
              ,
              {
                label: 'Clos',
                value: 'Clos',
              },
            ],
            when: {
              Clos: [
                [
                  {
                    type: 'date',
                    label: 'Date de cloture',
                    name: 'FraudTracking.closure_date',
                    defaultValue: new Date(),
                  },
                ],
              ],
            },
          },
        ],

        [
          {
            type: 'custom',
            Component: function Process({
              form,
              input: { name },
              disabled,
              readOnly,
            }) {
              const { findProcesses } = useProcesses()
              const { findFraudTypes } = useFraudTypes()
              const { findScenarios, calculateScenario } = useScenarios()
              const { fraud } = useFraudStore((f) => f)
              const [init, setInit] = useState(true)

              const [processes, setProcesses] = useState([])
              const [fraudTypes, setFraudTypes] = useState([])
              const [scenarios, setScenarios] = useState([])
              const [calculatedScenarios, setCalculatedScenarios] = useState([])

              const { ProcessId, FraudTypeId, ScenarioId } = form.watch([
                'ProcessId',
                'FraudTypeId',
                'ScenarioId',
              ])

              const formatOptionLabel = useCallback((value) => {
                return (
                  <>
                    <span className={`scenario-${value.classNameScenario}`}>
                      {value.label}
                    </span>
                  </>
                )
              }, [])

              const getScenarioClassNameByIndc = useCallback(
                (indexScenario) => {
                  if (indexScenario === 0) {
                    return 'perfect'
                  } else if (indexScenario > 0) {
                    return 'not-bad'
                  }

                  return ''
                },
                []
              )

              const setProcessesClassName = useCallback(
                (processes) => {
                  const entities = processes.reduce((entities, entity) => {
                    const indexScenario = calculatedScenarios.findIndex(
                      (cs) => cs.FraudType.Process.id === entity.value
                    )

                    entities.push({
                      ...entity,
                      classNameScenario: getScenarioClassNameByIndc(
                        indexScenario
                      ),
                    })

                    return entities
                  }, [])

                  return entities
                },
                [calculatedScenarios]
              )

              const setFraudTypesClassName = useCallback(
                (fraudTypes) => {
                  const entities = fraudTypes.reduce((entities, entity) => {
                    const indexScenario = calculatedScenarios.findIndex(
                      (cs) => cs.FraudType.id === entity.value
                    )

                    entities.push({
                      ...entity,
                      classNameScenario: getScenarioClassNameByIndc(
                        indexScenario
                      ),
                    })

                    return entities
                  }, [])

                  return entities
                },
                [calculatedScenarios]
              )

              const setScenariosClassName = useCallback(
                (scenarios) => {
                  const entities = scenarios.reduce((entities, entity) => {
                    const indexScenario = calculatedScenarios.findIndex(
                      (cs) => cs.id === entity.value
                    )

                    entities.push({
                      ...entity,
                      classNameScenario: getScenarioClassNameByIndc(
                        indexScenario
                      ),
                    })

                    return entities
                  }, [])

                  return entities
                },
                [calculatedScenarios]
              )

              useEffect(() => {
                // Calculate scenario only in modification.
                if (!readOnly) {
                  calculateScenario(fraud.id).then(async ({ results }) => {
                    results.sort((a, b) => a.priority - b.priority)

                    //     // Check if first is  same as current fraud
                    setCalculatedScenarios(results)

                    if (
                      results.length &&
                      !fraud.ProcessId &&
                      !fraud.FraudTypeId &&
                      !fraud.ScenarioId
                    ) {
                      form.setValue(
                        'ProcessId',
                        results[0].FraudType.Process.id
                      )
                      form.setValue('FraudTypeId', results[0].FraudType.id)
                      form.setValue('ScenarioId', results[0].id)
                      form.setValue('ScenarioRefId', results[0].id)
                    }
                  })
                }
              }, [fraud])

              useEffect(() => {
                async function findAndSetProcesses() {
                  const resCall = await findProcesses()
                  if (resCall.results) {
                    const pr = resCall.results.map(({ name, id }) => {
                      return {
                        label: name,
                        value: id,
                      }
                    })

                    if (readOnly) {
                      setProcesses(pr)
                    } else {
                      const newProcesses = setProcessesClassName(pr)
                      setProcesses(newProcesses)
                    }
                  }
                }

                if (processes.length > 0) {
                  const newProcesses = setProcessesClassName(processes)
                  setProcesses(newProcesses)
                } else {
                  findAndSetProcesses()
                }
              }, [calculatedScenarios])

              useEffect(() => {
                async function findAndSetFraudTypes() {
                  if (ProcessId) {
                    if (!init) {
                      setScenarios([])
                    }

                    const resCall = await findFraudTypes(ProcessId)

                    if (resCall.results) {
                      const ft = resCall.results.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))

                      setFraudTypes(setFraudTypesClassName(ft))
                    }
                  } else {
                    setFraudTypes([])
                    form.setValue('FraudTypeId', null)
                    form.setValue('ScenarioId', null)
                  }
                }

                if (processes.length) {
                  findAndSetFraudTypes()
                }
              }, [ProcessId, processes, calculatedScenarios])

              useEffect(() => {
                async function findAndSetScenarios() {
                  if (FraudTypeId) {
                    const resCall = await findScenarios(FraudTypeId)

                    if (resCall.results) {
                      const sc = resCall.results.map(({ name, id }) => ({
                        label: name,
                        value: id,
                      }))

                      setScenarios(setScenariosClassName(sc))
                    }
                  } else if (!init) {
                    form.setValue('ScenarioId', null)
                    setScenarios([])
                  }
                }

                findAndSetScenarios()
              }, [FraudTypeId, calculatedScenarios])

              // useEffect(() => {
              //   form.setValue('ScenarioRefId', ScenarioId)
              // }, [ScenarioId])

              return (
                <div className="flex-wrap form-row col-3">
                  <Controller
                    name="ProcessId"
                    control={form.control}
                    defaultValue={null}
                    render={({ onChange, value, name, defaultValue }) => {
                      return (
                        <Select
                          placeholder={'Processus...'}
                          name={name}
                          value={value}
                          disabled={disabled}
                          values={processes}
                          defaultValue={defaultValue}
                          formatOptionLabel={formatOptionLabel}
                          onChange={(e) => {
                            setInit(false)
                            onChange(e && e.value)
                          }}
                          className="w-full"
                          label={'Processus'}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="FraudTypeId"
                    control={form.control}
                    render={({ onChange, value, name }) => {
                      return (
                        <Select
                          noOptionsMessage={() =>
                            "Choisir d'abord un processus"
                          }
                          placeholder={'Type de fraude...'}
                          key={JSON.stringify(fraudTypes)}
                          name={name}
                          disabled={disabled}
                          value={value}
                          formatOptionLabel={formatOptionLabel}
                          values={fraudTypes}
                          className="w-full"
                          onChange={(e) => {
                            setInit(false)
                            onChange(e && e.value)
                          }}
                          label={'Type de fraude'}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="ScenarioId"
                    control={form.control}
                    render={({ onChange, value, name }) => {
                      return (
                        <Select
                          noOptionsMessage={() =>
                            "Choisir d'abord un type de fraude"
                          }
                          key={JSON.stringify(scenarios)}
                          placeholder={'Scénario...'}
                          name={name}
                          disabled={disabled}
                          value={value}
                          formatOptionLabel={formatOptionLabel}
                          className="w-full"
                          values={scenarios}
                          onChange={(e) => {
                            if (e && e.classNameScenario === 'perfect') {
                              form.setValue('ScenarioRefId', e.value)
                            }

                            onChange(e && e.value)
                          }}
                          label={'Scénario'}
                        />
                      )
                    }}
                  />
                  <Controller
                    name="ScenarioRefId"
                    control={form.control}
                    render={({ name, value }) => {
                      return <input type="hidden" name={name} value={value} />
                    }}
                  />
                  {/* <input
                    type="hidden"
                    name="ScenarioRefId"
                    ref={form.register()}
                  /> */}
                </div>
              )
            },
          },

          // {
          //   type: 'custom',
          //   name: 'FraudTypeId',
          //   Component: function Process({ form, input: { name } }) {
          //     const [fraudTypes, setFraudTypes] = useState([])
          //     const { findFraudTypes } = useFraudTypes()

          //     useEffect(() => {
          //       async function findAndSetFraudTypes() {
          //         const resCall = await findFraudTypes()

          //         if (resCall.results) {
          //           const ft = resCall.results.map(({ name, id }) => ({
          //             label: name,
          //             value: id,
          //           }))

          //           setFraudTypes(ft)
          //         }
          //       }

          //       findAndSetFraudTypes()
          //     }, [])

          //     return (
          //       <Controller
          //         name={name}
          //         control={form.control}
          //         render={({ onChange, value, name }) => (
          //           <Select
          //             // ref={selectRef}
          //             placeholder={'Type de fraude...'}
          //             name={name}
          //             value={value}
          //             values={fraudTypes}
          //             onChange={(e) => {
          //               // getLinkedBusinessLines(e)
          //               onChange(e && e.value)
          //             }}
          //             label={'Type de fraude'}
          //           />
          //         )}
          //       />
          //     )
          //   },
          // },
        ],

        [
          {
            type: 'select',
            label: 'Contentieux',
            autofocus: true,
            name: 'FraudTracking.litigation',
            values: [
              {
                label: 'Oui',
                value: 'Oui',
              },
              {
                label: 'Non',
                value: 'Non',
              },
              {
                label: 'À voir',
                value: 'À voir',
              },
              {
                label: 'En cours',
                value: 'En cours',
              },
            ],
            when: {
              Oui: [
                [
                  {
                    name: 'FraudTracking.litigation_ref',
                    label: 'Référence Contentieux / Recouvrement',
                  },
                ],
              ],
            },
          },
        ],
        [
          {
            type: 'custom',
            name: 'proven',
            label: 'Fraude aboutie',
            defaultValue: 'false',
            Component: ({ disabled, form, prefix, input }) => {
              let options = input.options
              let watchedFields = useWatch({
                control: form.control,
                name: [
                  'FraudBankTransferOuts',
                  'FraudCashOuts',
                  'FraudChequeOuts',
                  'FraudCbOuts',
                  'FraudLevyOuts',
                  'FraudBelongingsOuts',
                ],
              })

              watchedFields = Object.values(watchedFields).filter((wf) => wf)

              if (watchedFields.length > 0) {
                disabled = true
              }

              const inputName = prefix + input.name
              const inputError = getDeepValue(form.errors, inputName)

              // input.labelJsx = (
              //   <label>
              //     <span>{input.label}</span>
              //     {!disabled && (
              //       <Tooltip tooltip="Si le fraudeur est allé jusqu’au bout de sa tentative de fraude, cocher « oui »" />
              //     )}
              //   </label>
              // )
              return (
                <>
                  <YesNoInput
                    key={`${inputName}-yesno-${JSON.stringify(options)}`}
                    input={input}
                    error={inputError && inputError.message}
                    options={options}
                    form={form}
                    prefix={prefix}
                    name={inputName}
                    defaultValue={input.defaultValue}
                    disabled={disabled}
                  />
                </>
              )
            },
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudTracking.customer_complaint',
            label: 'Dépôt de plainte client',
            defaultValue: false,
            whenYes: [
              [
                {
                  hide: true,
                  type: 'hidden',
                  name: 'FraudTracking.FraudTrackingCustomerFile.id',
                },
              ],
              [
                {
                  type: 'date',
                  name: 'FraudTracking.customer_complaint_date',
                  label: 'Date du dépôt',
                },
                {
                  type: 'file',
                  name: 'FraudTracking.FraudTrackingCustomerFile.Files',
                  label: 'Fichiers',
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudTracking.business_line_complaint',
            label: 'Dépôt de plainte établissement',
            defaultValue: false,
            whenYes: [
              [
                {
                  hide: true,
                  type: 'hidden',
                  name: 'FraudTracking.FraudTrackingBusinessLineFile.id',
                },
              ],
              [
                {
                  type: 'date',
                  name: 'FraudTracking.business_line_complaint_date',
                  label: 'Date du dépôt',
                },
                {
                  type: 'file',
                  name: 'FraudTracking.FraudTrackingBusinessLineFile.Files',
                  label: 'Fichiers',
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudTracking.customer_relation_break_request',
            label: 'Demande de rupture de la relation client',
            defaultValue: false,
            whenYes: [
              [
                {
                  type: 'date',
                  name: 'FraudTracking.customer_relation_break_request_date',
                  label: 'Date de demande de rupture de la relation client',
                },
                {
                  type: 'date',
                  name: 'FraudTracking.customer_relation_break_date',
                  label: 'Date effective de rupture de la relation client',
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'textarea',
            name: 'FraudTracking.entity_action',
            label: 'Action entité',
            options: { rows: 15 },
          },
        ],
        [
          {
            type: 'textarea',
            name: 'FraudTracking.fix_action',
            label: 'Rappel de procédures / sensibilisation...',
            options: { rows: 15 },
          },
        ],
        [
          {
            label: 'Fichiers',
            type: 'file',
            name: 'FraudTracking.Files',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudTracking.major',
            label: 'Fraude majeure',
            whenYes: [
              [
                {
                  type: 'select',
                  name: 'FraudTracking.major_fraud_case',
                  label: 'Cas de fraude majeure',
                  values: [
                    {
                      label:
                        'Fraude déjouée ou aboutie supérieur ou égal à 150 K€',
                      value:
                        'Fraude déjouée ou aboutie supérieur ou égal à 150 K€',
                    },
                    {
                      label:
                        'Fraude risquant de se propager dans plusieurs établissements du Groupe ',
                      value:
                        'Fraude risquant de se propager dans plusieurs établissements du Groupe ',
                    },
                    {
                      label: 'Fraude dont le scénario est nouveau ',
                      value: 'Fraude dont le scénario est nouveau ',
                    },
                    {
                      label:
                        "Fraude susceptible d'impacter la réputation d'un établissement ou du Groupe",
                      value:
                        "Fraude susceptible d'impacter la réputation d'un établissement ou du Groupe",
                    },
                  ],
                },
              ],
            ],
          },
        ],
        // [
        //   {
        //     type: 'select',
        //     name: 'process',
        //     label: 'Processus',
        //     values: [
        //       { label: 'Monétique porteur', value: 'Monétique porteur' },
        //       { label: 'Monétique commerçant', value: 'Monétique commerçant' },
        //       { label: 'Chèques', value: 'Chèques' },
        //       { label: 'Compte client', value: 'Compte client' },
        //       { label: 'Espèces', value: 'Espèces' },
        //       { label: 'Logistique', value: 'Logistique' },
        //       {
        //         label: 'Virement international',
        //         value: 'Virement international',
        //       },
        //       { label: 'Virement', value: 'Virement' },
        //       { label: 'Prélèvement', value: 'Prélèvement' },
        //       { label: 'Effets', value: 'Effets' },
        //       { label: 'Crédit immobilier', value: 'Crédit immobilier' },
        //       { label: "Crédit d'équipement ", value: "Crédit d'équipement " },
        //       {
        //         label: 'Crédit de fonctionnement',
        //         value: 'Crédit de fonctionnement',
        //       },
        //       { label: 'Sécurité du SI', value: 'Sécurité du SI' },
        //       {
        //         label: 'Crédit à la consommation',
        //         value: 'Crédit à la consommation',
        //       },
        //       {
        //         label: 'Paiement en plusieurs fois sans frais',
        //         value: 'Paiement en plusieurs fois sans frais',
        //       },
        //       {
        //         label: 'Financement du client',
        //         value: 'Financement du client',
        //       },
        //       {
        //         label: 'Assurance Risque Crédit',
        //         value: 'Assurance Risque Crédit',
        //       },
        //       { label: 'Recouvrement ', value: 'Recouvrement ' },
        //       {
        //         label: 'Assurance Vie/capitalisation/retraite épargne',
        //         value: 'Assurance Vie/capitalisation/retraite épargne',
        //       },
        //       {
        //         label: 'Assurance IARD Habitation/ Véhicules/ PJ',
        //         value: 'Assurance IARD Habitation/ Véhicules/ PJ',
        //       },
        //       {
        //         label: 'Assurance Prévoyance/ MAV/ Obsèques',
        //         value: 'Assurance Prévoyance/ MAV/ Obsèques',
        //       },
        //       {
        //         label: 'Assurance Santé/ Dépendance',
        //         value: 'Assurance Santé/ Dépendance',
        //       },
        //       { label: 'Assurance ADE', value: 'Assurance ADE' },
        //     ],
        //   },
        // ],
      ],
    },
  ],
}
