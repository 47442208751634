import React from "react"

import Navigation from "components/Navigation"

const Layout = ({ children, location, className = "" }) => {
  return (
    <>
      <Navigation location={location} />
      <div className={`layout m-auto p-8 ${className}`}>
        {children}
      </div>
    </>
  )
}

export default Layout
