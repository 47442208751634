import amountAndCurrency from './commonfields/amountAndCurrency'

import { getInputOptsByBlockedField } from 'lib/utils'

export default {
  id: 'FraudCbIn',
  title: 'CB entrant',
  // next: "2",
  defaultValues: { FraudCbIns: [{}] },
  sections: [
    {
      // title: "Dépôts d'espèces",
      rows: [
        [
          {
            name: 'FraudCbIns',
            type: 'array',
            label: 'CB entrant',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        name: 'fraud_detection',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        label: "Nom de l'outil",
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  label: 'Agrégat',
                  defaultValue: false,

                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'merchant_fraud',
                  type: 'yes-no',
                  label: 'Fraude commerçant',
                },
              ],

              amountAndCurrency,

              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
