import React, { useEffect, useState } from 'react'

import { useFraudStore } from 'stores/fraud'
import { useWatch, Controller } from 'react-hook-form'
import Date from '../../form/Date'
import { getNumberFromString } from 'lib/utils'

import InputNumber from '../../form/InputNumber'

function getBankTransferInsTotal(FraudBankTransferIns = []) {
  const initTotal = {
    returned_amount: 0,
    blocked_amount: 0,
  }

  if (!FraudBankTransferIns) {
    return initTotal
  }

  return FraudBankTransferIns.reduce((totals, FraudBankTransferIn) => {
    totals.returned_amount += FraudBankTransferIn.returned_amount || 0
    totals.blocked_amount += FraudBankTransferIn.blocked_amount || 0

    return totals
  }, initTotal)
}

function getBankTransferOutsTotal(FraudBankTransferOuts = []) {
  const initTotal = {
    retrieved_amount: 0,
    blocked_amount: 0,
  }

  if (!FraudBankTransferOuts) {
    return initTotal
  }

  return FraudBankTransferOuts.reduce((totals, FraudBankTransferOut) => {
    totals.retrieved_amount += FraudBankTransferOut.retrieved_amount || 0
    totals.blocked_amount += FraudBankTransferOut.blocked_amount || 0

    return totals
  }, initTotal)
}

function getBlockedTotal(entities = []) {
  return entities.reduce((total, entity) => {
    return total + entity.blocked_amount
  }, 0)
}

const round = (number) => parseFloat(number).toFixed(2)

export default {
  id: 'loss',
  title: 'Pertes',
  // next: "cyber",
  defaultValues: {},
  sections: [
    {
      title: null,
      rows: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'Loss.id',
          },
        ],
      ],
    },
    {
      title: 'Récapitulatif',
      rows: [
        [
          {
            // TODO
            // Get all fraud types amount, and sum them.
            type: 'custom',
            // name: 'Loss.amount',
            label: 'Montant total',
            Component: function Loss() {
              const { getFraud } = useFraudStore((uf) => uf)
              const fraud = getFraud()

              const bankTransferInsTotals = getBankTransferInsTotal(
                fraud.FraudBankTransferIns
              )
              const bankTransferOutsTotals = getBankTransferOutsTotal(
                fraud.FraudBankTransferOuts
              )

              return (
                <div className="w-full pb-6">
                  <div className="flex items-center w-full pb-2">
                    <span className="flex flex-row w-1/4 px-2 text-center text-gray-800"></span>
                    <span className="w-1/6 pr-4 font-bold text-center">
                      Total
                    </span>
                    <span className="flex justify-center w-1/4 px-4 font-bold">
                      <span>Bloqué</span>
                    </span>
                  </div>
                  <div className="w-full border">
                    {fraud.is_bank_transfer_out && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Virements sortants
                        </span>

                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_bank_transfer_out)}€
                        </span>
                        <span
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                          className="flex justify-end w-1/4 px-4"
                        >
                          <span>{bankTransferOutsTotals.blocked_amount}€</span>
                        </span>
                        <span className="flex justify-between w-1/3 px-4">
                          <span>Récupéré :</span>
                          <span>
                            {bankTransferOutsTotals.retrieved_amount}€
                          </span>
                        </span>
                      </div>
                    )}
                    {fraud.is_bank_transfer_in && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Virements entrants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_bank_transfer_in)}€
                        </span>
                        <span
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                          className="flex justify-end w-1/4 px-4"
                        >
                          <span>{bankTransferInsTotals.blocked_amount}€</span>
                        </span>
                        <span className="flex justify-between w-1/3 px-4">
                          <span>Fonds renvoyés :</span>
                          <span>{bankTransferInsTotals.returned_amount}€</span>
                        </span>
                      </div>
                    )}
                    {fraud.is_belongings_in && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Effets entrants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_belongings_in)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(getBlockedTotal(fraud.FraudBelongingsIns))}€
                        </span>
                      </div>
                    )}
                    {fraud.is_belongings_out && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Effets sortants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_belongings_out)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {getBlockedTotal(fraud.FraudBelongingsOuts)}€
                        </span>
                      </div>
                    )}

                    {fraud.is_cash_in && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Espèces entrants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_cash_in)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(getBlockedTotal(fraud.FraudCashIns))}€
                        </span>
                      </div>
                    )}
                    {fraud.is_cash_out && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Espèces sortants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_cash_out)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {getBlockedTotal(fraud.FraudCashOuts)}€
                        </span>
                      </div>
                    )}

                    {fraud.is_cheque_in && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Chèques entrants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_cheque_in)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(getBlockedTotal(fraud.FraudChequeIns))}€
                        </span>
                      </div>
                    )}

                    {fraud.is_cheque_out && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Chèques sortants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_cheque_out)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {getBlockedTotal(fraud.FraudChequeOuts)}€
                        </span>
                      </div>
                    )}

                    {fraud.is_cb_in && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          CB entrants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_cb_in)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(getBlockedTotal(fraud.FraudCbIns))}€
                        </span>
                      </div>
                    )}

                    {fraud.is_cb_out && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          CB sortants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_cb_out)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {getBlockedTotal(fraud.FraudCbOuts)}€
                        </span>
                      </div>
                    )}

                    {fraud.is_levy_in && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Prélèvements entrants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_levy_in)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(
                            fraud.FraudLevyIns
                              ? getBlockedTotal(fraud.FraudLevyIns)
                              : 0
                          )}
                          €
                        </span>
                      </div>
                    )}

                    {fraud.is_levy_out && (
                      <div className="flex items-center p-1 border">
                        <span
                          className="flex flex-row w-1/4 px-2 text-gray-800"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          Prélèvements sortants
                        </span>
                        <span
                          className="w-1/6 pr-4 text-right"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(fraud.total_levy_out)}€
                        </span>
                        <span
                          className="flex justify-end w-1/4 px-4"
                          style={{
                            borderRight: '1px solid rgba(0, 0, 0, 0.1)',
                          }}
                        >
                          {round(
                            fraud.FraudLevyOuts
                              ? getBlockedTotal(fraud.FraudLevyOuts)
                              : 0
                          )}
                          €
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )
            },
          },
        ],
      ],
    },
    {
      title: "Pertes de l'établissement",
      rows: [
        [
          {
            type: 'custom',
            name: 'Loss.try_amount',
            Component: (props) => {
              const { form, key } = props
              const [disabled, setDisabled] = useState(false)

              const watchedFields = useWatch({
                control: form.control,
                name: ['total_in', 'total_out'],
              })

              useEffect(() => {
                if (
                  watchedFields['total_in'] > 0 ||
                  watchedFields['total_out'] > 0
                ) {
                  setDisabled(true)
                }
              }, [watchedFields])

              return (
                <InputNumber
                  label="Montant de la fraud brute"
                  name="Loss.try_amount"
                  placeholder="Montant de la fraud brute"
                  required={false}
                  disabled={disabled}
                  suffix="€"
                  form={form}
                  key={key}
                />
              )
            },
          },
          {
            type: 'number',
            name: 'Loss.recovery_amount',
            label: 'Montant récupéré / déjoué',
            placeholder: 'Montant récupéré / déjoué',
            suffix: '€',
          },
          {
            type: 'custom',
            name: 'Loss.recovery_rate',
            Component: ({ form, key, disabled }) => {
              const watchedFields = useWatch({
                control: form.control,
                name: ['Loss.recovery_amount', 'Loss.try_amount'],
              })

              useEffect(() => {
                let recovery_rate = parseFloat(
                  (100 * parseFloat(watchedFields['Loss.recovery_amount'])) /
                    parseFloat(watchedFields['Loss.try_amount'])
                ).toFixed(2)

                if (isNaN(recovery_rate)) {
                  recovery_rate = 0
                }

                form.setValue('Loss.recovery_rate', recovery_rate)
              }, [watchedFields])

              return (
                <InputNumber
                  label="Taux de récupération"
                  name="Loss.recovery_rate"
                  placeholder="Taux de récupération"
                  required={false}
                  disabld={disabled}
                  suffix="%"
                  form={form}
                  key={key}
                />
              )
            },
          },
        ],
        [
          {
            type: 'number',
            name: 'Loss.estimated_amount',
            label: 'Perte estimée',
            placeholder: 'Montant',
            defaultValue: 0,
            suffix: '€',
          },
          {
            type: 'number',
            name: 'Loss.bank_loss_amount',
            label: 'Perte banque (comptable)',
            placeholder: 'Montant',
            defaultValue: 0,
            suffix: '€',
          },
          {
            type: 'number',
            name: 'Loss.provision_amount',
            label: 'Montant de la Provision',
            defaultValue: 0,
            suffix: '€',
          },
        ],
        [
          {
            type: 'number',
            name: 'Loss.loss_bearer_amount',
            label: 'Montant de la perte : utilisateur du service de paiement',
            placeholder: 'Montant perte utilisateur',
          },
          {
            type: 'number',
            name: 'Loss.loss_bearer_amount_other',
            label: 'Montant de la perte : autres',
            placeholder: 'Montant perte autres',
            helpText:
              'Saisir le montant de la perte banque au moment de la fraude ventilée au niveau autres',
          },
        ],
        // [
        //   {
        //     type: 'select',
        //     name: 'Loss.loss_bearer',
        //     label: 'Porteur du préjudice',
        //     values: [
        //       {
        //         label: 'PSP',
        //         value: 'PSP',
        //       },
        //       {
        //         label: 'Utilisateur du service de paiement',
        //         value: 'Utilisateur du service de paiement',
        //       },
        //       {
        //         label: 'Autres',
        //         value: 'Autres',
        //       },
        //     ],
        //     when: {
        //       PSP: [
        //         [
        //           {
        //             type: 'number',
        //             name: 'Loss.loss_bearer_amount',
        //             label: 'Montant de la perte PSP',
        //             helpText:
        //               'Saisir le montant de la perte banque au moment de la fraude ventilée au niveau PSP',
        //           },
        //         ],
        //       ],
        //       'Utilisateur du service de paiement': [
        //         [
        //           {
        //             type: 'number',
        //             name: 'Loss.loss_bearer_amount',
        //             label:
        //               'Montant de la perte Utilisateur du service de paiement',
        //             helpText:
        //               'Saisir le montant de la perte banque au moment de la fraude ventilée au niveau Utilisateur du service de paiement',
        //           },
        //         ],
        //       ],
        //       Autres: [
        //         [
        //           {
        //             type: 'number',
        //             name: 'Loss.loss_bearer_amount',
        //             label: 'Montant de la perte autres',
        //             helpText:
        //               'Saisir le montant de la perte banque au moment de la fraude ventilée au niveau autres',
        //           },
        //         ],
        //       ],
        //     },
        //   },
        // ],
        [
          {
            name: 'Loss.osirisk_num',
            label: 'N° OSIRISK',
          },

          {
            type: 'custom',
            name: 'Loss.osirisk_date',
            Component: ({ form, prefix }) => {
              // const [recoveryRate, setRecoveryRate] = useState(0)
              const { register, errors, control } = form

              const [isRequired, setIsRequired] = useState(false)

              const watchedFields = useWatch({
                control: form.control,
                name: ['Loss.bank_loss_amount', 'Loss.provision_amount'],
              })
              useEffect(() => {
                if (Object.values(watchedFields).some((f) => f && f >= 0)) {
                  setIsRequired(true)
                } else {
                  setIsRequired(false)
                }
              }, [watchedFields])

              return (
                <Controller
                  name={`Loss.osirisk_date`}
                  control={control}
                  rules={{
                    required: isRequired
                      ? 'La date de comptabilisation est obligatoire'
                      : false,
                  }}
                  render={({ onChange, value, name, defaultValue }) => (
                    <Date
                      prefix={prefix}
                      name={name}
                      label="Date de comptabilisation"
                      required={isRequired}
                      placeholder="JJ/MM/YYYY"
                      className="flex flex-col"
                      value={value}
                      defaultValue={defaultValue}
                      onChange={onChange}
                      error={
                        (errors &&
                          errors.Loss &&
                          errors.Loss.osirisk_date &&
                          errors.Loss.osirisk_date.message) ||
                        undefined
                      }
                      form={{ control }}
                    />
                  )}
                />
              )
            },
          },
        ],
      ],
    },
  ],
}
