import React, { useEffect, useState, useCallback } from 'react'

import { useHistory, useParams, Link } from 'react-router-dom'

import Layout from 'components/Layout'
import FraudsForm from 'components/frauds/Form'
import { useFraudStore } from 'stores/fraud'
import useScenarios from 'hooks/useScenarios'
import useFrauds from 'hooks/useFrauds'
import { useForms } from 'stores/form'
import { useNotify } from 'stores/notify'
import ChangedScenarioModal from 'components/frauds/ChangedScenarioModal'

const FraudsNewPage = (props) => {
  let { ref, step } = useParams()
  
  const [loading, setLoading] = useState(true)
  const [scenarioRefChanged, setScenarioRefChanged] = useState(false)
  const [scenarioRef, setScenarioRef] = useState(null)
  const [newScenario, setNewScenario] = useState(null)

  const { getFraud } = useFrauds()
  const { calculateScenario } = useScenarios()

  const { resetForm, setForm } = useForms((uf) => uf)
  const FraudStore = useFraudStore((uf) => uf)
  const { setErrorMessage } = useNotify((n) => n)

  const fraud = FraudStore.getFraud()
  const history = useHistory()

  useEffect(() => {
    getFraud(ref).then((req) => {
      resetForm('fraud')
      
      if (req.error) {
        setErrorMessage(req.error.message)
        history.replace(`/fraudes/new/general`)
      } else {
        FraudStore.setFraud(req.results)
        setForm('fraud', step, req.results)
      }
      setLoading(false)
    })
  }, [ref])

  /**
   * Check if scenario ref changes.
   */
  const checkScenario = useCallback(async (fraud) => {
    if (fraud && fraud.ScenarioRefId) {
      const { results } = await calculateScenario(fraud.id)

      if (
        (results.length && fraud.ScenarioRefId !== results[0].id) ||
        (results.length === 0 && fraud.ScenarioRefId)
      ) {
        setScenarioRef(fraud.ScenarioRef)
        setNewScenario(results[0])

        if (results[0]) {
          setScenarioRefChanged(true)
        }
      }
    }
  }, [])

  return (
    <Layout location={props.location} className="w-4/6">
      <h1 className="flex justify-between">
        <span className="ml-2">Modification fraude : {fraud.label}</span>{' '}
      </h1>
      {loading && <></>}
      {!loading && (
        <FraudsForm
          {...props}
          readOnly={true}
          key={`fraud-step-${step}`}
          checkScenario={checkScenario}
        />
      )}
    </Layout>
  )
}

export default FraudsNewPage
