import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import useScenarios from 'hooks/useScenarios'
import Layout from 'components/Layout'
import ScenariosForm from 'components/scenarios/Form'

const ScenariosEditPage = ({ location }) => {
  let { id } = useParams()
  const [scenario, setScenario] = useState(null)
  const { getScenario } = useScenarios()

  useEffect(() => {
    getScenario(id).then((req) => {
      setScenario({
        ...req.results,
        conds: req.results.conds ? req.results.conds : [],
      })
    })
  }, [id])

  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <span className="ml-2">Fiche Scénario</span>
      </h1>
      {scenario && <ScenariosForm entity={scenario} />}
    </Layout>
  )
}

export default ScenariosEditPage
