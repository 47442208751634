/* eslint-disable class-methods-use-this */

const { REACT_APP_API_URL } = process.env

const ROUTES = {
  USERS: `${REACT_APP_API_URL}/users`,
  USERS_VIEW: `${REACT_APP_API_URL}/user-list-views`,
  FRAUDS: `${REACT_APP_API_URL}/frauds`,
  FRAUDLISTVIEWS: `${REACT_APP_API_URL}/fraud-list-views`,
  FRAUD_DOCUMENTARIES: `${REACT_APP_API_URL}/fraud-documentaries`,
  FAKE_DOCS: `${REACT_APP_API_URL}/fake-docs`,
  CURRENCIES: `${REACT_APP_API_URL}/currencies`,
  BUSINESS_LINES: `${REACT_APP_API_URL}/business-lines`,
  CUSTOMERS: `${REACT_APP_API_URL}/customers`,
  ADDRESSES: `${REACT_APP_API_URL}/addresses`,
  CUSTOMER_ADDRESSES: `${REACT_APP_API_URL}/customer-addresses`,
  ACCREDITATIONS: `${REACT_APP_API_URL}/accreditations`,
  PROCESSES: `${REACT_APP_API_URL}/processes`,
  SCENARIOS: `${REACT_APP_API_URL}/scenarios`,
  FRAUD_TYPES: `${REACT_APP_API_URL}/fraud-types`,
  CUSTOMER_BUSINESS_LINES: `${REACT_APP_API_URL}/customer-business-lines`,
  EXPORT: `${REACT_APP_API_URL}/export`,
  FILES: `${REACT_APP_API_URL}/files`,
  AGENCIES: `${REACT_APP_API_URL}/agencies`,
  HANDSHAKE: `${REACT_APP_API_URL}/handshake`,
  CITIES: `${REACT_APP_API_URL}/cities`,
  IMPORTS: `${REACT_APP_API_URL}/fraud-imports`,
  IMPORTLISTVIEWS: `${REACT_APP_API_URL}/fraud-import-list-views`,
}

const DEFAULT_HEADERS = {
  Accept: 'application/json',
}

// TODO refactor and make it readable
const queryParams = (qs) => {
  if (qs && Object.keys(qs).length > 0) {
    let params = []

    const stdParams = Object.keys(qs)
      .filter((k) => k !== 'filters' && k !== 'order')
      .map((key) => [key, encodeURIComponent(qs[key] || '')].join('='))
      .join('&')

    params.push(`?${stdParams}`)

    if (qs.filters && qs.filters.length > 0) {
      params.push(`filters=${encodeURIComponent(JSON.stringify(qs.filters))}`)
    }

    if (qs.order && qs.order.length > 0) {
      const formattedOrder = qs.order
        .map((f) => `[${f.map((v) => `"${v}"`).join(',')}]`)
        .join(',')

      params.push(`order=${encodeURIComponent(`[${formattedOrder}]`)}`)
    }

    return params.join('&')
  }
  return ''
}

function parseBody(body, headers) {
  if (!body) {
    return undefined
  }

  if (headers && headers['Content-Type'] === 'multipart/form-data') {
    return body
  }

  headers['Content-Type'] = 'application/json'
  return JSON.stringify(body)
}

function request(method, url, params = {}, retries = 0) {
  const { token, body, query, fileName, headers = {} } = params
  const bodyParsed = parseBody(body, headers)

  const newHeaders = { ...headers }

  // Let browser put it.
  if (newHeaders && newHeaders['Content-Type'] === 'multipart/form-data') {
    delete newHeaders['Content-Type']
  }

  const options = {
    method: method.toUpperCase(),
    headers: token
      ? {
          ...DEFAULT_HEADERS,
          ...newHeaders,
          Authorization: `Bearer ${token}`,
          // role,
        }
      : { ...DEFAULT_HEADERS, ...newHeaders },
    body: bodyParsed,
  }

  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {
      const error = new Error('Le service est temporairement indisponible')
      error.type = 'Request Timeout'
      // storeApi.setState({
      //   msgAppWide: { message: error.message, type: 'error' },
      // })
      reject(error)
    }, params.timeout || 180000)

    // url += queryParams(query)
    // redirectApi.getState().setRedirect(window.location.href)
    const urlWithParams = url + queryParams(query)

    fetch(urlWithParams, options)
      .then(async (response) => {
        clearTimeout(timeout)

        if (response.status === 404) {
          response.text().then((text) => {
            const error = new Error(text)
            error.type = 'Not found'
            error.statusCode = response.status
            reject(error)
          })
        } else if (response.status >= 200 && response.status <= 202) {
          let fileType

          const contentDisposition = response.headers.get('Content-disposition')

          if (response.headers.get('Content-Type')) {
            fileType = [
              'jpeg',
              'png',
              'jpg',
              'pdf',
              'txt',
              '.sheet',
              'zip',
              'xlsx'
            ].find((type) =>
              response.headers.get('Content-Type').includes(type)
            )
          }

          if (
            fileType ||
            (contentDisposition &&
              contentDisposition.indexOf('attachment') >= 0)
          ) {
            const blob = await response.blob()
            const fileUrl =
              typeof window !== 'undefined' && window.URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.style.display = 'none'
            a.href = fileUrl
            // the filename you want

            const regExpFilename = /filename="(?<filename>.*)"/
            const contentDispositionHeader = response.headers.get(
              'Content-disposition'
            )

            const filename =
              regExpFilename.exec(contentDispositionHeader)?.groups?.filename ??
              null
            a.download = filename

            document.body.appendChild(a)
            a.click()

            typeof window !== 'undefined' && window.URL.revokeObjectURL(fileUrl)
            resolve()
          } else {
            response.json().then((json) => {
              let errors

              let headerContent = response.headers.get('X-Errors')
              if (headerContent) {
                try {
                  errors = JSON.parse(atob(headerContent))
                } catch (err) {}
              }
              resolve({
                results: json,
                total: response.headers.get('X-Total-Count'),
                errors,
              })
            })
          }
        } else if (response.status >= 203 && response.status < 300) {
          try {
            resolve({
              results: { success: true },
              total: response.headers.get('X-Total-Count'),
            })
          } catch (error) {
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error)
          }
        } else if (response.status === 498) {
          reject(498)
        } else if (response.status === 423) {
          reject(423)
        } else {
          response.text().then((text) => {
            const error = new Error(text)
            error.type = 'Not found'
            error.statusCode = response.status
            // storeApi.setState({
            //   msgAppWide: { message: error.message, type: 'error' },
            // })
            reject(error)
          })
        }
      })
      .catch((error) => {
        console.error(error)
        if (error.statusCode === 498) {
          reject(498)
          return
        }
        if (retries < 5) {
          setTimeout(() => {
            resolve(request(method, url, params, retries + 1))
          }, 2000)
          return
        }

        error = new Error('Le service est temporairement indisponible')
        error.type = 'Client Error'
        // storeApi.setState({
        //   msgAppWide: { message: error.message, type: 'error' },
        // })
        reject(error)
      })
  })
}

function post(url, params) {
  return request('post', url, params)
}

function put(url, params) {
  return request('put', url, params)
}

function patch(url, params) {
  return request('patch', url, params)
}

function get(url, params) {
  return request('get', url, params)
}

function del(url, params) {
  return request('delete', url, params)
}

async function getOne({ route, id, token, query }) {
  const req = await get(`${route}/${id}`, { token, query })

  if (req) {
    return req.results
  }

  return null
}

// DEBUG
function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}

/**
 * @class
 * @name Api
 * @description Safir Api client
 * The weird wraping with a WeakMap is to keep the user token private
 */
export default {
  signin: (params) => {
    if (params.email === 'error@mail.com') {
      throw new Error('Email invalide')
    }
    return post(`${ROUTES.USERS}/login`, { body: params })
  },
  signup: (params) => {
    return post(`${ROUTES.USERS}`, { body: params })
  },
  passwordForgotRequest: (params) => {
    return post(`${ROUTES.USERS}/reinit-password`, { body: params })
  },
  validateRecoveryCode: ({ email, recoveryCode }) => {
    return get(`${ROUTES.USERS}/reinit-password/${email}/${recoveryCode}`)
  },
  changePassword: (token, body) => {
    return patch(`${ROUTES.USERS}/reinit-password`, { token, body })
  },
  updateUser: (token, params) => {
    return patch(`${ROUTES.USERS}`, { token, body: params })
  },
  getUser: (token, id, params) =>
    get(`${ROUTES.USERS}/${id}`, { token, ...params }),
  getUsers: (token, params) => get(ROUTES.USERS, { token, ...params }),
  getUsersView: (token, params) =>
    get(ROUTES.USERS_VIEW + '/same-business-line', { token, ...params }),
  deleteUser: (token, id, params) =>
    del(`${ROUTES.USERS}/${id}`, { token, ...params }),
  getFrauds: (token, params) => get(ROUTES.FRAUDS, { token, ...params }),
  // getFraudListViews: (token, params) => get(ROUTES.FRAUDLISTVIEWS, { token, ...params }),
  getFraudDefinition: (token) =>
    get(`${ROUTES.FRAUDS}/get-model-definition`, { token }),
  getFraudsSameBusinessLines: (token, params) =>
    get(`${ROUTES.FRAUDS}/same-business-line`, { token, ...params }),
  getFraudListViewsSameBusinessLines: (token, params) =>
    get(`${ROUTES.FRAUDLISTVIEWS}/same-business-line`, { token, ...params }),
  getCurrencies: (token, currency, date) =>
    get(`${ROUTES.CURRENCIES}/currency-by-date/${currency}/${date}`, { token }),
  getFraud: (token, id, params) =>
    get(`${ROUTES.FRAUDS}/${id}`, { token, ...params }),
  createFraud: (token, params) =>
    post(`${ROUTES.FRAUDS}`, { token, ...params }),
  updateFraud: (token, id, params) =>
    patch(`${ROUTES.FRAUDS}/${id}`, { token, ...params }),
  deleteFraud: (token, id, params) =>
    del(`${ROUTES.FRAUDS}/${id}`, { token, ...params }),
  duplicateFraud: (token, id, params) =>
    get(`${ROUTES.FRAUDS}/${id}/duplicate`, { token, ...params }),
  createFakeDoc: (token, params) =>
    post(`${ROUTES.FAKE_DOCS}`, { token, ...params }),
  createFraudDocumentary: (token, params) =>
    post(`${ROUTES.FRAUD_DOCUMENTARIES}`, { token, ...params }),
  getBusinessLines: (token, params) =>
    get(ROUTES.BUSINESS_LINES, { token, ...params }),
  getBusinessLineByAccToken: (token) =>
    get(`${ROUTES.BUSINESS_LINES}/by-accreditation-token`, {
      token,
      query: { limit: 1000 },
    }),
  getBusinessLinesByNetwotkId: (token, networkId) =>
    get(`${ROUTES.BUSINESS_LINES}/find-by-network-id/${networkId}`, {
      token,
      query: { limit: 1000 },
    }),
  deleteCustomerBusinessLine: (token, id, params) =>
    del(`${ROUTES.CUSTOMER_BUSINESS_LINES}/${id}`, { token, ...params }),
  createCustomerBusinessLine: (token, params) =>
    post(`${ROUTES.CUSTOMER_BUSINESS_LINES}`, { token, ...params }),
  updateCustomerBusinessLines: (token, id, params) =>
    patch(`${ROUTES.CUSTOMER_BUSINESS_LINES}/${id}`, { token, ...params }),
  createCustomer: (token, params) =>
    post(`${ROUTES.CUSTOMERS}`, { token, ...params }),
  getCustomers: (token, params) => get(ROUTES.CUSTOMERS, { token, ...params }),
  getCustomersSameBusinessLines: (token, params) =>
    get(`${ROUTES.CUSTOMERS}/same-business-line`, {
      token,
      ...params,
    }),
  getCustomer: (token, id, params) =>
    get(`${ROUTES.CUSTOMERS}/${id}`, { token, ...params }),
  updateCustomer: (token, id, params) =>
    patch(`${ROUTES.CUSTOMERS}/${id}`, { token, ...params }),
  deleteCustomer: (token, id, params) =>
    del(`${ROUTES.CUSTOMERS}/${id}`, { token, ...params }),
  createAddress: (token, params) =>
    post(`${ROUTES.ADDRESSES}`, { token, ...params }),
  getAddresses: (token, params) => get(ROUTES.ADDRESSES, { token, ...params }),
  findAddress: (token, params) =>
    get(`${ROUTES.ADDRESSES}/find`, { token, ...params }),
  getCustomersOnSameAddresses: (token, id, params) =>
    get(`${ROUTES.CUSTOMERS}/${id}/customers-on-same-addresses`, {
      token,
      ...params,
    }),
  deleteCustomerAddress: (token, id, params) =>
    del(`${ROUTES.CUSTOMER_ADDRESSES}/${id}`, { token, ...params }),
  createCustomerAddress: (token, params) =>
    post(`${ROUTES.CUSTOMER_ADDRESSES}`, { token, ...params }),
  getFraudsFromCustomer: (token, id) =>
    get(`${ROUTES.CUSTOMERS}/frauds/${id}`, { token }),
  upsertAccreditation: (token, params) => {
    return post(`${ROUTES.ACCREDITATIONS}/upsert`, { token, ...params })
  },
  acceptAccreditation: (token, formData) => {
    return post(`${ROUTES.ACCREDITATIONS}/accept`, {
      token,
      body: formData,
    })
  },
  declineAccreditation: (token, userAccreditationtoken) => {
    return post(`${ROUTES.ACCREDITATIONS}/decline`, {
      token,
      body: { token: userAccreditationtoken },
    })
  },

  registerAccreditation: ({
    firstname,
    lastname,
    email,
    BusinessLineId,
    role,
    token,
  }) => {
    return post(`${ROUTES.ACCREDITATIONS}/register`, {
      body: { lastname, firstname, email, BusinessLineId, role, token },
    })
  },

  requestAccess: (email) => {
    return post(`${ROUTES.ACCREDITATIONS}/request-access`, {
      body: { email },
    })
  },

  getAllMyBusinessLines: (token) => {
    return get(`${ROUTES.USERS}/my-business-lines-full`, { token })
  },
  reinitPassword: (email) => {
    return post(`${ROUTES.USERS}/reinit-password`, {
      body: { email },
    })
  },

  checkRegistrationToken: (token) => {
    return post(`${ROUTES.ACCREDITATIONS}/check-token`, {
      body: { token },
    })
  },
  checkLoginToken: (token) => {
    return post(`${ROUTES.USERS}/check-login-token`, {
      body: { token },
    })
  },
  setPassword: ({ token, password }) => {
    return post(`${ROUTES.USERS}/set-password`, {
      body: { token, password },
    })
  },
  findProcesses: (token, params) => {
    return get(`${ROUTES.PROCESSES}`, { token, ...params })
  },
  getProcesses: (token, params) => {
    return get(`${ROUTES.PROCESSES}`, { token, ...params })
  },
  getFraudTypes: (token, params) => {
    return get(`${ROUTES.FRAUD_TYPES}`, { token, ...params })
  },

  getFraudType: (token, id, params) =>
    get(`${ROUTES.FRAUD_TYPES}/${id}`, { token, ...params }),
  deleteFraudType: (token, id, params) =>
    del(`${ROUTES.FRAUD_TYPES}/${id}`, { token, ...params }),
  createFraudType: (token, params) =>
    post(`${ROUTES.FRAUD_TYPES}`, { token, ...params }),
  updateFraudType: (token, id, params) =>
    patch(`${ROUTES.FRAUD_TYPES}/${id}`, { token, ...params }),

  getProcess: (token, id, params) =>
    get(`${ROUTES.PROCESSES}/${id}`, { token, ...params }),
  deleteProcess: (token, id, params) =>
    del(`${ROUTES.PROCESSES}/${id}`, { token, ...params }),
  createProcess: (token, params) =>
    post(`${ROUTES.PROCESSES}`, { token, ...params }),
  updateProcess: (token, id, params) =>
    patch(`${ROUTES.PROCESSES}/${id}`, { token, ...params }),

  getScenarios: (token, params) => {
    return get(`${ROUTES.SCENARIOS}`, { token, ...params })
  },
  calculateScenario: (token, fraudId) => {
    return get(`${ROUTES.SCENARIOS}/calculate-scenarios/${fraudId}`, {
      token,
    })
  },
  deleteScenario: (token, id, params) =>
    del(`${ROUTES.SCENARIOS}/${id}`, { token, ...params }),
  getScenario: (token, id, params) =>
    get(`${ROUTES.SCENARIOS}/${id}`, { token, ...params }),
  createScenario: (token, params) =>
    post(`${ROUTES.SCENARIOS}`, { token, ...params }),
  updateScenario: (token, id, params) =>
    patch(`${ROUTES.SCENARIOS}/${id}`, { token, ...params }),
  export: (token, params) => {
    return post(`${ROUTES.EXPORT}`, {
      token,
      ...params,
    })
  },
  downloadFile: (token, params) => {
    return get(`${ROUTES.FILES}`, { token, ...params })
  },
  checkValidationCode: ({ code, doubleAuthToken }) => {
    return post(`${ROUTES.USERS}/check-auth-code`, {
      body: { doubleAuthToken, code },
    })
  },
  sendNewCode: ({ doubleAuthToken }) => {
    return post(`${ROUTES.USERS}/send-new-code`, {
      body: { doubleAuthToken },
    })
  },

  getHandshake: (token) => get(`${ROUTES.HANDSHAKE}`, { token }),
  connectAs: (token, id) => get(`${ROUTES.USERS}/connect-as/${id}`, { token }),
  findAgenciesByBusinessLineId: (token, params) =>
    get(`${ROUTES.AGENCIES}`, { token, ...params }),

  findCityByPostalCode: (token, params) =>
    get(`${ROUTES.CITIES}`, { token, ...params }),
  importFrauds: (token, params) =>
    post(`${ROUTES.FRAUDS}/import`, { token, ...params, timeout: 12000000 }),
  getFraudImports: (token, params) =>
    get(`${ROUTES.IMPORTLISTVIEWS}/same-business-line`, { token, ...params }),
  getFraudImport: (token, id) => get(`${ROUTES.IMPORTS}/${id}`, { token }),
  rollbackFraudImport: (token, id) =>
    del(`${ROUTES.IMPORTS}/${id}/rollback`, { token }),
  exportNewFraudTable: (token, id) =>
    get(`${ROUTES.IMPORTS}/export-new/${id}`, { token }),
}
