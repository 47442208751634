import React from 'react'

import CustomerForm from 'components/common/customer/CustomerForm'
import UserIcon from 'components/svg/user'
import Layout from 'components/Layout'
import { useHistory } from "react-router-dom";



const UsersNewPage = ({ location }) => {
  const history = useHistory();
  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <UserIcon className="fill-current text-brand" />
        <span className="ml-2">Fiche Client</span>
      </h1>
      <CustomerForm
        onSuccess={() => {
          history.push('/clients')
        }}
      />
    </Layout>
  )
}

export default UsersNewPage
