import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'

const body = document.querySelector("body")
const confirmRoot = document.createElement("div")

export default (question) => {


    return new Promise((resolve) => {

        const confirm = (answer) => {
            ReactDOM.unmountComponentAtNode(confirmRoot)
            resolve(answer)
        }

        ReactDOM.render(<Modal title={question} tiny isOpen={true} onClose={() => confirm(false)}>
            <div>
                <div className="flex justify-end mt-8">
                    <div className="py-2 px-4 rounded-sm bg-gray-700 text-white cursor-pointer"
                        onClick={() => confirm(false)}>Annuler</div>
                    <div className="ml-2 p-2 px-4 rounded-sm bg-red-500 text-white cursor-pointer"
                        onClick={() => confirm(true)}>Ok</div>
                </div>

            </div>
        </Modal>, confirmRoot)
    })
}
