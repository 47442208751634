import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <path d="M50 4.102C45.102 4.102 2.5 4.5 2.5 19c0 2 .898 3.699 2.3 5.199L40.404 62.8 40.399 83c0 1.3.7 2.5 1.7 3.3l13.8 9.301c1.602 1.102 3.7-.101 3.7-2l.003-30.703 35.602-38.602c1.399-1.5 2.301-3.199 2.301-5.199C97.501 4.5 54.9 4.101 50.001 4.101zM50 25c-21.199 0-34.199-3.602-37.801-6 3.7-2.399 16.602-6 37.801-6s34.199 3.601 37.801 6C84.199 21.398 71.199 25 50 25z" />
        </svg>
    )
}

export default SvgComponent
