import Api from 'lib/api'

import { useUser } from 'stores/user'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    getFraudListViewsSameBusinessLines: (
      offset = 0,
      limit = 100,
      filters,
      order
    ) => {
      filters = filters.reduce((acc, f) => {
        if (f[2] === 'Brouillon complet') {
          f[2] = 'Brouillon'
          acc.push(f)
          acc.push(['completed', 'like', '1'])
        } else if (f[2] === 'Brouillon incomplet') {
          f[2] = 'Brouillon'
          acc.push(f)
          acc.push(['completed', 'like', '0'])
        } else {
          acc.push(f)
        }
        return acc
      }, [])

      return Api.getFraudListViewsSameBusinessLines(token, {
        query: { offset, limit, filters, order },
      })
    },
  }
}
