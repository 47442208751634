import React from 'react'

import { useForm } from 'react-hook-form'

import LoggedOutHeader from 'components/common/LoggedOutHeader'

const LoginComponent = ({ onSubmit }) => {
  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <div className="form-container w-full">Vous n'avez pas accès à cette application.</div>
        </div>
      </div>
    </div>
  )
}

export default LoginComponent
