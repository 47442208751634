import Api from 'lib/api'

import { useUser } from 'stores/user'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    upsertCustomer: async (customer) => {
      //   setLoading(true)
      try {
        let rawResponse
        if (customer.id) {
          rawResponse = await Api.updateCustomer(token, customer.id, {
            body: customer,
            query: { populate: true },
          })
        } else {
          rawResponse = await Api.createCustomer(token, {
            body: customer,
            query: { populate: true },
          })
        }

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    getCustomers: async (offset = 0, limit = 100, filters, order) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getCustomers(token, {
          query: { offset, limit, filters, order, populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    getCustomersSameBusinessLines: async (
      offset = 0,
      limit = 100,
      filters,
      order
    ) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getCustomersSameBusinessLines(token, {
          query: { offset, limit, filters, order, populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
        // setLoading(false)
      }
    },
    deleteCustomer: async (payload) => {
      const { id } = payload

      try {
        const rawResponse = await Api.deleteCustomer(token, id)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    deleteCustomerAddress: async (payload) => {
      const { id } = payload

      try {
        const rawResponse = await Api.deleteCustomerAddress(token, id)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    getCustomersOnSameAddresses: async (id) => {
      try {
        const rawResponse = await Api.getCustomersOnSameAddresses(token, id, {})

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    getCustomer: async (id) => {
      try {
        const rawResponse = await Api.getCustomer(token, id, {
          query: { populate: true },
        })

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
    getFrauds: async (id) => {
      try {
        const rawResponse = await Api.getFraudsFromCustomer(token, id)

        return rawResponse
      } catch (e) {
        console.error(e)
        return { error: e }
      } finally {
      }
    },
  }
}
