import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <path d="M26.395 75.238H9.668c-4.945 0-6.156-4.043-2.695-8.988l36.57-52.23c3.464-4.946 10.34-8.989 15.285-8.989h16.727c4.945 0 6.156 4.043 2.695 8.989L41.676 66.25c-3.46 4.941-10.34 8.988-15.281 8.988z" />
            <path d="M90.332 25.004H78.801L45.02 73.25c-4.035 5.766-12.055 10.48-17.816 10.48h-3.703l-1.746 2.496c-3.461 4.945-2.25 8.988 2.695 8.988h16.727c4.945 0 11.82-4.043 15.281-8.988l36.57-52.234c3.461-4.941 2.25-8.988-2.695-8.988z" />
        </svg>
    )
}

export default SvgComponent
