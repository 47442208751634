// import shallow from "zustand/shallow.cjs"

// import Router from "next/router"
// import useStore from "../store"
import Api from 'lib/api'

import { useUser } from 'stores/user'

/**
 * Returns a shallow copy of `object`, with props included in `keys` removed
 * @param {*} object Original object
 * @param {string[]} keys An array of keys to exclude from the shallow copy
 * @returns {*}
 *
 * @example
 * const orignal = { name: 'abji', num: '9' }
 * const other = remove(orignal, ['num'])
 *
 * expect(other).toMatchObject({ name: 'abji' })
 */

export default () => {
  const token = useUser(({ token }) => token)

  return {
    getImportList: (offset = 0, limit = 100, filters, order) => {
      return Api.getFraudImports(token, {
        query: { offset, limit, filters, order },
      })
    },
    getImport: (id) => {
      return Api.getFraudImport(token, id)
    },
    rollbackFraudImport: (id) => {
      return Api.rollbackFraudImport(token, id)
    },
    exportNewFraudTable: (id) => {
      return Api.exportNewFraudTable(token, id)
    }
  }
}
