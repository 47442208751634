import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import useCustomers from 'hooks/useCustomers'

export default ({ customer }) => {
    const { getCustomersOnSameAddresses } = useCustomers()
    const [customers, setCustomers] = useState([])
    useEffect(() => {
        const asyncGetCustomersSameAddresses = async () => {
            const req = await getCustomersOnSameAddresses(customer.id)
            if (req.results) {
                setCustomers(req.results)
            } else {
                console.error(req.error);
            }
        }
        if (customer && customer.id) {
            asyncGetCustomersSameAddresses()

        } else {
            setCustomers([])
        }
    }, [customer])

    if (customers.length === 0) return null

    return <div>
        <div className="p-2 bg-yellow-200 text-yellow-700 text-sm mt-2">
            <span>
                D'autres clients ont une adresse en commun :
                        </span>
            {customers.map(c => <Link to={`/clients/${c.id}/edit`} className="cursor-pointer underline ml-2 font-bold">{c.firstname} {c.lastname}</Link>)}
        </div>
    </div>

}
