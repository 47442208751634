import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      style={{ enableBackground: 'new 0 0 512 512' }}
      width="1.5em"
      height="1.5em"
    >
      <ellipse
        style={{ fill: '#E04F5F' }}
        cx="256"
        cy="256"
        rx="256"
        ry="255.832"
      />
      <g transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 77.26 32)">
        <rect
          x="3.98"
          y="-427.615"
          style={{ fill: '#FFFFFF' }}
          width="55.992"
          height="285.672"
        />
        <rect
          x="-110.828"
          y="-312.815"
          style={{ fill: '#FFFFFF' }}
          width="285.672"
          height="55.992"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default SvgComponent
