import React from 'react'
import Layout from 'components/Layout'
import FraudTypesForm from 'components/fraud-types/Form'

const FraudTypesNewPage = ({ location }) => {
  return (
    <Layout location={location}>
      <FraudTypesForm />
    </Layout>
  )
}

export default FraudTypesNewPage
