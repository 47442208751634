import React, { useState, useEffect } from 'react'
import Select from '../../form/Select'
import Modal from '../Modal'
import NewAddressForm from './NewAddressForm'
import { Controller } from 'react-hook-form'
import useAddresses from '../../../hooks/useAddresses'

const getAddressPart = (str) => {
  return {
    line1: str,
  }
}

const getSelectLabel = (address) =>
  `${address.line1} ${address.postalCode} ${address.city} ${address.country}`

const formatOptionLabel = (value) => {
  if (value.id) {

    return <>
      <span className="font-bold mr-2">{value.line1}</span>
      <span className="text-xs text-gray-600">{value.line2}</span>
      <span className="text-xs text-gray-600 pl-1">{value.postalCode} {value.city} {value.country}</span>

    </>
  }
  return value.label
}

export default ({ form, prefix, setCurrentStepData, input: { name } }) => {
  //   console.log('name', name)

  const [openModal, setOpenModal] = useState(false)
  const [addresses, setAddresses] = useState([])

  const [newOption, setNewOption] = useState({
    line1: '',
    postalCode: '',
  })

  const { getAddresses } = useAddresses()

  useEffect(() => {
    // TODO: swap getCustomers by a findCustomer
    // to avoid loading the whole DB
    const asyncGetAddresses = async () => {
      const req = await getAddresses()

      const addressesList = req.results.map((address) => ({
        ...address,
        value: address.id,
        label: getSelectLabel(address),
      }))

      setAddresses(addressesList)
    }

    asyncGetAddresses()
  }, [])

  const onNewAddress = (newAddress) => {
    const addressEntry = {
      ...newAddress,
      label: getSelectLabel(newAddress),
      value: newAddress.id,
    }

    setCurrentStepData({
      addressSearch: addressEntry.id
    })

    setAddresses([addressEntry, ...addresses])
  }


  return (
    <div className="w-2/3">
      <Controller
        control={form.control}
        name={name}
        render={({ onChange, onBlur, value, name }) => {
          {
            /*const currentValue = typeof value === 'object' ? value.value : value*/
          }
          return (
            <Select
              placeholder={'2 avenue...'}
              name={name}
              value={value}
              values={addresses}
              options={addresses}
              onChange={(address) => {
                if (address) {
                  onChange(address.id)
                } else {
                  onChange(null)
                }
              }}
              label={'Choisir une adresse'}
              className="w-full"
              formatOptionLabel={formatOptionLabel}
              creatable={{
                formatCreateLabel: (str) => `Créer l'adresse ${str}`,
                onCreateOption: (str) => {
                  setNewOption(getAddressPart(str))
                  setOpenModal(true)
                },
              }}
            />
          )
        }}
      />

      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        title="Créer une adresse"
      >
        <NewAddressForm
          address={newOption}
          onSuccess={(newAddress) => {
            setOpenModal(false)
            onNewAddress(newAddress)
          }}
          onCancel={() => setOpenModal(false)}
        />
      </Modal>
    </div>
  )
}
