import React from "react";

const CurrencyInfo = ({ totalByCurrency }) => {
  return (
    <div className="group">
      <div className="px-2 ml-3 text-black bg-gray-200 rounded-full ">i</div>
      <ul
        className="absolute z-10 flex flex-col hidden w-12 w-32 p-3 bg-white shadow-lg transform translate-x-8 -translate-y-8 group-hover:block"
      >
        {Object.keys(totalByCurrency).map((c) => (
          <li className="w-full" key={c}>
            {c} : {totalByCurrency[c]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CurrencyInfo;
