import React, { useMemo, useState, useCallback } from 'react'
import Table from 'components/common/table'
import useScenarios from 'hooks/useScenarios'
import { useHistory } from 'react-router-dom'
import { dateFmt } from 'components/common/table/formats'
import { DefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  EditAction,
  RemoveAction,
} from 'components/common/table/actions'
import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'updatedAt',
    desc: true,
  },
]

const ScenariosList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { getScenarios, deleteScenario } = useScenarios()

  const onError = useCallback((err) => {
    if (err.statusCode === 401) {
      setErrorMessage(err.message)
      history.replace('/')
    } else {
      setErrorMessage('Une erreur est survenue')
    }
  })

  const fetchData = useApiFetch(getScenarios, onError)

  const [removed, setRemoved] = useState(null)

  const onRemove = async (id) => {
    const req = await deleteScenario(id)
    if (req.results && req.results.success) {
      setSuccessMessage(`Elément supprimé avec succès`)
      setRemoved(id)
    } else {
      setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Processus',
        accessor: 'FraudType.Process.name', // accessor is the "key" in the data
        // disableSortBy: true,
        Filter: DefaultColumnFilter,
        // disableFilters: true,
        disableSortBy: true,
        className: 'font-normal',
        // Filter: DefaultColumnFilter,
      },
      {
        Header: 'Type de fraude',
        accessor: 'FraudType.name', // accessor is the "key" in the data
        // Filter: DefaultColumnFilter,
        disableSortBy: true,
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Scénario',
        accessor: 'name', // accessor is the "key" in the data
        Filter: DefaultColumnFilter,
        // bold: true
        className: 'font-bold',
      },
      {
        Header: 'Modifié le',
        accessor: 'updatedAt',
        disableFilters: true,
        className: 'whitespace-no-wrap',
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <EditAction entity={"le scénario"} to={`/scenarios/${props.value}/edit`} />
            <RemoveAction onRemove={onRemove} {...props} />
          </Actions>
        ),
      },
    ],
    []
  )

  const addScenario = () => {
    history.push('/scenarios/new')
  }

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste des scénarios"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        initialSortBy={initialSortBy}
        addEntity={addScenario}
      />
    </div>
  )
}

export default ScenariosList
