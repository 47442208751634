import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import shallow from 'lib/shallow'

const filterStore = create(
  persist(
    'filters',
    immer((set, get) => ({
      filters: {},
      // Saves the filters values in a persisted store
      // so that it can be retrieved later
      setFilters: (namespace, filters) => {
        set((state) => {
          state.filters[namespace] = filters
        })
      },
      // Gets form values from the store for the form
      // with the given name
      resetFilters: (namespace) => {
        set((state) => {
          if (state.filters[namespace]) state.filters[namespace] = []
        })
      },
    }))
  )
)

export const useFilter = (slices) => shallow(filterStore, slices)
export const filterApi = filterStore
