import React, { useCallback } from 'react'
import { useNotify } from 'stores/notify'

const Notify = () => {
  const { messages, removeMessage } = useNotify(
    ({ messages, removeMessage }) => ({
      messages,
      removeMessage,
    })
  )

  const reset = useCallback(
    (message) => {
      removeMessage(message)
    },
    [removeMessage]
  )

  messages.forEach((message, indc) => {
    setTimeout(() => {
      removeMessage(message)
    }, 5000 + indc * 1000)
  })

  return (
    <>
      {messages ? (
        <div className="z-50 notify-container">
          {messages.map((message) => (
            <div
              key={message.id}
              onClick={() => reset(message)}
              className={`relative flex justify-end`}
              role="alert"
            >
              <div
                className={`notify notify-${message.level} inline-flex justify-between`}
              >
                <div>{message.text}</div>
                <button className=" bg-transparent text-2xl font-semibold leading-none outline-none focus:outline-none">
                  <span>×</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
    // <>
    //   {message ? (
    //     <div
    //       onClick={reset}
    //       className={`z-50 notify notify-${message.level}`}
    //       role="alert"
    //     >
    //       <div>{message.text}</div>
    //       <button className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-4 outline-none focus:outline-none">
    //         <span>×</span>
    //       </button>
    //     </div>
    //   ) : (
    //     <></>
    //   )}
    // </>
  )
}

export default Notify
