import React from 'react'

import { useHistory } from 'react-router-dom'

import Form from 'components/form/Form'
import confirm from 'components/common/ConfirmModal'

import useProceses from 'hooks/useProcesses'
import { useNotify } from 'stores/notify'

import createFormdefinitions from './FormDefinitions.js'

export default function ProcesesForm({ entity = {} }) {
  const { updateProcess, createProcess } = useProceses()
  const history = useHistory()

  const formdefinitions = createFormdefinitions()

  const { setErrorMessage, setSuccessMessage } = useNotify((n) => n)

  async function onSubmit(entity) {
    try {
      if (entity.id) {
        await updateProcess(entity)
      } else {
        await createProcess(entity)
      }

      setSuccessMessage('Processus bien enregistré')
      history.push('/processus')
    } catch (err) {
      console.error(err)
      setErrorMessage(err.message)
    }
  }

  async function onCancel() {
    if (await confirm('Êtes-vous sûr de vouloir quitter ?')) {
      history.push('/processus')
    }
  }

  return (
    <Form
      entity={entity}
      formdefinitions={formdefinitions}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  )
}
