import { userApi } from "./user"
import { notifyApi } from "./notify"
import { formsApi } from "./form"
import { redirectApi } from './redirect'

export default {
  user: userApi,
  notify: notifyApi,
  forms: formsApi,
  redirect: redirectApi,
}
