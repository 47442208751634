import React from 'react'

import Layout from 'components/Layout'
import ProcessesList from 'components/processes/List'

const ProcessesListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ProcessesList />
    </Layout>
  )
}

export default ProcessesListPage
