import React, { useState, useEffect } from 'react'
import Select from '../../form/Select'
import { Controller } from 'react-hook-form'
import useUsers from 'hooks/useUsers'

export default function BusinessLineSelect({
  form,
  disabled = false,
  // prefix,
  // setCurrentStepData,
  input: { name, className, isClearable, options },
}) {
  const { getAllMyBusinessLines } = useUsers()

  const [businessLines, setBusinessLines] = useState([])

  useEffect(() => {
    const asyncGetBusinessLines = async () => {
      const req = await getAllMyBusinessLines()
      const res = req.results.map((bl) => ({
        ...bl,
        label: bl.name,
        value: bl.id,
      }))
      setBusinessLines(res)

      if (req.results.length === 1) {
        form.setValue(name, res[0].value)
      }
    }

    asyncGetBusinessLines()
  }, [])

  return (
    <Controller
      name={name}
      rules={options}
      control={form.control}
      render={({ onChange, value, name }) => {
        return (
          <Select
            placeholder={'Établissement...'}
            name={name}
            value={value}
            className={className}
            values={businessLines}
            disabled={disabled}
            isClearable={isClearable}
            formatOptionLabel={(value) => {
              return (
                <>
                  <span
                    className="text-accent mt-1 border-solid border-accent border float-left px-2 lowercase align-middle rounded-full opacity-75"
                    style={{ fontSize: '11px' }}
                  >
                    Niveau {value.level}
                  </span>
                  <span className="font-bold mr-2 ml-2">{value.name}</span>
                </>
              )
            }}
            options={options}
            onChange={(e) => {
              onChange(e && e.value)
            }}
            label={'Établissement de rattachement'}
          />
        )
      }}
    />
  )
}
