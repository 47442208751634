import API from "./api"

export default [
  { label: 'Dirham des émirats arabes unis', value: 'AED' },
  { label: 'Afghani', value: 'AFN' },
  { label: 'Lek albanais', value: 'ALL' },
  { label: 'Dram', value: 'AMD' },
  { label: 'Florin des Antilles néerlandaises', value: 'ANG' },
  { label: 'Kwanza angolais', value: 'AOA' },
  { label: 'Peso argentin', value: 'ARS' },
  { label: 'Dollar australien', value: 'AUD' },
  { label: 'Florin arubais', value: 'AWG' },
  { label: 'Manat azerbaïdjanais', value: 'AZN' },
  { label: 'Mark convertible', value: 'BAM' },
  { label: 'Dollar barbadien', value: 'BBD' },
  { label: 'Taka', value: 'BDT' },
  { label: 'Lev bulgare', value: 'BGN' },
  { label: 'Dinar bahreïni', value: 'BHD' },
  { label: 'Franc burundais', value: 'BIF' },
  { label: 'Dollar bermudien', value: 'BMD' },
  { label: 'Dollar de Brunei', value: 'BND' },
  { label: 'Boliviano bolivien', value: 'BOB' },
  { label: 'Réal brésilien', value: 'BRL' },
  { label: 'Dollar bahaméen', value: 'BSD' },
  { label: 'Bitcoin', value: 'BTC' },
  { label: 'Ngultrum', value: 'BTN' },
  { label: 'Pula botswanais', value: 'BWP' },
  { label: 'Rouble biélorusse', value: 'BYN' },
  { label: 'Dollar bélizien', value: 'BZD' },
  { label: 'Dollar canadien', value: 'CAD' },
  { label: 'Franc congolais', value: 'CDF' },
  { label: 'Franc suisse', value: 'CHF' },
  { label: 'Unité d’investissement chilienn', value: 'CLF' },
  { label: 'Peso chilien', value: 'CLP' },
  { label: 'Yuan chinois', value: 'CNH' },
  { label: 'Yuan', value: 'CNY' },
  { label: 'Peso colombien', value: 'COP' },
  { label: 'Colon costaricien', value: 'CRC' },
  { label: 'Peso cubain', value: 'CUC' },
  { label: 'Peso cubain convertible', value: 'CUP' },
  { label: 'Escudo cap-verdien', value: 'CVE' },
  { label: 'Couronne tchèque', value: 'CZK' },
  { label: 'Franc de Djibouti', value: 'DJF' },
  { label: 'Couronne danoise', value: 'DKK' },
  { label: 'Peso dominicain', value: 'DOP' },
  { label: 'Dinar algérien', value: 'DZD' },
  { label: 'Livre égyptienne', value: 'EGP' },
  { label: 'Nakfa érythréen', value: 'ERN' },
  { label: 'Birr éthiopien', value: 'ETB' },
  { label: 'Euro', value: 'EUR' },
  { label: 'Dollar de Fidji', value: 'FJD' },
  { label: 'Livre des Îles Malouines', value: 'FKP' },
  { label: 'Livre sterling', value: 'GBP' },
  { label: 'Lari', value: 'GEL' },
  { label: 'Livre de Guernesey', value: 'GGP' },
  { label: 'Cedi', value: 'GHS' },
  { label: 'Livre de Gibraltar', value: 'GIP' },
  { label: 'Dalasi gambien', value: 'GMD' },
  { label: 'Franc guinéen', value: 'GNF' },
  { label: 'Quetzal guatémaltèque', value: 'GTQ' },
  { label: 'Dollar guyanien', value: 'GYD' },
  { label: 'Dollar de Hong Kong', value: 'HKD' },
  { label: 'Lempira hondurien', value: 'HNL' },
  { label: 'Kuna', value: 'HRK' },
  { label: 'Gourde haïtienne', value: 'HTG' },
  { label: 'Forint hongrois', value: 'HUF' },
  { label: 'Roupie indonésienne', value: 'IDR' },
  { label: 'Shekel', value: 'ILS' },
  { label: 'Livre mannoise', value: 'IMP' },
  { label: 'Roupie indienne', value: 'INR' },
  { label: 'Dinar irakien', value: 'IQD' },
  { label: 'Rial iranien', value: 'IRR' },
  { label: 'Couronne islandaise', value: 'ISK' },
  { label: 'Livre de Jersey', value: 'JEP' },
  { label: 'Dollar jamaïcain', value: 'JMD' },
  { label: 'Dinar jordanien', value: 'JOD' },
  { label: 'Yen', value: 'JPY' },
  { label: 'Shilling kényan', value: 'KES' },
  { label: 'Som', value: 'KGS' },
  { label: 'Riel', value: 'KHR' },
  { label: 'Franc comorien', value: 'KMF' },
  { label: 'Won nord-coréen', value: 'KPW' },
  { label: 'Won sud-coréen', value: 'KRW' },
  { label: 'Dinar koweïtien', value: 'KWD' },
  { label: 'Dollar des îles Caïmans', value: 'KYD' },
  { label: 'Tenge', value: 'KZT' },
  { label: 'Kip', value: 'LAK' },
  { label: 'Livre libanaise', value: 'LBP' },
  { label: 'Roupie srilankaise', value: 'LKR' },
  { label: 'Dollar libérien', value: 'LRD' },
  { label: 'Loti lesothan', value: 'LSL' },
  { label: 'Dinar libyen', value: 'LYD' },
  { label: 'Dirham marocain', value: 'MAD' },
  { label: 'Leu moldave', value: 'MDL' },
  { label: 'Ariary malgache', value: 'MGA' },
  { label: 'Denar', value: 'MKD' },
  { label: 'Kyat', value: 'MMK' },
  { label: 'Tugrik', value: 'MNT' },
  { label: 'Pataca', value: 'MOP' },
  { label: 'Ouguiya mauritanien', value: 'MRO' },
  { label: 'MRU', value: 'MRU' },
  { label: 'Roupie mauricienne', value: 'MUR' },
  { label: 'Rufiyaa', value: 'MVR' },
  { label: 'Kwacha malawien', value: 'MWK' },
  { label: 'Peso mexicain', value: 'MXN' },
  { label: 'Ringgit', value: 'MYR' },
  { label: 'Metical mozambicain', value: 'MZN' },
  { label: 'Dollar namibien', value: 'NAD' },
  { label: 'Naira nigérian', value: 'NGN' },
  { label: 'Córdoba nicaraguayen', value: 'NIO' },
  { label: 'Couronne norvégienne', value: 'NOK' },
  { label: 'Roupie népalaise', value: 'NPR' },
  { label: 'Dollar néo-zélandais', value: 'NZD' },
  { label: 'Rial omanais', value: 'OMR' },
  { label: 'Balboa panaméen', value: 'PAB' },
  { label: 'Nuevo sol péruvien', value: 'PEN' },
  { label: 'Kina', value: 'PGK' },
  { label: 'Peso philippin', value: 'PHP' },
  { label: 'Roupie pakistanaise', value: 'PKR' },
  { label: 'Zloty polonais', value: 'PLN' },
  { label: 'Guaraní paraguayen', value: 'PYG' },
  { label: 'Riyal qatari', value: 'QAR' },
  { label: 'Leu roumain', value: 'RON' },
  { label: 'Dinar serbe', value: 'RSD' },
  { label: 'Rouble russe', value: 'RUB' },
  { label: 'Franc rwandais', value: 'RWF' },
  { label: 'Riyal saoudien', value: 'SAR' },
  { label: 'Dollar des îles Salomon', value: 'SBD' },
  { label: 'Roupie seychelloise', value: 'SCR' },
  { label: 'Livre soudanaise', value: 'SDG' },
  { label: 'Couronne suédoise', value: 'SEK' },
  { label: 'Dollar de Singapour', value: 'SGD' },
  { label: 'Livre de Sainte-Hélène', value: 'SHP' },
  { label: 'Leone sierra-léonais', value: 'SLL' },
  { label: 'Shilling somalien', value: 'SOS' },
  { label: 'Dollar de Surinam', value: 'SRD' },
  { label: 'Livre sud-soudanaise', value: 'SSP' },
  { label: 'Dobra santoméen', value: 'STD' },
  { label: 'Dobra', value: 'STN' },
  { label: 'Colon salvadorien ', value: 'SVC' },
  { label: 'Livre syrienne', value: 'SYP' },
  { label: 'Lilangeni swazilandais', value: 'SZL' },
  { label: 'Baht', value: 'THB' },
  { label: 'Somoni', value: 'TJS' },
  { label: 'Manat turkmène', value: 'TMT' },
  { label: 'Dinar tunisien', value: 'TND' },
  { label: "Pa'anga", value: 'TOP' },
  { label: 'Livre turque', value: 'TRY' },
  { label: 'Dollar trinidadien', value: 'TTD' },
  { label: 'Nouveau dollar de Taïwan', value: 'TWD' },
  { label: 'Shilling tanzanien', value: 'TZS' },
  { label: 'Hryvnia', value: 'UAH' },
  { label: 'Shilling ougandais', value: 'UGX' },
  { label: 'Dollar américain', value: 'USD' },
  { label: 'Peso uruguayen', value: 'UYU' },
  { label: 'Sum', value: 'UZS' },
  { label: 'Bolívar vénézuélien', value: 'VEF' },
  { label: 'Bolivar vénézuélien', value: 'VES' },
  { label: 'Dong', value: 'VND' },
  { label: 'Vatu', value: 'VUV' },
  { label: 'Tala', value: 'WST' },
  { label: 'Franc CFA', value: 'XAF' },
  { label: 'Argent', value: 'XAG' },
  { label: "Once troy d'or", value: 'XAU' },
  { label: 'Dollar des Caraïbes orientales', value: 'XCD' },
  { label: 'Special Drawing Rights', value: 'XDR' },
  { label: 'Franc CFA', value: 'XOF' },
  { label: 'Once de palladium', value: 'XPD' },
  { label: 'Franc Pacifique', value: 'XPF' },
  { label: 'Once de platine', value: 'XPT' },
  { label: 'Rial yéménite', value: 'YER' },
  { label: 'Rand sud-africain', value: 'ZAR' },
  { label: 'Kwacha zambien', value: 'ZMW' },
  { label: 'Dollar du Zimbabwe', value: 'ZWL' },
]

/**
 * Get currencies rate by date
 * https://exchangeratesapi.io/
 * @param {*} date
 */
export const getCurrenciesRate = async (token, currency, date) =>
  API.getCurrencies(token, currency, date)
