import React, { useEffect } from 'react'

import { Controller } from 'react-hook-form'

export default function MultiCheckBox({
  label,
  name,
  prefix = "",
  form,
  error,
  whenYes,
  whenNo,
  register,
  value,
  options,
  setValue,
  disabled=false,
  defaultValue,
  helpText,
}) {
  return (
    <div className="flex flex-col w-full my-4">
      <div className="flex flex-row items-center justify-between w-full">
        <span
          dangerouslySetInnerHTML={{ __html: label }}
          style={{ maxWidth: '80%' }}
        />
        <div className="flex flex-row">
          {options.choices.map((choice, indc) => {
            const checkboxName = choice.name ? prefix + choice.name : name
            return (
              <React.Fragment key={checkboxName}>
                <label
                  htmlFor={checkboxName}
                  className="checkbox-label ml-4 flex items-center whitespace-no-wrap"
                >
                  <input
                    type="checkbox"
                    className="mr-1"
                    disabled={disabled}
                    id={checkboxName}
                    name={checkboxName}
                    ref={form.register()}
                  />
                  <span>{choice.label}</span>
                </label>
              </React.Fragment>
            )
          })}
        </div>
      </div>
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}
      {/*value === 'yes' && <div className="complement-input">{whenYes}</div>*/}
      {/*value === 'no' && <div className="complement-input">{whenNo}</div>*/}
    </div>
  )
}
