import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    findFraudTypes(processId) {
      return Api.getFraudTypes(token, {
        query: { filters: [['ProcessId', processId]], offset: 0, limit: 1000 },
      })
    },
    getFraudTypes(offset = 0, limit = 100, filters, order, populate = true) {
      return Api.getFraudTypes(token, {
        query: { offset, limit, filters, order, populate },
      })
    },

    getFraudType(id, populate = false) {
      return Api.getFraudType(token, id, { query: { populate } })
    },
    deleteFraudType(id) {
      return Api.deleteFraudType(token, id)
    },
    createFraudType(body) {
      return Api.createFraudType(token, { body })
    },
    updateFraudType(body) {
      return Api.updateFraudType(token, body.id, { body })
    },
  }
}
