import NetworkOrganisationSelect from 'components/common/NetworkOrganisationSelect'
import { getInputOptsByBlockedField } from 'lib/utils'

const blockedFieldName = 'FraudTrade.blocked'

export default {
  id: 'trade',
  title: 'Trades',
  // next: "2",
  // defaultValues: { FraudTrades: [{}] },
  sections: [
    {
      title: null,
      rows: [
        // [
        //   {
        //     name: 'fraud-trade',
        //     type: 'array',
        //     label: 'Trade',
        //     fields: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'FraudTrade.id',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudTrade.blocked',
            defaultValue: 'false',
            label: 'Opération déjouée',
            whenYes: [
              [
                {
                  name: 'FraudTrade.fraud_detection_tool',
                  label: "Nom de l'outil",
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'select',
            autofocus: true,
            name: 'FraudTrade.fraud_occurence',
            label: 'Survenance de la fraude',
            values: [
              {
                label: 'Import',
                value: 'Import',
              },
              {
                label: 'Export',
                value: 'Export',
              },
              {
                label: 'Garantie émise',
                value: 'Garantie émise',
              },
            ],
          },
        ],
        [
          {
            type: 'select',
            name: 'FraudTrade.fraudulent_document_use',
            label: 'Utilisation du document frauduleux',
            placeholder: 'Choisir une utilisation',
            values: [
              {
                label: 'Collatéral',
                value: 'Collatéral',
              },
              {
                label: 'Paiement',
                value: 'Paiement',
              },
              {
                label: 'Garantie',
                value: 'Garantie',
              },
            ],
          },
          {
            type: 'select',
            name: 'FraudTrade.fraudulent_document_nature',
            label: 'Nature des documents frauduleux',
            placeholder: 'Choisir une nature',
            values: [
              {
                label: 'Factures',
                value: 'Factures',
              },
              {
                label: 'Documents de transport',
                value: 'Documents de transport',
              },
              {
                label: 'Certificats',
                value: 'Certificats',
              },
              {
                label: 'Contrats',
                value: 'Contrats',
              },
            ],
          },
        ],

        [
          {
            networkName: 'FraudTrade.affectedNetworkId',
            organisationName: 'FraudTrade.organisationNetworkId',
            type: 'custom',
            label: 'Client',
            Component: (props) =>
              NetworkOrganisationSelect({ ...props, blockedFieldName }),
          },
        ],

        [
          {
            type: 'number',
            label: "Nombre d'opérations impactées",
            name: 'FraudTrade.nb_impacted_operations',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
          },
          { type: 'blank' },
        ],

        [
          {
            type: 'date',
            name: 'FraudTrade.execution_date',
            label: "Date d'exécution",
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
          },
          { type: 'blank' },
        ],
        //     ],
        //   },
        // ],
      ],
    },
  ],
}
