import React, { useState } from 'react'
import amountAndCurrency from './commonfields/amountAndCurrency'
import { getInputOptsByBlockedField } from 'lib/utils'

const CashAutomaton = [
  [
    {
      name: 'automaton_ref',
      label: "Référence d'automate",
      // placeholder: "Référence automate",
    },
    {
      name: 'automaton_model',
      label: "Modèle de l'automate",
      // placeholder: "Modèle automate",
    },
  ],
]

export default {
  id: 'FraudCashOut',
  title: "Sorties d'espèces",
  // next: "2",
  defaultValues: { FraudCashOuts: [{}] },
  sections: [
    {
      // title: "Sorties d'espèces",
      rows: [
        [
          {
            name: 'FraudCashOuts',
            label: "Sortie d'espèces",
            type: 'array',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
                // {
                //   hide: true,
                //   type: 'custom',
                //   Component: ({ form, prefix }) => {
                //     const [wasBlocked, setWasBlocked] = useState(false)
                //     const blocked = form.watch(prefix + 'blocked')
                //     console.log("🚀 ~ file: cash-out.js ~ line 48 ~ blocked", blocked)
                //     console.log("🚀 ~ file: cash-out.js ~ line 47 ~ wasBlocked", wasBlocked)
                //     console.log("🚀 ~ file: cash-out.js ~ line 47 ~ form.errors", form.errors)

                //     if (blocked !== wasBlocked) {
                //       form.trigger()
                //       setWasBlocked(blocked)
                //     }
                //     // form.trigger()
                //     return <div />
                //   },
                // },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        name: 'fraud_detection',
                        label: "Nom de l'outil",
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  label: 'Agrégat',
                  defaultValue: 'false',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'transaction_id',
                  label: 'Identifiant transaction',
                  helpText: 'Identifiant du retrait',
                  autofocus: true,
                  // options: { required: 'Ce champ est requis' },
                },
              ],

              amountAndCurrency,

              ...CashAutomaton,
              [
                {
                  type: 'select',
                  name: 'automaton_location',
                  label: "Implantation de l'automate",
                  placeholder: 'Choisir une implantation',
                  values: [
                    {
                      label: "Intérieur de l'agence",
                      value: "Intérieur de l'agence",
                    },
                    {
                      label: 'Extérieur',
                      value: 'Extérieur',
                    },
                  ],
                },
              ],

              [
                {
                  type: 'select',
                  name: 'attack_nature',
                  label: "Nature d'attaque",
                  values: [
                    {
                      label: 'Physique',
                      value: 'Physique',
                    },
                    {
                      label: 'Logique',
                      value: 'Logique',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'withdrawal_means',
                  label: 'Moyen de retrait',
                  values: [
                    {
                      label: 'Guichet',
                      value: 'Guichet',
                    },
                    {
                      label: 'Automate',
                      value: 'Automate',
                    },
                  ],
                },
              ],
              [
                {
                  name: 'withdrawal_agency',
                  label: 'Lieu de retrait / attaque',
                  placeholder: "Nom de l'agence",
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'oneshot_card_used',
                  label: 'Utilisation de carte OneShot',
                },
                { type: 'blank' },
              ],
              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'operation",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
