import React, { useEffect } from 'react'

import { Controller } from 'react-hook-form'

export default function YesNoInput({
  input,
  name,
  yes,
  no,
  form,
  error,
  whenYes,
  whenNo,
  register,
  disabled,
  value,
  setValue,
  defaultValue,
  options,
  required,
  classes = "",
}) {
  if (required) {
    classes += ' required'
  }

  useEffect(() => {
    if (input.whenYes && `${value}` === 'false') {
      input.whenYes.map((row) =>
        row.map((input) => input.name && setValue(input.name, ''))
      )
    }
    if (input.whenNo && `${value}` === 'true') {
      input.whenNo.map((row) =>
        row.map((input) => input.name && setValue(input.name, ''))
      )
    }
  }, [setValue, value, input])

  const realDefaultValue = value === undefined ? defaultValue : value

  return (
    <div className="flex flex-col w-full my-4">
      <div className="flex flex-row items-center justify-between w-full">
        {!input.labelJsx && (
          <span
            className={classes}
            dangerouslySetInnerHTML={{ __html: input.label }}
            style={{ maxWidth: '80%' }}
          />
        )}

        {input.labelJsx}

        <div className="flex flex-row">
          <Controller
            control={form.control}
            name={name}
            rules={options}
            defaultValue={realDefaultValue}
            render={({ onChange, onBlur, value, name }) => {
              return (
                <>
                  <label
                    htmlFor={`true-${name}`}
                    className={`radio-label`}
                  >
                    <input
                      //ref={register()}
                      type="radio"
                      id={`true-${name}`}
                      name={name}
                      value="true"
                      disabled={disabled}
                      onChange={(e) => {
                        onChange(true)
                      }}
                      checked={`${value}` === 'true'}
                    />
                    <span>{yes || 'Oui'}</span>
                  </label>
                  <label htmlFor={`false-${name}`} className="radio-label">
                    <input
                      //ref={register()}
                      type="radio"
                      id={`false-${name}`}
                      name={name}
                      value="false"
                      disabled={disabled}
                      onChange={(e) => {
                        onChange(false)
                      }}
                      checked={`${value}` === 'false'}
                    />
                    <span>{no || 'Non'}</span>
                  </label>
                </>
              )
            }}
          />
        </div>
      </div>
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{input.helpText}</span>
      )}
      {`${value}` === 'true' && (
        <div className="complement-input">{whenYes}</div>
      )}
      {`${value}` === 'false' && (
        <div className="complement-input">{whenNo}</div>
      )}
    </div>
  )
}
