export const USER_ROLE = {
  ADMIN: 'Administrateur',
  REFERENT: 'Référent',
  DECLARANT: 'Déclarant',
  DECLARANT_TRANSVERSE: 'Déclarant transverse',
  AUDIT_ETABLISSEMENT: 'Audit établissement',
  AUDIT_GROUPE: 'Audit groupe',
}

export const FRAUD_STATUS = {
  BROUILLON: 'Brouillon',
  EN_COURS: 'En cours',
  CLOS: 'Clos',
}

export const COUNTRIES = [
  {
    label: 'Afghanistan',
    value: 'AF',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Åland',
    value: 'AX',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Albanie', value: 'AL', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Algérie', value: 'DZ', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Samoa américaines',
    value: 'AS',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Andorre', value: 'AD', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Angola', value: 'AO', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Anguilla',
    value: 'AI',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Antarctique',
    value: 'AQ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Antigua-et-Barbuda',
    value: 'AG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Argentine',
    value: 'AR',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Arménie', value: 'AM', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Aruba', value: 'AW', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Australie',
    value: 'AU',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Autriche',
    value: 'AT',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Azerbaïdjan',
    value: 'AZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Bahamas', value: 'BS', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Bahreïn', value: 'BH', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Bangladesh',
    value: 'BD',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Barbade', value: 'BB', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Biélorussie',
    value: 'BY',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Belgique',
    value: 'BE',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Belize', value: 'BZ', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Bénin', value: 'BJ', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Bermudes',
    value: 'BM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Bhoutan', value: 'BT', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Bolivie', value: 'BO', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Bosnie-Herzégovine',
    value: 'BA',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Botswana',
    value: 'BW',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Île Bouvet',
    value: 'BV',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Brésil', value: 'BR', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'British Virgin Islands',
    value: 'VG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: "Territoire britannique de l'Océan Indien",
    value: 'IO',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Bulgarie',
    value: 'BG',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Burundi', value: 'BI', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Cambodge',
    value: 'KH',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Cameroun',
    value: 'CM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Canada', value: 'CA', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Cap-Vert',
    value: 'CV',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Iles Cayman',
    value: 'KY',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'République centrafricaine',
    value: 'CF',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Tchad', value: 'TD', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Chili', value: 'CL', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Chine', value: 'CN', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Hong Kong',
    value: 'HK',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Macao', value: 'MO', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Île Christmas',
    value: 'CX',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Cocos',
    value: 'CC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Colombie',
    value: 'CO',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Comores', value: 'KM', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'République du Congo',
    value: 'CG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'République démocratique du Congo',
    value: 'CD',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Cook',
    value: 'CK',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: "Côte d'Ivoire",
    value: 'CI',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Croatie',
    value: 'HR',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Cuba', value: 'CU', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Chypre',
    value: 'CY',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'République tchèque',
    value: 'CZ',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Danemark',
    value: 'DK',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Dominique',
    value: 'DM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'République dominicaine',
    value: 'DO',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Équateur',
    value: 'EC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Égypte', value: 'EG', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Salvador',
    value: 'SV',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Guinée équatoriale',
    value: 'GQ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Érythrée',
    value: 'ER',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Estonie',
    value: 'EE',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Éthiopie',
    value: 'ET',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Falkland',
    value: 'FK',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Féroé',
    value: 'FO',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Fidji', value: 'FJ', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Finlande',
    value: 'FI',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'France', value: 'FR', zone_geo: 'Transaction nationale' },
  { label: 'Guyane française', value: 'GF', zone_geo: 'Transaction nationale' },
  {
    label: 'Polynésie française',
    value: 'PF',
    zone_geo: "Transfrontalière hors de l'EEE",
    type_virement: 'Virement SEPA (SCT)',
  },
  {
    label: 'Terres australes et antarctiques françaises',
    value: 'TF',
    zone_geo: 'Transaction nationale',
  },
  { label: 'Gabon', value: 'GA', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Gambie', value: 'GM', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Géorgie', value: 'GE', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Allemagne',
    value: 'DE',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Ghana', value: 'GH', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Gibraltar',
    value: 'GI',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Grèce',
    value: 'GR',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Groenland',
    value: 'GL',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Grenade', value: 'GD', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Guadeloupe', value: 'GP', zone_geo: 'Transaction nationale' },
  { label: 'Guam', value: 'GU', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Guatemala',
    value: 'GT',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Guernesey',
    value: 'GG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Guinée', value: 'GN', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Guinée-Bissau',
    value: 'GW',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Guyane', value: 'GY', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Haïti', value: 'HT', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Îles Heard-et-MacDonald', value: 'HM' },
  {
    label: 'Saint-Siège (Vatican)',
    value: 'VA',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Honduras',
    value: 'HN',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Hongrie',
    value: 'HU',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Islande',
    value: 'IS',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Inde', value: 'IN', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Indonésie',
    value: 'ID',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Iran', value: 'IR', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Irak', value: 'IQ', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Irlande',
    value: 'IE',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Ile de Man',
    value: 'IM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Israël', value: 'IL', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Italie',
    value: 'IT',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Jamaïque',
    value: 'JM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Japon', value: 'JP', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Jersey', value: 'JE', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Jordanie',
    value: 'JO',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Kenya', value: 'KE', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Kiribati',
    value: 'KI',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Corée du Nord',
    value: 'KP',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Corée du Sud',
    value: 'KR',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Koweït', value: 'KW', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Kirghizistan',
    value: 'KG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Laos', value: 'LA', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Lettonie',
    value: 'LV',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Liban', value: 'LB', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Lesotho', value: 'LS', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Libéria', value: 'LR', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Libye', value: 'LY', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Liechtenstein',
    value: 'LI',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Lituanie',
    value: 'LT',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Macédoine',
    value: 'MK',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Madagascar',
    value: 'MG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Malawi', value: 'MW', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Malaisie',
    value: 'MY',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Maldives',
    value: 'MV',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Mali', value: 'ML', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Malte',
    value: 'MT',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Îles Marshall',
    value: 'MH',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Martinique',
    value: 'MQ',
    zone_geo: 'Transaction nationale',
  },
  {
    label: 'Mauritanie',
    value: 'MR',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Maurice', value: 'MU', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Mayotte', value: 'YT', zone_geo: 'Transaction nationale' },
  { label: 'Mexique', value: 'MX', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Micronésie',
    value: 'FM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Moldavie',
    value: 'MD',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Monaco', value: 'MC', zone_geo: 'Transaction nationale' },
  {
    label: 'Mongolie',
    value: 'MN',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Monténégro',
    value: 'ME',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Montserrat',
    value: 'MS',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Maroc', value: 'MA', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Mozambique',
    value: 'MZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Myanmar', value: 'MM', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Namibie', value: 'NA', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Nauru', value: 'NR', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Népal', value: 'NP', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Pays-Bas',
    value: 'NL',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Nouvelle-Calédonie',
    value: 'NC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Nouvelle-Zélande',
    value: 'NZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Niger', value: 'NE', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Nigeria', value: 'NG', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Niue', value: 'NU', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Île Norfolk',
    value: 'NF',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Mariannes du Nord',
    value: 'MP',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Norvège',
    value: 'NO',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Oman', value: 'OM', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Pakistan',
    value: 'PK',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Palau', value: 'PW', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Palestine',
    value: 'PS',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Panama', value: 'PA', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Papouasie-Nouvelle-Guinée',
    value: 'PG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Paraguay',
    value: 'PY',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Pérou', value: 'PE', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Philippines',
    value: 'PH',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Pitcairn',
    value: 'PN',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Pologne',
    value: 'PL',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Portugal',
    value: 'PT',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Qatar', value: 'QA', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Réunion', value: 'RE', zone_geo: 'Transaction nationale' },
  {
    label: 'Roumanie',
    value: 'RO',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Russie',
    value: 'RU',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Rwanda', value: 'RW', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Saint-Barthélemy',
    value: 'BL',
    zone_geo: 'Transaction nationale',
    type_virement: 'Virement SEPA (SCT)',
  },
  {
    label: 'Sainte-Hélène',
    value: 'SH',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Saint-Kitts-et-Nevis',
    value: 'KN',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Sainte-Lucie',
    value: 'LC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Saint-Martin (partie française)',
    value: 'MF',
    zone_geo: 'Transaction nationale',
    type_virement: 'Virement SEPA (SCT)',
  },
  {
    label: 'Saint-Martin (partie néerlandaise)',
    value: 'SX',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Saint-Pierre-et-Miquelon',
    value: 'PM',
    zone_geo: 'Transaction nationale',
  },
  {
    label: 'Saint-Vincent-et-les Grenadines',
    value: 'VC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Samoa', value: 'WS', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Saint-Marin',
    value: 'SM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Sao Tomé-et-Principe',
    value: 'ST',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Arabie Saoudite',
    value: 'SA',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Sénégal', value: 'SN', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Serbie', value: 'RS', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Seychelles',
    value: 'SC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Singapour',
    value: 'SG',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Slovaquie',
    value: 'SK',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Slovénie',
    value: 'SI',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Îles Salomon',
    value: 'SB',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Somalie', value: 'SO', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Afrique du Sud',
    value: 'ZA',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Géorgie du Sud et les îles Sandwich du Sud',
    value: 'GS',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Sud-Soudan',
    value: 'SS',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Espagne',
    value: 'ES',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Soudan', value: 'SD', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Suriname',
    value: 'SR',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Svalbard et Jan Mayen',
    value: 'SJ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Eswatini',
    value: 'SZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Suède',
    value: 'SE',
    zone_geo: "Transfrontalière au sein de l'EEE",
  },
  { label: 'Suisse', value: 'CH', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Syrie', value: 'SY', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Taiwan', value: 'TW', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Tadjikistan',
    value: 'TJ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Tanzanie',
    value: 'TZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Thaïlande',
    value: 'TH',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Togo', value: 'TG', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Tokelau', value: 'TK', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Tonga', value: 'TO', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Trinité-et-Tobago',
    value: 'TT',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Tunisie', value: 'TN', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Turquie', value: 'TR', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Turkménistan',
    value: 'TM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Turques-et-Caïques',
    value: 'TC',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Tuvalu', value: 'TV', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Ouganda', value: 'UG', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Ukraine', value: 'UA', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Émirats Arabes Unis',
    value: 'AE',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Royaume-Uni',
    value: 'GB',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'États-Unis',
    value: 'US',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles mineures éloignées des États-Unis',
    value: 'UM',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Uruguay', value: 'UY', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Ouzbékistan',
    value: 'UZ',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Vanuatu', value: 'VU', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Venezuela',
    value: 'VE',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Viêt Nam',
    value: 'VN',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Îles Vierges américaines',
    value: 'VI',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    label: 'Wallis-et-Futuna',
    value: 'WF',
    zone_geo: "Transfrontalière hors de l'EEE",
    type_virement: 'Virement SEPA (SCT)',
  },
  {
    label: 'Sahara occidental',
    value: 'EH',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { label: 'Yémen', value: 'YE', zone_geo: "Transfrontalière hors de l'EEE" },
  { label: 'Zambie', value: 'ZM', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  {
    value: 'BQ',
    label: 'Bonaire, Saint-Eustache et Saba-Bonaire',
    zone_geo: "Transfrontalière hors de l'EEE",
  },
  { value: 'CW', label: 'Curaçao', zone_geo: "Transfrontalière hors de l'EEE" },
  {
    value: 'HM',
    label: "Heard-et-Îles MacDonald (l'Île)",
    zone_geo: "Transfrontalière hors de l'EEE",
  },
]

export default {
  USER_ROLE,
}
