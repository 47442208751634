import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
      <g
        fill="none"
        stroke="#000"
        strokeLinecap="square"
        strokeMiterlimit={10}
        strokeWidth={14}
      >
        <path
          d="M19.531 80.469L80.47 19.53M80.469 80.469L19.53 19.53"
          strokeWidth={10.9375}
        />
      </g>
    </svg>
  )
}

export default SvgComponent