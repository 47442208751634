import React, { useState, useEffect } from 'react'

const DefaultColumnFilter = (props) => {
  const {
    column: { filterValue, preFilteredRows, setFilter, filterOptions },
    totalRows,
    pageCount,
    state: { pageSize },
  } = props
  // const count = preFilteredRows.length
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <input
      className="w-full px-2 py-1 text-xs rounded-sm"
      value={(filterValue && filterValue.value) || ''}
      onChange={(e) => {
        if (e.target.value) {
          setFilter({
            value: e.target.value,
            operator: 'like',
          })
        } else {
          setFilter(undefined)
        }
      }}
      placeholder={placeholder}
    />
  )
}

const DateColumnFilter = (props) => {
  const {
    column: { setFilter, filterOptions },
    totalRows,
    state: { pageSize },
  } = props
  let placeholder = `recherche parmi ${totalRows} lignes`
  const [before, setBefore] = useState()
  const [after, setAfter] = useState()

  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }

  useEffect(() => {
    let filters = []

    if (before) {
      filters.push({
        value: before,
        operator: '<=',
      })
    }

    if (after) {
      filters.push({
        value: after,
        operator: '>=',
      })
    }

    if (setFilter) {
      setFilter(filters)
    }
  }, [before, after])

  return (
    <>
      <div>
        De
        <input
          className="pl-1"
          type="date"
          placeholder=""
          value={after}
          onChange={(e) => {
            if (e.target.value) {
              setAfter(e.target.value)
            }
          }}
          placeholder={placeholder}
        />
      </div>
      <div>
        à
        <input
          className="pl-1"
          placeholder=""
          type="date"
          value={before}
          onChange={(e) => {
            if (e.target.value) {
              setBefore(e.target.value)
            }
          }}
          placeholder={placeholder}
        />
      </div>
    </>
  )
}

const BooleanColumnFilter = (props) => {
  const {
    column: { filterValue, preFilteredRows, setFilter, filterOptions },
    totalRows,
    pageCount,
    state: { pageSize },
  } = props
  // const count = preFilteredRows.length
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <select
      className="w-full px-2 py-1 text-xs rounded-sm"
      value={(filterValue && filterValue.value) || ''}
      onChange={(e) => {
        let v = 'tous'
        if (e.target.value === '1') v = '1'
        if (e.target.value === '0') v = '0'

        if (v !== 'tous')
          setFilter({
            value: v,
            operator: 'like',
          })
        else setFilter(undefined)
      }}
      placeholder={placeholder}
    >
      <option value="tous">Indifférent</option>
      <option value="1">0ui</option>
      <option value="0">Non</option>
    </select>
  )
}

const SelectColumnFilter = (props) => {
  const {
    values,
    column: { filterValue, preFilteredRows, setFilter, filterOptions },
    totalRows,
    pageCount,
    state: { pageSize },
  } = props
  // const count = preFilteredRows.length
  let placeholder = ``
  if (filterOptions && filterOptions.placeholder) {
    placeholder = filterOptions.placeholder(totalRows)
  }
  return (
    <select
      className="w-full px-2 py-1 text-xs rounded-sm"
      value={(filterValue && filterValue.value) || ''}
      onChange={(e) => {
        const v = e.target.value
        if (v !== 'tous')
          setFilter({
            value: v,
            operator: 'like',
          })
        else setFilter(undefined)
      }}
      placeholder={placeholder}
    >
      <option value="tous">Indifférent</option>
      {values.map((v) => (
        <option value={v}>{v}</option>
      ))}
    </select>
  )
}

export {
  SelectColumnFilter,
  BooleanColumnFilter,
  DefaultColumnFilter,
  DateColumnFilter,
}
