import MultiNetworkOrganisationSelect from 'components/common/MultiNetworkOrganisationSelect.js'
import { getDeepValue, getInputOptsByBlockedField } from 'lib/utils'

import React from 'react'
import Select from 'components/form/Select'
import { Controller } from 'react-hook-form'
const blockedFieldName = 'FraudConsumerCredit.blocked'

export default {
  id: 'FraudConsumerCredit',
  title: 'Crédit consommation',
  // next: "cyber",
  // defaultValues: { FraudConsumerCredit: [{}] },
  sections: [
    {
      title: null,
      rows: [
        // [
        //   {
        //     name: 'FraudConsumerCredit',
        //     type: 'array',
        //     label: 'Crédit conso',
        //     defaultLength: 1,
        //     fields: [
        [
          // Used for update entity by its id
          {
            hide: true,
            type: 'hidden',
            name: 'FraudConsumerCredit.id',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudConsumerCredit.blocked',
            defaultValue: 'false',
            label: 'Opération déjouée',
            whenYes: [
              [
                {
                  name: 'FraudConsumerCredit.fraud_detection',
                  label: "Nom de l'outil",
                },
              ],
            ],
          },
        ],
        [
          {
            type: 'custom',
            name: 'FraudConsumerCredit.product_type',
            label: 'Type de produit',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
            autofocus: true,
            Component: ({
              form,
              prefix,
              disabled,
              input: { name, values, label },
            }) => {
              const selectError = getDeepValue(form.errors, prefix + name)

              return (
                <Controller
                  name={prefix + name}
                  control={form.control}
                  rules={values}
                  render={({ onChange, value, defaultValue, name }) => {
                    const arrayValue = value && value.split('/')
                    return (
                      <Select
                        name={name}
                        options={values}
                        defaultValue={defaultValue}
                        value={arrayValue}
                        error={selectError && selectError.message}
                        values={values}
                        onChange={(e) => {
                          onChange(e && e.map((v) => v.value).join('/'))
                        }}
                        label={label}
                        isMulti
                      />
                    )
                  }}
                />
              )
            },
            values: [
              {
                label: 'Crédit Renouvelable',
                value: 'Crédit Renouvelable',
              },
              {
                label: 'Prêt Personnel',
                value: 'Prêt Personnel',
              },
              {
                label: 'PNF',
                value: 'PNF',
              },
              {
                label: 'Prêt personnel SAMY',
                value: 'Prêt personnel SAMY',
              },
              {
                label: 'Prêt personnel RST',
                value: 'Prêt personnel RST',
              },
              {
                label: 'Prêt CZEN',
                value: 'Prêt CZEN',
              },
            ],
          },

          {
            type: 'select',
            name: 'FraudConsumerCredit.recovery_type',
            label: 'Type recouvrement',
            values: [
              {
                label: 'Contentieux civil',
                value: 'Contentieux civil',
              },
              {
                label: 'Contentieux Pénal',
                value: 'Contentieux Pénal',
              },
              {
                label: 'Contentieux pénal suivi par le réseau',
                value: 'Contentieux pénal suivi par le réseau',
              },
            ],
          },
        ],
        [
          {
            name: 'FraudConsumerCredit.FraudConsumerCreditAffectedNetworks',
            type: 'array',
            label: 'Réseaux et organisations impactés',
            defaultLength: 1,
            maxNb: 6,
            fields: [
              [
                {
                  organisationName: 'FraudConsumerCreditAffectedOrganizations',
                  type: 'custom',
                  label: 'Client',
                  Component: (props) =>
                    MultiNetworkOrganisationSelect({
                      ...props,
                      blockedFieldName,
                    }),
                },
              ],
            ],
          },
        ],
        [
          {
            name: 'FraudConsumerCredit.reference',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
            label: 'Référence crédit',
            type: 'textarea',
          },
        ],
        [
          {
            type: 'number',
            name: 'FraudConsumerCredit.affected_credits_number',
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
            label: 'Nombre de crédits impactés',
          },
          {
            type: 'number',
            name: 'FraudConsumerCredit.avoided_amount',
            label: 'Montant de préjudice évité',
            suffix: '€',
          },
        ],
        [
          {
            type: 'textarea',
            name: 'FraudConsumerCredit.other_customer_ids',
            label: 'Identifiants autres clients',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudConsumerCredit.lock',
            defaultValue: false,
            label: 'Verrou',
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'FraudConsumerCredit.submitted_complaint',
            defaultValue: false,
            label: 'Dépôt de plainte',
            whenYes: [
              [
                {
                  type: 'select',
                  name: 'FraudConsumerCredit.complaint_actor',
                  label: 'Dépôt de plainte réalisé par',
                  values: [
                    {
                      label: 'BPCEFI',
                      value: 'BPCEFI',
                    },
                    {
                      label: 'Les réseaux BP ou CE',
                      value: 'Les réseaux BP ou CE',
                    },
                    {
                      label: 'Neuilly Contentieux',
                      value: 'Neuilly Contentieux',
                    },
                  ],
                },
              ],
            ],
          },
        ],

        [
          {
            type: 'date',
            name: 'FraudConsumerCredit.execution_date',
            label: "Date d'exécution",
            options: (props) =>
              getInputOptsByBlockedField(props, {}, blockedFieldName),
          },
          { type: 'blank' },
        ],
        //     ],
        //   },
        // ],
      ],
    },
  ],
}
