import React, { useState, useEffect, useCallback } from 'react'
import Select from '../../form/Select'
import Input from '../../form/Input'
import { useForm, Controller } from 'react-hook-form'
import useCustomers from 'hooks/useCustomers'
import useAddresses from 'hooks/useAddresses'
import useAgencies from 'hooks/useAgencies'
import useCustomerBusinessLines from 'hooks/useCustomerBusinessLines'
import { useUser } from 'stores/user'
import useBusinessLines from 'hooks/useBusinessLines'
import { useNotify } from 'stores/notify'
import useUsers from 'hooks/useUsers'
import Date from '../../form/Date'
import AddressSelect from '../address/AddressSelect'
import TrashIcon from 'components/svg/trash'
import EditIcon from 'components/svg/edit'
import CheckIcon from 'components/svg/check'
import CloseIcon from 'components/svg/close'
import dayjs from 'dayjs'
import ClockIcon from 'components/svg/clock'
import BusinessLineSelect from '../business-line/BusinessLineSelect'
import Confirm from 'components/common/ConfirmModal'

const getErrorForfield = (errors, fieldName) => {
  return (errors && errors[fieldName] && errors[fieldName].message) || undefined
}

const CUSTOMER_TYPES = [
  {
    label: 'Particulier',
    value: 'Particulier',
  },
  {
    label: 'Corporate',
    value: 'Corporate',
  },
  {
    label: 'Professionnel',
    value: 'Professionnel',
  },
]

export default ({
  customer = { id: null },
  onSuccess = () => {},
  onCancel = () => {},
  BusinessLineId,
  consult = false,
}) => {
  const { hierarchicalBusinessLines = [], BusinessLines = [], role } = useUser(
    ({ BusinessLines, role, hierarchicalBusinessLines }) => ({
      BusinessLines,
      role,
      hierarchicalBusinessLines,
    })
  )

  const { firstname, lastname } = customer
  const defaultValues = customer.id
    ? {
        ...customer,
      }
    : {
        firstname,
        lastname,
        birthdate: null,
        customer_segment: CUSTOMER_TYPES[0].value,
      }
  const form = useForm({
    defaultValues,
  })

  const {
    register,
    handleSubmit,
    watch,
    errors,
    control,
    formState,
    clearErrors,
    reset,
  } = form
  const watchCustomerSegment = watch('customer_segment')

  const { isAdmin } = useUsers()

  const [errorsOnSubmit, setErrorsOnSubmit] = useState(false)
  const [showCompanyInputs, setShowCompanyInputs] = useState(false)
  const [currentCustomer, setCurrentCustomer] = useState(customer)
  const [hasAddresses, setHasAddresses] = useState(false)
  const [showAddressSelect, setShowAddressSelect] = useState(false)
  const [hasBusinessLines, setHasBusinessLines] = useState(false)
  const [showBusinessLineSelect, setShowBusinessLineSelect] = useState(false)
  const [fraudsFromOther, setFraudsFromOther] = useState(null)
  const [fraudsFromBL, setFraudsFromBL] = useState(null)
  const [agencies, setAgencies] = useState([])
  const [blRefs, setBlRefs] = useState({})

  const { upsertCustomer, getCustomer, getFrauds } = useCustomers()
  const { deleteCustomerAddress, createCustomerAddress } = useAddresses()
  const { updateCustomerBusinessLines } = useCustomerBusinessLines()
  const { findAgenciesByBusinessLineId } = useAgencies()

  const {
    deleteCustomerBusinessLine,
    createCustomerBusinessLine,
  } = useBusinessLines()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const isCurrentUserBusinessLine = useCallback(
    (bl) => {
      return hierarchicalBusinessLines.find((b) => b.id === bl.id) != null
    },
    [BusinessLines]
  )

  // get the customer even if we already got
  // it from the parent component.
  // Avoid sync issues and make the component work
  // in isolation
  useEffect(() => {
    const asyncGetCustomer = async (id) => {
      const req = await getCustomer(id)
      if (req.results) {
        reset(req.results)
        setCurrentCustomer(req.results)
      } else {
        setErrorMessage(req.error.message)
      }
    }
    if (customer.id) {
      asyncGetCustomer(customer.id)
    }
  }, [customer.id])

  useEffect(() => {
    const asyncGetFrauds = async (id) => {
      const req = await getFrauds(id)

      if (req.results) {
        setFraudsFromOther(
          req.results.filter(
            (f) => !BusinessLines.find((bl) => bl.id === f.businessLineId)
          )
        )
        setFraudsFromBL(
          req.results.filter((f) =>
            BusinessLines.find((bl) => bl.id === f.businessLineId)
          )
        )
      }
    }
    if (customer.id) {
      asyncGetFrauds(customer.id)
    }
  }, [customer.id])

  useEffect(() => {
    if (watchCustomerSegment !== CUSTOMER_TYPES[0].value) {
      setShowCompanyInputs(true)
    } else {
      setShowCompanyInputs(false)
    }
  }, [watchCustomerSegment])

  useEffect(() => {
    if (formState.isDirty) {
      clearErrors()
      setErrorsOnSubmit(false)
    }
  }, [formState.isDirty])


  useEffect(() => {
    if (currentCustomer.Addresses && currentCustomer.Addresses.length > 0) {
      setHasAddresses(true)
      setShowAddressSelect(false)
    } else {
      setShowAddressSelect(true)
      setHasAddresses(false)
    }

    if (
      currentCustomer.BusinessLines &&
      currentCustomer.BusinessLines.length > 0
    ) {
      setHasBusinessLines(true)
      setShowBusinessLineSelect(false)
    } else {
      setHasBusinessLines(false)
      setShowBusinessLineSelect(true)
    }

  }, [currentCustomer])

  useEffect(() => {
    if (customer.id) {
      setCurrentCustomer(customer)
    }
  }, [customer])

  useEffect(() => {
    const values = form.getValues()
    const agency_domiciliation = values['agency_domiciliation']
    const agencyDomOpt = {
      label: agency_domiciliation,
      value: agency_domiciliation,
    }

    let agenciesList = []

    if (agency_domiciliation) {
      agenciesList.push(agencyDomOpt)
    }

    if (BusinessLineId) {
      findAgenciesByBusinessLineId(BusinessLineId).then((req) => {
        if (req.results && req.results.length > 0) {
          agenciesList = [
            ...agenciesList,
            ...req.results.map(({ name }) => ({
              value: name,
              label: name,
            })),
          ]
        }

        setAgencies(agenciesList)
      })
    } else {
      setAgencies(agenciesList)
    }
  }, [BusinessLineId])

  const onSubmit = async (customer) => {
    setErrorsOnSubmit(false)
    const newCustomer = {
      ...currentCustomer,
      ...customer,
      // BusinessLineId: customer.BusinessLineId ? customer.BusinessLineId.id : null
    }
    if (
      (!newCustomer.Addresses || newCustomer.Addresses.length < 1) &&
      !newCustomer.addressSearch
    ) {
      setErrorsOnSubmit('Le client doit avoir une adresse de domiciliation')
      return
    }

    if (
      (!newCustomer.BusinessLines || newCustomer.BusinessLines.length < 1) &&
      (!newCustomer.ref || !newCustomer.BusinessLineId)
    ) {
      setErrorsOnSubmit(
        'Le client doit avoir un établissement avec une référence client correspondante'
      )
      return
    }

    if (
      (newCustomer.ref && !newCustomer.BusinessLineId) ||
      (newCustomer.BusinessLineId && !newCustomer.ref)
    ) {
      setErrorsOnSubmit(
        "L'établissement de rattachement et la référence client interne doivent être remplis"
      )
      return
    }

    if (
      !newCustomer.ref &&
      !newCustomer.BusinessLineId &&
      !newCustomer.BusinessLines &&
      showBusinessLineSelect
    ) {
      setErrorsOnSubmit(
        "L'établissement de rattachement et la référence client interne doivent être remplis"
      )
      return
    }

    // we shouldn't update belongsTo relation
    // directly else we might remove the entities
    // entirely
    // TODO: fix sequelize
    delete newCustomer.Addresses
    delete newCustomer.BusinessLines
    const req = await upsertCustomer(newCustomer)
    if (req.error) {
      setErrorMessage(req.error.message)
    } else {
      let successPayload = req.results
      if (newCustomer.addressSearch) {
        const existingLink = req.results.Addresses.find(
          (a) => a.id === newCustomer.addressSearch
        )
        if (!existingLink) {
          const link = await createCustomerAddress({
            AddressId: newCustomer.addressSearch,
            CustomerId: req.results.id,
          })
          if (link.error) {
            setErrorMessage(link.error.message)
            return
          } else {
            successPayload = {
              ...successPayload,
              Addresses: [
                {
                  ...link.results.Address,
                  CustomerAddress: {
                    id: link.results.id,
                    updatedAt: link.results.updatedAt,
                  },
                },
                ...successPayload.Addresses,
              ],
            }
          }
        }
      }
      if (newCustomer.ref) {
        const existingBusinessLink = successPayload.BusinessLines.find(
          (bl) => bl.id === newCustomer.BusinessLineId
        )
        if (!existingBusinessLink) {
          const link = await createCustomerBusinessLine({
            BusinessLineId: newCustomer.BusinessLineId,
            CustomerId: successPayload.id,
            ref: newCustomer.ref,
          })
          if (link.error) {
            setErrorMessage(link.error.message)
            return
          } else {
            successPayload = {
              ...successPayload,
              BusinessLines: [
                {
                  ...link.results.BusinessLine,
                  CustomerBusinessLine: {
                    ref: link.results.ref,
                    id: link.results.id,
                    updatedAt: link.results.updatedAt,
                  },
                },
                ...successPayload.BusinessLines,
              ],
            }

            // add to the currentCustomer the new BusinessLine, and close BusinessLineSelect
            // ----- pas le bon endroit ??

            // const _tempCurrentCustomer = currentCustomer
            // _tempCurrentCustomer.BusinessLines = successPayload.BusinessLines
            // setCurrentCustomer(_tempCurrentCustomer)
            // setShowBusinessLineSelect(false)
          }
        } else {
          setErrorMessage('Le client est déjà associé à cet établissement')
          return
        }
      }
      setSuccessMessage(
        `La fiche client ${customer.firstname} ${customer.lastname} a été sauvée avec succès`
      )
      onSuccess(successPayload)
    }
  }

  const closeBusinessLineLink = async (businessLine) => {
    setBlRefs({ [businessLine.id]: businessLine.CustomerBusinessLine.ref })

    const newBls = currentCustomer.BusinessLines.map((bl) => {
      if (bl.id === businessLine.id) {
        bl.editing = false
      }

      return bl
    })

    setCurrentCustomer({
      ...currentCustomer,
      BusinessLines: newBls,
    })
  }

  const saveBusinessLineLink = async (businessLine) => {
    let isBlank = false

    if (!blRefs[businessLine.id]) {
      isBlank = true
    }

    const newBls = currentCustomer.BusinessLines.map((bl) => {
      if (bl.id === businessLine.id && isBlank) {
        bl.error = 'Champ obligatoire'
      } else if (bl.id === businessLine.id && !isBlank) {
        bl.CustomerBusinessLine.ref = blRefs[businessLine.id]
        bl.editing = false
        bl.error = false
      }

      return bl
    })

    if (!isBlank) {
      const ok = await updateCustomerBusinessLines(
        businessLine.CustomerBusinessLine.id,
        {
          ref: blRefs[businessLine.id],
        }
      )

      setSuccessMessage('Référence client bien engistrée')
    } else {
      setCurrentCustomer({
        ...currentCustomer,
        BusinessLines: newBls,
      })
    }
  }

  const editBusinessLine = async (businessLine) => {
    setBlRefs({ [businessLine.id]: businessLine.CustomerBusinessLine.ref })

    const newBls = currentCustomer.BusinessLines.map((bl) => {
      if (bl.id === businessLine.id) {
        bl.error = false
        bl.editing = true
      }

      return bl
    })

    setCurrentCustomer({
      ...currentCustomer,
      BusinessLines: newBls,
    })
  }

  const unlinkBusinessLine = async (businessLine) => {
    if (
      await Confirm('Êtes-vous sûr de vouloir supprimer cet établissemrnt ?')
    ) {
      if (customer.id) {
        const req = await deleteCustomerBusinessLine(
          businessLine.CustomerBusinessLine
        )
        if (req.results) {
          setSuccessMessage(
            `L'établissement a été dissocié du client avec succès`
          )
        } else {
          setErrorMessage(req.error.message)
        }
      }

      setCurrentCustomer({
        ...currentCustomer,
        BusinessLines: currentCustomer.BusinessLines.filter(
          (a) => a.id !== businessLine.id
        ),
      })
    }
  }

  const unlinkAddress = async (address) => {
    if (await Confirm('Êtes-vous sûr de vouloir supprimer cette addresse ?')) {
      if (customer.id) {
        const req = await deleteCustomerAddress(address.CustomerAddress)
        if (req.results) {
          setSuccessMessage(`L'adresse a été dissociée du client avec succès`)
        } else {
          setErrorMessage(req.error.message)
        }
      }

      setCurrentCustomer({
        ...currentCustomer,
        Addresses: currentCustomer.Addresses.filter((a) => a.id !== address.id),
      })
    }
  }

  const onError = (errors) => {
    setErrorsOnSubmit("Tous les champs marqués d'une étoile * sont requis.")
  }

  const isPro = watch('customer_segment') !== 'Part'

  return (
    <form key="customer-edition" id="customer-edition" className="w-full">
      <Controller
        name="customer_segment"
        control={control}
        rules={{ required: 'Type de client obligatoire' }}
        render={({ onChange, onBlur, value, name, defaultValue }) => (
          <>
            <Select
              disabled={consult}
              className="w-1/3"
              placeholder={'Type...'}
              required
              name={name}
              value={value}
              values={CUSTOMER_TYPES}
              options={CUSTOMER_TYPES}
              defaultValue={defaultValue}
              onChange={(e) => {
                onChange(e.value)
              }}
              label={'Type de client'}
            />
          </>
        )}
      />
      {showCompanyInputs && (
        <div className="form-row col-3 ">
          <Input
            disabled={consult}
            type="text"
            name="denomination"
            label="Dénomination"
            placeholder="Dénomination"
            ref={register({ required: 'Dénomination obligatoire' })}
            className="mr-4"
            required
          />
          <Input
            disabled={consult}
            type="text"
            name="siret"
            label="SIRET"
            placeholder="SIRET"
            ref={register({ required: 'SIRET obligatoire' })}
            className="ml-4 mr-4"
            required
          />
          <Input
            disabled={consult}
            type="text"
            name="siren"
            label="SIREN"
            placeholder="SIREN"
            ref={register({ required: 'SIREN obligatoire' })}
            className="ml-4"
            required
          />
        </div>
      )}

      <div className="form-row col-3">
        <Input
          disabled={consult}
          type="text"
          name="firstname"
          label="Prénom"
          placeholder="Prénom"
          ref={register({ required: isPro ? false : 'Prénom obligatoire' })}
          error={getErrorForfield(errors, 'firstname')}
          required={!isPro}
          className="mr-4"
        />
        <Input
          disabled={consult}
          type="text"
          name="lastname"
          placeholder="Nom"
          label="Nom"
          ref={register({
            required: isPro ? false : 'Nom de famille obligatoire',
          })}
          error={getErrorForfield(errors, 'lastname')}
          required={!isPro}
          className="mx-4"
        />

        <Date
          disabled={consult}
          name="birthdate"
          label="Date de naissance"
          required={!isPro}
          ref={register({
            required: isPro ? false : 'Date de naissance obligatoire',
          })}
          placeholder="JJ/MM/YYYY"
          className="flex flex-col ml-4"
          options={{
            min: '1900-01-01',
          }}
          error={getErrorForfield(errors, 'birthdate')}
          form={{ control }}
        />
      </div>

      <div className="">
        <Controller
          name="agency_domiciliation"
          control={control}
          render={({ onChange, value, name, defaultValue }) => (
            <>
              <Select
                creatable={{
                  formatCreateLabel: (str) => `Créer l'agence "${str}"`,
                }}
                className="w-1/3"
                disabled={consult}
                placeholder={'Agence...'}
                name={name}
                value={value}
                defaultValue={defaultValue}
                values={agencies}
                onChange={(e) => {
                  onChange(e && e.value)
                }}
                label={'Agence de domiciliation du client'}
              />
            </>
          )}
        />
      </div>

      <div className="mt-2 text-sm font-bold">Adresses associées au client</div>
      {hasAddresses && currentCustomer.Addresses && (
        <div className="mb-3 form-row col-1">
          <div className="w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {currentCustomer.Addresses.map((address) => (
              <div
                key={`address_${address.id}`}
                className="flex-auto p-4 mt-3 mr-3 text-xs bg-gray-200 rounded-sm"
              >
                <div className="mb-6">
                  <div>{address.line1}</div>
                  <div>{address.line2}</div>
                  <div>{address.postalCode}</div>
                  <div>{address.city}</div>
                  <div>{address.country}</div>
                </div>
                <div className="grid grid-cols-3">
                  {address.CustomerAddress && (
                    <div className="flex items-end justify-start mt-3 opacity-75 text col-span-2">
                      <span className="mr-1 text-base">
                        <ClockIcon />
                      </span>
                      <span>
                        {dayjs(address.CustomerAddress.updatedAt).format(
                          'DD/MM/YYYY'
                        )}
                      </span>
                    </div>
                  )}
                  {!consult && (
                    <div className="flex items-end justify-end text-base text">
                      <TrashIcon
                        onClick={() => unlinkAddress(address)}
                        className="inline-block text-red-600 cursor-pointer fill-current stroke-current"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {!consult && (
        <div className="mb-3 form-row col-1">
          {showAddressSelect && (
            <AddressSelect
              form={form}
              input={{ name: 'addressSearch' }}
              setCurrentStepData={(data) => {
                form.setValue('addressSearch', data.addressSearch)
              }}
            />
          )}

          {!showAddressSelect && (
            <div className="mb-3">
              <span
                onClick={() => setShowAddressSelect(true)}
                className="px-4 py-2 text-sm font-bold text-white bg-gray-700 rounded-sm cursor-pointer"
              >
                + Associer une adresse
              </span>
            </div>
          )}
        </div>
      )}

      <div className="mt-2 text-sm font-bold">
        Établissements associés au client
      </div>
      {hasBusinessLines && currentCustomer.BusinessLines && (
        <div className="mb-3 form-row col-1">
          <div className="w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {currentCustomer.BusinessLines.map((bl) => (
              <div
                key={`bl_${bl.id}`}
                className="p-4 mt-3 mr-3 text-xs bg-gray-200 rounded-sm grid"
              >
                <div className="mb-6 col-span-3">
                  <div className="font-bold">
                    <span>
                      {bl.name} / {bl.code}{' '}
                    </span>
                  </div>
                  {/* <div className="italic">{bl.domain_name}</div> */}
                  <div>
                    <span className="pr-1">réf. client :</span>
                    {bl.editing && (
                      <>
                        <input
                          type="text"
                          onChange={(e) =>
                            setBlRefs({ ...blRefs, [bl.id]: e.target.value })
                          }
                          value={blRefs[bl.id]}
                        />
                        {bl.error && (
                          <div className="text-red-600">{bl.error}</div>
                        )}
                      </>
                    )}
                    {!bl.editing && bl.CustomerBusinessLine.ref}
                  </div>
                </div>
                <div className="flex items-end justify-start mt-3 opacity-75 text col-span-2">
                  <span className="mr-1 text-base">
                    <ClockIcon />
                  </span>
                  <span>
                    {dayjs(bl.CustomerBusinessLine.updatedAt).format(
                      'DD/MM/YYYY'
                    )}
                  </span>
                </div>
                <div className="flex items-end justify-end text-base text">
                  {(isCurrentUserBusinessLine(bl) || isAdmin()) && (
                    <>
                      {bl.editing && (
                        <>
                          <CheckIcon
                            onClick={() => saveBusinessLineLink(bl)}
                            className="inline-block text-2xl text-green-600 cursor-pointer fill-current stroke-current"
                          />
                          <CloseIcon
                            onClick={() => closeBusinessLineLink(bl)}
                            className="inline-block ml-2 text-lg cursor-pointer fill-current stroke-current"
                          />
                        </>
                      )}
                      {!bl.editing && !consult && (
                        <>
                          <EditIcon
                            onClick={() => editBusinessLine(bl)}
                            className="inline-block text-blue-600 cursor-pointer fill-current stroke-current"
                          />
                          <TrashIcon
                            onClick={() => unlinkBusinessLine(bl)}
                            className="inline-block ml-2 text-red-600 cursor-pointer fill-current stroke-current"
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {!consult && (
        <>
          {showBusinessLineSelect && (
            <div className="form-row col-2 ">
              <BusinessLineSelect
                input={{ name: 'BusinessLineId', className: 'w-full' }}
                form={form}
              />
              <Input
                type="text"
                name="ref"
                label="Référence client interne"
                placeholder="0123..."
                ref={register}
                className="ml-4"
              />
            </div>
          )}
          {!showBusinessLineSelect && (
            <div className="mb-3">
              <span
                onClick={() => setShowBusinessLineSelect(true)}
                className="px-4 py-2 text-sm font-bold text-white bg-gray-700 rounded-sm cursor-pointer"
              >
                + Associer un établissement
              </span>
            </div>
          )}
        </>
      )}

      {fraudsFromBL && fraudsFromBL.length > 0 && (
        <>
          <div className="mt-2 text-sm font-bold">
            Fraudes dans mon établissement
          </div>
          <div className="mb-3 form-row col-1">
            <div className="form-row col-1">
              {fraudsFromBL.map((f) => {
                return (
                  <div
                    key={`bl_${f.id}`}
                    className="relative w-1/3 p-4 mt-3 mr-3 text-xs bg-gray-200 rounded-sm"
                  >
                    <div className="mb-6">
                      <div className="font-bold">Libellé : {f.label}</div>
                      <div className="">
                        Fraude aboutie: {f.proven ? 'Oui' : 'Non'}
                      </div>
                      <div className="">
                        Processus: {f.Process && f.Process.name}
                      </div>
                      <div className="">
                        Établissement: {f.BusinessLine && f.BusinessLine.name}
                      </div>
                      <div className="">
                        Déclarant:{' '}
                        {f.User && f.User.lastname + ' ' + f.User.firstname}
                      </div>
                    </div>
                    <div className="absolute bottom-0 left-0 flex items-center justify-center p-3 mt-3 opacity-75">
                      <span className="mr-1 text-base">
                        <ClockIcon />
                      </span>
                      <span>
                        {dayjs(f.occurence_date).format('DD/MM/YYYY')}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}
      {fraudsFromOther && fraudsFromOther.length > 0 && (
        <>
          <div className="mt-6 text-sm font-bold">
            Fraudes dans d'autres établissements
          </div>
          <div className="mb-3 form-row col-1">
            <div className="form-row col-1">
              {fraudsFromOther.map((f) => {
                return (
                  <div
                    key={`bl_${f.id}`}
                    className="relative w-1/3 p-4 mt-3 mr-3 text-xs bg-gray-200 rounded-sm"
                  >
                    <div className="mb-6">
                      <div className="font-bold">Libellé : {f.label}</div>
                      <div className="">
                        Fraude aboutie: {f.proven ? 'Oui' : 'Non'}
                      </div>
                      <div className="">
                        Processus: {f.Process && f.Process.name}
                      </div>
                      <div className="">
                        Établissement: {f.BusinessLine && f.BusinessLine.name}
                      </div>
                      <div className="">
                        Déclarant:{' '}
                        {f.User && f.User.lastname + ' ' + f.User.firstname}
                      </div>
                    </div>
                    <div className="absolute bottom-0 left-0 flex items-center justify-center p-3 mt-3 opacity-75">
                      <span className="mr-1 text-base">
                        <ClockIcon />
                      </span>
                      <span>
                        {f.occurence_date
                          ? dayjs(f.occurence_date).format('DD/MM/YYYY')
                          : '-'}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}

      <div className="flex justify-end mt-8">
        <div
          className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
          onClick={onCancel}
        >
          {consult ? "Retour" : "Annuler" }
        </div>
        {!consult &&
        <div
          className="p-2 px-4 ml-2 text-white rounded-sm cursor-pointer bg-accent"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Sauver
        </div>
        }
      </div>
      {errorsOnSubmit && (
        <div className="mt-2 text-sm text-right text-red-500">
          {errorsOnSubmit}
        </div>
      )}
    </form>
  )
}
