import React, { useCallback } from 'react'

import useUsers from 'hooks/useUsers'

export default function FormDef() {
  const {
    isReferent,
    isDeclarantTransverse,
    isAdmin,
    getAllMyBusinessLines,
    isNPS,
    isAuditEtablissement,
    isAuditGroupe,
  } = useUsers()

  const getAllowedExports = useCallback(() => {
    let options = []

    const referentOptions = [
      {
        label: 'Export - Déclaration fraude',
        value: 'export_fraude',
      },
      {
        label: 'Export - Déclaration pertes',
        value: 'export_pertes',
      },
    ]

    if (isReferent() || isAuditEtablissement() || isDeclarantTransverse()) {
      options = referentOptions
    }

    if (isAdmin() || isAuditGroupe() || isNPS()) {
      options = [
        ...referentOptions,
        {
          label: 'Export NPS - Fraude paiement',
          value: 'export_nps_payment',
        },
        {
          label: 'Export NPS - Déclaration pertes',
          value: 'export_nps_loss',
        },
      ]
    }

    return options
  }, [isReferent, isAdmin, isNPS])

  return {
    id: 'export',
    title: 'Export',
    // next: "2",
    // defaultValues: { conditions: [] },
    sections: [
      {
        title: null,
        rows: [
          [
            {
              name: 'export-type',
              type: 'select',
              placeholder: 'Choisir un export',
              label: "Type d'export",
              options: {
                required: 'Ce champ est requis',
              },
              values: getAllowedExports(),
              when: {
                export_pertes: [
                  [
                    {
                      type: 'async-select',
                      name: 'businessLinesIds',
                      label: 'Établissement(s)',
                      isMulti: true,
                      getList: getAllMyBusinessLines,
                    },
                  ],
                ],
                export_fraude: [
                  [
                    {
                      type: 'async-select',
                      name: 'businessLinesIds',
                      label: 'Établissement(s)',
                      isMulti: true,
                      getList: getAllMyBusinessLines,
                    },
                  ],
                  [
                    {
                      type: 'select',
                      name: 'fraudType',
                      label: 'Type de fraude',
                      placeholder: 'Choisir...',
                      defaultValue: 'all',
                      values: [
                        {
                          label: ' Tous les types',
                          value: 'all',
                        },
                        {
                          label: 'Virements entrant',
                          value: 'FraudBankTransferIns',
                        },
                        {
                          label: 'Virements sortant',
                          value: 'FraudBankTransferOuts',
                        },
                        {
                          label: 'Dépôts espèces',
                          value: 'FraudCashIns',
                        },
                        {
                          label: 'Sorties espèces',
                          value: 'FraudCashOuts',
                        },
                        {
                          label: 'Remise chèques',
                          value: 'FraudChequeIns',
                        },
                        {
                          label: 'Emissions chèques',
                          value: 'FraudChequeOuts',
                        },
                        {
                          label: 'Prélèvements entrants',
                          value: 'FraudLevyIns',
                        },
                        {
                          label: 'Prélèvements sortants',
                          value: 'FraudLevyOuts',
                        },
                        {
                          label: 'Fraude Documentaire',
                          value: 'FraudDocumentary',
                        },
                        {
                          label: 'Effets entrants',
                          value: 'FraudBelongingsIns',
                        },
                        {
                          label: 'Effets sortants',
                          value: 'FraudBelongingsOuts',
                        },
                        {
                          label: 'Trade',
                          value: 'FraudTrade',
                        },
                        {
                          label: 'Cyber',
                          value: 'FraudCyber',
                        },
                        {
                          label: 'Crédit conso',
                          value: 'FraudConsumerCredit',
                        },
                        {
                          label: 'Fonds entrants CB',
                          value: 'is_cb_in',
                        },
                        {
                          label: 'Fonds sortants CB',
                          value: 'is_cb_out',
                        },
                        {
                          label: 'Fonds sortants Monnaie électronique',
                          value: 'is_electronic_currency',
                        },
                        {
                          label: 'Fonds sortants retrait CB',
                          value: 'is_credit_card_withdrawal',
                        },
                        {
                          label: 'Assurance',
                          value: 'FraudInsurance',
                        },
                      ],
                    },
                  ],
                  [
                    {
                      name: 'date-options',
                      label: 'Type de date',
                      type: 'select',
                      values: [
                        {
                          label: 'Date de survenance',
                          value: 'occurence_date',
                        },
                        {
                          label: 'Date de déclaration',
                          value: 'createdAt',
                        },
                        {
                          label: 'Date de modification',
                          value: 'updatedAt',
                        },
                        {
                          label: 'Date de détection',
                          value: 'detection_date',
                        },
                      ],
                      defaultValue: 'occurence_date',
                      options: {
                        required: true,
                      },
                    },
                  ],
                ],
              },
            },
          ],
          [
            {
              name: 'start-date',
              type: 'date',
              label: 'Période du',
              options: {
                required: true,
                // showMonthYearPicker: true,
              },
            },
            {
              name: 'end-date',
              type: 'date',
              label: 'au',
              options: {
                required: true,
                // showMonthYearPicker: true,
              },
              rules: {
                validate: (value, form, prefix) => {
                  const startDate = form.watch(`${prefix}start-date`)
                  if (value < startDate) {
                    return 'La date de fin ne peut pas être inférieure à la date de début'
                  }
                },
              },
            },
          ],
        ],
      },
    ],
  }
}
