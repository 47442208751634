import React from 'react'

import { Controller } from 'react-hook-form'

import DatePicker, { registerLocale } from 'react-datepicker'
import fr from 'date-fns/locale/fr'

import 'react-datepicker/dist/react-datepicker.css'
import { useMemo, useCallback } from 'react'
registerLocale('fr', fr)

export default function ControlledDatePicker({
  className,
  name,
  label,
  error,
  helpText,
  defaultValue,
  required,
  rules,
  form,
  options,
  disabled = false,
  prefix,
  placeholder = '',
}) {
  let classes = className || 'flex flex-col'

  if (required) {
    classes += ' required'
  }

  const wrapValidate = useCallback((fn) => {
    return (value) => fn(value, form, prefix)
  }, [])

  return (
    <Controller
      control={form.control}
      /* rules={required ? { required: 'Date obligatoire' } : {}} */
      rules={{
        ...rules,
        required: options ? options.required : null,
        validate: rules && rules.validate && wrapValidate(rules.validate),
      }}
      name={name}
      render={({ onChange, onBlur, value, name }) => {
        let selected = null

        try {
          if (value) {
            selected = new Date(value)
            if (isNaN(selected)) {
              if (options && options.showMonthYearPicker) {
                //make date with year and month
                selected = new Date(
                  value.split('/')[1],
                  value.split('/')[0] - 1
                )
              } else {
                selected = new Date()
              }
            }
          }

          if (!value && defaultValue) {
            form.setValue(name, defaultValue)
            selected = defaultValue
          }
        } catch (e) {
          // ignore
        }

        return (
          <label className={classes}>
            <span className={`block ${disabled ? 'opacity-50' : ''}`}>
              {label}
            </span>
            <DatePicker
              locale={fr}
              placeholderText={placeholder}
              selected={selected}
              shouldCloseOnSelect={true}
              onChange={(date, e) => {
                if (e && typeof e.preventDefault === 'function') {
                  e.preventDefault()
                }

                if (onChange) {
                  onChange(date)
                }
                onChange((date && date.toISOString()) || date)
              }}
              onBlur={onBlur}
              disabled={disabled}
              firstDayOfWeek={1}
              showMonthYearPicker={options && options.showMonthYearPicker}
              dateFormat={
                options && options.showMonthYearPicker
                  ? 'MM/yyyy'
                  : 'dd/MM/yyyy'
              }
              minDate={
                options && options.min
                  ? new Date(options.min)
                  : new Date('1971-01-01')
              }
              maxDate={
                options && options.max ? new Date(options.max) : new Date()
              }
              popperModifiers={{
                offset: {
                  enabled: true,
                  offset: '5px, 10px',
                },
                preventOverflow: {
                  enabled: true,
                  escapeWithReference: false,
                  boundariesElement: document.querySelector('.form-content'),
                },
              }}
            />
            {helpText && <span className="pb-6 help-text">{helpText}</span>}
            {error && <span className="help-text error">{error}</span>}
          </label>
        )
      }}
    />
  )
}
