import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import { useNotify } from 'stores/notify'
// import { useUser } from 'stores/user'
import useUsers from 'hooks/useUsers'
import Register from 'components/Register'
import LoggedOutHeader from 'components/common/LoggedOutHeader'
import confirm from 'components/common/ConfirmModal'
import useBusinessLines from 'hooks/useBusinessLines'

export default function RegisterPage({ history }) {
  // const [userAccreditate, setUserAccreditate] = useState(null)
  const [finishMessage, setFinishMessage] = useState(null)
  const [email, setEmail] = useState(null)
  const [businessLines, setBusinessLines] = useState([])

  const { getBusinessLineByAccToken } = useBusinessLines()

  const params = new URL(document.location).searchParams
  const token = params.get('token')

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  useEffect(() => {
    const asyncGetBusinessLines = async () => {
      try {
        const req = await getBusinessLineByAccToken(token)
        const res = req.results
          .filter((bl) => bl.name)
          .map((bl) => ({
            ...bl,
            label: bl.name,
            value: bl.id,
          }))
        setBusinessLines(res)
      } catch (err) {
        setErrorMessage(err.message)
      }
    }

    asyncGetBusinessLines()
  }, [])

  const form = useForm()

  const { checkRegistrationToken, registerAccreditation } = useUsers()

  const accreditationRequestSubmit = async (params) => {
    if (await confirm(`Confirmer l'envoi de la demande d'accréditation ?`)) {
      try {
        await registerAccreditation({ ...params, token })
        setFinishMessage(
          "Merci d'avoir fait votre demande d'accréditation, vous recevrez la réponse à votre demande par email."
        )
      } catch (err) {
        setErrorMessage(err.message)
      }
    }
  }

  useEffect(() => {
    const checkLinkValidity = async () => {
      try {
        const res = await checkRegistrationToken(token)
        const { results } = res

        if (results && results.email) {
          setEmail(results.email)
        }
      } catch (err) {
        setErrorMessage(
          'Une erreur est survenue. Merci de refaire une demande.'
        )
        history.replace('/request-access')
        // if no token, route throw error
      }
    }
    checkLinkValidity()
  }, [token])

  useEffect(() => {
    form.reset({ email })
  }, [email])

  const onError = (e) => {
    console.error(e)
  }
  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 ">
          {finishMessage && (
            <div className="w-full">
              <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md w-full">
                {finishMessage}
              </div>
            </div>
          )}
          {!finishMessage && (
            <form
              onSubmit={form.handleSubmit(accreditationRequestSubmit, onError)}
              className="form-container w-full"
            >
              <p>
                Veuillez remplir le formulaire suivant. Un référent sera chargé
                de valider votre demande.
              </p>
              <Register form={form} businessLines={businessLines} />
              <div className="flex items-center justify-center w-full">
                <button
                  className="bg-accent text-white py-3 px-4 rounded"
                  type="submit"
                >
                  Envoyer la demande d'accréditation
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
