import React, { useEffect, useState } from 'react'

import { Controller, useFieldArray } from 'react-hook-form'

import useFrauds from 'hooks/useFrauds'

import Select from 'components/form/Select'
import FormRow from 'components/form/FormRow'

const operators = [
  {
    label: 'Égal à',
    value: '=',
  },
  {
    label: 'Différent de',
    value: '!=',
  },
]

const operatorsWhenNoField = [
  {
    label: 'Est présent',
    value: 'is not null',
  },
  {
    label: "N'est pas présent",
    value: 'is null',
  },
]

function Condition({
  field,
  form,
  fidx,
  models,
  prefix,
  modelsWithFields,
  remove,
}) {
  const [fields, setFields] = useState([])
  const [operatorList, setOperatorList] = useState([])
  const model = form.watch(`${prefix}.model`)
  const fieldField = form.watch(`${prefix}.field`)
  const operatorField = form.watch(`${prefix}.operator`)

  const [valueField, setValueField] = useState({})

  const { setValue } = form

  useEffect(() => {
    let modelFields = []

    if (model && modelsWithFields[model]) {
      modelFields = Object.keys(modelsWithFields[model].fields).reduce(
        (modelFields, field) => {
          modelFields.push({
            label: modelsWithFields[model].fields[field].label,
            value: field,
          })
          return modelFields
        },
        []
      )
    }
    modelFields.sort((a, b) => a.label.localeCompare(b.label))

    setFields(modelFields)
  }, [model, modelsWithFields])

  useEffect(() => {
    if (
      model &&
      modelsWithFields[model] &&
      modelsWithFields[model].fields &&
      modelsWithFields[model].fields[fieldField]
    ) {
      setValueField({
        ...modelsWithFields[model].fields[fieldField],
        type: modelsWithFields[model].fields[fieldField].inputType,
      })
    }
  }, [fieldField, modelsWithFields])

  useEffect(() => {
    if (fieldField) {
      setOperatorList(operators)
      if (!operatorField)
        setValue(`${prefix}.operator`, operators[0].value)
    } else {
      setOperatorList(operatorsWhenNoField)
    }
  }, [fieldField])

  return (
    <div className="flex items-center mt-4">
      <div className="flex condition-line">
        <div className="">
          <div className="condition-col">
            <Controller
              control={form.control}
              name={`${prefix}.model`}
              defaultValue={field.model}
              render={({ onChange, value, name, defaultValue }) => {
                return (
                  <Select
                    label="Entité"
                    name={name}
                    defaultValue={defaultValue}
                    placeholder="Choisir une entité"
                    values={models}
                    value={value}
                    onChange={(e) => onChange(e && e.value)}
                  />
                )
              }}
            />
          </div>
          <div className="condition-col">
            <Controller
              control={form.control}
              name={`${prefix}.field`}
              defaultValue={field.field}
              render={({ onChange, value, name, defaultValue }) => (
                <Select
                  key={JSON.stringify(fields)}
                  label="Champ"
                  defaultValue={defaultValue}
                  name={name}
                  placeholder="Choisir un champ"
                  values={fields}
                  value={value}
                  onChange={(e) => onChange(e && e.value)}
                />
              )}
            />
          </div>
          <div className="condition-col">
            <Controller
              control={form.control}
              name={`${prefix}.operator`}
              render={({ onChange, value, name }) => {
                return (
                  <Select
                    label="Opérateur"
                    name={name}
                    placeholder="Choisir un opérateur"
                    values={operatorList}
                    value={value}
                    onChange={(e) => onChange(e && e.value)}
                  />
                )
              }}
            />
          </div>
          <div className="condition-col">
            {fieldField && (
              <FormRow
                inputs={[{ ...valueField, name: 'value', label: 'Valeur' }]}
                namePrefix={prefix}
                ridx={fidx}
                form={form}
              />
            )}
          </div>
          <div className="pt-2 text-right">
            <button
              onClick={() => remove(fidx)}
              type="button"
              className="text-sm text-red-600 underline"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function ConditionWithSubConditions({
  models,
  modelsWithFields,
  fidx,
  form,
  remove,
  field,
  prefix,
  level,
}) {
  const subCondsPrefix = `${prefix}.subconds`

  const subCondsFieldArray = useFieldArray({
    control: form.control,
    name: subCondsPrefix,
  })

  // Initialize with one element
  useEffect(() => {
    if (!subCondsFieldArray.fields.length) {
      subCondsFieldArray.append({})
    }
  }, [])

  return (
    <div>
      <div className="pb-2 text-red-600">
        <button
          onClick={() => remove(fidx)}
          type="button"
          className="underline"
        >
          Supprimer la condition #{fidx + 1}
        </button>
      </div>
      <div className="flex justify-start">
        <div className="flex flex-wrap">
          {subCondsFieldArray.fields.map((subfield, ffidx) => (
            <div className="flex" key={`subcon-${subfield.id}-${ffidx}`}>
              <Condition
                key={subfield ? subfield.id : ffidx}
                append={subCondsFieldArray.append}
                form={form}
                remove={subCondsFieldArray.remove}
                prefix={`${subCondsPrefix}[${ffidx}]`}
                models={models}
                fidx={ffidx}
                field={field}
                level={level}
                modelsWithFields={modelsWithFields}
              />
              {ffidx < subCondsFieldArray.fields.length - 1 && (
                <div className="flex items-center px-4 condition-or">
                  <span className="px-4 py-1 font-bold bg-gray-200 rounded-full">
                    OU
                  </span>
                </div>
              )}
            </div>
          ))}
          <div className="flex items-center pl-8 mt-2">
            <button
              type="button"
              className="px-4 py-1 font-bold text-white rounded-full bg-purple"
              onClick={() => {
                subCondsFieldArray.append()
              }}
            >
              + OU
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default function Conditions({ form }) {
  const { getFraudDefinition } = useFrauds()

  const [models, setModels] = useState([])
  const [modelsWithFields, setModelsWithFields] = useState({})

  useEffect(() => {
    getFraudDefinition().then((res) => {
      if (res.results) {
        const fraudModels = Object.keys(res.results).reduce(
          (fraudModels, modelName) => {
            fraudModels.push({
              value: modelName,
              label: res.results[modelName].label,
            })
            return fraudModels
          },
          []
        )
        fraudModels.sort((a, b) => a.label.localeCompare(b.label))

        // setModels(fraudModels)
        setModels([
          {
            label: 'Fraude',
            options: fraudModels.filter((m) => m.value === 'Fraud'),
          },
          {
            label: 'Client',
            options: fraudModels.filter((m) => m.value === 'Customer'),
          },
          {
            label: 'Type de fraude',
            options: fraudModels.filter(
              (m) => !['Fraud', 'Customer'].includes(m.value)
            ),
          },
        ])
        setModelsWithFields(res.results)
      }
    })
  }, [])

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'conds',
  })

  return (
    <div className="w-full">
      <div>
        {fields.map((field, fidx) => (
          <div className="condition-group" key={field ? field.id : fidx}>
            <ConditionWithSubConditions
              models={models}
              modelsWithFields={modelsWithFields}
              form={form}
              field={field}
              fidx={fidx}
              remove={remove}
              prefix={`conds[${fidx}]`}
            />
            <div
              className="absolute w-full text-center"
              style={{ bottom: '-10px' }}
            >
              {fidx < fields.length - 1 && (
                <span className="px-4 py-1 font-bold bg-gray-400 rounded-full">
                  ET
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="relative flex justify-center w-full">
        <button
          type="button"
          className="absolute px-4 py-1 mb-2 font-bold text-white rounded-full bg-purple"
          style={{ top: '-17px' }}
          onClick={() => append()}
        >
          + ET
        </button>
      </div>
    </div>
  )
}
