import React, { useState, useEffect } from 'react'

import { getNumberFromString } from 'lib/utils'

import { Controller } from 'react-hook-form'

export function NumberInputView({
  classes,
  label,
  disabled,
  autofocus,
  suffix,
  helpText,
  placeholder,
  error,
  value,
  name,
  onChange,
  min,
}) {
  const [typedValue, setTypedValue] = useState(value)
  useEffect(() => {
    if (typedValue === undefined && value) {
      setTypedValue(value)
    }
  }, [value, typedValue])

  return (
    <label className={classes}>
      <span>{label}</span>

      <div className="flex items-center">
        <input
          type="text"
          id={name}
          name={name}
          placeholder={typeof placeholder !== 'undefined' ? placeholder : label}
          value={typedValue}
          disabled={disabled}
          autoFocus={autofocus}
          pattern="[0-9]+(\.)?"
          onChange={(e) => {
            const numberVal = getNumberFromString(e.target.value)
            onChange(numberVal)
            setTypedValue(e.target.value)
          }}
          min={min}
        />
        {suffix && <span>{suffix}</span>}
      </div>
      {helpText && <span className="help-text">{helpText}</span>}
      {error && <span className="help-text error">{error}</span>}
    </label>
  )
}

export default React.forwardRef(
  (
    {
      // type,
      label,
      name,
      placeholder,
      required,
      // onChange,
      className,
      helpText,
      error,
      suffix,
      form,
      defaultValue,
      disabled = undefined,
      autofocus = false,
      hide = false,
      options,
    },
    ref
  ) => {
    let classes = className

    if (required) {
      classes += ' required'
    }

    if (disabled) {
      classes += ' cursor-not-allowed'
    }

    const display = hide ? 'none' : ''

    return (
      <label className={classes} htmlFor={name} style={{ display: display }}>
        <Controller
          control={form.control}
          name={name}
          rules={options}
          render={({ onChange, value, name }) => {
            return (
              <NumberInputView
                classes={classes}
                label={label}
                disabled={disabled}
                autofocus={autofocus}
                suffix={suffix}
                helpText={helpText}
                placeholder={placeholder}
                error={error}
                value={value}
                name={name}
                onChange={onChange}
                min={
                  options
                    ? options.min
                      ? options.min.value
                        ? options.min.value
                        : undefined
                      : undefined
                    : undefined
                }
                return
              />
            )
          }}
        />
      </label>
    )
  }
)
