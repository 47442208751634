import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg
      width=".8em"
      height=".8em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M50 0c13.688 0 25.141 11.172 25.141 25.141C75.141 38.829 63.688 50 50 50c-13.965 0-25.141-11.172-25.141-24.859A25.037 25.037 0 0150 0zm0 6.984c-10.055 0-17.879 8.102-17.879 18.156 0 9.778 7.82 17.88 17.879 17.88 9.777 0 17.879-8.102 17.879-17.88 0-10.055-8.102-18.156-17.879-18.156zM27.375 51.676h44.973c11.453 0 20.949 9.219 20.949 20.672v22.906c0 2.516-2.234 4.75-4.75 4.75H11.453c-2.516 0-4.75-2.234-4.75-4.75V72.348a20.626 20.626 0 0120.672-20.672zm44.973 6.984H27.375c-7.543 0-13.688 6.145-13.688 13.688V93.02h72.348V72.348c0-7.543-6.144-13.688-13.688-13.688z" />
    </svg>
  )
}

export default SvgComponent
