import React, { useEffect } from "react";
import { useNotify } from "stores/notify";
import { useForm } from "react-hook-form";
import { useUser } from "stores/user";
import useUsers from "hooks/useUsers";
import passwords from "lib/passwords";
import Input from "components/form/Input";
import LoggedOutHeader from "components/common/LoggedOutHeader";

const PasswordHint = () => {
  return (
    <ul>
      <li>
        • le mot de passe doit avoir une longueur minimale de 8 caractères{" "}
      </li>
      <li>
        • le mot de passe doit être composé avec une combinaison d’au moins 3
        types de caractères parmi les 4 suivants : majuscules (A-Z), minuscules
        (a-z), chiffres (0-9) et caractères spéciaux ;
      </li>
      <li>
        • le mot de passe ne doit pas être constitué d’une combinaison d’un ou
        plusieurs mots communs et de chiffres.
      </li>
    </ul>
  );
};

const validatePassword = (value) => {
  const validation1 =
    [
      "^(?=.*[a-z]).+$",
      "^(?=.*[A-Z]).+$",
      "^(?=.*\\d).+$",
      "^(?=.*[!@#$%^&*()_+\\-=\\[\\]{};':\"\\|,.<>\\/?]).+$",
    ]
      .map((re) => !!value.match(new RegExp(re)))
      .filter((v) => v).length >= 3;

  const validation2 = value.length >= 8;

  const validation3 = !passwords.find((p) =>
    value.toLowerCase().includes(p.toLowerCase())
  );
  return (validation1 && validation2 && validation3) || <PasswordHint />;
};

export default function SetPasswordPage({ history }) {
  const { checkLoginToken, setPassword } = useUsers();
  const { setUser } = useUser((o) => o);
  // const [userAccreditate, setUserAccreditate] = useState(null)
  // const [finishMessage, setFinishMessage] = useState(null)
  // const [email, setEmail] = useState(null)

  const params = new URL(document.location).searchParams;
  const token = params.get("token");

  const { errors, handleSubmit, register, watch } = useForm();

  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  );

  const onSubmit = async (params) => {
    try {
      const res = await setPassword({ ...params, token });
      if (res && res.results && res.results.tokens) {
        setUser(res.results);
      }
      setSuccessMessage(
        'Votre mot de passe a été enregistré. Bienvenue dans FREGAT.'
      )
      history.replace("/fraudes");
    } catch (err) {
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    const checkUserToken = async () => {
      try {
        await checkLoginToken(token);
      } catch (err) {
        setErrorMessage("Le token a expiré.");
        history.replace("/");
        // if no token, route throw error
      }
    };
    checkUserToken();
  }, [token]);

  function validatePasswordComfirmation(value) {
    return watch("password") === value || " ";
  }

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="w-full form-container"
          >
            <p>
              Veuillez définir votre mot de passe d'accès à l'application
            </p>
            <Input
              key={`section-password`}
              ref={register({ required: "requis", validate: validatePassword })}
              type="password"
              name="password"
              label="Mot de passe"
              value={watch("password")}
              error={errors.password && errors.password.message}
              required={"Champ obligatoire"}
              autofocus={true}
            />
            <Input
              key={`section-confirmation-password`}
              ref={register({
                required: "requis",
                validate: validatePasswordComfirmation,
              })}
              type="password"
              name="passwordComfirmation"
              label="Confirmation du mot de passe"
              value={watch("passwordComfirmation")}
              error={
                errors.passwordComfirmation &&
                errors.passwordComfirmation.message
              }
              required={"Champ obligatoire"}
              autofocus={true}
            />
            {errors.passwordComfirmation &&
              errors.passwordComfirmation.message && (
                <span className="text-sm text-red-500">
                  Les mots de passes ne correspondent pas.
                </span>
              )}

            <div className="flex items-center justify-center w-full">
              <button
                className="px-4 py-3 text-white rounded bg-accent"
                type="submit"
              >
                Enregistrer
              </button>
            </div>
            {/* <span className="text-sm text-red-500"> */}
            {/*   {errors && errors.password && ( */}
            {/*     <ul> */}
            {/*       <li> */}
            {/*         • le mot de passe doit avoir une longueur minimale de 8 */}
            {/*         caractères ; */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         • le mot de passe doit être composé avec une combinaison */}
            {/*         d’au moins 3 types de caractères parmi les 4 suivants : */}
            {/*         majuscules (A-Z), minuscules (a-z), chiffres (0-9) et */}
            {/*         caractères spéciaux ; */}
            {/*       </li> */}
            {/*       <li> */}
            {/*         • le mot de passe ne doit pas être constitué d’une */}
            {/*         combinaison d’un ou plusieurs mots communs et de chiffres. */}
            {/*       </li> */}
            {/*     </ul> */}
            {/*   )} */}
            {/* </span> */}
          </form>
        </div>
      </div>
    </div>
  );
}
