import useProcesses from 'hooks/useProcesses'

export default function FormDef() {
  const { findProcesses } = useProcesses()

  return {
    id: 'fraud-type',
    title: 'Type de fraude',
    sections: [
      {
        title: null,
        rows: [
          [
            // Used for update entity by its id
            {
              hide: true,
              type: 'hidden',
              name: 'id',
            },
          ],
          [
            {
              name: 'name',
              label: 'Nom',
            },
          ],
        ],
      },
    ],
  }
}
