import React, { useMemo, useCallback } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import CreatableSelect from 'react-select/creatable'

function findValue(options, value) {
  return options.reduce((optionFound, option) => {
    let val
    if (option.hasOwnProperty('options')) {
      val = findValue(option.options, value)
    } else if (option.value === value) {
      val = option
    }

    return optionFound || val
  }, null)
}

export default React.forwardRef((props, ref) => {
  const {
    defaultOptions,
    handleInputChange,
    loadOptions,
    label,
    name,
    placeholder = 'Choisir une valeur',
    required,
    onChange,
    className,
    helpText,
    error,
    value,
    values = [],
    options,
    defaultValue,
    when,
    isMulti,
    isClearable = true,
    creatable = undefined,
    async = undefined,
    formatOptionLabel = undefined,
    filterOption,
    disabled = false,
    noOptionsMessage = () => 'Aucune valeur disponible',
    keepOldValue = false,
  } = props
  let SelectComponent = Select
  if (creatable && !async) {
    SelectComponent = CreatableSelect
  } else if (creatable && async) {
    SelectComponent = AsyncCreatableSelect
  } else if (!creatable && async) {
    SelectComponent = AsyncSelect
  }

  let classes = className

  if (options && options.required) {
    classes += ' required'
  }

  let width = 'w-64'

  const classNames = (className || '').split(' ')

  if (className) {
    const widthFound = classNames.find((c) => c.startsWith('w-'))

    if (widthFound) {
      width = widthFound
    }
  }

  const classNameWithoutWidth =
    (className && classNames.filter((c) => !c.startsWith('w-'))) || ''

  const dynamicProps = {}

  let realValue = undefined
  let realDefaultValue = undefined

  if (isMulti && value) {
    realValue = value.map((v) => findValue(values, v))
  } else if (value) {
    realValue = findValue(values, value)
  }
  
  if (isMulti && defaultValue) {
    realDefaultValue = defaultValue
  } else if (defaultValue) {
    realDefaultValue = findValue(values, defaultValue)
  }

  // Set filter on label by default
  const filterOptionFn =
    filterOption ||
    useCallback(({ label, value, data }, inputValue) => {
      return (
        !inputValue ||
        (
          (label &&
            label.toString().toLowerCase().match(inputValue.toLowerCase())) ||
          []
        ).length > 0
      )
    })

  if (keepOldValue && !realValue && value && typeof value === 'string') {
    values.push({
      label: value,
      value,
    })
  }

  const sortedOptions = useMemo(() => {
    let sortedOptions = values.reduce((sortedOptions, option) => {
      if (option.hasOwnProperty('options')) {
        option.options.sort((a, b) => a.label.localeCompare(b.label))
      }
      sortedOptions.push(option)
      return sortedOptions
    }, [])

    sortedOptions = sortedOptions.sort((a, b) => a.label.localeCompare(b.label))
    return sortedOptions
  }, [values])

  return (
    <label className={classes} htmlFor={name}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      <SelectComponent
        defaultOptions={defaultOptions}
        onInputChange={handleInputChange}
        loadOptions={loadOptions}
        filterOption={filterOptionFn}
        noOptionsMessage={noOptionsMessage}
        ref={ref}
        isClearable={isClearable}
        isMulti={isMulti}
        placeholder={placeholder}
        defaultValue={realDefaultValue}
        name={name}
        isDisabled={disabled}
        className={`${classNameWithoutWidth} ${width}`}
        value={realValue}
        options={sortedOptions}
        hideSelectedOptions={false}
        label={label}
        formatOptionLabel={formatOptionLabel}
        onChange={(e) => onChange(e)}
        menuPortalTarget={document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
        required={!!options && !!options.required}
        {...dynamicProps}
        {...creatable}
      />
      {error ? (
        <span className="help-text error">{error}</span>
      ) : (
        <span className="help-text">{helpText}</span>
      )}

      {when && value && when[value] && (
        <div className="complement-input">{when[value]}</div>
      )}
    </label>
  )
})
