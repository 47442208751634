import React, { useEffect, useState } from 'react'

import { Controller } from 'react-hook-form'
import Select from 'components/form/Select'


export default function SelectBusinessLine({ form, name, label, required, businessLines = [] }) {
  const { errors } = form

  return (
    <Controller
      name={name}
      control={form.control}
      rules={{ required }}
      render={({ onChange, onBlur, value, name }) => {
        return (
          <>
            <Select
              placeholder={'Établissement...'}
              required={required}
              className="w-full"
              name={name}
              value={value}
              values={businessLines}
              formatOptionLabel={(value) => {
                return (
                  <>
                    <span
                      className="text-accent mt-1 border-solid border-accent border float-left px-2 lowercase align-middle rounded-full opacity-75"
                      style={{ fontSize: '11px' }}
                    >
                      Niveau {value.level}
                    </span>
                    <span className="font-bold mr-2 ml-2">{value.name}</span>
                  </>
                )
              }}
              options={businessLines}
              onChange={(e) => {
                if (e) {
                  onChange(e.value)
                } else {
                  onChange(null)
                }
              }}
              label={label}
            />
            {errors && errors[name] && (
              <span className="text-sm text-red-500">
                {errors[name].message}
              </span>
            )}
          </>
        )
      }}
    />
  )
}
