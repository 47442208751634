import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useFraudImports from 'hooks/useFraudImports'
import fileDownload from 'js-file-download'
import JSZip from 'jszip'
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'

import confirm from 'components/common/ConfirmModal'
import { useNotify } from 'stores/notify'
import Accordion from 'components/imports/Accordion'
import ErrorIcon from 'components/svg/error'
import CheckIcon from 'components/svg/check'
import TrashIcon from 'components/svg/trash'
import DownloadIcon from 'components/svg/download'

const ImportShow = (props) => {
  const { id } = props
  const history = useHistory()

  const {
    exportNewFraudTable,
    getImport,
    rollbackFraudImport,
  } = useFraudImports()

  const { setErrorMessage, setSuccessMessage } = useNotify((n) => n)

  const [fraudImport, setFraudImport] = useState(null)

  useEffect(() => {
    const get = async () => {
      const { results } = await getImport(id)
      if (results) setFraudImport(results)
    }
    get()
  }, [])

  const getByState = useCallback(
    (fraudType, state) => {
      return fraudImport.feedback && fraudImport.feedback[fraudType]
        ? fraudImport.feedback[fraudType].filter((f) => f.state === state)
        : []
    },
    [fraudImport]
  )

  const onRemove = async (id) => {
    if (await confirm('Voulez-vous annuler cet import ?')) {
      try {
        const req = await rollbackFraudImport(id)

        if (req.results && req.results.success) {
          setSuccessMessage(`Import annulé avec succès`)
          history.replace('/imports-logging')
        } else {
          setErrorMessage(
            `Impossible d'annuler l'import' : ${req.error.message}`
          )
        }
      } catch (error) {
        setErrorMessage(`Impossible d'annuler l'import. ${error.message}`)
      }
    }
  }

  const handleExportNewFrauds = useCallback(async () => {
    const res = await exportNewFraudTable(fraudImport.id)
    if (res.results) {
      const zip = new JSZip()
      zip.file(`${res.results.filename}`, res.results.content, { base64: true })
      const zipContent = await zip.generateAsync({ type: 'blob' })
      fileDownload(zipContent, 'export.zip')
    } else {
      setErrorMessage("La table de correspondance n'a pas pu être exporté")
    }
  })

  const fileHasError = useMemo(() => {
    const hasFeedback =
      fraudImport && fraudImport.state === 'error' && fraudImport.feedback
    const feedbacks = Object.entries(fraudImport?.feedback || {}).reduce(
      (acc, [key, feedbacks]) => {
        if (key.startsWith('Imp_')) {
          return acc.concat(feedbacks)
        }

        return acc
      },
      []
    )
    return feedbacks.some((feedback) => feedback.state !== 'ok')
  }, [fraudImport])

  const otherImportFailure = useMemo(() => {
    return fraudImport && fraudImport.state !== 'ok' && !fileHasError
  }, [fraudImport, fileHasError])

  return (
    <div location={props.location}>
      <h1 className="flex justify-between">
        <span className="ml-2">Import n° {fraudImport?.id}</span>{' '}
        <div className="flex items-center">
          {fraudImport && fraudImport.state === 'ok' && (
            <span title="Télécharger des identifiants de l'import">
              <DownloadIcon
                className="ml-4 text-blue-500 cursor-pointer fill-current"
                onClick={handleExportNewFrauds}
              />
            </span>
          )}
          {fraudImport?.canRollBack && (
            <span title="Supprimer l'import et revenir sur la version précédente">
              <TrashIcon
                className="ml-4 text-red-500 cursor-pointer fill-current"
                onClick={() => {
                  onRemove(fraudImport.id)
                }}
              />
            </span>
          )}
        </div>
      </h1>

      {fraudImport && fraudImport.feedback && (
        <div
          className="relative w-full p-6 mx-auto mt-0 mt-8 bg-white rounded-sm shadow-lg form-container"
          style={{ overflow: 'visible' }}
        >
          <div className="w-full">
            <div className="flex flex-row justify-center">
              {fraudImport.state === 'ok' ? (
                <div className="flex flex-row justify-center w-full">
                  <span className="flex items-center justify-center w-8 text-white bg-green-200 rounded-full">
                    <CheckIcon />
                  </span>
                  <span className="ml-2 text-xl">
                    L’import a été réalisé avec succès
                  </span>
                </div>
              ) : null}
              {fileHasError ? (
                <div className="flex flex-row justify-center w-full">
                  <ErrorIcon />
                  <span className="ml-2 text-xl">
                    Le fichier comporte des erreurs
                  </span>
                </div>
              ) : null}
              {otherImportFailure ? (
                <div className="flex flex-row justify-center w-full">
                  <ErrorIcon />
                  <span className="ml-2 text-xl">
                    Une erreur est survenue lors de l'import. Réessayez plus
                    tard.
                  </span>
                </div>
              ) : null}
            </div>
            <div className="flex flex-row justify-start w-full mt-4">
              Nom du fichier:
              <span className="ml-2 font-bold">{fraudImport.filename}</span>
            </div>
            <div className="flex flex-row justify-start w-full mt-4">
              Date de l'import:
              <span className="ml-2 font-bold ">
                {dayjs(fraudImport.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            {Object.keys(fraudImport.feedback).map((fraudType) => (
              <Accordion
                generalState={fraudImport.state}
                tab={fraudType}
                warnings={getByState(fraudType, 'warning')}
                errors={getByState(fraudType, 'error')}
                oks={getByState(fraudType, 'ok')}
                importState={fraudImport.state}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  )
}
export default ImportShow
