import Api from "lib/api";

import { useUser } from "stores/user";

export default () => {
  const token = useUser(({ token }) => token)

  return {
    createAddress: async (address) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.createAddress(token, {
          body: address,
        });

        return rawResponse;
      } catch (e) {
        console.error(e);
        return { error: e };
      } finally {
        // setLoading(false)
      }
    },
    getAddresses: async (offset, limit, filters) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.getAddresses(token, {
          // TODO: check with Kevin for params support
          query: { offset: 0, limit: 100, filters },
        });

        return rawResponse;
      } catch (e) {
        console.error(e);
        return { error: e };
      } finally {
        // setLoading(false)
      }
    },

    findAddress: async (address) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.findAddress(token, {
          query: address,
        });

        return rawResponse;
      } catch (e) {
        console.error(e);
        return { error: e };
      } finally {
        // setLoading(false)
      }
    },
    deleteCustomerAddress: async (customerAddress) => {
      //   setLoading(true)
      const { id } = customerAddress
      try {
        const rawResponse = await Api.deleteCustomerAddress(token, id);

        return rawResponse;
      } catch (e) {
        console.error(e);
        return { error: e };
      } finally {
        // setLoading(false)
      }
    },
    createCustomerAddress: async (payload) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.createCustomerAddress(token, {
          body: payload,
          query: { populate: true }
        });

        return rawResponse;
      } catch (e) {
        console.error(e);
        return { error: e };
      } finally {
        // setLoading(false)
      }
    },
    findCityByPostalCode: async (postalCode) => {
      //   setLoading(true)
      try {
        const rawResponse = await Api.findCityByPostalCode(token, {
          query: { limit: 100, filters: [["postalCode",postalCode]] }
        });

        return rawResponse;
      } catch (e) {
        console.error(e);
        return { error: e };
      } finally {
        // setLoading(false)
      }
    },
  };
};
