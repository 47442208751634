import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
      <path d="M6 34a8 8 0 008 8v40a16 16 0 0016 16h40a16 16 0 0015.999-16V42a8 8 0 008-8v-8a8 8 0 00-8-8h-16v-8a8 8 0 00-8-8h-24a8 8 0 00-8 8v8h-16a8 8 0 00-8 8zm72 48a8 8 0 01-8 8H30a8 8 0 01-8-8V42h56zM38 10h24v8H38zM14 26h72v8H14z" />
      <path d="M34 82a3.997 3.997 0 004-4V54c0-2.21-1.79-4-4-4s-4 1.79-4 4v24a3.997 3.997 0 004 4zM50 82a3.997 3.997 0 004-4V54c0-2.21-1.79-4-4-4s-4 1.79-4 4v24a3.997 3.997 0 004 4zM66 82a3.997 3.997 0 004-4V54c0-2.21-1.79-4-4-4s-4 1.79-4 4v24a3.997 3.997 0 004 4z" />
    </svg>
  )
}

export default SvgComponent
