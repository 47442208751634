import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
            <path d="M64.664 60.738l7.43-7.43a4.687 4.687 0 000-6.613l-7.43-7.43L35.336 9.93c-2.27-2.27-5.613-2.636-7.43-.82-1.82 1.82-1.449 5.164.82 7.43L58.06 45.87c2.27 2.27 2.27 5.98 0 8.25L28.723 83.457c-2.27 2.27-2.637 5.613-.82 7.43 1.82 1.82 5.163 1.449 7.429-.82z" />
        </svg>
    )
}

export default SvgComponent
