import React, { useState } from 'react'
import Table from 'components/common/table'
import useUsers from 'hooks/useUsers'
import { useUser } from 'stores/user'
import { dateFmt } from 'components/common/table/formats'
import { DefaultColumnFilter } from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  EditAction,
  RemoveAction,
  ConnectAsAction,
} from 'components/common/table/actions'
import { useNotify } from 'stores/notify'
import { useHistory } from 'react-router-dom'

const IfAdmin = ({ children }) => {
  const { isAdmin } = useUsers()

  if (isAdmin()) {
    return children
  }
  return null
}
const initialSortBy = [
  {
    id: 'updatedAt',
    desc: true,
  },
]

const List = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { setUser } = useUser(({ setUser }) => ({
    setUser,
  }))

  const { getUsersView, deleteUser, connectAs } = useUsers()

  function onError(err) {
    if (err.statusCode === 401) {
      setErrorMessage(err.message)
      history.replace('/')
    } else {
      setErrorMessage('Une erreur est survenue')
    }
  }

  const fetchData = useApiFetch(getUsersView, onError)

  const [removed, setRemoved] = useState(null)
  const onRemove = async (id) => {
    const req = await deleteUser({ id })
    if (req.results && req.results.success) {
      setSuccessMessage(`Elément supprimé avec succès`)
      setRemoved(id)
    } else {
      setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Utilisateur',
        id: 'userName',
        filterOptions: {
          placeholder: (count) => `recherche parmi ${count} lignes`,
        },
        Filter: DefaultColumnFilter,
        accessor: (row) => row.userName,
      },
      {
        Header: 'Email',
        accessor: 'email',
        Filter: DefaultColumnFilter,
        accessor: (row) => row.email,
      },
      {
        Header: 'Rôle',
        accessor: 'role',
        accessor: (row) => {
          let roles = []
          if (row.role) {
            roles.push(row.role)
          }

          if (row.BusinessLines && row.BusinessLines.length > 0) {
            const groupedRoles = row.BusinessLines.reduce(
              (groupedRoles, bl) => {
                if (bl.UserBusinessLine) {
                  const { role } = bl.UserBusinessLine
                  if (groupedRoles[role]) {
                    groupedRoles[role] = `${groupedRoles[role]}, ${bl.name}`
                  } else {
                    groupedRoles[role] = `${role} : ${bl.name}`
                  }
                }

                return groupedRoles
              },
              {}
            )

            roles = [...roles, ...Object.values(groupedRoles)]
          }

          return (
            <ul>
              {roles.map((role, i) => (
                <li key={`roles_li_${i}`}>{role}</li>
              ))}
            </ul>
          )
        },
        disableFilters: true,
        disableSortBy: true,
      },
      {
        Header: 'Dernière mise à jour',
        accessor: 'updatedAt',
        disableFilters: true,
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <IfAdmin>
              <ConnectAsAction
                user={props.row.original}
                connectAs={connectAs}
                setUser={setUser}
              />
            </IfAdmin>
            <EditAction
              entity={"l'utilisateur"}
              to={`/utilisateurs/${props.value}/edit`}
            />
            <RemoveAction onRemove={onRemove} {...props} />
          </Actions>
        ),
      },
    ],
    []
  )

  const addUser = () => {
    history.push('/utilisateurs/new')
  }
  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste utilisateurs"
        columns={columns}
        initialPageSize={10}
        initialSortBy={[{ id: 'userName', desc: false }]}
        rowRemoved={removed}
        addEntity={addUser}
        initialSortBy={initialSortBy}
      />
    </div>
  )
}

export default List
