import React, { useState, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import SortDesc from 'components/svg/sortDesc'
import SortAsc from 'components/svg/sortAsc'

function getNbError(errorState, errors) {
  return errors.reduce((nbErrors, { state, payload }) => {
    if (state === errorState) {
      return nbErrors + payload.length
    }

    return nbErrors
  }, 0)
}

const FraudLink = ({ importState, importRef, fraudRef, isNew }) => {
  if ((isNew && importState === 'error') || fraudRef === 'BAD_FOREIGN_KEY') {
    return <span className="opacity-75">{importRef}</span>
  }
  return (
    <Link className="opacity-75 text-blue-500 underline" to={`/fraudes/${fraudRef}/customer`}>
      {importRef}
    </Link>
  )
}

export default ({ importState, tab, warnings, errors, oks }) => {
  const [isOpen, setIsOpen] = useState(false)
  const nbErrors = useMemo(() => {
    return getNbError('error', errors)
  }, [warnings])

  const nbWarnings = useMemo(() => {
    return getNbError('warning', warnings)
  }, [warnings])

  if (warnings.length > 0 || errors.length > 0 || oks.length > 0)
    return (
      <div>
        <hr className="my-6" />
        <div
          className="cursor-pointer"
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className="flex flex-row w-full">
            <span className="w-1/3 text-lg">
              Onglet <span className="font-semibold">{tab}</span>
            </span>
            <span className="w-1/3 text-lg">
              {oks.length > 0 && (
                <>
                  <span className="font-semibold text-green-400">
                    {oks.length}{' '}
                  </span>
                  ligne(s) valide(s)
                </>
              )}
            </span>
            <span className="w-1/3 text-lg">
              {warnings.length > 0 && (
                <>
                  <span className="font-semibold text-orange-400">
                    {nbWarnings}{' '}
                  </span>
                  erreur(s) non bloquante(s)
                </>
              )}
            </span>
            <span className="w-1/3 text-lg">
              {errors.length > 0 && (
                <>
                  <span className="font-semibold text-red-400">
                    {nbErrors}{' '}
                  </span>
                  erreur(s) bloquante(s)
                </>
              )}
            </span>

            <span className="absolute right-0 mb-4 mr-12">
              {isOpen ? <SortAsc /> : <SortDesc />}
            </span>
          </div>
        </div>
        {isOpen && (
          <>
            <div className="mt-6">
              {oks &&
                oks.map(({ importRef, isNew, fraudRef, line, payload }) => (
                  <div className="flex flex-row">
                    <span className="w-32 font-semibold text-green-400">
                      Ligne {line}
                    </span>
                    <span className="w-48 text-black">
                      Fraude{' '}
                      <FraudLink
                        importState={importState}
                        importRef={importRef}
                        isNew={isNew}
                        fraudRef={fraudRef}
                      />
                    </span>
                  </div>
                ))}
            </div>
            <div className="mt-6">
              {warnings &&
                warnings.map(
                  ({ line, isNew, payload, importRef, fraudRef }) => (
                    <div className="flex flex-row">
                      <span className="w-32 font-semibold text-orange-400">
                        Ligne {line}
                      </span>
                      <span className="w-48 text-black">
                        Fraude{' '}
                        <FraudLink
                          importState={importState}
                          importRef={importRef}
                          isNew={isNew}
                          fraudRef={fraudRef}
                        />
                      </span>
                      <ul>
                        {payload.map((e) => (
                          <li style={{ listStyleType: 'square' }}>{e}</li>
                        ))}
                      </ul>
                    </div>
                  )
                )}
            </div>
            <div className="mt-6">
              {errors &&
                errors.map(({ isNew, importRef, fraudRef, line, payload }) => (
                  <div className="flex flex-row">
                    <span className="w-32 font-semibold text-red-400 ">
                      Ligne {line}
                    </span>
                    <span className="w-48 text-black">
                      Fraude{' '}
                      <FraudLink
                        importState={importState}
                        importRef={importRef}
                        isNew={isNew}
                        fraudRef={fraudRef}
                      />
                    </span>
                    <ul>
                      {payload.map((e) => (
                        <li style={{ listStyleType: 'square' }}>{e}</li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    )
  return <div></div>
}
