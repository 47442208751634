// import axios from "axios";
// import Blob from "blob";
// import FormData from "form-data";
import React, { useState, useRef, useCallBack } from 'react'
import Files from 'react-files'
import { useCallback } from 'react'
import useFiles from 'hooks/useFiles'
import { useNotify } from 'stores/notify'
// const { REACT_APP_API_URL } = process.env

export default function File(
  {
    label,
    name,
    placeholder,
    required,
    onChange,
    className,
    helpText,
    error,
    value = [],
    values,
    options,
    form,
    disabled = false,
    multiple = true,
    btnHelpText = 'Glissez-déposez vos fichiers ou cliquez ici pour téléverser vos documents',
  },
  ref
) {
  const refs = useRef()
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  let classes = className

  if (required) {
    classes += ' required'
  }

  const onFilesError = (error, file) => {}

  const { downloadFile } = useFiles()

  const filesRemoveOne = (file) => {
    refs.current.removeFile(file)

    // setTimeout(() => {
    form.setValue(
      name,
      value.filter(({ id }) => id !== file.id)
    )
    // }, 500)
  }

  const getFileHref = useCallback((file) => {
    if (file.preview && file.preview.url) {
      return file.preview.url
    }
  }, [])

  const getFile = useCallback(async (event, file) => {
    if (!file.preview) {
      // event.preventDefault()
      try {
        await downloadFile(file.path)
      } catch (err) {
        setErrorMessage(
          'Une erreur est survenue lors du téléchargement du fichier.'
        )
      }
    } else {
      const href = getFileHref(file)
      window.open(href, '_blank')
    }
  }, [])

  const handleChanges = (files = []) => {
    // Keep unique files
    let newValue = value || []

    // Keep persisted files, because new files are value, also in files
    newValue = newValue.filter((f) => !f.preview)

    // Remove dupli
    form.setValue(
      name,
      [...newValue, ...files].map((f) => {
        // Add dirty field, to detect file type when save.
        f.typee = 'file'
        return f
      })
    )
  }

  return (
    <label className={classes} htmlFor={name}>
      <span dangerouslySetInnerHTML={{ __html: label }} />
      {options && options.limit && (
        <span className="pl-4 text-red-500">
          {options.limit} document maximum
        </span>
      )}
      {!disabled && (
        <Files
          ref={(r) => (refs.current = r)}
          className="p-4 mt-3 text-xs cursor-pointer files-dropzone-list"
          style={{ height: '100px' }}
          onChange={(f) => {
            handleChanges(f)
          }}
          accepts={[
            'image/*',
            '.pdf',
            '.txt',
            '.docx',
            '.doc',
            '.odf',
            '.xls',
            '.xlsx',
            '.ppt',
            '.pptx',
          ]}
          onError={onFilesError}
          multiple={multiple}
          maxFiles={options && options.limit}
          maxFileSize={20000000}
          minFileSize={0}
          clickable
        >
          {btnHelpText}
        </Files>
      )}
      {value && value.length > 0 ? (
        <>
          <div className="files-list">
            <ul>
              {value.map((file) => (
                <li
                  className="relative flex items-center mx-2 files-list-item"
                  key={file.uid}
                >
                  <div className="files-list-item-content">
                    <div
                      className="cursor-pointer files-list-item-content-item files-list-item-content-item-1"
                      onClick={(e) => getFile(e, file)}
                    >
                      {file.name}
                    </div>
                    {!disabled && (
                      <div
                        className="absolute top-0 right-0 px-3 my-auto text-xl font-bold text-red-500 bg-gray-200 rounded-full cursor-pointer bg-opacity-75"
                        onClick={() => filesRemoveOne(file)}
                      >
                        x
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : null}

      {error && <span className="help-text error">{error}</span>}
    </label>
  )
}
