import React from 'react'
import Layout from 'components/Layout'
import ScenariosForm from 'components/scenarios/Form'

const ScenariosNewPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ScenariosForm />
    </Layout>
  )
}

export default ScenariosNewPage
