import React, { useCallback, useState } from 'react'
import Login from 'components/Login'
import CodeValidation from 'components/CodeValidation'

import { useUser } from 'stores/user'
import { useNotify } from 'stores/notify'

const LoginPage = () => {
  const { checkValidationCode, doubleAuthToken, login, error } = useUser(
    ({ checkValidationCode, doubleAuthToken, login, error }) => ({
      login,
      doubleAuthToken,
      checkValidationCode,
      error,
    })
  )
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const [validatingCode, setValidatingCode] = useState(false)

  if (error && error.message) setErrorMessage(error.message)

  const submit = useCallback(
    async (params) => {
      // setLoading(true)
      const { success } = await login(params)
      if (success) setValidatingCode(true)
    },
    [login]
  )

  const submitCodeValidation = async (params) => {
    try {
      checkValidationCode({
        code: params.validationCode,
        doubleAuthToken,
      })
    } catch (err) {
      setErrorMessage('Une erreur s’est produite, veuillez réessayer plus tard')
    }
  }
  if (validatingCode)
    return <CodeValidation validateCode={submitCodeValidation} />

  return <Login onSubmit={submit} />
}

export default LoginPage
