import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { useUser } from 'stores/user'
import Input from 'components/form/Input'

export default function CodeValidation({ validateCode }) {
  const [maskSendNew, setMaskSendNew] = useState(false)

  const { doubleAuthToken, error, sendNewCode } = useUser(
    ({ doubleAuthToken, sendNewCode, error }) => ({
      doubleAuthToken,
      error,
      sendNewCode,
    })
  )

  const form = useForm()
  const { errors, handleSubmit, register, watch } = form

  const handleSendNew = useCallback(() => {
    sendNewCode({ doubleAuthToken })
    setMaskSendNew(true)
  }, [doubleAuthToken, sendNewCode, setMaskSendNew])

  return (
    <div className="flex items-center justify-center mt-32 align-middle sms-validation">
      <div className="w-5/12 page-container">
        <form
          onSubmit={handleSubmit(validateCode)}
          className="w-full form-container"
        >
          <p>Veuillez saisir le code qui vous a été envoyé pas email:</p>
          <Input
            form={form}
            key={`section-password`}
            ref={register({ required: 'requis' })}
            type="number"
            name="validationCode"
            label="Code de validation"
            value={watch('validation')}
            error={errors.password && errors.password.message}
            required={'Champ obligatoire'}
            autofocus={true}
          />
          <p className="text-red-500">{error && error.message}</p>
          <div className="flex items-center justify-between w-full">
            {maskSendNew ? (
              <div></div>
            ) : (
              <button
                className={`${maskSendNew ? 'hidden' : ''} text-blue-600`}
                type="button"
                onClick={handleSendNew}
              >
                Code non reçu ? Cliquez ici pour le renvoyer.
              </button>
            )}
            <button className="action-button" type="submit">
              Valider
            </button>
          </div>
          <span className="text-sm text-red-500">
            {errors && errors.validationCode && errors.validationCode.message}
          </span>
        </form>
      </div>
    </div>
  )
}
