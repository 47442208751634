import React from "react"

import Layout from "components/Layout"
import ScenariosList from "components/scenarios/List"

const ScenariosListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ScenariosList />
    </Layout>
  )
}

export default ScenariosListPage
