import React, { useState, useEffect } from 'react'

import { useParams } from 'react-router-dom'

import useFraudTypes from 'hooks/useFraudTypes'
import Layout from 'components/Layout'
import FraudTypesForm from 'components/fraud-types/Form'

const FraudTypesEditPage = ({ location }) => {
  let { id } = useParams()
  const [scenario, setScenario] = useState(null)
  const { getFraudType } = useFraudTypes()

  useEffect(() => {
    getFraudType(id).then((req) => {
      setScenario(req.results)
    })
  }, [id])

  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <span className="ml-2">Fiche Scénario</span>
      </h1>
      {scenario && <FraudTypesForm entity={scenario} />}
    </Layout>
  )
}

export default FraudTypesEditPage
