import React, { useState, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { validateBIC } from 'lib/codeBic'

import { AmountAndCurrencyWithProp } from './commonfields/amountAndCurrency'
import BICComponent from './commonfields/BICComponent'
import Countries from './commonfields/countries'
import Select from 'components/form/Select'
import FormRow from 'components/form/FormRow'
import { getDeepValue, getInputOptsByBlockedAndFraudTypeField } from 'lib/utils'
import YesNoInput from 'components/form/YesNoInput'
import Tooltip from 'components/common/Tooltip'

export default {
  id: 'bank-transfer-out',
  title: 'Virements sortants',
  // next: "2",
  defaultValues: { FraudBankTransferOuts: [{}] },
  sections: [
    {
      // title: "Virements sortants",
      rows: [
        [
          {
            name: 'FraudBankTransferOuts',
            type: 'array',
            label: 'Virement sortant',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        type: 'select',
                        name: 'fraud_detection',
                        label: "Nom de l'outil",
                        options: (props) =>
                          getInputOptsByBlockedAndFraudTypeField(props, {
                            required: 'Ce champ est requis',
                          }),
                        placeholder: 'Choisir un moyen',
                        values: [
                          {
                            label: 'Contre-Appel',
                            value: 'Contre-Appel',
                          },
                          {
                            label: 'PRISMOP',
                            value: 'PRISMOP',
                          },
                          {
                            label: 'Autres outils',
                            value: 'Autres outils',
                          },
                        ],
                        when: {
                          'Autres outils': [
                            [
                              {
                                name: 'fraud_detection_tool',
                                placeholder: "Précisez l'outil",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  defaultValue: 'false',
                  type: 'yes-no',
                  label: 'Agrégat',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedAndFraudTypeField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  name: 'transaction_id',
                  label: 'Identifiant de la transaction',
                  autofocus: true,
                },
                { type: 'blank' },
              ],
              [
                {
                  name: 'bank_name',
                  label: 'Banque du bénéficiaire',
                },
                {
                  name: 'bank_domiciliation',
                  label: 'Domiciliation bancaire du bénéficiaire',
                  placeholder: 'IBAN / BBAN',
                },
              ],
              [
                {
                  name: 'swift_bic',
                  type: 'custom',
                  Component: BICComponent,
                  label: 'Code SWIFT / BIC du bénéficiaire',
                  options: (props) => {
                    const required =
                      props.form.watch(props.prefix + 'aggregate') ===
                      'false' || !props.form.watch(props.prefix + 'aggregate')
                    if (required)
                      return getInputOptsByBlockedAndFraudTypeField(props, {
                        required: 'Ce champ est requis',
                        validate: validateBIC,
                      })

                    return getInputOptsByBlockedAndFraudTypeField(props, {
                      validate: validateBIC,
                    })
                  },
                },
              ],

              AmountAndCurrencyWithProp(
                false,
                getInputOptsByBlockedAndFraudTypeField
              ),

              [
                {
                  type: 'number',
                  name: 'retrieved_amount',
                  label: 'Montant récupéré recall',
                  suffix: '€',
                },
                // {
                //   type: 'number',
                //   name: 'prismop_blocked_amount',
                //   label: 'Montant bloqué Prismop',
                //   suffix: '€',
                // },
                // {
                //   type: 'number',
                //   name: 'other_blocked_amount',
                //   label: 'Montant bloqué autre que prismop',
                //   suffix: '€',
                // },
              ],

              [
                {
                  type: 'select',
                  name: 'geo_zone',
                  label: 'Zone géographique',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  values: [
                    {
                      label: 'Transaction nationale',
                      value: 'Transaction nationale',
                    },
                    {
                      label: "Transfrontalière au sein de l'EEE",
                      value: "Transfrontalière au sein de l'EEE",
                    },
                    {
                      label: "Transfrontalière hors de l'EEE",
                      value: "Transfrontalière hors de l'EEE",
                    },
                  ],
                },
                Countries({
                  label: 'Pays de destination',
                  name: 'country',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                }),
              ],

              [
                {
                  type: 'select',
                  name: 'payment_type',
                  label: 'Schéma de virement',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  placeholder: 'Choisir un schéma de virement',
                  values: [
                    {
                      label: 'Virement SEPA (SCT)',
                      value: 'Virement SEPA (SCT)',
                    },
                    {
                      label: 'Virement SEPA instantanés (SCT inst)',
                      value: 'Virement SEPA instantanés (SCT inst)',
                    },
                    {
                      label: 'VGM',
                      value: 'VGM',
                    },
                    {
                      label: 'Autres virements non SEPA hors VGM',
                      value: 'Autres virements non SEPA hors VGM',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'init_channel',
                  label: "Canal d'initiation",
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  values: [
                    {
                      value:
                        'Support papier (courrier, formulaire, télécopie…)',
                      label:
                        'Support papier (courrier, formulaire, télécopie…)',
                    },
                    {
                      value: 'Téléphone',
                      label: 'Téléphone',
                    },
                    {
                      value: 'Internet (BAD, app bancaire)',
                      label: 'Internet (BAD, app bancaire)',
                    },
                    {
                      value: 'Solution de paiement mobile',
                      label: 'Solution de paiement mobile',
                    },
                    { value: 'Télématique', label: 'Télématique' },
                    {
                      value: 'Automate au guichet',
                      label: 'Automate au guichet',
                    },
                    { value: 'GAB', label: 'GAB' },
                  ],
                  when: {
                    'Internet (BAD, app bancaire)': [[
                      {
                        type: 'yes-no',
                        name: 'ecommerce',
                        label: 'Virement e-commerce',
                        defaultValue: 'false'
                        // helpText:
                        //   "Est-ce que le client est à l'origine de la déclaration ?",
                      },
                      {
                        hide: true,
                        type: 'hidden',
                        name: 'mobile_private_payment',
                        defaultValue: 'true'
                      },
                    ]],
                    'Solution de paiement mobile': [[
                      {
                        type: 'yes-no',
                        name: 'mobile_private_payment',
                        label: 'Paiement mobile entre particuliers',
                        defaultValue: 'true'
                        // helpText:
                        //   "Est-ce que le client est à l'origine de la déclaration ?",
                      },
                      {
                        hide: true,
                        type: 'hidden',
                        name: 'ecommerce',
                        defaultValue: 'false'
                      },
                    ]],
                  },
                },
              ],
              [
                {
                  type: 'custom',
                  name: 'auth_method',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  label: "Méthode d'authentification",
                  Component: function AuthMethod({
                    disabled = false,
                    form,
                    prefix,
                    input,
                  }) {
                    const initChannel = form.watch(prefix + 'init_channel')

                    const [isDisabled, setIsDisabled] = useState(false)

                    const selectError = getDeepValue(
                      form.errors,
                      prefix + input.name
                    )

                    useEffect(() => {
                      if (
                        [
                          'Support papier (courrier, formulaire, télécopie…)',
                          'Téléphone',
                        ].includes(initChannel)
                      ) {
                        setIsDisabled(true)
                        form.unregister(prefix + input.name)
                      } else {
                        setIsDisabled(false)
                      }
                    }, [initChannel])

                    const inputOptions = input.options({ form, prefix })

                    return (
                      <Controller
                        name={prefix + input.name}
                        control={form.control}
                        rules={!isDisabled ? inputOptions : {}}
                        render={({ onChange, value, name }) => {
                          return (
                            <Select
                              name={name}
                              options={!isDisabled ? inputOptions : {}}
                              value={value}
                              disabled={disabled || isDisabled}
                              values={[
                                {
                                  label:
                                    "Authentification forte (Ex. Sécur' Pass)",
                                  value:
                                    "Authentification forte (Ex. Sécur' Pass)",
                                },
                                {
                                  label: 'Sans Authentification forte',
                                  value: 'Sans Authentification forte',
                                },
                              ]}
                              onChange={(e) => {
                                onChange(e && e.value)
                              }}
                              label={input.label}
                              error={selectError && selectError.message}
                            />
                          )
                        }}
                      />
                    )
                  },
                },
                {
                  type: 'custom',
                  name: 'auth_exemption',
                  label: 'Exemption Authentification',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  Component: function ExemptionAuthentification({
                    form,
                    prefix,
                    input,
                    readOnly,
                    idx,
                    iidx,
                    disabled = false,
                  }) {
                    // Si [canal d'initiation] = « Internet ou Télématique ou Guichet bancaire ou GAB »
                    // et [Méthode d'authentification] = « AF » alors le champ [Exemption Authentification] est verrouillé à la saisie
                    const [isDisabled, setIsDisabled] = useState(false)

                    const initValues = [
                      {
                        label:
                          'Art. 11 RTS (paiement sans contact de faible montant)',
                        value:
                          'Art. 11 RTS (paiement sans contact de faible montant)',
                      },
                      {
                        label: 'Art. 12 RTS (automate de parking/transport)',
                        value: 'Art. 12 RTS (automate de parking/transport)',
                      },
                      {
                        label: 'Art. 13 RTS (bénéficiaire de confiance)',
                        value: 'Art. 13 RTS (bénéficiaire de confiance)',
                      },
                      {
                        label: 'Art. 14 RTS (opération récurrente)',
                        value: 'Art. 14 RTS (opération récurrente)',
                      },
                      {
                        label: 'Art. 15 RTS (paiement à soi-même)',
                        value: 'Art. 15 RTS (paiement à soi-même)',
                      },
                      {
                        label: 'Art. 16 RTS (faible montant)',
                        value: 'Art. 16 RTS (faible montant)',
                      },
                      {
                        label: 'Art. 17 RTS (protocole de paiement sécurisé)',
                        value: 'Art. 17 RTS (protocole de paiement sécurisé)',
                      },
                      {
                        label: 'Art. 18 RTS (analyse des risques)',
                        value: 'Art. 18 RTS (analyse des risques)',
                      },
                      {
                        label: 'Paiements initiés par les commerçants',
                        value: 'Paiements initiés par les commerçants',
                      },
                      {
                        label: 'Autres motifs',
                        value: 'Autres motifs',
                      },
                    ]
                    // const [values, setValues] = useState(initValues)
                    const initChannelName = prefix + 'init_channel'
                    const authMethodName = prefix + 'auth_method'
                    const authExemptionName = prefix + 'auth_exemption'

                    const watchedFiles = form.watch([
                      initChannelName,
                      authMethodName,
                      authExemptionName,
                    ])

                    const inputOptions = input.options({ form, prefix })

                    const initChannel = watchedFiles[initChannelName]
                    const authMethod = watchedFiles[authMethodName]
                    // const authExemption = watchedFiles[authExemptionName]

                    useEffect(() => {
                      if (
                        ['Téléphone', 'Support papier (courrier, formulaire, télécopie…)'].includes(initChannel)
                        || ([
                          'Internet (BAD, app bancaire)',
                          'Télématique',
                          'Automate au guichet',
                          'Téléphone',
                          'GAB',
                        ].includes(initChannel) &&
                          authMethod ===
                          "Authentification forte (Ex. Sécur' Pass)")
                      ) {
                        form.setValue(authExemptionName, null)
                        form.setValue(authExemptionName, null)
                        form.unregister(prefix + input.name)
                        setIsDisabled(true)
                      } else {
                        setIsDisabled(false)
                      }
                    }, [initChannel, authMethod])

                    const selectError = getDeepValue(
                      form.errors,
                      prefix + input.name
                    )

                    return (
                      <>
                        {!isDisabled && (
                          <Controller
                            name={prefix + input.name}
                            control={form.control}
                            rules={inputOptions}
                            render={({ onChange, value, name }) => {
                              return (
                                <Select
                                  name={name}
                                  key={JSON.stringify(initValues) + isDisabled}
                                  value={value}
                                  values={initValues}
                                  error={selectError && selectError.message}
                                  options={inputOptions}
                                  onChange={(e) => {
                                    onChange(e && e.value)
                                  }}
                                  label={input.label}
                                  disabled={disabled}
                                  when={{
                                    'Autres motifs': [
                                      [
                                        <FormRow
                                          inputs={[
                                            {
                                              name: 'auth_exemption_other',
                                              placeholder: 'Précisez',
                                              disabled: isDisabled,
                                            },
                                          ]}
                                          namePrefix={prefix}
                                          idx={`${idx}-${iidx}-${'Autres motifs'}`}
                                          ridx={'0'}
                                          form={form}
                                          readOnly={readOnly}
                                        />,
                                      ],
                                    ],
                                  }}
                                />
                              )
                            }}
                          />
                        )}
                        {isDisabled && (
                          <Select
                            name={prefix + input.name}
                            disabled={isDisabled}
                            label={input.label}
                          />
                        )}
                      </>
                    )
                  },
                },
              ],
              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'operation",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'fraud_type',
                  label: 'Type de fraude',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  placeholder: 'Choisir un type de fraude',
                  values: [
                    {
                      label: 'Faux',
                      value: 'Faux',
                    },
                    {
                      label: 'Falsification',
                      value: 'Falsification',
                    },
                    {
                      label: 'Détournement',
                      value: 'Détournement',
                    },
                    {
                      label: 'Escroquerie',
                      value: 'Escroquerie',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'custom',
                  name: 'psip',
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                  label: 'PSIP',
                  defaultValue: 'false',
                  Component: ({ disabled, form, prefix, input }) => {
                    let options = input.options

                    const inputName = prefix + input.name
                    const inputError = getDeepValue(form.errors, inputName)

                    input.labelJsx = (
                      <label>
                        <span>{input.label}</span>
                        <Tooltip tooltip="Virement initié par un Prestataire de Service d'Initiation de paiement" />
                      </label>
                    )
                    return (
                      <>
                        <YesNoInput
                          key={`${inputName}-yesno-${JSON.stringify(options)}`}
                          input={input}
                          error={inputError && inputError.message}
                          options={options}
                          form={form}
                          prefix={prefix}
                          name={inputName}
                          value={form.watch(inputName)}
                          defaultValue={input.defaultValue}
                          disabled={disabled}
                        />
                      </>
                    )
                  },
                },
              ],
              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
