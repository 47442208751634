import { useUser } from 'stores/user'
import Api from 'lib/api'

export default () => {
  const token = useUser(({ token }) => token)

  return {
    findAgenciesByBusinessLineId(BusinessLineId) {
      return Api.findAgenciesByBusinessLineId(token, {
        query: {
          offset: 0,
          limit: 1000,
          filters: [['BusinessLineId', BusinessLineId]],
        },
      })
    },
  }
}
