import * as React from 'react'

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
      <path d="m93.602 2.5h-66.602c-2.1016 0-3.8984 1.6992-3.8984 3.8984v16.801h-16.703c-2.1016 0-3.8984 1.6992-3.8984 3.8984v66.602c0 2.1016 1.6992 3.8984 3.8984 3.8984l66.602 0.003906c2.1016 0 3.8984-1.6992 3.8984-3.8984v-16.805h16.801c2.1016 0 3.8984-1.6992 3.8984-3.8984l0.003906-66.602c-0.10156-2.1992-1.8008-3.8984-4-3.8984zm-36 61.602c0 1-0.80078 1.8008-1.8008 1.8008l-10.5-0.003906v10.5c0 1-0.80078 1.8008-1.8008 1.8008h-7.5c-1 0-1.8008-0.80078-1.8008-1.8008v-10.5h-10.598c-1 0-1.8008-0.80078-1.8008-1.8008v-7.5c0-1 0.80078-1.8008 1.8008-1.8008h10.5v-10.496c0-1 0.80078-1.8008 1.8008-1.8008h7.5c1 0 1.8008 0.80078 1.8008 1.8008v10.5h10.5c1 0 1.8008 0.80078 1.8008 1.8008l-0.003906 7.5zm32.199 5h-12.902v-42.102c0-2.1016-1.6992-3.8984-3.8984-3.8984h-42.102v-12.902h58.898z" />
    </svg>
  )
}

export default SvgComponent
