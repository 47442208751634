import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { useNotify } from 'stores/notify'
import useUsers from 'hooks/useUsers'
import Input from 'components/form/Input'
import LoggedOutHeader from 'components/common/LoggedOutHeader'

export default function RequestAccess() {
  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))

  const { requestAccess } = useUsers()
  const [email, setEmail] = useState(null)

  const [success, setSucccess] = useState(false)
  const onSubmit = async (params) => {
    try {
      await requestAccess(params.email)
      setEmail(params.email)
      setSucccess(true)
    } catch (err) {
      setErrorMessage(err.message)
    }
  }

  const { register, handleSubmit, errors, watch } = useForm()

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 ">
          <form
            className="form-container w-full"
            onSubmit={handleSubmit(onSubmit)}
          >
            {!success && (
              <>
                <p>
                  Veuillez saisir votre adresse email pour démarrer une demande
                  d'accréditation :
                </p>
                <Input
                  key={`requestaccess-section-email`}
                  ref={register({ required: 'Champ obligatoire' })}
                  type="text"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={watch('email')}
                  error={errors.email && errors.email.message}
                  required={'Champ obligatoire'}
                  autofocus={true}
                />

                <div className="flex items-center justify-center w-full">
                  <button
                    className="bg-accent text-white py-3 px-4 rounded"
                    type="submit"
                  >
                    Envoyer la demande d'accès
                  </button>
                </div>
              </>
            )}
            {success && (
              <div>
                Un email vous a été envoyé, vous invitant à créer votre profil.
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
