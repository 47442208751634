import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M50 2.914C23.996 2.914 2.914 23.996 2.914 50S23.996 97.086 50 97.086 97.086 76.004 97.086 50 76.004 2.914 50 2.914zm0 85.398c-21.125 0-38.312-17.188-38.312-38.312S28.876 11.688 50 11.688 88.312 28.876 88.312 50 71.124 88.312 50 88.312zm4.387-60.023h-8.77v17.328l-17.328-.004v8.77h17.328V71.71h8.77V54.387h17.328v-8.77l-17.328-.004z" />
        </svg>
    )
}

export default SvgComponent
