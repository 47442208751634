import React, { useMemo, useState } from 'react'

import { Link } from 'react-router-dom'

import Table from 'components/common/table'
import useFraudImports from 'hooks/useFraudImports'
import { dateFmt } from 'components/common/table/formats'
import {
  DefaultColumnFilter,
  DateColumnFilter,
} from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  RemoveAction,
  ShowAction,
} from 'components/common/table/actions'
import { useNotify } from 'stores/notify'

const initialPageSize = 10
const initialSortBy = [
  {
    id: 'createdAt',
    desc: true,
  },
]

const ImportsList = () => {
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { getImportList, rollbackFraudImport } = useFraudImports()

  const fetchData = useApiFetch(getImportList)

  const [removed, setRemoved] = useState(null)

  const onRemove = async (id) => {
    try {
      const req = await rollbackFraudImport(id)

      if (req.results && req.results.success) {
        setRemoved(id)
        setSuccessMessage(`Import annulé avec succès`)
      } else {
        setErrorMessage(`Impossible d'annuler l'import' : ${req.error.message}`)
      }
    } catch (error) {
      setErrorMessage(`Impossible d'annuler l'import. ${error.message}`)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'N°',
        accessor: 'id', // accessor is the "key" in the data
        Cell: ({ row: { original } }) => {
          return <Link to={`/import/${original.id}`}>{original.id}</Link>
        },
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'État',
        accessor: 'state',
        Filter: DefaultColumnFilter,
        Cell: ({ value }) => value === "error" ? "Echec" : "Succès",
      },
      {
        Header: 'Établissement',
        accessor: 'businessLineName',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Nom du fichier',
        accessor: 'filename',
        Filter: DefaultColumnFilter,
      },
      {
        Header: 'Créée le',
        accessor: 'createdAt',
        Filter: DateColumnFilter,
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        Cell: (props) => (
          <Actions>
            <ShowAction to={`/import/${props.value}`} />
            {props.row.original.canRollBack && (
              <RemoveAction onRemove={onRemove} {...props} />
            )}
          </Actions>
        ),
      },
    ],
    []
  )

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Historique des imports"
        initialPageSize={initialPageSize}
        columns={columns}
        rowRemoved={removed}
        initialSortBy={initialSortBy}
      />
    </div>
  )
}

export default ImportsList
