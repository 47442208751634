import React from 'react'
import { useFieldArray, Controller } from 'react-hook-form'

import File from './File'
import Select from './Select'
import AsyncSelect from './AsyncSelect'
import Input from './Input'
// import Hidden from './Hidden'
import TextArea from './TextArea'
import MultiCheckBox from './MultiCheckBox'
import CheckBox from './CheckBox'
import YesNoInput from './YesNoInput'
import Date from './Date'
import InputNumber from './InputNumber'
import DuplicateIcon from 'components/svg/duplicate'
import TrashIcon from 'components/svg/trash'
import { getDeepValue } from 'lib/utils'
import GdprInstruction from './GdprInstruction'
import confirm from 'components/common/ConfirmModal'

function InputArray({
  inputs,
  name,
  label,
  idx,
  form,
  setCurrentStepData,
  defaultLength = 0,
  canDelete = true,
  canClone = true,
  maxNb = 0,
  readOnly = false,
}) {
  const { control } = form
  const { fields, append, remove } = useFieldArray({ control, name })
  const errors = getDeepValue(form.errors, name)

  return (
    <div id={`${idx}`} className="w-full">
      {fields.map((field, fidx) => {
        // If field name is id, it's overrided by useFieldArray if null.
        // Use uid instead.
        field.uid = field.id || field.uid
        field.id = isNaN(field.id) ? null : field.id

        return (
          <div
            key={`${idx}-${field.uid}`}
            idx={`${idx}-${fidx}`}
            fieldid={`${field.uid}`}
            uid={`${field.uid}`}
            className="flex flex-col w-full pb-3 mb-14"
          >
            <div
              className="flex items-center justify-between w-full"
              style={{ borderBottom: '4px solid #202020' }}
            >
              <div className="text-lg font-bold">Numéro #{fidx + 1}</div>
              {!readOnly && (
                <div className="flex justify-end">
                  {canClone && (
                    <button
                      type="button"
                      onClick={() => {
                        // Duplicate line.
                        // Retrieve array, clone object at fidx indice and push it to array.
                        const currentObj = form.getValues()
                        const newObj = {
                          ...getDeepValue(currentObj, `${name}[${fidx}]`),
                        }

                        // Remove id to create a new one in DB.
                        delete newObj.id

                        const obj = getDeepValue(currentObj, name)

                        obj.push(newObj)

                        setCurrentStepData(currentObj)
                      }}
                    >
                      <DuplicateIcon />
                    </button>
                  )}

                  {canDelete && (
                    <button
                      type="button"
                      // style={{ position: 'relative', top: '1em' }}
                      className="ml-2"
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if (
                          confirm(
                            'Confirmez-vous la suppression de cet élément ?'
                          )
                        ) {
                          const currentObj = form.getValues()
                          const newObj = getDeepValue(currentObj, name)
                          newObj.splice(fidx, 1)
                          remove(fidx)

                          setCurrentStepData(currentObj)
                        }
                      }}
                    >
                      <TrashIcon stroke={'red'} fill={'red'} />
                    </button>
                  )}
                </div>
              )}
            </div>

            {inputs.map((row, r) => (
              <FormRow
                key={`${field.uid}-${r}`}
                namePrefix={`${name}[${fidx}]`}
                inputs={row}
                idx={`${field.uid}-${fidx}-${r}-l1`}
                form={form}
                field={field}
                readOnly={readOnly}
                setCurrentStepData={setCurrentStepData}
                defaultLength={defaultLength}
              />
            ))}
          </div>
        )
      })}
      <div className="w-full text-right">
        {!readOnly && (maxNb === 0 || maxNb > fields.length) && (
          <>
            <button
              type="button"
              className="action-button"
              onClick={() => {
                form.clearErrors(name)
                append({})
              }}
            >
              + Ajouter {label} #{fields.length + 1}
            </button>
            {errors && errors.message && (
              <div className="text-sm text-red-600">{errors.message}</div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default function FormRow({
  inputs,
  idx,
  form,
  namePrefix,
  field,
  setCurrentStepData,
  readOnly = false,
}) {
  // Specif for "id" input name.
  // change field id to
  // field.uid = id

  const { register, watch, errors, setValue, control } = form
  let prefix = !!namePrefix ? namePrefix + '.' : ''

  prefix = prefix.replace(/\.\./g, '.')

  return (
    <div
      key={`section-${idx}`}
      className={`form-row col-${inputs.length} flex-wrap`}
    >
      {inputs.map((input, iidx) => {
        const inputName = prefix + input.name
        input.form = form
        const inputError = getDeepValue(errors, inputName)

        let options = input.options

        if (input.options && typeof input.options === 'function') {
          options = input.options({ form, prefix })
        }

        return (
          <React.Fragment key={`section-${idx}-${iidx}-row`}>
            {![
              'blank',
              'yes-no',
              'info-message',
              'array',
              'select',
              'async-select',
              'textarea',
              // 'hidden',
              'custom',
              'number',
              'file',
              'multi-checkbox',
              'date',
              'checkbox',
              'gdprInstruction',
            ].includes(input.type) && (
              <Input
                key={`section-${idx}-input-${JSON.stringify(options)}`}
                ref={register(options)}
                type={input.inputType || input.type || 'text'}
                name={inputName}
                label={input.label}
                placeholder={input.placeholder}
                helpText={input.helpText}
                error={inputError && inputError.message}
                required={!!options && !!options.required}
                suffix={input.suffix}
                defaultValue={input.defaultValue}
                value={watch(inputName)}
                disabled={readOnly || input.disabled}
                autofocus={input.autofocus}
                hide={input.hide}
                readOnly={readOnly}
              />
            )}
            {input.type === 'blank' && <div />}
            {input.type === 'gdprInstruction' && <GdprInstruction />}
            {input.type === 'number' && (
              <InputNumber
                key={`section-${idx}-input`}
                name={inputName}
                suffix={input.suffix}
                label={input.label}
                error={inputError && inputError.message}
                helpText={input.helpText}
                required={options && options.required}
                defaultValue={input.defaultValue}
                form={form}
                options={options}
                disabled={readOnly || input.disabled}
                placeholder={input.placeholder}
                readOnly={readOnly}
                min={options && options.min}
              />
            )}
            {input.type === 'checkbox' && (
              <CheckBox
                key={`section-${idx}-date`}
                prefix={prefix}
                name={inputName}
                label={input.label}
                autofocus={input.autofocus}
                suffix={input.suffix}
                defaultValue={input.defaultValue}
                disabled={readOnly || input.disabled}
                error={inputError && inputError.message}
                helpText={input.helpText}
                required={!!options && !!options.required}
                form={form}
                rules={input.rules}
                options={options}
                readOnly={readOnly}
              />
            )}
            {input.type === 'date' && (
              <Date
                key={`section-${idx}-date`}
                prefix={prefix}
                name={inputName}
                label={input.label}
                autofocus={input.autofocus}
                suffix={input.suffix}
                defaultValue={input.defaultValue}
                disabled={readOnly || input.disabled}
                error={inputError && inputError.message}
                helpText={input.helpText}
                required={!!options && !!options.required}
                form={form}
                rules={input.rules}
                options={options}
                readOnly={readOnly}
              />
            )}
            {/* {input.type === 'hidden' && (
              <Hidden
                key={`section-${idx}-input`}
                ref={register(input.options)}
                type={input.type || 'text'}
                name={prefix + input.name}
                label={input.label}
                placeholder={input.placeholder}
                helpText={input.helpText}
                value={form.watch(prefix + input.name)}
                error={errors[input.name] && errors[input.name].message}
                required={!!input.options && !!input.options.required}
              />
            )} */}
            {input.type === 'custom' && (
              <input.Component
                input={input}
                form={form}
                idx={idx}
                iidx={iidx}
                key={`section-${idx}-${iidx}-custom`}
                prefix={prefix}
                disabled={readOnly || input.disabled}
                setCurrentStepData={setCurrentStepData}
                readOnly={readOnly}
              />
            )}
            {input.type === 'yes-no' && (
              <YesNoInput
                key={`section-${idx}-${iidx}-true-false`}
                input={input}
                options={options}
                required={!!options && !!options.required}
                readOnly={readOnly}
                form={form}
                disabled={readOnly || input.disabled}
                name={inputName}
                label={input.label}
                register={register}
                value={watch(inputName)}
                defaultValue={input.defaultValue}
                setValue={setValue}
                error={inputError && inputError.message}
                required={!!options && !!options.required}
                whenYes={
                  input.whenYes &&
                  input.whenYes.map((row, ynridx) => (
                    <FormRow
                      key={`section-${idx}-${iidx}-true-false-${ynridx}-no`}
                      inputs={row}
                      namePrefix={prefix}
                      idx={`${idx}-${iidx}-true`}
                      ridx={ynridx}
                      form={form}
                      readOnly={readOnly}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))
                }
                whenNo={
                  input.whenNo &&
                  input.whenNo.map((row, ynridx) => (
                    <FormRow
                      key={`section-${idx}-${iidx}-true-false-${ynridx}-no`}
                      inputs={row}
                      namePrefix={prefix}
                      idx={`${idx}-${iidx}-false`}
                      ridx={ynridx}
                      readOnly={readOnly}
                      form={form}
                      setCurrentStepData={setCurrentStepData}
                    />
                  ))
                }
              /> // YesNoInput
            )}
            {/* TODO: replace inputName in key by something better */}
            {input.type === 'array' && (
              <InputArray
                key={`section-${inputName}-${idx}-${iidx}-array`}
                idx={`section-${inputName}-${idx}-${iidx}-array`}
                inputs={input.fields}
                name={prefix + input.name}
                label={input.label}
                readOnly={readOnly || input.disabled}
                form={form}
                setCurrentStepData={setCurrentStepData}
                defaultLength={input.defaultLength}
                canDelete={input.canDelete}
                canClone={input.canClone}
                maxNb={input.maxNb}
                readOnly={readOnly}
              />
            )}
            {input.type === 'multi-checkbox' && (
              <MultiCheckBox
                input={input}
                name={inputName}
                label={input.label}
                disabled={readOnly || input.disabled}
                error={inputError && inputError.message}
                helpText={input.helpText}
                required={!!options && !!options.required}
                form={form}
                options={options}
                readOnly={readOnly}
              />
            )}
            {input.type === 'info-message' && (
              <div
                className="form-row"
                dangerouslySetInnerHTML={{ __html: input.label }}
              />
            )}
            {input.type === 'async-select' && (
              <AsyncSelect
                defaultValue={input.defaultValue}
                label={input.label}
                form={form}
                getList={input.getList}
                values={input.values}
                isMulti={input.isMulti}
                readOnly={readOnly}
                disabled={readOnly || input.disabled}
                options={options}
                placeholder={input.placeholder}
                className={input.className}
                helpText={input.helpText}
                isClearable={input.isClearable}
                name={inputName}
                error={inputError && inputError.message}
                formatOptionLabel={input.formatOptionLabel}
                when={
                  input.when &&
                  Object.keys(input.when).reduce((acc, whenKey, ynridx) => {
                    acc[whenKey] = input.when[whenKey].map((row) => (
                      <FormRow
                        inputs={row}
                        namePrefix={prefix}
                        idx={`${idx}-${iidx}-${whenKey}`}
                        ridx={ynridx}
                        form={form}
                      />
                    ))
                    return acc
                  }, {})
                }
              />
            )}
            {input.type === 'select' && (
              <>
                <Controller
                  key={`section-${idx}-select-${inputName}`}
                  rules={options}
                  name={inputName}
                  defaultValue={
                    (field && field[input.name]) || input.defaultValue
                  }
                  render={(props) => {
                    const selectError = getDeepValue(errors, inputName)
                    return (
                      <Select
                        defaultValue={input.defaultValue}
                        isClearable={input.isClearable}
                        label={input.label}
                        values={input.values}
                        value={props.value}
                        readOnly={readOnly}
                        disabled={readOnly || input.disabled}
                        key={`${inputName}-${JSON.stringify(
                          input.values
                        )}-${JSON.stringify(options)}`}
                        isMulti={input.isMulti}
                        options={options}
                        placeholder={input.placeholder}
                        className={input.className}
                        helpText={input.helpText}
                        onChange={(e) => {
                          if (input.isMulti && e && e.length > 0) {
                            props.onChange(e.map((e) => e.value).join('/'))
                          } else {
                            props.onChange((e && e.value) || null)
                          }
                        }}
                        name={inputName}
                        error={selectError && selectError.message}
                        when={
                          input.when &&
                          Object.keys(input.when).reduce(
                            (acc, whenKey, ynridx) => {
                              acc[whenKey] = input.when[whenKey].map((row) => (
                                <FormRow
                                  inputs={row}
                                  namePrefix={prefix}
                                  idx={`${idx}-${iidx}-${whenKey}`}
                                  ridx={ynridx}
                                  form={form}
                                  readOnly={readOnly}
                                />
                              ))
                              return acc
                            },
                            {}
                          )
                        }
                      />
                    )
                  }}
                  name={prefix + input.name}
                  control={control}
                />
              </>
            )}
            {input.type === 'textarea' && (
              <TextArea
                defaultValue={field ? field[input.name] : input.defaultValue}
                key={`section-${idx}-input`}
                ref={register(options)}
                type={input.type || 'text'}
                disabled={readOnly || input.disabled}
                name={prefix + input.name}
                label={input.label}
                readOnly={readOnly}
                placeholder={input.placeholder}
                helpText={input.helpText}
                value={form.watch(prefix + input.name)}
                error={errors[input.name] && errors[input.name].message}
                required={!!options && !!options.required}
                rows={options && options.rows}
              />
            )}
            {input.type === 'file' && (
              <Controller
                rules={options}
                render={(props) => {
                  return (
                    <File
                      disabled={readOnly || input.disabled}
                      className="files-dropzone"
                      {...input}
                      {...props}
                      form={form}
                      name={inputName}
                      error={errors[input.name] && errors[input.name].message}
                    />
                  )
                }}
                name={inputName}
                control={control}
              />
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
