import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M47.789 83.461a3.13 3.13 0 004.422 0l31.25-31.25a3.13 3.13 0 00-.04-4.383 3.13 3.13 0 00-4.382-.039L53.125 73.707V6.25a3.124 3.124 0 10-6.25 0v67.457L20.961 47.79a3.13 3.13 0 00-4.383.04 3.13 3.13 0 00-.039 4.382zM81.25 90.625h-62.5a3.124 3.124 0 100 6.25h62.5a3.124 3.124 0 100-6.25z" />
    </svg>
  )
}
export default SvgComponent


