import React from 'react'

import { useParams } from 'react-router-dom'

import UserForm from 'components/users/Form'
import UserIcon from 'components/svg/user'
import Layout from 'components/Layout'

const UsersEditPage = ({ location }) => {
  let { id } = useParams()


  return (
    <Layout location={location}>
      <h1 className="flex items-center">
        <UserIcon className="fill-current text-brand" />
        <span className="ml-2">Fiche Utilisateur</span>
      </h1>
      <UserForm id={id} />
    </Layout>
  )
}

export default UsersEditPage
