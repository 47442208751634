import React from 'react'
import { Controller } from 'react-hook-form'
import Select from 'components/form/Select'
import BusinessLineSelect from 'components/common/business-line/BusinessLineSelect'
import SelectBusinessLine from 'components/common/business-line/SelectBusinessLine'
import Input from 'components/form/Input'
import CheckBox from 'components/form/CheckBox'
import { USER_ROLE } from 'lib/constants'

export default function RegisterPage({
  form,
  isAdmin = false,
  isValidateAccessPage = false,
  businessLines,
}) {
  const { watch, register, control, errors } = form

  const roles = [
    USER_ROLE.DECLARANT,
    USER_ROLE.DECLARANT_TRANSVERSE,
    USER_ROLE.AUDIT_ETABLISSEMENT,
  ]
  if (isAdmin) {
    roles.push(USER_ROLE.REFERENT)
    roles.push(USER_ROLE.AUDIT_GROUPE)
    roles.push(USER_ROLE.ADMIN)
  }
  const rolesSelectOpts = roles.map((r) => ({ value: r, label: r }))

  return (
    <>
      <div className="w-full mb-4">
        {!isValidateAccessPage && (
          <Input
            key={`accreditation-email`}
            ref={register({ required: 'Champ obligatoire' })}
            type="text"
            name="email"
            label="Email"
            placeholder="Email"
            value={watch('email')}
            error={errors.email && errors.email.message}
            required={'Champ obligatoire'}
            autofocus={true}
          />
        )}
        {isValidateAccessPage && (
          <>
            <label
              className="block mb-2 text-sm text-grey-darker"
              htmlFor="lastName"
            >
              Email
            </label>
            {watch('email')}
          </>
        )}
      </div>
      <Input
        key={`section-firstname`}
        ref={register({ required: 'Champ obligatoire' })}
        type="text"
        name="firstname"
        label="Prénom"
        placeholder="Prénom"
        value={watch('firstname')}
        error={errors.firstname && errors.firstname.message}
        required={'Champ obligatoire'}
      />

      <Input
        key={`section-lastname`}
        ref={register({ required: 'Champ obligatoire' })}
        type="text"
        name="lastname"
        label="Nom"
        placeholder="Nom"
        value={watch('lastname')}
        error={errors.lastname && errors.lastname.message}
        required={'Champ obligatoire'}
      />

      {/* <label
          className="block mb-2 text-sm font-bold text-grey-darker"
          htmlFor="lastName"
        >
          Prénom
        </label> */}
      {/* <Input
          className="w-full px-3 py-2 border rounded shadow appearance-none text-grey-darker"
          type="text"
          placeholder="nom"
          required
          name="firstname"
          ref={register}
        /> */}

      {/* <label
          className="block mb-2 text-sm font-bold text-grey-darker"
          htmlFor="firstName"
        >
          Rôle
        </label> */}

      <Controller
        name="role"
        control={control}
        rules={{ required: 'Champ obligatoire' }}
        render={({ onChange, onBlur, value, name }) => (
          <>
            <Select
              // ref={selectRef}
              placeholder={'Rôle...'}
              name={name}
              className="w-1/3"
              label="Rôle"
              value={value}
              required={true}
              values={rolesSelectOpts}
              onChange={(e) => {
                onChange(e && e.value)
              }}
            />
            {errors && errors[name] && (
              <span className="text-sm text-red-500">
                {errors[name].message}
              </span>
            )}
          </>
        )}
      />

      <div className="w-full mt-4 ">
        {businessLines ? (
          <SelectBusinessLine
            businessLines={businessLines}
            label="Établissement de rattachement"
            name="BusinessLineId"
            form={form}
            required={'Champ obligatoire'}
          />
        ) : (
          <BusinessLineSelect
            form={form}
            input={{
               className: 'w-1/2',
              name: 'BusinessLineId',
              options: { required: true },
            }}
          />
        )}
      </div>

      <div className="mt-4">
        {isAdmin && (
          <CheckBox
            key={`section-validated`}
            name="validated"
            label="Validé"
            form={form}
            error={errors['validated'] && errors['validated'].message}
          />
        )}
      </div>
    </>
  )
}
