import React, { useState } from 'react'
import dayjs from 'dayjs'
import Table from 'components/common/table'
import useCustomers from 'hooks/useCustomers'
import { useUser } from 'stores/user'
import { dateFmt } from 'components/common/table/formats'
import {
  DefaultColumnFilter,
  DateColumnFilter,
} from 'components/common/table/filters'
import { useApiFetch } from 'components/common/table/hooks'
import {
  Actions,
  EditAction,
  ShowAction,
} from 'components/common/table/actions'
import { useNotify } from 'stores/notify'
import { useHistory } from 'react-router-dom'
import { USER_ROLE } from 'lib/constants'

const initialSortBy = [
  {
    id: 'updatedAt',
    desc: true,
  },
]

const CustomersList = () => {
  const history = useHistory()
  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  const { getCustomersSameBusinessLines, deleteCustomer } = useCustomers()

  const fetchData = useApiFetch(getCustomersSameBusinessLines)

  function tableOnError() {
    setErrorMessage('Une erreur interne est survenue')
  }

  const [removed, setRemoved] = useState(null)
  const onRemove = async (id) => {
    const req = await deleteCustomer({ id })
    if (req.results && req.results.success) {
      setSuccessMessage(`Elément supprimé avec succès`)
      setRemoved(id)
    } else {
      setErrorMessage(`Impossible de supprimer: ${req.error.message}`)
    }
  }
  const { role } = useUser(({ role }) => ({ role }))

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div className="flex w-full">
            <div className="w-1/4">Réf.</div>
            <div className="w-3/4">Établissement</div>
          </div>
        ),
        id: 'businessLines',
        disableSortBy: true,
        // filterOptions: {
        //   placeholder: (count) => `recherche sur NOM parmi ${count} lignes`,
        // },
        Filter: DefaultColumnFilter,
        accessor: (row) => {
          return (
            row.businessLines &&
            row.businessLines
              .split(',')
              .filter((r) => r.replace(/ /g, '') !== '()')
              .map((bl, indc) => {
                const splittedBl = bl.trim().split(')')
                return (
                  <div className="flex w-full">
                    <div className="w-1/4">{splittedBl[0].substring(1)}</div>
                    <div className="w-3/4">{splittedBl[1]}</div>
                  </div>
                )
                // return <div className={`${indc > 0 ? 'mt-2' : ''}`}>{bl}</div>
              })
          )
        },
      },
      {
        Header: 'Nom',
        accessor: (row) => {
          return row.customer_segment === 'Particulier'
            ? row.customerName
            : row.denomination
        },
        // disableFilters: true,
        Filter: DefaultColumnFilter,
        // Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: 'Date de naissance',
        accessor: 'birthdate',
        Filter: DateColumnFilter,
        Cell: ({ value }) => {
          const date = dateFmt(value)

          return !dayjs(value).isValid() ? '' : date
        },
      },
      {
        Header: 'Créé le',
        accessor: 'createdAt',
        Filter: DateColumnFilter,
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: 'Modifié le',
        accessor: 'updatedAt',
        Filter: DateColumnFilter,
        Cell: ({ value }) => dateFmt(value),
      },
      {
        Header: '',
        accessor: 'id',
        id: 'actions',
        disableFilters: true,
        disableSortBy: true,
        Cell: (props) => (
          <Actions>
            {USER_ROLE.AUDIT_GROUPE !== role &&
              USER_ROLE.AUDIT_ETABLISSEMENT !== role ? (
                <EditAction
                  entity={'le client'}
                  to={`/clients/${props.row.original.customerId}/edit`}
                />
              ) : 
                <ShowAction to={`/clients/${props.row.original.customerId}/edit`} />
            }
          </Actions>
        ),
      },
    ],
    []
  )

  const addCustomer = () => {
    history.push('/clients/new')
  }

  return (
    <div className="flex justify-center w-full pt-12">
      <Table
        fetchData={fetchData}
        name="Liste clients"
        columns={columns}
        initialPageSize={10}
        rowRemoved={removed}
        onError={tableOnError}
        addEntity={
          role &&
          role.includes(
            USER_ROLE.ADMIN,
            USER_ROLE.REFERENT,
            USER_ROLE.DECLARANT,
            USER_ROLE.DECLARANT_TRANSVERSE
          )
            ? addCustomer
            : undefined
        }
        initialSortBy={initialSortBy}
      />
    </div>
  )
}

export default CustomersList
