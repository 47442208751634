import create from 'zustand'
import { immer, persist } from 'state/middlewares'
import shallow from 'lib/shallow'

// user store
// TODO: move each store in its own module
const notifyStore = create(
  persist(
    'notify',
    immer((set, get) => ({
      messages: [],
      message: null,
      getMessage: () => {
        const state = get()
        return state.message
      },
      setMessage: (message) => {
        set((state) => {
          state.message = message
        })
      },
      addMessage: (message) => {
        set((state) => {
          // state.messages.push({ ...message, id: new Date().getTime() })
          state.messages = [
            ...state.messages,
            { ...message, id: new Date().getTime() },
          ]
        })
      },
      removeMessage: ({ id }) => {
        set((state) => {
          state.messages = state.messages.filter((m) => m.id !== id)
        })
      },
      setInfoMessage: (text) => {
        set((state) => {
          state.message = { level: 'info', text }
        })
      },
      setWarningMessage: (text) => {
        const state = get()
        state.addMessage({ level: 'warning', text })
      },
      setErrorMessage: (text) => {
        const state = get()
        state.addMessage({ level: 'error', text })
      },
      setSuccessMessage: (text) => {
        const state = get()
        state.addMessage({ level: 'success', text })
      },
    }))
  )
)

export const useNotify = (slices) => shallow(notifyStore, slices)
export const notifyApi = notifyStore
