import React, { useState, useEffect, useRef } from 'react'

import { Redirect, Link } from 'react-router-dom'
import useScrollPosition from '@react-hook/window-scroll'
import WarningIcon from 'components/svg/warning'
import { useFraudStore } from 'stores/fraud'
import FormDefinitions from './formdefinition'

import useUsers from 'hooks/useUsers'

import confirm from 'components/common/ConfirmModal'

export default function Breadcrumbs({
  steps = [],
  currentStep,
  changeStep,
  isFinish = false,
  saveAndPublish,
  saveAndGoBack,
  saveAndGoNext,
  saveInDraft,
  readOnly,
}) {
  const [stepsState, setStepsState] = useState([])
  const [redirect, setRedirect] = useState(false)
  const breadcrumbRef = useRef(null)
  const [fixedStyle, setFixedStyle] = useState({})
  const scrollY = useScrollPosition()
  const { fraud } = useFraudStore((f) => f)
  const { isDeclarant } = useUsers()

  useEffect(() => {
    if (breadcrumbRef && breadcrumbRef.current) {
      const { top, left, width } = breadcrumbRef.current.getBoundingClientRect()
      if (top <= 0) {
        setFixedStyle({
          left,
          position: 'fixed',
          top: 0,
          width,
          // width: "25%"
        })
      } else {
        setFixedStyle({})
      }
    }
  }, [scrollY])

  useEffect(() => {
    const allStepsStates = steps.reduce((stepsState, step) => {
      let stepStatus = ''

      if (fraud && fraud.FraudBreadcrumb) {
        stepStatus = fraud.FraudBreadcrumb[step.replace(/\-/g, '_')] || ''
      }

      return [
        ...stepsState,
        {
          step,
          state: stepStatus,
          label: FormDefinitions[step].title,
        },
      ]
    }, [])
    setStepsState(allStepsStates)
  }, [fraud, steps, currentStep])

  return (
    <div className="flex-grow w-1/4 ml-8" ref={breadcrumbRef}>
      <ul className="relative z-10 fraud-breadcrumbs" style={{ ...fixedStyle }}>
        {stepsState.map(({ step, state, label }, indc) => (
          <React.Fragment key={`bc_${label}`}>
            <li
              className={`${
                currentStep === step ? 'active' : ''
              } state-${state} flex items-center`}
              onClick={() => changeStep({ goto: step })}
            >
              <span className="mr-1">
                {indc + 1}. {label}
              </span>

              {state === 'ko' && <WarningIcon className="fill-current" />}
            </li>
          </React.Fragment>
        ))}
        <div
          className="absolute left-0 z-10 w-full position"
          style={{ bottom: '-80px', padding: 0 }}
        >
          <div className="flex justify-center w-full">
            {currentStep !== 'customer' && (
              <button
                type="button"
                onClick={() => {
                  saveAndGoBack()
                }}
                className="w-10 h-10 mr-1 bg-gray-300 rounded-full"
              >
                &larr;
              </button>
            )}
            {currentStep === 'customer' && <div />}

            {readOnly && isDeclarant() && (
              <div>
                {redirect && (
                  <Redirect to={`/fraudes/${fraud.ref}/edit/${currentStep}`} />
                )}
                <button
                  type="button"
                  className="h-10 px-4 text-xs text-white bg-gray-300 rounded-full bg-accent"
                  onClick={async () => {
                    if (await confirm('Voulez-vous modifier la fraude ?'))
                      setRedirect(true)
                  }}
                >
                  Modifier
                </button>
              </div>
            )}

            {!readOnly && (
              <>
                <button
                  type="button"
                  className="h-10 px-4 text-xs text-white bg-gray-300 rounded-full bg-accent"
                  onClick={async () => {
                    if (
                      await confirm(
                        'Voulez-vous enregistrer et mettre la fraude en brouillon ?'
                      )
                    ) {
                      saveInDraft()
                    }
                  }}
                >
                  Brouillon
                </button>
                <button
                  type="button"
                  className="h-10 px-4 ml-1 text-xs text-white rounded-full bg-brand"
                  onClick={async () => {
                    if (
                      await confirm(
                        'Voulez-vous enregistrer et publier la fraude ?'
                      )
                    ) {
                      saveAndPublish()
                    }
                  }}
                >
                  Enregistrer
                </button>
              </>
            )}
            {!isFinish && (
              <button
                type="button"
                onClick={() => {
                  saveAndGoNext()
                }}
                className="w-10 h-10 ml-1 bg-gray-300 rounded-full"
              >
                &rarr;
              </button>
            )}
          </div>
        </div>
      </ul>
    </div>
  )
}
