import React from "react"

import Layout from "components/Layout"
import ImportList from "components/imports/List"

const ImportListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ImportList />
    </Layout>
  )
}

export default ImportListPage
