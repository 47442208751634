import { AmountAndCurrencyWithProp } from './commonfields/amountAndCurrency'
import Countries from './commonfields/countries'
import BICComponent from './commonfields/BICComponent'
import { validateBIC } from 'lib/codeBic'

import { getInputOptsByBlockedAndFraudTypeField } from 'lib/utils'

export default {
  id: 'bank-transfer-in',
  title: 'Virements entrants',
  // next: "2",
  defaultValues: { FraudBankTransferIns: [{}] },
  sections: [
    {
      // title: "Virements entrants",
      rows: [
        [
          {
            name: 'FraudBankTransferIns',
            type: 'array',
            label: 'Virement entrant',
            fields: [
              [
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        type: 'select',
                        name: 'fraud_detection',
                        label: "Nom de l'outil",
                        options: (props) =>
                          getInputOptsByBlockedAndFraudTypeField(props, {
                            required: 'Ce champ est requis',
                          }),
                        placeholder: 'Choisir un moyen',
                        values: [
                          {
                            label: 'PRISMOP',
                            value: 'PRISMOP',
                          },
                          {
                            label: 'Autres outils',
                            value: 'Autres outils',
                          },
                        ],
                        when: {
                          'Autres outils': [
                            [
                              {
                                name: 'fraud_detection_tool',
                                placeholder: "Précisez l'outil",
                              },
                            ],
                          ],
                        },
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  label: 'Agrégat',
                  defaultValue: 'false',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedAndFraudTypeField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  name: 'transaction_id',
                  label: 'Identifiant de la transaction',
                  autofocus: true,
                },
                { type: 'blank' },
              ],
              [
                {
                  name: 'bank_name',
                  label: "Banque du donneur d'ordre",
                },
                {
                  name: 'bank_domiciliation',
                  label: "Domiciliation bancaire du donneur d'ordre",
                  placeholder: 'IBAN / BBAN',
                },
              ],
              [
                {
                  name: 'swift_bic',
                  type: 'custom',
                  Component: BICComponent,
                  label: "Code SWIFT / BIC du donneur d'ordre",
                  options: (props) => {
                    const required =
                      props.form.watch(props.prefix + 'aggregate') ===
                        'false' || !props.form.watch(props.prefix + 'aggregate')
                    if (required)
                      return getInputOptsByBlockedAndFraudTypeField(props, {
                        required: 'Ce champ est requis',
                        validate: validateBIC,
                      })
                  },
                },
              ],

              AmountAndCurrencyWithProp(
                false,
                getInputOptsByBlockedAndFraudTypeField
              ),

              [
                {
                  type: 'number',
                  name: 'returned_amount',
                  label: 'Montant des fonds renvoyés',
                  suffix: '€',
                },
                { type: 'blank' },
              ],

              [
                {
                  type: 'select',
                  name: 'geo_zone',
                  label: 'Zone géographique',
                  values: [
                    {
                      label: 'Transaction nationale',
                      value: 'Transaction nationale',
                    },
                    {
                      label: "Transfrontalière au sein de l'EEE",
                      value: "Transfrontalière au sein de l'EEE",
                    },
                    {
                      label: "Transfrontalière hors de l'EEE",
                      value: "Transfrontalière hors de l'EEE",
                    },
                  ],
                },
                Countries({
                  label: "Pays d'émission",
                  name: 'country',
                }),
              ],

              [
                {
                  type: 'select',
                  name: 'payment_type',
                  label: 'Schéma de virement',
                  placeholder: 'Choisir un Schéma de virement',
                  values: [
                    {
                      label: 'Virement SEPA (SCT)',
                      value: 'Virement SEPA (SCT)',
                    },
                    {
                      label: 'Virement SEPA instantanés (SCT inst)',
                      value: 'Virement SEPA instantanés (SCT inst)',
                    },
                    {
                      label: 'VGM',
                      value: 'VGM',
                    },
                    {
                      label: 'Autres virements non SEPA hors VGM',
                      value: 'Autres virements non SEPA hors VGM',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'fraud_type',
                  label: 'Type de fraude',
                  placeholder: 'Choisir un type de fraude',
                  values: [
                    {
                      label: 'Faux',
                      value: 'Faux',
                    },
                    {
                      label: 'Falsification',
                      value: 'Falsification',
                    },
                    {
                      label: 'Détournement',
                      value: 'Détournement',
                    },
                    {
                      label: 'Escroquerie',
                      value: 'Escroquerie',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'operation",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'select',
                  name: 'recall_response',
                  label: 'Réponse au recall',
                  values: [
                    {
                      label: 'Fonds transmis',
                      value: 'Fonds transmis',
                    },
                    {
                      label: 'Fonds non disponibles',
                      value: 'Fonds non disponibles',
                    },
                    {
                      label: 'Recall partiel',
                      value: 'Recall partiel',
                    },
                  ],
                },
              ],

              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  // defaultValue: new Date(),
                  options: (props) =>
                    getInputOptsByBlockedAndFraudTypeField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
