/* eslint-disable react/display-name */
import React from 'react'

export default React.forwardRef(
  (
    {
      type,
      label,
      name,
      placeholder,
      required,
      onChange,
      className,
      helpText,
      error,
      value,
      suffix,
      defaultValue,
      disabled = undefined,
      autofocus = false,
      hide = false,
    },
    ref
  ) => {
    let classes = className

    if (required) {
      classes += ' required'
    }

    if (disabled) {
      classes += ' cursor-not-allowed'
    }

    const display = hide ? 'none' : ''

    return (
      <label className={classes} htmlFor={name} style={{ display: display }}>
        <span
          dangerouslySetInnerHTML={{ __html: label }}
          className={`${disabled ? 'opacity-50' : ''}`}
        />
        <div className="flex items-center">
          <input
            ref={ref}
            type={type}
            id={name}
            name={name}
            placeholder={
              typeof placeholder !== 'undefined' ? placeholder : label
            }
            defaultValue={value || defaultValue}
            disabled={disabled}
            autoFocus={autofocus}
          />
          {suffix && <span>{suffix}</span>}
        </div>
        {error ? (
          <span className="help-text error">{error}</span>
        ) : (
          <span className="help-text">{helpText}</span>
        )}
      </label>
    )
  }
)
