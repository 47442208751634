import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'

import { dateFmt } from 'components/common/table/formats'
import { Link } from 'react-router-dom'

import { useFraudStore } from 'stores/fraud'
import { getDeepValue } from 'lib/utils'
import CurrencyInfo from 'components/frauds/CurrencyInfo'

import CustomAlertModal from 'components/common/CustomAlertModal'

const convertToNumber = (numb) => {
  if (!isNaN(numb)) return numb
  return 0
}

const isEmptyArray = (array) => {
  return !array || !Array.isArray(array) || array.length === 0
}

const chequeCalculation = (cheques) => {
  const {
    total,
    block_amount,
    nb_cheque,
    total_non_fraudulent,
    total_by_currency,
    totalOccurencesNumber
  } = cheques.reduce(
    (result, fco) => {
      if (fco.FraudCheques) {
        fco.FraudCheques.forEach(
          ({ currency, amount, consolidated_currency }) => {
            if (fco.blocked) {
              result.block_amount += convertToNumber(consolidated_currency)
            }
            if (fco.status !== 'Non frauduleux') {
              result.total_non_fraudulent += convertToNumber(
                consolidated_currency
              )
            }
            result.total += convertToNumber(consolidated_currency)
            result.total_by_currency[currency] =
              result.total_by_currency[currency] || 0 + amount
          }
        )
        result.nb_cheque += fco.FraudCheques.length
        result.totalOccurencesNumber += fco.occurences_number || 1
      } else {
        const { blocked, consolidated_currency } = fco
        if (blocked) {
          result.block_amount += convertToNumber(consolidated_currency)
        }
        if (fco.status !== 'Non frauduleux') {
          result.total_non_fraudulent += convertToNumber(consolidated_currency)
        }
        result.total += convertToNumber(consolidated_currency)
        result.total_by_currency[fco.currency] =
          result.total_by_currency[fco.currency] || 0 + fco.amount
        result.nb_cheque += 1
        result.totalOccurencesNumber += fco.occurences_number || 1
      }

      return result
    },
    {
      total: 0,
      total_non_fraudulent: 0,
      total_by_currency: {},
      nb_cheque: 0,
      block_amount: 0,
      totalOccurencesNumber:0
    }
  )
  return {
    total,
    block_amount,
    nb_cheque,
    total_by_currency,
    total_non_fraudulent,
totalOccurencesNumber
  }
}

const getBlockedInfo = ({ blocked, fraud_detection, fraud_detection_tool }) => {
  if (!blocked) return <span>Non</span>

  let tool = fraud_detection

  if (tool === 'Autres outils' || (!tool && fraud_detection_tool)) {
    tool = fraud_detection_tool
  }

  if (!tool) {
    tool = '-'
  }

  return `Oui (${tool})`
}
const showArrayLine = (label, value) => {
  return (
    <div className="flex items-center p-1 border">
      <span
        className="flex flex-row w-1/3 px-2 text-gray-800"
        style={{
          borderRight: '1px solid rgba(0, 0, 0, 0.1)',
        }}
      >
        {label}
      </span>
      <span className="w-1/4 ml-4">{value ? value : '-'}</span>
    </div>
  )
}

const Tag = ({ label, value }) => {
  return (
    <div className="inline-block px-2 py-1 mt-2 mr-2 text-sm text-white rounded bg-brand ">
      {label} :<span className="ml-2 font-bold uppercase">{value}</span>
    </div>
  )
}

const showDetail = (title, details) => {
  if (details) {
    CustomAlertModal({
      verticalScroll: true,
      children: details,
      title,
      medium: true,
    })
  }
}

const TinyTable = ({ wide = false, link, title, rows, details }) => {
  return (
    <div
      className={`w-full ${
        wide ? 'flex-col' : ''
      } flex justify-between items-center mb-6`}
    >
      <div
        className={`${
          wide ? 'w-full' : 'w-1/3'
        } bg-gray-200 text-center px-4 py-2 h-full flex flex-col justify-center`}
      >
        <span
          className={`text-sm font-bold ${details ? 'cursor-pointer' : 'cursor-default'}`}
          onClick={() => showDetail(title, details)}
        >
          {title}
        </span>
        <div>
          <Link to={link} className="text-xs text-gray-600">
            <span>accès rapide</span>
            <span className="ml-2 text-xs">⟶</span>
          </Link>
        </div>
      </div>
      <div className={`${wide ? 'w-full' : 'flex-grow'} border `}>
        {rows.map((row, i) => {
          return (
            <div
              key={`${row.label}_${i}`}
              className="flex items-center justify-between p-1 border"
            >
              <span
                className={`flex flex-row text-gray-800 ${
                  wide ? 'w-2/4' : 'w-2/3'
                } px-2 text-xs`}
                style={{ borderRight: '1px solid rgba(0,0,0,0.1)' }}
              >
                {row.label}
                {row.info && <span className="font-bold">{row.info}</span>}
              </span>
              <span className="font-bold">
                {row.value && row.value !== "null" ? row.value : ''}
              </span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
// function displayDeepFieldSameLine(label, obj, path) {
//   return displayFieldSameLine({ label, value: getDeepValue(obj, path) })
// }

export default {
  id: 'overview',
  title: 'Synthèse',
  // next: "cyber",
  defaultValues: {},
  sections: [
    {
      rows: [
        [
          {
            type: 'custom',
            label: 'Libellé',
            name: 'label',
            Component: function ({ input: { label, name } }) {
              const { fraud } = useFraudStore((uf) => uf)
              const date = fraud.occurence_date
                ? dayjs(fraud.occurence_date).format('DD-MM-YYYY')
                : 'Date non saisie'
              return (
                <div
                  className="w-full pb-4 mb-8"
                  style={{
                    borderBottom: '1px solid rgba(0,0,0,0.2)',
                  }}
                >
                  <div className="mb-4 text-2xl font-bold">{fraud.label}</div>
                  <div className="text-sm text-gray-800">
                    <span className="mr-2">Auteur</span>
                    <span className="text-lg font-bold">
                      {fraud.User && (
                        <>
                          {fraud.User.firstname} {fraud.User.lastname}
                        </>
                      )}
                      {!fraud.User && <>Inconnu</>}
                    </span>
                  </div>
                  <div className="text-sm text-gray-800">
                    <span className="mr-2">
                      Quelle est la date de survenance ?
                    </span>
                    <span className="text-lg font-bold">{date}</span>
                  </div>
                  <div className="mb-4 text-sm text-gray-800">
                    <span className="mr-2">La fraude est-elle aboutie ? </span>
                    <span className="text-lg font-bold">
                      {fraud.proven ? 'Oui' : 'Non'}
                    </span>
                  </div>
                </div>
              )
              // return displayFieldSameLine({ label, value: fraud[name] })
            },
          },
        ],
      ],
    },

    {
      title: 'Informations client',
      rows: [
        [
          {
            type: 'custom',
            label: 'Identitié du client',
            Component: ({ input: { label } }) => {
              const { fraud } = useFraudStore((uf) => uf)
              const [customerIdentity, setCustomerIdentity] = useState('')

              useEffect(() => {
                if (fraud.Customer) {
                  setCustomerIdentity(
                    `${fraud.Customer.firstname} ${fraud.Customer.lastname}`
                  )
                }
              }, [fraud])

              return (
                <div className="relative w-full mt-2">
                  <div className="inline-block text-xl font-bold">
                    {customerIdentity}
                  </div>

                  <div className="flex items-center">
                    <Tag
                      label="Segment"
                      value={getDeepValue(fraud, 'Customer.customer_segment')}
                    />
                    <Tag label="Comportement" value={fraud.customer_behavior} />
                  </div>
                </div>
              )
              // return displayFieldSameLine({ label, value: customerIdentity })
            },
          },
        ],
        [
          {
            name: 'Customer.',
            type: 'custom',
            label: 'Identifiant client',
            Component: function IdCustomer({ input: { label } }) {
              const { fraud } = useFraudStore((uf) => uf)

              const [customerRef, setCustomerRef] = useState({
                ref: 'Sans référence',
                businessLineName: '-',
              })
              // find customer business line ref related to
              // the current fraud
              useEffect(() => {
                if (fraud.Customer && fraud.Customer.BusinessLines) {
                  const customerBusinessLineFound = fraud.Customer.BusinessLines.find(
                    (customerBusinessLine) =>
                      customerBusinessLine.id === fraud.BusinessLineId
                  )

                  if (
                    customerBusinessLineFound &&
                    customerBusinessLineFound.CustomerBusinessLine
                  ) {
                    setCustomerRef({
                      ref: customerBusinessLineFound.CustomerBusinessLine.ref,
                      businessLineName: customerBusinessLineFound.name,
                    })
                  }
                }
              }, [fraud])

              return (
                <div
                  className="w-full pb-10 mb-6"
                  style={{ borderBottom: '1px solid rgba(0,0,0,0.2)' }}
                >
                  <Tag
                    label="Référence client"
                    value={`${customerRef.ref} / ${customerRef.businessLineName}`}
                  />
                  {/* <div className="mt-4 text-gray-800">Référence client : {customerRef.ref} ({customerRef.businessLineName})</div> */}
                </div>
              )

              // return displayFieldSameLine({ label, value: customerRef })
            },
          },
        ],
      ],
    },
    {
      title: 'Type de fraude',
      rows: [
        [
          {
            type: 'custom',
            label: 'Fraude documentaire',
            Component: function FraudDocumentary({ readOnly }) {
              const { fraud } = useFraudStore((uf) => uf)

              if (
                !fraud.hasOwnProperty('FraudDocumentary') ||
                !fraud.FraudDocumentary
              ) {
                return null
              }

              const {
                FraudDocumentary: {
                  occurence,
                  fake_doc_use,
                  FakeDocs = [],
                  execution_date,
                  blocked,
                },
              } = fraud

              let docs = FakeDocs.reduce((docs, { nature }) => {
                docs.push(nature)
                return docs
              }, [])
              docs = Array.from(new Set(docs))

              const rows = [
                {
                  label: 'Survenance de la fraude',
                  value: occurence,
                },
                {
                  label: 'Utilisation du document frauduleux',
                  value: `${fake_doc_use}`,
                },
                {
                  label: 'Nature des documents frauduleux',
                  value:
                    docs && docs.length > 0 ? docs.join(',') : 'aucun document',
                },
                {
                  label: "Date d'exécution",
                  value: execution_date
                    ? dayjs(execution_date).format('DD-MM-YYYY')
                    : 'non saisie',
                },
                {
                  label: 'Opération déjouée',
                  value: blocked ? 'oui' : 'non',
                },
              ]

              const details = (
                <div>
                  {fraud.FraudDocumentary.FakeDocs.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Document #{indc + 1}
                      </h3>
                      <div className="border">
                        {['Identité', 'Revenus'].includes(ft.nature) && (
                          <>
                            {showArrayLine(
                              'Nature des documents frauduleux',
                              `${ft.nature} (${ft.identity_type})`
                            )}
                          </>
                        )}

                        {!['Identité', 'Revenus'].includes(ft.nature) && (
                          <>
                            {showArrayLine(
                              'Nature des documents frauduleux',
                              ft.nature
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ))}
                  <div className="mt-4 border">
                    {showArrayLine(
                      "Date d'exécution",
                      fraud.FraudDocumentary.execution_date
                        ? dateFmt(fraud.FraudDocumentary.execution_date)
                        : '-'
                    )}
                    {showArrayLine(
                      'Opération déjouée',
                      getBlockedInfo(fraud.FraudDocumentary.blocked)
                    )}
                  </div>
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }documentary`}
                  wide
                  title="Fraude documentaire"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],

        [
          {
            type: 'custom',
            label: 'Trades',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (!fraud.hasOwnProperty('FraudTrade') || !fraud.FraudTrade) {
                return null
              }

              const rows = []

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${!readOnly ? 'edit/' : ''}trade`}
                  wide
                  title="Trades"
                  rows={rows}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Cyber',
            Component: function FraudCyber({ readOnly }) {
              const { fraud } = useFraudStore((uf) => uf)

              if (!fraud.hasOwnProperty('FraudCyber') || !fraud.FraudCyber) {
                return null
              }
              const {
                FraudCyber: {
                  subscription_id,
                  subscription_customer_lastname,
                  subscription_customer_firstname,
                  subscription_customer_civility,
                  sub_subscription_id,
                  sub_subscription_customer_lastname,
                  sub_subscription_customer_firstname,
                  sub_subscription_customer_civility,
                },
              } = fraud

              const sub_customer = sub_subscription_customer_firstname
                ? `${sub_subscription_customer_civility} ${sub_subscription_customer_firstname} ${sub_subscription_customer_lastname}`
                : ''
              const customer = subscription_customer_firstname
                ? `${subscription_customer_civility} ${subscription_customer_firstname} ${subscription_customer_lastname}`
                : ''
              const rows = [
                {
                  label: 'Client abonnement',
                  value: customer,
                },
                {
                  label: 'Identifiant abonnement',
                  value: subscription_id,
                },
                {
                  label: 'Client sous-abonnement',
                  value: sub_customer,
                },
                {
                  label: 'Identifiant sous-abonnement',
                  value: sub_subscription_id,
                },
              ]

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${!readOnly ? 'edit/' : ''}cyber`}
                  wide
                  title="Cyber"
                  rows={rows}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Crédit consommation',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (
                !fraud.hasOwnProperty('FraudConsumerCredit') ||
                !fraud.FraudConsumerCredit
              ) {
                return null
              }
              const rows = []

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }consumer-credit`}
                  wide
                  title="Crédit consommation"
                  rows={rows}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Assurance',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (
                !fraud.hasOwnProperty('FraudInsurance') ||
                !fraud.FraudInsurance
              ) {
                return null
              }

              const rows = []

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }insurance`}
                  wide
                  title="Assurance"
                  rows={rows}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Virements entrants',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudBankTransferIns)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudBankTransferIns.reduce(
                (result, fbti) => {
                  if (fbti.status !== 'Non frauduleux')
                    result.total += convertToNumber(fbti.consolidated_currency)
                  result.total_by_currency[fbti.currency] =
                    result.total_by_currency[fbti.currency] || 0 + fbti.amount

                  if (fbti.blocked) {
                    result.block_amount += convertToNumber(
                      fbti.consolidated_currency
                    )
                  }
                  result.totalOccurencesNumber += fbti.occurences_number || 1

                  return result
                },
                {
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                  totalOccurencesNumber: 0
                }
              )

              const rows = [
                {
                  label: 'Nombre de virements émis',
                  value: totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total.toFixed(2)} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount.toFixed(2)} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudBankTransferIns.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Virement #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant transaction',
                          ft.transaction_id
                        )}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine(
                          "Domiciliation bancaire du donneur d'ordre",
                          ft.bank_domiciliation
                        )}
                        {showArrayLine(
                          "Banque du donneur d'ordre",
                          ft.bank_name
                        )}
                        {showArrayLine('Schéma de virement', ft.payment_type)}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }bank-transfer-in`}
                  title="Virements reçus"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Virements sortants',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudBankTransferOuts)) {
                return null
              }

              const {
                total,
                prismop_blocked_amount,
                other_blocked_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudBankTransferOuts.reduce(
                (result, fbto) => {
                  if (fbto.status !== 'Non frauduleux') {
                    result.total += convertToNumber(fbto.consolidated_currency)
                    result.total_by_currency[fbto.currency] =
                      result.total_by_currency[fbto.currency] || 0 + fbto.amount
                  }

                  if (fbto.blocked) {
                    if (fbto.fraud_detection === 'PRISMOP') {
                      result.prismop_blocked_amount += convertToNumber(
                        fbto.consolidated_currency
                      )
                    } else {
                      result.other_blocked_amount += convertToNumber(
                        fbto.consolidated_currency
                      )
                    }
                  }
                  result.totalOccurencesNumber += fbto.occurences_number || 1

                  return result
                },
                {
                  total: 0,
                  totalOccurencesNumber:0,
                  total_by_currency: {},
                  prismop_blocked_amount: 0,
                  other_blocked_amount: 0,
                }
              )

              const rows = [
                {
                  label: 'Nombre de virements émis',
                  value: totalOccurencesNumber
                },
                {
                  label: `Contrevaleur total en Euros`,
                  value: `${total} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué par Prismop',
                  value: `${prismop_blocked_amount} €`,
                },
                {
                  label: 'Montant total bloqué par un autre outil',
                  value: `${other_blocked_amount} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudBankTransferOuts.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Virement #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant transaction',
                          ft.transaction_id
                        )}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine(
                          'Domiciliation bancaire du bénéficiaire',
                          ft.bank_domiciliation
                        )}
                        {showArrayLine('Banque du bénéficiare', ft.bank_name)}
                        {showArrayLine('Schéma de virement', ft.payment_type)}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }bank-transfer-out`}
                  title="Virements sortants"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: "Dépôts d'espèces",
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudCashIns)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudCashIns.reduce(
                (result, fci) => {
                  if (fci.status !== 'Non frauduleux')
                    result.total += convertToNumber(fci.consolidated_currency)
                  result.total_by_currency[fci.currency] =
                    result.total_by_currency[fci.currency] || 0 + fci.amount

                  if (fci.blocked) {
                    result.block_amount += convertToNumber(
                      fci.consolidated_currency
                    )
                  }
                  result.totalOccurencesNumber += fci.occurences_number || 1

                  return result
                },
                {
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                  totalOccurencesNumber: 0
                }
              )

              const rows = [
                {
                  label: 'Nombre de dépôts',
                  value: totalOccurencesNumber
                },
                {
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                  label: 'Contrevaleur total en Euros',
                  value: `${total} €`,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudCashIns.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Dépôt d'espèces #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant transaction',
                          ft.transaction_id
                        )}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine('Moyen de dépôt', ft.deposit_way)}
                        {showArrayLine('Lieu de dépôt', ft.deposit_agency)}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }cash-in`}
                  title="Dépôts d'espèces"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: "Sorties d'espèces",
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudCashOuts)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudCashOuts.reduce(
                (result, fci) => {
                  if (fci.status !== 'Non frauduleux')
                    result.total += convertToNumber(fci.consolidated_currency)
                  result.total_by_currency[fci.currency] =
                    result.total_by_currency[fci.currency] || 0 + fci.amount
                  if (fci.blocked) {
                    result.block_amount += convertToNumber(
                      fci.consolidated_currency
                    )
                  }
                  result.totalOccurencesNumber += fci.occurences_number || 1
                  return result
                },
                {
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                  totalOccurencesNumber:0
                }
              )

              const rows = [
                {
                  label: 'Nombre de sorties',
                  value: totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudCashOuts.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Sorties d'espèces #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant transaction',
                          ft.transaction_id
                        )}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine('Moyen de retrait', ft.withdrawal_means)}
                        {showArrayLine('Lieu de retrait', ft.withdrawal_agency)}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }cash-out`}
                  title="Sortie d'espèces"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Remise de chèques',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudChequeIns)) {
                return null
              }

              const {
                total,
                block_amount,
                nb_cheque,
                total_non_fraudulent,
                total_by_currency,
                totalOccurencesNumber
              } = chequeCalculation(fraud.FraudChequeIns)

              const rows = [
                {
                  label: 'Nombre de remises',
                  value: totalOccurencesNumber 
                },
                {
                  label: 'Nombre de chèques',
                  value: `${nb_cheque}`,
                },
                {
                  label: 'Montant total des opérations',
                  value: `${total_non_fraudulent.toFixed(2)} €`,
                },
                {
                  label: 'Contrevaleur total en Euros des remises',
                  value: `${total.toFixed(2)} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount.toFixed(2)} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudChequeIns.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Remise de chèques #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant transaction',
                          ft.transaction_id
                        )}
                        {showArrayLine('Type de remise', ft.deposit_type)}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                      {ft.FraudCheques.map((fc, indc) => (
                        <div className="pt-2 pl-8" key={fc.id}>
                          <h3 className="font-bold">Chèque #{indc + 1}</h3>
                          <div className="border">
                            {showArrayLine(
                              'Numéro de chèque',
                              `${fc.cheque_num}`
                            )}
                            {showArrayLine(
                              'Montant',
                              `${fc.amount ? fc.amount : '0'} ${
                                fc.currency ? fc.currency : 'pas de devise'
                              }`
                            )}
                            {fc.devise !== 'EUR' && (
                              <>
                                {showArrayLine(
                                  'Montant en euros',
                                  `${fc.consolidated_currency} EUR`
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }cheque-in`}
                  title="Remises de chèques"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Émissions de chèques',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudChequeOuts)) {
                return null
              }
              const {
                block_amount,
                total_non_fraudulent,
                total_by_currency,
                totalOccurencesNumber
              } = chequeCalculation(fraud.FraudChequeOuts)

              const rows = [
                {
                  label: 'Nombre de chèques',
                  value: totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros des opérations',
                  value: `${total_non_fraudulent.toFixed(2)} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount.toFixed(2)} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudChequeOuts.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Émission de chèque #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant du paiement du chèque',
                          ft.transaction_id
                        )}
                        {showArrayLine('Numéro du chèque', `${ft.cheque_num}`)}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine('Type de chèque', ft.cheque_type)}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }cheque-out`}
                  title="Emissions de chèques"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],

        [
          {
            type: 'custom',
            label: 'CB entrants',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudCbIns)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber,
              } = fraud.FraudCbIns.reduce(
                (result, ft) => {
                  result.totalOccurencesNumber += ft.occurences_number || 1
                  result.total += convertToNumber(ft.consolidated_currency)
                  result.total_by_currency[ft.currency] =
                    result.total_by_currency[ft.currency] || 0 + ft.amount

                  if (ft.blocked) {
                    result.block_amount += convertToNumber(
                      ft.consolidated_currency
                    )
                  }

                  return result
                },
                {
                  totalOccurencesNumber: 0,
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                }
              )

              const rows = [
                {
                  label: 'Nombre de CB entrant',
                  value: totalOccurencesNumber,
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount} €`,
                },
              ]

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${!readOnly ? 'edit/' : ''}cb-in`}
                  title="CB entrants"
                  rows={rows}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'CB sortants',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudCbOuts)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber,
              } = fraud.FraudCbOuts.reduce(
                (result, ft) => {
                  result.totalOccurencesNumber += ft.occurences_number || 1
                  result.total += convertToNumber(ft.consolidated_currency)
                  result.total_by_currency[ft.currency] =
                    result.total_by_currency[ft.currency] || 0 + ft.amount

                  if (ft.blocked) {
                    result.block_amount += convertToNumber(
                      ft.consolidated_currency
                    )
                  }

                  return result
                },
                {
                  totalOccurencesNumber: 0,
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                }
              )

              const rows = [
                {
                  label: "Nombre d'occurences",
                  value: totalOccurencesNumber,
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount} €`,
                },
              ]

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }cb-out`}
                  title="CB sortants"
                  rows={rows}
                />
              )
            },
          },
        ],

        [
          {
            type: 'custom',
            label: 'Prélèvements entrants',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudLevyIns)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudLevyIns.reduce(
                (result, flo) => {
                  if (flo.status !== 'Non frauduleux') {
                    result.total += convertToNumber(flo.consolidated_currency)
                    result.total_by_currency[flo.currency] =
                      result.total_by_currency[flo.currency] || 0 + flo.amount
                  }
                  if (flo.blocked) {
                    result.block_amount += convertToNumber(
                      flo.consolidated_currency
                    )
                  }
                  result.totalOccurencesNumber += flo.occurences_number || 1

                  return result
                },
                {
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                  totalOccurencesNumber: 0
                }
              )

              const rows = [
                {
                  label: 'Nombre de prélèvements',
                  value: totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudLevyIns.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Prélèvement entrant #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant du paiement du chèque',
                          ft.transaction_id
                        )}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine('Nature du prélevement', ft.nature)}

                        {showArrayLine(
                          'Domiciliation bancaire',
                          ft.bank_domiciliation
                        )}
                        {showArrayLine(
                          'Identifiant du créancier',
                          ft.creditor_id
                        )}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }levy-in`}
                  title="Prélèvements entrants"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Prélèvements sortants',
            Component: ({ readOnly }) => {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudLevyOuts)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudLevyOuts.reduce(
                (result, flo) => {
                  if (flo.status !== 'Non frauduleux') {
                    result.total += convertToNumber(flo.consolidated_currency)
                    result.total_by_currency[flo.currency] =
                      result.total_by_currency[flo.currency] || 0 + flo.amount
                  }
                  if (flo.blocked) {
                    result.block_amount += convertToNumber(
                      flo.consolidated_currency
                    )
                  }

                  result.totalOccurencesNumber += flo.occurences_number || 1
                  return result
                },
                {
                  total: 0,
                  totalOccurencesNumber: 0,
                  block_amount: 0,
                  total_by_currency: {},
                }
              )

              const rows = [
                {
                  label: 'Nombre de prélèvements',
                  value: totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount} €`,
                },
              ]

              const details = (
                <div>
                  {fraud.FraudLevyOuts.map((ft, indc) => (
                    <>
                      <h3
                        className={`pb-2 font-bold ${indc > 0 ? 'mt-4' : ''}`}
                      >
                        Prélèvement sortant #{indc + 1}
                      </h3>
                      <div className="border">
                        {showArrayLine(
                          'Identifiant du paiement du chèque',
                          ft.transaction_id
                        )}
                        {showArrayLine(
                          'Montant',
                          `${ft.amount ? ft.amount : '0'} ${
                            ft.currency ? ft.currency : 'pas de devise'
                          }`
                        )}
                        {ft.currency !== 'EUR' && (
                          <>
                            {showArrayLine(
                              'Montant en euros',
                              `${
                                ft.consolidated_currency
                                  ? ft.consolidated_currency
                                  : '0'
                              } EUR`
                            )}
                          </>
                        )}
                        {showArrayLine('Nature du prélevement', `${ft.nature}`)}

                        {showArrayLine(
                          'Domiciliation bancaire',
                          ft.bank_domiciliation
                        )}
                        {showArrayLine(
                          'Identifiant du créancier',
                          ft.creditor_id
                        )}
                        {showArrayLine(
                          "Date d'exécution",
                          ft.execution_date ? dateFmt(ft.execution_date) : '-'
                        )}
                        {showArrayLine('Opération déjouée', getBlockedInfo(ft))}
                      </div>
                    </>
                  ))}
                </div>
              )

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }levy-out`}
                  title="Prélèvements sortants"
                  rows={rows}
                  details={details}
                />
              )
            },
          },
        ],

        [
          {
            type: 'custom',
            label: 'Effets entrants',
            Component: function FraudBelongingsIn({ readOnly }) {
              // const { fraud } = useFraudStore((uf) => uf)

              // if (
              //   !fraud.hasOwnProperty('FraudBelongingsIn') ||
              //   !fraud.FraudBelongingsIn
              // ) {
              //   return null
              // }

              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudBelongingsIns)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudBelongingsIns.reduce(
                (result, fbti) => {
                  if (fbti.status !== 'Non frauduleux')
                    result.total += convertToNumber(fbti.consolidated_currency)
                  result.total_by_currency[fbti.currency] =
                    result.total_by_currency[fbti.currency] || 0 + fbti.amount

                  if (fbti.blocked) {
                    result.block_amount += convertToNumber(
                      fbti.consolidated_currency
                    )
                  }
                  result.totalOccurencesNumber += fbti.occurences_number || 1

                  return result
                },
                {
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                  totalOccurencesNumber:0
                }
              )

              const rows = [
                {
                  label: "Nombre d'effets entrants",
                  value:totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total.toFixed(2)} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount.toFixed(2)} €`,
                },
              ]

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }belongings-in`}
                  title="Effets entrants"
                  rows={rows}
                />
              )

              // const {
              //   currency,
              //   amount,
              //   consolidated_currency,
              // } = fraud.FraudBelongingsIn

              // const rows = [
              //   {
              //     label: 'Transaction',
              //     value: `${amount} ${currency === 'EUR' ? '€' : currency}`,
              //   },
              //   {
              //     label: 'Montant consolidé en euro',
              //     value: `${
              //       currency !== 'EUR' ? consolidated_currency : amount
              //     } €`,
              //   },
              // ]

              // return (
              //   <TinyTable
              //     link={`/fraudes/${fraud.id}/${
              //       !readOnly ? 'edit/' : ''
              //     }belongings-in`}
              //     title="Effets entrants"
              //     rows={rows}
              //   />
              // )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Effets sortants',
            Component: function FraudBelongingsOut({ readOnly }) {
              const { fraud } = useFraudStore((uf) => uf)

              if (isEmptyArray(fraud.FraudBelongingsOuts)) {
                return null
              }

              const {
                total,
                block_amount,
                total_by_currency,
                totalOccurencesNumber
              } = fraud.FraudBelongingsOuts.reduce(
                (result, fbti) => {
                  if (fbti.status !== 'Non frauduleux')
                    result.total += convertToNumber(fbti.consolidated_currency)
                  result.total_by_currency[fbti.currency] =
                    result.total_by_currency[fbti.currency] || 0 + fbti.amount

                  if (fbti.blocked) {
                    result.block_amount += convertToNumber(
                      fbti.consolidated_currency
                    )
                  }
                  result.totalOccurencesNumber += fbti.occurences_number || 1

                  return result
                },
                {
                  total: 0,
                  block_amount: 0,
                  total_by_currency: {},
                  totalOccurencesNumber: 0
                }
              )

              const rows = [
                {
                  label: "Nombre d'effets sortants",
                  value: totalOccurencesNumber
                },
                {
                  label: 'Contrevaleur total en Euros',
                  value: `${total.toFixed(2)} €`,
                  info: <CurrencyInfo totalByCurrency={total_by_currency} />,
                },
                {
                  label: 'Montant total bloqué',
                  value: `${block_amount.toFixed(2)} €`,
                },
              ]

              return (
                <TinyTable
                  link={`/fraudes/${fraud.id}/${
                    !readOnly ? 'edit/' : ''
                  }belongings-out`}
                  title="Effets sortants"
                  rows={rows}
                />
              )
            },
          },
        ],
      ],
    },
    {
      title: 'Informations pertes',
      rows: [
        [
          {
            type: 'custom',
            // label: 'Montant de la tentative',
            Component: function ({ input: { label } }) {
              const { fraud } = useFraudStore((uf) => uf)
              return (
                <div className="flex items-center justify-between w-full">
                  <div className="w-1/3 h-full p-2 text-center ">
                    <div className="mb-2 text-xl font-bold">
                      {getDeepValue(fraud, 'Loss.try_amount') || 0} €
                    </div>
                    <div className="text-xs text-gray-600">
                      Montant de la fraude brute
                    </div>
                  </div>
                  <div className="w-1/3 h-full p-2 text-center ">
                    <div className="mb-2 text-xl font-bold">
                      {getDeepValue(fraud, 'Loss.recovery_amount') || 0} €
                    </div>
                    <div className="text-xs text-gray-600">
                       Montant récupéré / déjoué
                    </div>
                  </div>
                  <div className="w-1/3 h-full p-2 text-center ">
                    <div className="mb-2 text-xl font-bold">
                      {getDeepValue(fraud, 'Loss.recovery_rate') || 0} %
                    </div>
                    <div className="text-xs text-gray-600">
                      Taux de récupération
                    </div>
                  </div>
                </div>
              )
            },
          },
        ],
        [
          {
            type: 'custom',
            label: 'Montant de la perte estimée',
            Component: function ({ input: { label } }) {
              const { fraud } = useFraudStore((uf) => uf)

              return (
                <div className="flex items-center justify-between w-full mt-2">
                  <div className="w-1/3 h-full p-2 text-center ">
                    <div className="mb-2 text-xl font-bold">
                      {getDeepValue(fraud, 'Loss.estimated_amount') || 0} €
                    </div>
                    <div className="text-xs text-gray-600">
                      Montant de la perte estimée
                    </div>
                  </div>
                  <div className="w-1/3 h-full p-2 text-center ">
                    <div className="mb-2 text-xl font-bold">
                      {getDeepValue(fraud, 'Loss.bank_loss_amount') || 0} €
                    </div>
                    <div className="text-xs text-gray-600">
                      Montant de la perte comptable
                    </div>
                  </div>
                  <div className="w-1/3 h-full p-2 text-center ">
                    <div className="mb-2 text-xl font-bold">
                      {getDeepValue(fraud, 'Loss.provision_amount') || 0} €
                    </div>
                    <div className="text-xs text-gray-600">
                      Montant de la provision
                    </div>
                  </div>
                </div>
              )
            },
          },
        ],
      ],
    },
  ],
}
