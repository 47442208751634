import React, { useEffect, useState, useCallback } from 'react'
// import { useTable } from 'react-table'
import {
  useFilters,
  useAsyncDebounce,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table'
import { SortIndicator } from 'components/common/table/sort'
import ArrowLeftIcon from 'components/svg/arrowLeft'
import ArrowRightIcon from 'components/svg/arrowRight'
import FilterIcon from 'components/svg/filter'
import RefreshIcon from 'components/svg/refresh'
import AddIcon from 'components/svg/add'
import { useRef } from 'react'
import { useFilter } from 'stores/filter'

const PaginateNumber = ({ gotoPage, index, pageIndex }) => {
  return (
    <span
      onClick={() => gotoPage(index)}
      className={`mx-2 cursor-pointer px-3 py-1 rounded-sm text-gray-700 ${
        pageIndex === index ? 'font-bold bg-gray-300 text-white' : ''
      }`}
    >
      {index + 1}
    </span>
  )
}

const Pagination = ({
  maxPageShown = 7,
  gotoPage,
  setPageSize,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageSize,
  pageCount,
}) => {
  if (maxPageShown < 5) {
    throw new Error("maxPageShown can't be less than 5")
  }

  let pageNumbers = [...Array(pageCount).keys()].map((i) => {
    return (
      <PaginateNumber
        gotoPage={gotoPage}
        pageIndex={pageIndex}
        index={i}
        key={pageIndex + ' ' + i}
      />
    )
  })

  if (pageCount > maxPageShown) {
    const pages = [...Array(pageCount).keys()]
    const sideLength = Math.round(maxPageShown / 2)

    // Take siblings, page one after the other
    // first the pageIndex
    // then going right
    // then going left
    // until either side is empty
    // or the maxPageShown is reached
    const range = []
    let newCursor = pageIndex
    let direction = -1
    const maxSide = sideLength - 1
    for (let i = 0; i < maxSide; ) {
      let idx = newCursor + direction * i
      direction = direction * -1
      if (pages[idx] !== undefined) {
        range.push(pages[idx])
        i++
      } else {
        direction = direction * -1
        idx = idx - (i + 1) * direction
        if (pages[idx] !== undefined) {
          range.push(pages[idx])
          i++
        } else {
          // no more pages to find
          break
        }
      }
      newCursor = idx
    }

    let sideWithIndex = range.sort((a, b) => {
      if (a < b) return -1
      if (a > b) return 1
      return 0
    })

    let sideRight = []

    // compute distance of pageIndex from the end of the Array
    const pageIndexIsLeft = pages.length - pageIndex > pageIndex ? true : false
    let sideLeft = pages.slice(pages.length - sideLength, pages.length - 1)
    if (!pageIndexIsLeft) {
      sideRight = sideWithIndex
      sideLeft = pages.slice(0, sideLength - 1)
    } else {
      sideRight = sideLeft
      sideLeft = sideWithIndex
    }

    pageNumbers = [
      ...sideLeft.map((i) => (
        <PaginateNumber gotoPage={gotoPage} pageIndex={pageIndex} index={i} />
      )),
      <span className="text-gray-500">...</span>,
      ...sideRight.map((i) => (
        <PaginateNumber gotoPage={gotoPage} pageIndex={pageIndex} index={i} />
      )),
    ]
  }

  return (
    <div className="flex justify-center">
      <div className="flex items-center">
        <span
          className={`${
            canPreviousPage ? 'cursor-pointer text-gray-700' : 'text-gray-400'
          }`}
          onClick={() => (canPreviousPage ? previousPage() : null)}
        >
          <ArrowLeftIcon className="fill-current" />
        </span>
        {pageNumbers.map((p) => p)}
        <span
          className={`${
            canNextPage ? 'cursor-pointer text-gray-700' : 'text-gray-400'
          }`}
          onClick={() => (canNextPage ? nextPage() : null)}
        >
          <ArrowRightIcon className="fill-current" />
        </span>
      </div>
    </div>
  )
}

export default ({
  name = 'Listing',
  columns = [],
  rowRemoved = null,
  rowAdded = null,
  // data = [],
  fetchData = () => null,
  initialPageSize = 10,
  initialSortBy,
  addEntity,
  onError = console.error,
  // manualPagination = false,
  // manualFilters = false,
  // loading = false,
  // pageCount: controlledPageCount,
}) => {
  const fetchDataDebounced = useAsyncDebounce(fetchData, 500)
  const skipPageResetRef = useRef()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  // const [controlledPageSize, setControlledPageSize] = useState(undefined)
  const [controlledPageCount, setControlledPageCount] = useState(undefined)
  const [controlledTotalRows, setControlledTotalRows] = useState(undefined)
  const { setFilters, resetFilters, filters: storedFilters } = useFilter(
    (o) => o
  )

  const asyncFetchData = useCallback(async (params = {}) => {
    const {
      pageSize = initialPageSize,
      pageIndex = 0,
      order = [],
      filters = [],
    } = params
    // setLoading(true)

    const [results, totalCount, nbRows] = await fetchDataDebounced({
      pageIndex,
      pageSize,
      filters,
      order,
    })
    if (results) {
      setData(results)
      setControlledPageCount(totalCount)
      setControlledTotalRows(nbRows)
    }

    // TODO refreshing bug
    // if we set the loading flag
    // the pagination resets at the first page
    // setLoading(false)
  }, [])

  useEffect(() => {
    skipPageResetRef.current = false
  })

  // useEffect(() => {
  //   asyncFetchData()
  // }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    totalRows,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setAllFilters,
    state: { pageIndex, pageSize, filters, sortBy },
  } = useTable(
    {
      columns,
      data,
      totalRows: controlledTotalRows,
      pageCount: controlledPageCount,
      manualSortBy: true,
      manualPagination: true,
      manualFilters: true,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        ...(initialSortBy && { sortBy: initialSortBy }),
        // sortBy: undefined
        ...(storedFilters[name] && { filters: storedFilters[name] }),
      },
      disableSortRemove: true, // (uncomment for switching asc/desc only)
      autoResetPage: !skipPageResetRef.current,
      autoResetExpanded: !skipPageResetRef.current,
      autoResetGroupBy: !skipPageResetRef.current,
      autoResetSelectedRows: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
      autoResetFilters: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    if (filters) {
      setFilters(name, filters)
      if (filters.length > 0) setShowFilters(true)
    }
  }, [setFilters, filters])

  useEffect(() => {
    skipPageResetRef.current = true

    // Filters can be an array of filters.
    // So reconstruct them
    let newFilters =
      storedFilters[name] &&
      storedFilters[name].filter(
        (f) =>
          (Array.isArray(f.value) && f.value.length) || !Array.isArray(f.value)
      )

    newFilters =
      newFilters &&
      newFilters.reduce((newFilters, filter) => {
        if (Array.isArray(filter.value)) {
          filter.value.forEach((f) => {
            newFilters.push({
              id: filter.id,
              value: {
                value: f.value,
                operator: f.operator,
              },
            })
          })
        } else {
          newFilters.push(filter)
        }
        return newFilters
      }, [])

    asyncFetchData({
      pageIndex,
      pageSize,
      filters: newFilters,
      order: sortBy,
    }).catch(onError)
  }, [pageIndex, pageSize, sortBy, storedFilters, rowRemoved, rowAdded])

  const toggleFilters = useCallback(() => {
    if (!skipPageResetRef.current) {
      // if (showFilters) {
      //   setAllFilters([])
      // }
      setShowFilters(!showFilters)
    }
  }, [showFilters])

  return (
    <div className="w-full">
      <div {...getTableProps()} className="w-full px-4 mb-12">
        <div className="relative flex flex-col w-full min-w-0 mb-6 break-words bg-white rounded shadow-lg">
          <div className="px-4 py-3 mb-0 border-0 rounded-t">
            <div className="flex flex-wrap items-center justify-between">
              <div className="relative flex-1 flex-grow w-full max-w-full px-4">
                <h3 className="text-lg font-semibold text-gray-800">
                  {name}
                  <sup className="pl-1 font-thin">
                    ({controlledTotalRows || 0})
                  </sup>
                </h3>
              </div>
              {showFilters && (
                <div
                  title="Réinitialiser les filtres"
                  onClick={() => {
                    setAllFilters([])
                    resetFilters(name)
                  }}
                  className={`text-gray-800  cursor-pointer mr-4`}
                >
                  <RefreshIcon />
                </div>
              )}
              <div
                className={`${
                  showFilters ? 'text-gray-800' : 'text-gray-500'
                } cursor-pointer mr-4`}
              >
                <FilterIcon className="fill-current" onClick={toggleFilters} />
              </div>

              {addEntity && (
                <div
                  title="Ajouter un élément"
                  className={`text-gray-500 text-xl cursor-pointer mr-2`}
                >
                  <AddIcon className="fill-current" onClick={addEntity} />
                </div>
              )}
            </div>
          </div>
          <div className="block w-full overflow-x-auto felist">
            <table className="items-center w-full bg-transparent border-collapse">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={JSON.stringify(
                      headerGroup.headers.map(({ id }) => id)
                    )}
                  >
                    {headerGroup.headers.map((column) => (
                      <th
                        key={column.id}
                        className="px-6 py-3 text-xs font-semibold text-left text-gray-600 uppercase whitespace-no-wrap align-baseline bg-gray-100 border border-l-0 border-r-0 border-gray-200 border-solid"
                      >
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          title="Trier par"
                          className="flex items-center cursor-pointer"
                        >
                          <span className="w-full">
                            {column.render('Header')}
                          </span>
                          <span className="inline-block text-xl">
                            <SortIndicator column={column} />
                          </span>
                        </div>
                        {column.canFilter && showFilters && (
                          <div className="mt-2">{column.render('Filter')}</div>
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()} className="hover:bg-gray-100">
                      {row.cells.map((cell, i) => {
                        if (i === 0) {
                          return (
                            <th
                              {...cell.getCellProps()}
                              className="flex items-center p-4 px-6 text-xs text-left align-middle border-t-0 border-l-0 border-r-0"
                            >
                              {' '}
                              <span
                                className={`w-full ${
                                  cell.column.className || 'font-bold'
                                }`}
                              >
                                {cell.render('Cell')}
                              </span>
                            </th>
                          )
                        }
                        return (
                          <td
                            {...cell.getCellProps()}
                            className="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0"
                          >
                            <span className={`${cell.column.className}`}>
                              {cell.render('Cell')}
                            </span>
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className="mx-4 mt-4 mb-2 text-right">
            <select
              value={pageSize}
              className="text-sm text-gray-600"
              onChange={(e) => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Afficher {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        setPageSize={setPageSize}
      />
    </div>
  )
}
