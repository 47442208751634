import * as React from 'react'
function SvgComponent(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 100 100"
      fillOpacity=".5"
      opacity=".5"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M72.48 36.066a88.33 88.33 0 01-22.961 3.043h-.004c-13.082 0-21.773-2.969-21.859-3a2.002 2.002 0 00-2.316 3.012l23 34c.375.55.996.879 1.66.879s1.285-.328 1.656-.88l23-34a1.999 1.999 0 00-.027-2.28 2.007 2.007 0 00-2.149-.774z" />
    </svg>
  )
}
export default SvgComponent
