import React from 'react'
import ReactDOM from 'react-dom'
import Modal from './Modal'

// const body = document.querySelector("body")
const alertRoot = document.createElement('div')

export default ({ children, title, medium, tiny, verticalScroll = false }) => {
  return new Promise((resolve) => {
    const close = () => {
      ReactDOM.unmountComponentAtNode(alertRoot)
      document.body.classList.remove('overflow-hidden', 'h-full')
      resolve()
    }

    ReactDOM.render(
      <Modal
        verticalScroll={verticalScroll}
        title={title}
        medium={medium}
        tiny={tiny}
        isOpen={true}
        onClose={() => close(false)}
      >
        <div className="overflow-auto" style={{ maxHeight: '60vh' }}>
          {children}
        </div>
        <div>
          <div className="flex justify-end mt-8">
            <div
              className="ml-2 p-2 px-4 rounded-sm bg-red-500 text-white cursor-pointer"
              onClick={() => close()}
            >
              Ok
            </div>
          </div>
        </div>
      </Modal>,
      alertRoot
    )
  })
}
