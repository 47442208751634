import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import Select from 'components/form/Select'
import { getDeepValue } from 'lib/utils'
import amountAndCurrency from './commonfields/amountAndCurrency'
import { getInputOptsByBlockedField } from 'lib/utils'

export default {
  id: 'FraudChequeOut',
  title: 'Émission chèques',
  // next: "2",
  defaultValues: { FraudChequeOuts: [{}] },
  sections: [
    {
      // title: "Émission chèque",
      rows: [
        [
          {
            name: 'FraudChequeOuts',
            type: 'array',
            label: 'Émission de chèque',
            fields: [
              [
                // Used for update entity by its id
                {
                  hide: true,
                  type: 'hidden',
                  name: 'id',
                },
              ],
              [
                {
                  type: 'yes-no',
                  name: 'blocked',
                  defaultValue: 'false',
                  label: 'Opération déjouée',
                  whenYes: [
                    [
                      {
                        type: 'select',
                        name: 'fraud_detection',
                        label: "Nom de l'outil",
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        placeholder: 'Choisir un moyen',
                        values: [
                          {
                            label: 'CTC',
                            value: 'CTC',
                          },
                          {
                            label: "Réserve d'encaissement",
                            value: "Réserve d'encaissement",
                          },
                          {
                            label: 'Outils internes',
                            value: 'Outils internes',
                          },
                          {
                            label: 'Autres',
                            value: 'Autres',
                          },
                        ],
                      },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'aggregate',
                  type: 'yes-no',
                  defaultValue: 'false',
                  label: 'Agrégat',
                  whenYes: [
                    [
                      {
                        label: "Nombre d'occurrences",
                        name: 'occurences_number',
                        options: (props) =>
                          getInputOptsByBlockedField(props, {
                            required: 'Ce champ est requis',
                          }),
                        type: 'number',
                      },
                      { type: 'blank' },
                    ],
                  ],
                },
              ],
              [
                {
                  //   type: "yes-no",
                  name: 'transaction_id',
                  label: 'Identifiant transaction',
                  helpText: 'Identifiant du paiement du chèque',
                  autofocus: true,
                },
              ],

              amountAndCurrency,
              [
                {
                  type: 'select',
                  name: 'reject_reason',
                  label: 'Motif de rejet',
                  placeholder: 'Choisir un motif',
                  values: [
                    { label: '10-Perte', value: '10-Perte' },
                    { label: '11-Vol', value: '11-Vol' },
                    {
                      label: '12-Utilisation frauduleuse',
                      value: '12-Utilisation frauduleuse',
                    },
                    {
                      label: '13-Décision judiciaire',
                      value: '13-Décision judiciaire',
                    },
                    {
                      label: '20-Chèque prescrit',
                      value: '20-Chèque prescrit',
                    },
                    {
                      label: "30-Absence d'une mention obligatoire",
                      value: "30-Absence d'une mention obligatoire",
                    },
                    {
                      label: '31-Signature non conforme',
                      value: '31-Signature non conforme',
                    },
                    {
                      label: '32-Insuffisance signature',
                      value: '32-Insuffisance signature',
                    },
                    {
                      label: '33-Falsification Surcharge',
                      value: '33-Falsification Surcharge',
                    },
                    {
                      label: '34-Faux chèques',
                      value: '34-Faux chèques',
                    },
                    {
                      label: "35-Absence ou irrégularité de l'endos",
                      value: "35-Absence ou irrégularité de l'endos",
                    },
                    {
                      label: '40-Décision judiciaire',
                      value: '40-Décision judiciaire',
                    },
                    {
                      label: '41-Saisie attribution ou conservatoire',
                      value: '41-Saisie attribution ou conservatoire',
                    },
                    {
                      label: '42-Avis à tiers détenteur',
                      value: '42-Avis à tiers détenteur',
                    },
                    {
                      label: '43-Décès du titulaire',
                      value: '43-Décès du titulaire',
                    },
                    {
                      label:
                        '44-Dénonciation de convention de compte collectif',
                      value:
                        '44-Dénonciation de convention de compte collectif',
                    },
                    {
                      label: '45-Motif réglementaire',
                      value: '45-Motif réglementaire',
                    },
                    {
                      label: '60-Chèque impayé pour le montant total',
                      value: '60-Chèque impayé pour le montant total',
                    },
                    {
                      label: '61-Chèque impayé pour le montant  partiel',
                      value: '61-Chèque impayé pour le montant  partiel',
                    },
                    {
                      label: '62-Compte clôturé',
                      value: '62-Compte clôturé',
                    },
                    {
                      label: 'Rejet hors délai',
                      value: 'Rejet hors délai',
                    },
                  ],
                },

                {
                  type: 'custom',
                  name: `fraud_type`,
                  label: 'Type de fraude',

                  Component: function FraudType({
                    form,
                    prefix,
                    input,
                    disabled,
                  }) {
                    const reject_reason = form.watch(prefix + 'reject_reason')

                    let selection
                    useEffect(() => {
                      if (
                        [
                          '31-Signature non conforme',
                          'Rejet hors délai',
                          '10-Perte',
                          '11-Vol',
                          '12-Utilisation frauduleuse',
                        ].includes(reject_reason)
                      ) {
                        selection = 'Vol/perte'
                      } else if (
                        ['33-Falsification Surcharge'].includes(reject_reason)
                      ) {
                        selection = 'Falsification'
                      } else if (['34-Faux chèques'].includes(reject_reason)) {
                        selection = 'Contrefaçon'
                      } else if (
                        ["35-Absence ou irrégularité de l'endos"].includes(
                          reject_reason
                        )
                      ) {
                        selection = 'Détournement/rejeu'
                      } else {
                        if (reject_reason)
                          selection = 'Hors périmètre collecte BdF'
                      }
                      if (selection)
                        form.setValue(`${prefix}fraud_type`, selection)
                    }, [reject_reason])

                    const inputOptions = input.options({ form, prefix })
                    const inputNetworkError = getDeepValue(
                      form.errors,
                      prefix + input.name
                    )

                    return (
                      <Controller
                        name={prefix + input.name}
                        control={form.control}
                        rules={inputOptions}
                        render={({ onChange, value, defaultValue, name }) => {
                          return (
                            <Select
                              name={name}
                              options={inputOptions}
                              defaultValue={defaultValue}
                              value={value}
                              disabled={disabled}
                              error={
                                inputNetworkError && inputNetworkError.message
                              }
                              values={[
                                {
                                  value: 'Vol/perte',
                                  label: 'Vol/perte',
                                },
                                {
                                  value: 'Contrefaçon',
                                  label: 'Contrefaçon',
                                },
                                {
                                  value: 'Falsification',
                                  label: 'Falsification',
                                },
                                {
                                  value: 'Détournement/rejeu',
                                  label: 'Détournement/rejeu',
                                },
                                {
                                  value: 'Hors périmètre collecte BdF',
                                  label: 'Hors périmètre collecte BdF',
                                },
                              ]}
                              onChange={(e) => {
                                onChange(e && e.value)
                              }}
                              label={input.label}
                            />
                          )
                        }}
                      />
                    )
                  },
                  options: (props) => ({
                    required: 'Ce champ est requis',
                  }),
                  autofocus: true,
                },
              ],

              [
                {
                  name: 'cheque_num',
                  label: 'Numéro de chèque',
                },
                {
                  type: 'select',
                  name: 'cheque_type',
                  label: 'Type de chèque',
                  values: [
                    {
                      label: 'Chèque de banque',
                      value: 'Chèque de banque',
                    },
                    {
                      label: 'Chèque classique',
                      value: 'Chèque classique',
                    },
                  ],
                },
              ],
              [
                {
                  name: 'bank_name',
                  label: 'Nom de la banque remettante',
                },
              ],

              [
                {
                  type: 'yes-no',
                  name: 'opposition',
                  label: 'Opposition de chèque',
                  whenYes: [
                    [
                      {
                        type: 'date',
                        name: 'date_opposition',
                        label: "Date d'opposition du chèque",
                      },
                    ],
                  ],
                },
              ],

              [
                {
                  type: 'select',
                  name: 'status',
                  label: "Statut de l'operation",
                  values: [
                    {
                      label: "En cours d'analyse",
                      value: "En cours d'analyse",
                    },
                    {
                      label: 'Frauduleux',
                      value: 'Frauduleux',
                    },
                    {
                      label: 'Non frauduleux',
                      value: 'Non frauduleux',
                    },
                  ],
                },
              ],
              [
                {
                  type: 'date',
                  name: 'execution_date',
                  label: "Date d'exécution",
                  options: (props) =>
                    getInputOptsByBlockedField(props, {
                      required: 'Ce champ est requis',
                    }),
                },
                { type: 'blank' },
              ],
            ],
          },
        ],
      ],
    },
  ],
}
