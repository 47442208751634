import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import FormRow from 'components/form/FormRow'
import Spinner from 'reactjs-simple-spinner'

export default function DefaultForm({
  entity,
  formdefinitions,
  onSubmit,
  onError,
  onCancel,
  btnSave = 'Enregistrer',
  loading = false,
}) {
  const form = useForm({ defaultValues: formdefinitions.defaultValues })
  const { reset } = form

  useEffect(() => {
    reset(entity)
  }, [reset, entity])

  return (
    <div
      className="relative w-full p-6 mx-auto mt-0 bg-white rounded-sm shadow-lg form-container"
      style={{ overflow: 'visible' }}
    >
      <form onSubmit={form.handleSubmit(onSubmit, onError)} className="w-full">
        <div>
          {formdefinitions.sections.map(({ title, rows }, sidx) => (
            <React.Fragment key={`section-${sidx}`}>
              {title && (
                <div key={`section-${sidx}-title`} className="section-header">
                  {title}
                </div>
              )}
              {rows &&
                rows.map((row, ridx) => (
                  <FormRow
                    key={`section-${sidx}-${ridx}`}
                    inputs={row}
                    idx={`$${sidx}-${ridx}`}
                    form={form}
                  />
                ))}
            </React.Fragment>
          ))}
        </div>

        <div className="flex justify-end mt-8">
          <button
            type="submit"
            className={`${
              loading ? 'bg-opacity-50' : ''
            } p-2 px-4 ml-2 text-white rounded-sm cursor-pointer bg-accent`}
            disabled={ loading }
          >
            {loading ? <Spinner size={24} lineFgColor="#AC0477" /> : btnSave}
          </button>
        </div>
      </form>
    </div>
  )
}
