import React, { useState, useCallback, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import useFrauds from 'hooks/useFrauds'
import { useFraudStore } from 'stores/fraud'
import Modal from 'components/common/Modal'

export default function ChangedScenarioModal({
  isOpen,
  scenarioRef = {},
  newScenario = {},
  reference,
  close,
}) {
  const history = useHistory()

  const fraudStore = useFraudStore((f) => f)
  const fraud = fraudStore.getFraud()

  const [action, setAction] = useState(null)
  const { updateFraud } = useFrauds()

  const doAction = useCallback(async () => {
    if (action === 'nothing') {
      close()
    } else if (action === 'set-scenario') {
      await updateFraud({
        ref: reference,
        ScenarioRefId: newScenario.id,
        ScenarioId: newScenario.id,
        FraudTypeId: newScenario.FraudType.id,
        ProcessId: newScenario.FraudType.Process.id,
      })
      // const form = getForm('fraud', 'fraud-tracking')
      fraudStore.setFraud({
        ...fraud,
        ScenarioId: newScenario.id,
        FraudTypeId: newScenario.FraudType.id,
        ProcessId: newScenario.FraudType.Process.id,
      })
      close()
    } else if (action === 'set-scenario-manually') {
      close()
      history.push(`/fraudes/${reference}/fraud-tracking`)
    }
  }, [action, fraud, newScenario, reference, history])

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }, 1000)
  }, [])

  return (
    <>
      {isOpen && (
        <Modal
          medium
          title={'Le scénario calculé a changé.'}
          isOpen={isOpen}
          onClose={close}
        >
          <p className="w-full pt-4">
            <span className="inline-block">
              Ancien scénario de référence :{' '}
            </span>
            <br />
            <span className="font-bold">{scenarioRef.name}</span>
          </p>
          <p className="w-full pt-4">
            <span className="inline-block">Nouveau scénario calculé : </span>
            <br />
            <span className="font-bold">{newScenario.name}</span>
          </p>

          <p className="py-4">Que souhaitez-vous faire ?</p>

          <ul>
            <li>
              <label>
                <input
                  type="radio"
                  name="action"
                  onClick={() => {
                    setAction('nothing')
                  }}
                />
                <span className="pl-2">Ne rien faire</span>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="action"
                  onClick={() => {
                    setAction('set-scenario')
                  }}
                />
                <span className="pl-2">Enregistrer le nouveau scénario</span>
              </label>
            </li>
            <li>
              <label>
                <input
                  type="radio"
                  name="action"
                  onClick={() => {
                    setAction('set-scenario-manually')
                  }}
                />
                <span className="pl-2">Saisir le scénario manuellement</span>
              </label>
            </li>
          </ul>
          <div className="flex justify-center pt-4">
            <button
              className="p-2 px-4 ml-2 text-white bg-red-500 rounded-sm cursor-pointer"
              onClick={() => doAction(true)}
            >
              Enregistrer
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}
