import React, { useState, useEffect } from 'react'

import { useNotify } from 'stores/notify'
import {  useHistory } from 'react-router-dom'

// import UserIcon from 'components/svg/user'
import useUsers from 'hooks/useUsers'
// import Layout from 'components/Layout'
// import UsersForm from 'components/users/Form'
import { useForm } from 'react-hook-form'
import Register from 'components/Register'

const Form = ({ id }) => {
  const [user, setUser] = useState(null)
  const [errorsOnSubmit, setErrorsOnSubmit] = useState(false)

  const { getUser, upsertAccreditation, isAdmin } = useUsers()
  const form = useForm()
  const history = useHistory()

  const { setErrorMessage, setSuccessMessage } = useNotify(
    ({ setErrorMessage, setSuccessMessage }) => ({
      setErrorMessage,
      setSuccessMessage,
    })
  )

  useEffect(() => {
    if (id) {
      getUser(id).then((req) => {
        if (req.results) {
          const currentUser = req.results

          if (currentUser.BusinessLines && currentUser.BusinessLines.length) {
            currentUser.BusinessLineId = currentUser.BusinessLines[0].id

            if (currentUser.BusinessLines[0].UserBusinessLine) {
              currentUser.role =
                currentUser.BusinessLines[0].UserBusinessLine.role
            }
          }
          setUser(currentUser)
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (user) {
      form.reset(user)
    }
  }, [user])

  const onError = (errors) => {
    setErrorsOnSubmit("Tous les champs marqués d'une étoile * sont requis.")
  }

  const onSubmit = async (entity) => {
    setErrorsOnSubmit(false)
    const userPayload = {
      ...user,
      ...entity,
    }

    const req = await upsertAccreditation(userPayload)
    
    if (req.results) {
      setSuccessMessage('Fiche utilisateur enregistrée')
      history.push('/utilisateurs')
    } else {
      if (isAdmin() && req.error.statusCode === 404) {
        setErrorMessage("Nom de domaine non pris en charge")
      } else {
        setErrorMessage(req.error.message)
      }
    }
  }

  const onCancel = () => {
    history.push('/utilisateurs')
  }
  return (
    <form
      className={`form-container mx-auto mt-4 w-full relative rounded-sm bg-white p-6 shadow-lg`}
    >
      <Register form={form} isAdmin={isAdmin()}  />
      <div className="flex justify-end w-full mt-8">
        <div
          className="px-4 py-2 text-white bg-gray-700 rounded-sm cursor-pointer"
          onClick={onCancel}
        >
          Annuler
        </div>
        <div
          className="p-2 px-4 ml-2 text-white rounded-sm cursor-pointer bg-accent"
          onClick={form.handleSubmit(onSubmit, onError)}
        >
          Sauver
        </div>
      </div>
      {errorsOnSubmit && (
        <div className="mt-2 text-sm text-right text-red-500">
          {errorsOnSubmit}
        </div>
      )}
    </form>
  )
}

export default Form
