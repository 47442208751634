import * as React from "react"

function SvgComponent(props) {
    return (
        <svg
            width="1em"
            height="1em"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M40 32.332h20v5H40zM40 43.332h20v5H40z" />
            <path d="M71.332 55H71l-1 1V27.332c0-4-3.332-7.332-7.668-7.332h-25c-4 0-7.332 3.332-7.332 7.332V56l-1-1c-1.668 0-3.332 1-5 2-2.332 2.332-4 5.668-4 9v6c0 5.668 5.668 8 8.668 8h43C75 80 80 77.668 80 72v-6c0-5.332-4.332-11-8.668-11zm-.664 14.332c-.332 1-1.668 2-2.668 2-.332 0-.668 0-1.332-.332-1-.332-2-1.668-2-2.668 0-1.332.668-2.332 2-2.668.332 0 .668-.332 1.332-.332.668 0 .668 0 1.332.332 1 .332 2 1.668 2 2.668-.332.336-.332.668-.664 1zm-14.668 2c-.332 0-.668 0-1.332-.332-.668-.332-1.332-1-1.668-1.668 0-.332-.332-.668-.332-1.332 0-1.668 1.332-3 3-3 .332 0 .668 0 1.332.332.332 0 .668.332 1 .668.332.332.332.668.668 1 0 .332.332.668.332 1.332 0 1.336-1.332 3-3 3zm-21-44C35 26 36 25 37.668 25h25c1.332 0 2.668 1 2.668 2.332V56h-30l-.004-28.668z" />
        </svg>
    )
}

export default SvgComponent
