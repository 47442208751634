import React, { useState, useEffect } from 'react'

import { useForm } from 'react-hook-form'
import { useUser } from 'stores/user'
import { useRedirect } from 'stores/redirect'
import { useNotify } from 'stores/notify'
import confirm from 'components/common/ConfirmModal'
import useUsers from 'hooks/useUsers'
import LoggedOutHeader from 'components/common/LoggedOutHeader'
import Register from 'components/Register'
import { USER_ROLE } from 'lib/constants'

export default function RegisterPage(props) {
  const params = new URL(document.location).searchParams

  const [finishMessage, setFinishMessage] = useState(null)
  const [userToValidate, setUserToValidate] = useState({})

  const {
    acceptAccreditation,
    checkRegistrationToken,
    declineAccreditation,
    getAllMyBusinessLines,
    isReferent,
    isAdmin,
  } = useUsers()
  const user = useUser(({ token }) => ({ token }))

  const form = useForm()

  const [businessLines, setBusinessLines] = useState([])

  const { setErrorMessage } = useNotify(({ setErrorMessage }) => ({
    setErrorMessage,
  }))
  const { setRedirect } = useRedirect(({ setRedirect }) => ({
    setRedirect,
  }))

  if (!user.token && window.location.href.indexOf('accreditation') > -1) {
    setRedirect(window.location.href)
    props.history.replace('/login')
  }

  // Check user access.
  // Need have Référent role.
  useEffect(() => {
    if (!user || !user.token) {
      setErrorMessage(
        "Vous devez vous identifier pour consulter cette demande d'accréditation"
      )
      props.history.replace('/')
      return
    } else if (!isReferent() || isAdmin()) {
      setErrorMessage(
        "Vous n'avez pas les droits pour accepter une demande d'accréditation"
      )
      props.history.replace('/')
      return
    }
  }, [user, isReferent, isAdmin])

  useEffect(() => {
    const asyncGetBusinessLines = async () => {
      const req = await getAllMyBusinessLines()
      const res = req.results.map((bl) => ({
        ...bl,
        label: bl.name,
        value: bl.id,
      }))
      setBusinessLines(res)
    }

    asyncGetBusinessLines()
  }, [])

  const token = params.get('token') // is the string "Jonathan Smith".

  // Call API to check token.
  useEffect(() => {
    async function getUserByToken() {
      try {
        const userByTokenResult = await checkRegistrationToken(token)

        if (userByTokenResult) {
          const userByToken = userByTokenResult.results

          if (userByToken.BusinessLines && userByToken.BusinessLines.length) {
            userByToken.BusinessLineId = userByToken.BusinessLines[0].id

            if (userByToken.BusinessLines[0].UserBusinessLine) {
              userByToken.role =
                userByToken.BusinessLines[0].UserBusinessLine.role
            }
          }

          setUserToValidate(userByToken)
        } else {
          setErrorMessage(
            `L'utilisateur n'a pas été trouvé avec ce token ${token}`
          )
        }
      } catch (err) {
        // setErrorMessage(err.message)
      }
    }

    getUserByToken()
  }, [token])

  useEffect(() => {
    form.reset(userToValidate)
  }, [userToValidate])

  const declineAccreditationSubmit = async () => {
    if (await confirm('Êtes-vous sûr de refuser la demande ?')) {
      try {
        await declineAccreditation(token)
        setFinishMessage(
          'La demande a été refusée, la personne concernée recevra un mail de notification.'
        )
      } catch (err) {
        setErrorMessage(err)
      }
    }
  }

  // const { register, handleSubmit, errors } = useForm()
  const acceptAccreditationSubmit = async (data) => {
    if (await confirm('Êtes-vous sûr de confirmer la demande ?')) {
      try {
        await acceptAccreditation({ token, ...data })
        setFinishMessage(
          'La demande a été acceptée, la personne concernée recevra un mail avec un lien de connexion unique.'
        )
      } catch (err) {
        setErrorMessage(err.message)
      }
    }
  }

  return (
    <div>
      <LoggedOutHeader />
      <div className="flex items-center justify-center align-middle">
        <div className="flex flex-col w-1/2 px-8 pt-6 pb-8 mb-4 ">
          {finishMessage && (
            <div className="w-full">
              <div className="flex flex-col w-1/2 w-full px-8 pt-6 pb-8 mb-4 bg-white rounded shadow-md">
                {finishMessage}
              </div>
            </div>
          )}
          {!finishMessage && (
            <form
              onSubmit={form.handleSubmit(acceptAccreditationSubmit)}
              className="w-full form-container"
            >
              <div className="flex flex-col items-center justify-between">
                <p className="mb-4">
                  Accepter la demande a pour effet de générer un lien de
                  connexion à la personne faisant la demande d'accréditation.
                  Cette personne pourra ainsi se connecter au panneau
                  d'administration.
                </p>
              </div>

              <Register form={form} isValidateAccessPage businessLines={businessLines} />
              <div className="flex items-center justify-center w-full">
                <button
                  className="px-4 py-3 text-white rounded bg-accent"
                  type="submit"
                >
                  Accepter la demande
                </button>
                <button
                  className="px-4 py-3 ml-4 text-white bg-gray-500 rounded"
                  onClick={() => declineAccreditationSubmit()}
                  type="button"
                >
                  Refuser la demande
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}
