import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useWatch } from 'react-hook-form'
import { useAsyncDebounce } from 'react-table'

import { getInputOptsByBlockedField, getDeepValue } from 'lib/utils'
import currencies, { getCurrenciesRate } from 'lib/currencies'
import { useUser } from 'stores/user'
import forms from '..'

export const AmountAndCurrencyWithProp = (
  prop = false,
  getInputOptions = getInputOptsByBlockedField
) => [
  {
    type: 'number',
    name: prop ? `${prop}.amount` : 'amount',
    label: 'Montant',
    options: (props) =>
      getInputOptions(props, {
        required: 'Ce champ est requis',
        min: {
          value: 0,
          message: 'Le montant doit être positif',
        },
      }),
    // options: {
    //   required: 'Ce champ est obligatoire',
    //     min: {
    //     value: 0, message:"Le montant doit être positif"
    //     }
    //   }
  },
  {
    type: 'select',
    name: prop ? `${prop}.currency` : 'currency',
    label: 'Devise',
    values: currencies,
    isClearable: false,
    className: 'w-36',
    defaultValue: 'EUR',
    // options: { required: 'Ce champ est requis' },
    options: (props) =>
      getInputOptions(props, {
        required: 'Ce champ est requis',
      }),
  },

  {
    type: 'custom',
    Component: ({ form, prefix }) => {
      if (prop && !prefix) {
        prefix = `${prop}.`
      }

      const getCurrenciesRateDebounced = useAsyncDebounce(
        getCurrenciesRate,
        750
      )
      const consolidatedCurrencyName = `${prefix}consolidated_currency`
      const executionDateName = `${prefix}execution_date`
      const amountName = `${prefix}amount`
      const currencyName = `${prefix}currency`

      const watchedFields = useWatch({
        control: form.control,
        name: [
          executionDateName,
          amountName,
          currencyName,
          consolidatedCurrencyName,
        ],
      })

      const executionDate = watchedFields[executionDateName]
      const amount = watchedFields[amountName]
      const currency = watchedFields[currencyName] || 'EUR'
      const consolidatedCurrency = watchedFields[consolidatedCurrencyName]

      const token = useUser(({ token }) => token)

      useEffect(() => {
        async function setConsolidatedCurrency() {
          const currencyRes = await getCurrenciesRateDebounced(
            token,
            currency,
            dayjs(executionDate).format('YYYY-MM-DD')
          )

          if (
            currencyRes &&
            currencyRes.results &&
            currencyRes.results.rates[currency]
          ) {
            const consolidated_currency_calculated = (
              (amount * currencyRes.results.rates['EUR']) /
              currencyRes.results.rates[currency]
            ).toFixed(2)

            form.setValue(
              consolidatedCurrencyName,
              consolidated_currency_calculated
            )
          }
        }

        if (
          amount > 0 &&
          currency &&
          currency === 'EUR' &&
          consolidatedCurrency != amount
        ) {
          form.setValue(consolidatedCurrencyName, amount)
        }
        if (executionDate && amount > 0 && currency && currency !== 'EUR') {
          setConsolidatedCurrency()
        }
        if (!amount || !currency) {
          form.setValue(consolidatedCurrencyName, 0)
        }
      }, [
        executionDate,
        amount,
        currency,
        consolidatedCurrencyName,
        form,
        token,
      ])

      const options = getInputOptions(
        { form, prefix },
        {
          required: 'Ce champ est requis',
        }
      )

      const consolidatedCurrencyError = getDeepValue(
        form.errors,
        consolidatedCurrencyName
      )

      return (
        <label className={`${options.required ? 'required' : ''}`}>
          <span>
            <span>Devise consolidée </span>

            {currency && currency !== 'EUR' && (
              <span className="relative group">
                <span
                  className="relative text-black bg-gray-200 rounded-full"
                  style={{
                    width: '16px',
                    height: '16px',
                    display: 'inline-block',
                    fontSize: '1em',
                    lineHeight: '16px',
                    textAlign: 'center',
                  }}
                >
                  ?
                </span>
                <div className="absolute z-10 flex flex-col hidden p-3 whitespace-no-wrap bg-white shadow-lg transform translate-x-8 -translate-y-8 group-hover:block">
                  Aide à la conversion sur :{' '}
                  <a
                    href="https://www.ecb.europa.eu/stats/policy_and_exchange_rates/euro_reference_exchange_rates/html/index.en.html"
                    target="_blank"
                    style={{ color: 'blue', textDecoration: 'underline' }}
                  >
                    bce.fr
                  </a>
                </div>
              </span>
            )}
          </span>
          <div className="flex items-center">
            {!currency ||
              (currency === 'EUR' && (
                <div
                  className="w-full px-0 pb-1 border-0 border-b border-input-border"
                  style={{ paddingTop: '.6rem' }}
                >
                  <>
                    {consolidatedCurrency || '0'}
                    <input
                      ref={form.register(options)}
                      type="text"
                      id={consolidatedCurrencyName}
                      name={consolidatedCurrencyName}
                      className="hidden"
                      defaultValue={consolidatedCurrency || 0}
                    />
                    <div>
                      {consolidatedCurrencyError && (
                        <span className="help-text error">
                          {consolidatedCurrencyError}
                        </span>
                      )}
                    </div>
                  </>
                </div>
              ))}

            {currency && currency !== 'EUR' && (
              <input
                ref={form.register}
                type="text"
                id={consolidatedCurrencyName}
                name={consolidatedCurrencyName}
                defaultValue={consolidatedCurrency || 0}
              />
            )}

            <span>€</span>
          </div>
        </label>
      )
    },
  },
]

export default AmountAndCurrencyWithProp()
