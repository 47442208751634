import * as React from "react"

function SvgComponent(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 100 100" {...props}>
      <path d="M99.973 37.395c0-2.578-2.098-4.68-4.672-4.68H67.274V4.68A4.681 4.681 0 0062.602 0H4.7A4.681 4.681 0 00.028 4.68v57.93A4.681 4.681 0 004.7 67.29h28.027v28.035a4.681 4.681 0 004.672 4.68h57.902c2.574 0 4.672-2.102 4.672-4.68zm-67.25 0v26.332l-29.145-.004V3.563h60.141v29.152h-26.32a4.682 4.682 0 00-4.676 4.68zm63.695 59.043H36.277v-60.16h60.141z" />
    </svg>
  )
}

export default SvgComponent
