import React from "react"

import Layout from "components/Layout"
import UsersList from "components/users/List"

const UsersListPage = ({ location }) => {
  return (
    <Layout location={location}>
      <UsersList />
    </Layout>
  )
}

export default UsersListPage
