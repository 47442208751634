import React from 'react'
import CustomerSelect from 'components/common/customer/CustomerSelect'

import BusinessLineSelect from 'components/common/business-line/BusinessLineSelect'

export default {
  id: 'customer',
  title: 'Informations client',
  // next: "2",
  defaultValues: { customer_declaration: 'true' },
  sections: [
    {
      title: null,
      rows: [
        [
          {
            type: 'hidden',
            name: 'id',
          },
        ],
        [
          {
            name: 'BusinessLineId',
            label: 'Établissement',
            isClearable: false,
            type: 'custom',
            options: { required: 'Ce champ est requis' },
            className: 'w-1/2',
            Component: BusinessLineSelect,
          },
        ],
        [
          {
            name: 'CustomerId',
            type: 'custom',
            label: 'Client',
            Component: (props) => {
              return (
                <CustomerSelect
                  {...props}
                  BusinessLineId={props.form.watch('BusinessLineId')}
                />
              )
            },
          },
        ],
        [
          {
            type: 'yes-no',
            name: 'customer_declaration',
            label: "Est-ce que le client est à l'origine de la détection ?",
            // helpText:
            //   "Est-ce que le client est à l'origine de la déclaration ?",
          },
        ],
        [
          {
            type: 'select',
            name: 'customer_behavior',
            label: 'Comportement client',
            placeholder: 'Choisir un comportement',
            values: [
              {
                label: 'Manipulé',
                value: 'Manipulé',
              },
              {
                label: 'Suspect',
                value: 'Suspect',
              },
              {
                label: 'Usurpé',
                value: 'Usurpé',
              },
            ],
            when: {
              Manipulé: [
                [
                {
                  label: "Cas d'escroquerie",
                  name: 'scam_case',
                  type: 'select',
                  options: { required: 'Ce champ est requis' },
                  values: [
                    {
                      value: "Cas d'ingénierie sociale",
                      label: "Cas d'ingénierie sociale",
                    },
                    {
                      value:
                        "Paiement en échange d'un produit ou service inexistant",
                      label:
                        "Paiement en échange d'un produit ou service inexistant",
                    },
                  ],
                },
              ],
              ],
            },
          },
        ],
      ],
    },
  ],
}
