import React from 'react'
import useFraudTypes from 'hooks/useFraudTypes'

import Conditions from './Conditions'

export default function FormDef() {

  const { getFraudTypes } = useFraudTypes()

  return {
    id: 'scenario',
    title: 'Scénario',
    sections: [
      {
        title: null,
        rows: [
          [
            // Used for update entity by its id
            {
              hide: true,
              type: 'hidden',
              name: 'id',
            },
          ],
          [
            {
              name: 'name',
              label: 'Nom',
            },
          ],
          [
            {
              type: 'async-select',
              name: 'FraudTypeId',
              className: 'w-full',
              label: 'Type de fraude',
              formatOptionLabel: (value) => {
                return (
                  <>
                    <span
                      className="text-accent mt-1 border-solid border-accent border float-left px-2 lowercase align-middle rounded-full opacity-75"
                      style={{ fontSize: '11px' }}
                    >
                      {value.Process.name}
                    </span>
                    <span className="font-bold mr-2 ml-2">{value.name}</span>
                  </>
                )
                return <span>?</span>
              },
              getList: getFraudTypes,
            },
            { type: 'number', name: 'priority', label: 'Priorité' },
          ],
        ],
      },
      {
        title: 'Conditions',
        rows: [
          [
            {
              type: 'custom',
              Component: () => {
                return (
                  <div className="pt-2 pb-8">
                    <p>
                      Vous pouvez créer des conditions pour définir votre
                      scénario.
                    </p>
                    <p>
                      Chaque ligne est une succession de OU : c'est à dire qu'il
                      faut au moins une valeur de vrai pour valider la ligne.
                    </p>
                    <p>
                      Pour que le scénario soient choisi, il faut que toutes les
                      lignes soient vrai.
                    </p>
                  </div>
                )
              },
            },
          ],
          [
            {
              type: 'custom',
              Component: Conditions,
            },
          ],
        ],
      },
    ],
  }
}
