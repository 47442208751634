import React, { useState, useEffect } from 'react'
import Select from 'components/form/Select'
import { Controller } from 'react-hook-form'

export default function FraudTypeSelect({
  form,
  name,
  placeholder,
  label,
  getList,
  className,
  isMulti,
  options,
  helpText,
  defaultValue,
  formatOptionLabel,
  isClearable = true,
  error,
}) {
  const [entities, setEntities] = useState([])

  useEffect(() => {
    const asyncGetEntities = async () => {
      const req = await getList()
      const res = req.results.map((bl) => ({
        ...bl,
        label: bl.name,
        value: bl.id,
      }))
      setEntities(res)

      if (!isClearable && res && res.length > 0) {
        form.setValue(name, res[0].id)
      }
    }

    asyncGetEntities()
  }, [])

  return (
    <Controller
      name={name}
      rules={options}
      control={form.control}
      render={({ onChange, value, name }) => {
        return (
          <Select
            isClearable={isClearable}
            defaultValue={defaultValue}
            formatOptionLabel={formatOptionLabel}
            placeholder={placeholder}
            name={name}
            value={value}
            isMulti={isMulti}
            onChange={(e) => {
               onChange((e && e.value) || (Array.isArray(e) && e.map(t => t.value)) || null)
            }}
            className={className}
            values={entities}
            options={options}
            error={error}
            helpText={helpText}
            label={label}
          />
        )
      }}
    />
  )
}
