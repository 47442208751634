import React, { useCallback, useState, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useUser } from 'stores/user'
import useUsers from 'hooks/useUsers'
import { USER_ROLE } from 'lib/constants'

const IfAdmin = ({ children }) => {
  const { isAdmin } = useUsers()

  if (isAdmin()) {
    return children
  }
  return null
}
const IfDeclarant = ({ children }) => {
  const { isDeclarant } = useUsers()

  if (isDeclarant()) {
    return children
  }
  return null
}

const IfReferent = ({ children }) => {
  const { isAdmin, isReferent } = useUsers()

  if (isAdmin() || isReferent()) {
    return children
  }
  return null
}

const Navigation = (props) => {
  const { location } = props
  const { pathname } = location
  const user = useUser((user) => user)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const [showScenarioMenu, setShowScenarioMenu] = useState(false)
  const [showMenus, setShowMenus] = useState({})
  // const [businessLine, setBusinessLine] = useState(false)
  const role = useMemo(() => {
    if (user && user.BusinessLines && user.BusinessLines.length > 0) {
      return user.BusinessLines[0].UserBusinessLine.role
    }
  }, [user])

  const businessLine = useMemo(() => {
    if (user && user.BusinessLines && user.BusinessLines.length > 0) {
      return user.BusinessLines[0].name
    }
  }, [user])

  const logout = useCallback(() => {
    user.logout()
    window.location.href = '/login'
  }, [user])

  const toggleMenu = useCallback(
    (menu) => {
      const isOpen = !showMenus[menu]
      const newShowMenus = { ...showMenus }

      if (isOpen) {
        Object.keys(newShowMenus).forEach((key) => {
          newShowMenus[key] = false
        })
      }
      newShowMenus[menu] = isOpen
      setShowMenus(newShowMenus)
    },
    [setShowMenus, showMenus]
  )

  const getNameInitials = (user) => {
    return `${user.firstname[0]}${user.lastname[0]}`
  }

  return (
    <nav className="navigation">
      <div className="flex items-center menu-left">
        <Link to="/fraudes">
          <img
            src="/logo-bpce.png"
            alt="logo min bpce"
            className="logo"
            style={{ width: '200px' }}
          />
        </Link>
        <span>|</span>
        <span className="ml-4 font-bold text-brand">FREGAT</span>
        {/* <input type="text" placeholder="Rechercher une fraude" /> */}
      </div>
      <div className={`menu-right`}>
        <IfAdmin user={user}>
          <div className="relative">
            <Link to="#" onClick={() => toggleMenu('scenario')}>
              <span className="uppercase">Scénarios</span>
              <svg
                className="inline h-3 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </Link>
            {showMenus['scenario'] && (
              <ul className="absolute top-0 z-10 mt-10 text-left bg-white shadow ">
                <li>
                  <Link to="/processus">Processus</Link>
                </li>
                <li>
                  <Link to="/type-fraudes">Types de fraude</Link>
                </li>
                <li>
                  <Link to="/scenarios">Scenarios</Link>
                </li>
              </ul>
            )}
          </div>
        </IfAdmin>
        <IfReferent user={user}>
          <Link to="/utilisateurs">Utilisateurs</Link>
        </IfReferent>
        <Link to="/clients">Clients</Link>
        <Link to="/fraudes">Fraudes</Link>
        {role !== USER_ROLE.DECLARANT && <Link to="/exports">Exports</Link>}

        <IfDeclarant>
          <div>
            <Link to="#" onClick={() => toggleMenu('import')}>
              <span>Imports</span>
              <svg
                className="inline h-3 fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </Link>
            {showMenus['import'] && (
              <ul className="absolute top-0 z-10 mt-10 text-left bg-white shadow ">
                <li>
                  <Link to="/imports">Importer un fichier</Link>
                </li>
                <li>
                  <Link to="/imports-logging">Journalistation</Link>
                </li>
              </ul>
            )}
          </div>
        </IfDeclarant>
        {/* <Link to="/dashboard"> */}
        {/*   Reporting */}
        {/* </Link> */}

        <IfDeclarant role={role}>
          <Link to="/fraudes/new/customer" className="text-white bg-accent ">
            <button
              className={` ${
                pathname.indexOf('/fraudes/new') > -1 ? 'active' : ''
              }`}
            >
              + nouvelle fraude
            </button>
          </Link>
        </IfDeclarant>

        <div className="relative">
          <Link to="#" onClick={() => toggleMenu('help')}>
            <span className="text-base">?</span>
            <svg
              className="inline h-3 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </Link>
          {showMenus['help'] && (
            <ul
              className="absolute top-0 z-20 mt-10 text-left bg-white shadow "
              style={{ right: '-60px', fontSize: '0.75rem' }}
            >
              <li>
                <Link
                  to="/Guide-utilisateur-FREGAT_Connexion_V3.pdf"
                  target="_blank"
                  title="Guide de connexion"
                >
                  Guide de connexion
                </Link>
              </li>
              <li>
                <Link
                  to="/Guide-utilisateur-FREGAT-navigation-et-declaration.pdf"
                  target="_blank"
                  title="Guide de navigation"
                >
                  Guide de navigation
                </Link>
              </li>
              <li>
                <Link
                  to="/Bonnes-pratiques-012021.pdf"
                  target="_blank"
                  title="Bonnes pratiques de saisie"
                >
                  Bonnes pratiques de saisie
                </Link>
              </li>
              <li>
                <Link
                  to="/Guide_de_remplissage_fraude.3_2022_v2.pdf"
                  target="_blank"
                  title="Bonnes pratiques de saisie"
                >
                  Guide de remplissage BDF
                </Link>
              </li>
              <li>
                <Link
                  to="/Annuaire Correspondants Fraude.xlsx"
                  target="_blank"
                  title="Annuaire Correspondants Fraude"
                >
                  Annuaire Correspondants Fraude
                </Link>
              </li>
              <li>
                <Link
                  to="/Import_Template_V07_Remplissage.xlsx"
                  target="_blank"
                  title="Template d'import"
                >
                  Template d'import
                </Link>
              </li>
            </ul>
          )}
        </div>

        <div className="mr-8">
          <Link
            to="#"
            onClick={() => toggleMenu('user-menu')}
            className="user-avatar"
          >
            <span className="uppercase">{getNameInitials(user)}</span>
            <svg
              className="inline h-3 fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
            {/* <img src="svg/arrow-down.svg" /> */}
          </Link>
          {showMenus['user-menu'] && (
            <ul className={`user-menu`}>
              {/* <li> */}
              {/*   <Link to="#"> */}
              {/*     <i className="fa fa-user fa-fw"></i> Profile */}
              {/*   </Link> */}
              {/* </li> */}
              {/* <div className="border border-gray-800"></div> */}
              <li className="text-xs">
                <span className="text-sm font-bold">
                  {user.firstname} {user.lastname}
                </span>
                {role && <span>, {role}</span>}
              </li>
              {businessLine && <li className="text-xs ">{businessLine}</li>}
              <li className="flex items-center">
                <Link
                  href="#"
                  onClick={() => logout()}
                  className="flex items-center"
                >
                  <span className="block pt-1 text-center">
                    <i className="fas fa-sign-out-alt fa-fw"></i> Déconnexion
                  </span>
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </nav>
  )
}

export default Navigation
