import React from 'react'
import Layout from 'components/Layout'
import ProcessesForm from 'components/processes/Form'

const ProcessesNewPage = ({ location }) => {
  return (
    <Layout location={location}>
      <ProcessesForm />
    </Layout>
  )
}

export default ProcessesNewPage
